import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Popover, Spinner, Table } from 'react-bootstrap';
import Moment from 'react-moment';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useUserProvider } from '../../../providers/AllUsersProvider';
import { useTaskStatusProvider } from '../../../providers/TaskStatusProvider';
import { SwitchLinkDiv } from '../GlobalComponents/SwitchLinkDiv';

export function TasksList(props) {

    const [tasks, setTasks] = useState([]);

    const userProvider = useUserProvider();
    const taskStatusProvider = useTaskStatusProvider();

    const currentUser = useSelector((state) => state.auth.user, shallowEqual);


    const renderUsers = (persons, id) => {
        
        const p = userProvider.getUsersByIds(persons);
        const hasMe = persons.includes(currentUser.id);

        if (p.length <= 0)
            return <div className="btn btn-light btn-icon position-relative">
                <i className='la la-users icon-2x'></i>
                <span className="badge badge-light badge-count-mini position-absolute right-0 mr-n2 mt-n2">0</span>
            </div>;


        return <OverlayTrigger
            key={id}
            overlay={
                <Popover>
                    <div className="p-4">
                        
                        {p.map((person, index) => {
                            return <div key={person.id} className={`${person.id === currentUser.id && "font-size-lg font-weight-bold"} p-1`}>{person.firstname} {person.lastname}</div>
                        })}
                    </div>
                </Popover>
            }
        >

            <div className={`btn ${hasMe ? "btn-light-success" : "btn-light-primary"} btn-icon position-relative`}>
                <i className='la la-users icon-2x'></i>
                <span className="badge badge-primary badge-count-mini position-absolute right-0 mr-n2 mt-n2">{p.length}</span>
            </div>
        </OverlayTrigger>
    }

    const renderTaskStatus = (id) => {
        if (id === null)
            return <div className="status-item bg-light font-size-md rounded-md p-3">kein Status</div>;

        const tStatus = taskStatusProvider.getTaskStatusById(id);
        const style = { backgroundColor: `#${tStatus.color}`, color: `#${tStatus.fontcolor}` };
        return <div className="status-item font-size-md rounded-md p-3" style={style} >{tStatus.name}</div>;

    };
    const renderBullet = (id) => {
        if (id === null)
            return <span className="bullet bullet-bar bg-light align-self-stretch"></span>;
        const tStatus = taskStatusProvider.getTaskStatusById(id);
        return <span className="bullet bullet-bar align-self-stretch" style={{ backgroundColor: `#${tStatus.color}` }}></span>
    };
    const renderCommentButton = (item) => {
        return <div className="comments">
            <div className={`btn btn-light-primary ${item.updatesCount > 0 ? 'btn-light-primary' : 'btn-light'} btn-icon position-relative`}>
                <i className='la la-comments-o icon-2x'></i>
                <span className={`badge ${item.updatesCount > 0 ? 'badge-primary' : 'badge-light'} badge-count-mini position-absolute right-0 mr-n2 mt-n2`}>{item.updatesCount}</span>
            </div>
        </div>;
    }

    const renderTasks = () => {
        return tasks.map((item, index) => {
            return <SwitchLinkDiv isLink={!props.bulkEdit} to={`task/${item.id}`} className="d-block p-5 border-bottom bg-hover-light-o-20" key={item.id}>
                <div className="row min-h-50px">
                    {!props.bulkEdit && renderBullet((item.status ? item.status : null))}
                    <div className='col-12 col-lg-4 font-weight-bold font-size-lg py-1'>
                        <div className="d-flex">
                            <div className={`mt-n1 ml-n2 mr-4 ${props.bulkEdit ? 'd-block' : 'd-none'}`}>
                                <label className="checkbox checkbox-lg checkbox-lg checkbox-default zindex-4">
                                    <input type="checkbox" name="permissions" value={item.id} onChange={props.handleBulkCheck} checked={props.bulkChecked.length > 0 && props.bulkChecked.includes(item.id)} />
                                    <span className="bg-default border-primary"></span>
                                </label>
                            </div>
                            <div className="title flex-grow-1 pr-2">
                                {item.name}
                            </div>
                            {renderCommentButton(item)}
                        </div>


                    </div>
                    <div className='col-10 col-lg-2 mt-3 mt-lg-0 font-weight-bold font-size-lg py-1 text-lg-center'>
                        {item.from && <>
                            <Moment format="DD.MM.YYYY">{item.from}</Moment>
                        </>}
                        {item.to && <>
                            &nbsp;-&nbsp;<Moment format="DD.MM.YYYY">{item.to}</Moment>
                        </>}

                    </div>
                    <div className='col-2 col-lg-1 text-right text-lg-center py-1'>
                        {renderUsers((item.persons ? item.persons : []), item.id)}
                    </div>
                    <div className='col-12 col-lg-3'>
                        {item.data?.info && <>
                            <div className="info-item py-1 info-item-info d-flex">
                                <div className="icon mr-2">
                                    <i className="la la-info-circle icon-lg"></i>
                                </div>
                                <div className="text">
                                    {item.data.info}
                                </div>
                            </div>

                        </>}
                        {item.data?.external && <>
                            <div className="info-item py-1 info-item-external d-flex">
                                <div className="icon mr-2">
                                    <i className="la la-sign-out-alt icon-lg"></i>
                                </div>
                                <div className="text">
                                    {item.data.external}
                                </div>
                            </div>

                        </>}
                    </div>

                    <div className='col text-center py-1'>
                        {renderTaskStatus((item.status ? item.status : null))}
                    </div>
                </div>
            </SwitchLinkDiv>
        });

    }

    useEffect(() => {
        if (userProvider.allUsers.length > 0)
            setTasks(props.tasks);
    }, [props.tasks, userProvider.allUsers]);

    return <>
        {props.isLoading ?
            <div className={`d-flex flex-column align-items-center justify-content-center h-full mt-12 mb-4`}><Spinner animation="border" variant="primary" /></div>
            :
            <>{tasks.length > 0 ?
                <>
                    <div className="d-none d-lg-block bg-dark-o-10 mb-0 rounded-md font-weight-bold font-size-lg py-2 px-5">
                        <div className="row">

                            <div className="col-4">
                                Name
                            </div>
                            <div className="col-2 text-center">
                                Zeitraum
                            </div>
                            <div className="col-1 text-center">
                                Personen
                            </div>
                            <div className="col-3">
                                Quick Infos
                            </div>
                            <div className="col  text-center">
                                Status
                            </div>

                        </div>

                    </div>
                    {renderTasks()}
                </>
                : <div className="alert bg-light-info mt-10">Bislang keine Tasks vorhanden.</div>
            }
            </>
        }
    </>
}