import React, { useEffect, useState } from "react";
import axios from "axios";

export function DashboardGeneralAlerts(props) {
    const [zammadstatus, setZammadStatus] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const getZammadStatus = () => {
        return axios.create().get(process.env.REACT_APP_ZAMMAD_URL + '/api/v1/monitoring/health_check?token=eJEqsmdB_F1ZbDpeblXpVbnYrJvBAYmEXkqiVwYL_TFA2kSkDIYL9A').then(({ data }) => {
            setZammadStatus(data);
            setIsLoaded(true);
        }).catch(e => {
            setIsLoaded(true);
            setZammadStatus({});
        });
    }
    
    useEffect(() => {
        if(!isLoaded) {
            getZammadStatus()
        }
    }, []);

    useEffect(() => {
        //console.log(zammadstatus);
    }, [zammadstatus]);

    return <>
        {isLoaded &&
            <>{(!zammadstatus.healthy) && <div className="card card-custom bg-danger text-white gutter-b font-size-h5-sm">
                <div className="card-body p-4 px-6">
                    <i className="fas fa-exclamation-triangle text-white mr-4"></i>
                    ACHTUNG: Das Monitoring vom Ticket-System meldet Fehler oder ist nicht online!
                </div>
            </div>}</>
        }
    </>
}