import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Spinner, Table, Tooltip } from "react-bootstrap";
import { useUserProvider } from "../../../../../providers/AllUsersProvider";
import { useSubheader } from "../../../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import AdminUserModal from "./partials/AdminUserModal";
import { usePageTitle } from "../../../../../providers/PageTitleProvider";

export function AdminUsers(props) {

    const subheader = useSubheader();
    const pageTitle = usePageTitle();
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const userProvider = useUserProvider();
    const [allUsers, setAllUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [editId, setEditId] = useState(0);
    const [formTypeDelete, setFormTypeDelete] = useState(false);

    const departments = [
        'nicht definiert',
        'Chef',
        'Marketing / Handling',
        'Online - Marketing',
        'Grafik',
        'Programmierung',
        'Textierung',
        'Web-Crossing',
    ]

    const renderUsers = () => {
        return users.map((user, i) => <div key={i} className="d-block p-5 border-bottom bg-hover-light-o-20">
            <div className="row">
                <div className="col-1 text-center py-2 align-self-center"><i className={`la ${user.deleted_at == null ? 'la-check-circle text-success' : 'la-times-circle text-danger'} icon-lg`}></i></div>
                <div className="col-lg-2 py-2 align-self-center">{user.name}</div>
                <div className="col-lg-3 py-2 align-self-center">{user.email}</div>
                <div className="col-lg-2 py-2 align-self-center">{departments[user.department]}</div>
                <div className="col-lg-3 py-2 align-self-center">
                    {user.access_roles.length > 0 ? (user.access_roles[0].name !== 'super' ? user.access_roles[0].desc : user.access_roles[1].desc) : (<span className="text-danger">keine Gruppe</span>)}
                </div>
                <div className="col-lg-1 py-2 align-self-center">
                    <div className="d-flex d-print-none justify-content-end flex-nowrap">

                        <Button variant="btn btn-icon btn-clean btn-hover-light-primary btn-sm mt-n2 mr-2"
                            onClick={() => { setEditId(user.id); setShowModal(true) }}
                        ><i className="la la-pencil icon-md text-primary"></i></Button>
                        <Button variant="btn btn-icon btn-clean btn-hover-light-danger btn-sm mt-n2"
                            onClick={() => { setEditId(user.id); setShowModal(true); setFormTypeDelete(true) }}
                        ><i className={`la  ${user.deleted_at != null ? 'la-check-circle text-success' : 'la-times-circle text-danger'} icon-md`}></i></Button>

                    </div>
                </div>
            </div>
        </div>);
    };

    const handleSearch = () => {
        var filtered = allUsers;
        if (searchTerm !== '' && searchTerm.length > 2) {
            filtered = allUsers.filter(t => {
                return t.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    t.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    t.email.toLowerCase().includes(searchTerm.toLowerCase());
            });

            filtered.sort((a, b) => a.firstname.localeCompare(b.firstname));
        }
        setUsers(filtered);
    }

    const modalClose = () => {
        setShowModal(false);
        setEditId(0);
        setTimeout(() => setFormTypeDelete(false), 300);
    }
    const modalSubmit = () => {
        setShowModal(false);
        userProvider.fetchUsers();
        setEditId(0);
        setTimeout(() => setFormTypeDelete(false), 300);
    }

    const loadUsers = () => {
        if (userProvider.allUsers.length <= 0) {
            userProvider.fetchUsers();
            return;
        }
        setAllUsers(userProvider.allUsers);
        setUsers(userProvider.allUsers);
        setIsLoading(false);
    }

    useEffect(() => {
        loadUsers();
    }, [userProvider])

    useEffect(() => {
        subheader.setTitle("Benutzer Konten");
        pageTitle.setPageTitle("🐮 Benutzer Konten");
        loadUsers();
    }, [subheader]);

    useEffect(() => {
        handleSearch();
    }, [searchTerm]);

    return <>
        <div className="row">
            <div className="col-12">
                <div className={`card card-custom `}>
                    <div className="card-header border-1">
                        <h3 className="card-title text-dark">
                            Benutzer
                        </h3>
                        <div className="card-toolbar">
                            {/*<Link to="/stempeluhr" className="nav-link btn btn-icon btn-clean btn-hover-light-primary btn-sm">
                            <span className="svg-icon svg-icon-sm">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/Text/Bullet-list.svg"
                                    )}
                                />
                            </span>
                                    </Link>*/}
                        </div>
                    </div>
                    <div className="card-body">
                        <div className={`${isLoading ? 'd-flex' : 'd-none'} flex-column align-items-center justify-content-center h-full mt-4 mb-4`}><Spinner animation="border" variant="primary" /></div>
                        <div className={`${isLoading ? 'd-none' : 'd-block'}`}>

                            <div className="d-flex mt-0 mb-6 d-print-none">

                                <div className="input-icon h-40px flex-grow-1">
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid h-40px"
                                        placeholder="Suchen ..."
                                        id="hostsSearch"
                                        autoComplete="false"
                                        //autoFocus={true}
                                        onKeyUp={(e) => setSearchTerm(e.currentTarget.value)}

                                    />
                                    <span>
                                        <span className="svg-icon svg-icon-lg">
                                            <SVG
                                                src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                                            />
                                        </span>
                                    </span>
                                </div>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="latest-project">Benutzer erstellen</Tooltip>
                                    }
                                >
                                    <div
                                        onClick={() => setShowModal(true)}
                                        className={`btn btn-icon btn-default btn-hover-primary ml-3 h-40px w-40px flex-shrink-0`}
                                    >
                                        <span className="svg-icon svg-icon-lg">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Code/Plus.svg"
                                                )}
                                            />
                                        </span>
                                    </div>
                                </OverlayTrigger>
                            </div>


                            {allUsers.length > 0 && <>
                                <div className="bg-dark-o-10 mb-0 rounded-md font-weight-bold font-size-lg py-2 px-5">
                                    <div className="row">

                                        <div className="col-1 text-center">
                                            Aktiviert
                                        </div>
                                        <div className="col-2">
                                            Name
                                        </div>
                                        <div className="col-3">
                                            E-Mail
                                        </div>
                                        <div className="col-2">
                                            Abteilung
                                        </div>
                                        <div className="col-3">
                                            Gruppenrichtlinie
                                        </div>
                                        <div className="col-1">
                                        </div>

                                    </div>

                                </div>
                                {renderUsers()}
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AdminUserModal type={formTypeDelete ? 'delete' : 'edit'} edit={editId} show={showModal} onHide={modalClose} onSubmit={() => modalSubmit()} />

    </>
}