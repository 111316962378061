import React, { useEffect, useState, useRef } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import axios from "axios";
import { useUserProvider } from "../../../../providers/AllUsersProvider";
import { toast } from 'react-toastify';
import { TenorSearch } from "../../Tenor/TenorSearch";

import {TaskUpdateEditor} from "ckeditor5-stadl-editor";

export function TaskUpdateForm(props) {

    const [task, setTask] = useState('');
    const [lUsers, setLUsers] = useState([]);
    const [updateContent, setUpdateContent] = useState("");
    const [canSubmit, setCanSubmit] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const editorRef = useRef();
    const gifPop = useRef();

    const userProvider = useUserProvider();

    const submit = () => {
        if(isSubmitting)
            return;
        setIsSubmitting(true);
        if (!canSubmit){
            setIsSubmitting(false);
            return;
        }
        let body = {
            task_id: task.id,
            body: updateContent
        };
        let target = process.env.REACT_APP_API_URL + '/api/tasks_updates';
        if(props.editUpdate)
            target = process.env.REACT_APP_API_URL + '/api/tasks_updates/'+props.editUpdate;

        axios.post(target, body).then((d) => {
            props.setShouldRefetch(true);
            setUpdateContent('');
            setIsSubmitting(false);
        }).catch((e) => {
            toast.error("Es ist ein Fehler aufgetreten. Versuche es erneut.")
            setIsSubmitting(false);
        });
    }
    const getEditUpdate = (id) => {
        axios.get(process.env.REACT_APP_API_URL + '/api/tasks_updates/' + id).then((d) => {
            
            setUpdateContent(d.data.body);
        }).catch((e) => { });
    }

    const getUserFeed = (a) => {
        let useThem = lUsers;
        if (userProvider.allUsers.length === 0)
            return [];
        if (lUsers.length === 0)
            useThem = userProvider.usersWithoutMe;

        const u = useThem.filter(t => {
            if (t.deleted_at !== null)
                return false;
            const name = t.name.toLowerCase().replace(/ /g, '');
            const search = a.toLowerCase().replace(/ /g, '');
            return name.includes(search);
        });
        let r = [];
        if (u.length > 0)
            r = u.map((t) => {
                return { id: `@${t.firstname} ${t.lastname.charAt(0)}.`, userId: t.id, name: t.name }
            })
            if(task.project_id !== null && task.project_id !== 0 && String(task.customer_id).substring(0, 2) !== '0_')
                r.push({ id: `@alle_projekt_member`, userId: 'all_pm', name: 'Alle Projekt Mitglieder' })
            r.push({ id: `@alle_task_member`, userId: 'all_tm', name: 'Alle Task Mitglieder' })
            r.push({ id: `@alle`, userId: 'all', name: 'Wirklich alle (?!)' })
        return r;
    };
    const customItemRenderer = (item) => {
        const itemElement = document.createElement('div');

        itemElement.classList.add('custom-item');
        itemElement.id = `mention-list-item-id-${item.id}`;
        itemElement.textContent = `${item.name} `;

        return itemElement;

    }
    const resetEdit = () => {
        props.setEditUpdate(null);
        setUpdateContent('');
    }
    useEffect(() => {
        setTask(props.task);
    }, [props.task]);

    useEffect(() => {
        if (task.id !== undefined && userProvider.allUsers.length > 0)
            setCanSubmit(true);
    }, [task, userProvider]);

    useEffect(() => {
        if (userProvider.allUsers.length > 0)
            setLUsers(userProvider.usersWithoutMe)
    }, [userProvider]);
    useEffect(() => {
        if (props.editUpdate !== null)
            getEditUpdate(props.editUpdate);
    }, [props.editUpdate]);

    return <>
        {canSubmit && <><CKEditor
            className="h-150px"
            editor={TaskUpdateEditor}
            config={{
                language: 'de',
                width: '10%',
                mention: {
                    feeds: [{
                        marker: '@',
                        feed: getUserFeed,
                        minimumCharacters: 1,
                        itemRenderer: customItemRenderer,

                    }]
                }
            }}
            data={updateContent}
            onReady={editor => {
                editorRef.current = editor;
            }}
            onChange={(event, editor) => {
                //const data = editor.getData();
                setUpdateContent(editor.getData());
                //console.log({ event, editor, data });
            }}
        />


            <div className="mt-4 d-flex justify-content-between">
                <OverlayTrigger
                    key="gifsearch"
                    trigger="click"
                    rootClose
                    overlay={
                        <Popover style={{ maxWidth: 600 }}>
                            <div className="p-4">
                                <TenorSearch onSelect={result => {
                                    editorRef.current.model.change(writer => {
                                        //const imageUtils = editorRef.current.plugins.get( 'ImageUtils' );
                                        //imageUtils.insertImage( { src: result.media[0].gif.url, class: 'img-fluid' } );
                                        let img = editorRef.current.execute('insertImage', {
                                            source: [
                                                { src: result.media[0].gif.url, class: 'img-fluid' }
                                            ]
                                        });

                                    });
                                }} />

                            </div>
                        </Popover>
                    }
                >
                    <Button variant="light-primary"><i className="la la-image"></i>GIF</Button>
                </OverlayTrigger>
                <div className="formActionButtons">
                    {props.editUpdate !== null && <Button variant="default mr-4" onClick={() => resetEdit()}>Abbrechen</Button>}
                    <Button variant="primary" onClick={submit}>{!isSubmitting ? "Speichern" : <Spinner animation="border" variant="white" size="sm" className="mb-1"/>}</Button>
                </div>

            </div>
        </>
        }
    </>;
}


//LM9OHH3W6E6D
// https://uppy.io/docs/react/