import React, { useEffect, useState } from "react";
import generator from "generate-password";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button, Form, Table } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";

function GenerateCard(props) {


    return <div className="card card-custom card-stretch gutter-b">
        <div className="card-header border-0">
            <h3 className="card-title font-weight-bolder text-dark">Typ {props.type}</h3>
        </div>
        <div className="card-body pt-0 font-size-lg">
            <Table responsive variant="lg">
                <thead>
                    <tr>
                        <td style={{ width: '40%' }}>Domain</td>
                        <td style={{ width: '40%' }}>Value</td>
                        <td style={{ width: '10%' }}>TTL</td>
                        <td style={{ width: '10%' }}>Prio</td>
                    </tr>
                </thead>
                <tbody>
                    {props.data.map((item, key) => {
                        return <tr key={key}>
                            <td>
                                <div className="d-flex">
                                    {(item.name !== undefined && item.name !== "" && item.name !== null) && <>
                                        <CopyToClipboard className="d-print-none cursor-pointer" text={item.name}>
                                            <div>
                                                <div className="btn btn-hover-light-primary btn-icon btn-sm mt-n2 ml-n2">
                                                    <i><i className="la la-clipboard"></i></i>
                                                </div>
                                            </div>
                                        </CopyToClipboard>
                                        <div className="pl-2">{item.name}</div>
                                    </>}
                                </div>
                            </td>
                            <td>
                                <div className="d-flex">
                                    {(item.value !== undefined && item.value !== "" && item.value !== null) && <>
                                        <CopyToClipboard className="d-print-none cursor-pointer" text={item.value}>
                                            <div>
                                                <div className="btn btn-hover-light-primary btn-icon btn-sm mt-n2 ml-n2">
                                                    <i><i className="la la-clipboard"></i></i>
                                                </div>
                                            </div>
                                        </CopyToClipboard>
                                        <div className="pl-2">{item.value}</div>
                                    </>}
                                </div>
                            </td>
                            <td>{item.ttl}</td>
                            <td>{item.prio}</td>
                        </tr>
                    })}
                </tbody>
            </Table>

        </div>
    </div>
}


export function TechnikDnsCheck(props) {
    const [data, setData] = useState([]);
    const formik = useFormik({
        initialValues: {
            domain: ''
        },
        validationSchema: Yup.object().shape({
            domain: Yup.string().required().matches(/([a-z0-9|-]+\.)*[a-z0-9|-]+\.[a-z]+/, 'Muss eine gültige Domain sein!'),
        }),
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {

            setSubmitting(false);

            axios.post(process.env.REACT_APP_API_URL + '/api/dnscheck', values).then(d => {
                setData(d.data);
            }).catch(e => {
                toast.error("Die Domain konnte nicht geprüft werden.", { autoClose: 5500 });
                setData([]);
            });
            setSubmitting(true);
        },
    });

    return <>

        <div className="row">
            <div className="col-12 mb-8">
                <div className="card">
                    <div className="card-body">
                        <Form onSubmit={formik.handleSubmit}>
                            <div className="d-flex">
                                <div className="searchInput flex-grow-1 pr-6">

                                    <Form.Control
                                        type="text"
                                        placeholder="Domain eingeben"
                                        name="domain"
                                        value={''}
                                        {...formik.getFieldProps("domain")}
                                        isInvalid={formik.errors.domain}
                                    />
                                </div>
                                <Button variant="primary" type="submit">Records abfragen</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
        {Object.keys(data).length > 0 && (
            <div className="row">
                {Object.keys(data).map((key) => {
                    let item = data[key];
                    return <div className={key === 'TXT' ? 'col-lg-12' : 'col-lg-6'}><GenerateCard key={key} type={key} data={item} /></div>
                })}
            </div>
        )}
    </>
}
