import React, { useEffect, useState } from "react";
import { useHtmlClassService, useSubheader } from "../../../../_metronic/layout";
import axios from "axios";
import moment from 'moment';
import Select from 'react-select'


import { shallowEqual, useSelector } from "react-redux";
import Moment from "react-moment";
import { useAsideSecondary } from "../../../../_metronic/layout/_core/MpAsideSecondary";
import { useParams } from "react-router-dom";
import { useUserProvider } from "../../../../providers/AllUsersProvider";
import { usePageTitle } from "../../../../providers/PageTitleProvider";

export function StempeluhrUrlaube() {
    const user = useSelector((state) => state.auth.user, shallowEqual);

    const subheader = useSubheader();
    const MpAsideSecondary = useAsideSecondary();

    const pageTitle = usePageTitle();

    const uiService = useHtmlClassService();
    const [selectUserConfig, setSelectUserConfig] = useState({});
    const [selectedUser, setSelectedUser] = useState({});
    const [vacationData, setVacationData] = useState([]);

    const { urlUser } = useParams();

    const userProvider = useUserProvider();

    const fetchVacations = () => {
        let u = null;
        if (userProvider.can('adminStempel'))
            u = selectedUser.value;

        axios.post(process.env.REACT_APP_API_URL + '/api/stempeltimevacations', { user: u }).then(({ data }) => {
            setVacationData(data);
        }).catch(e => console.log(e));
    };

    const fetchAllUsers = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/users').then(({ data }) => {
            mapUsersForSelect(data);
        }).catch(e => console.log(e));
    };

    const mapUsersForSelect = (data) => {
        let options = data.map((item, i) => {
            return { label: item.name, value: item.id };
        });
        setSelectUserConfig(options);
    };

    const generateVacationCards = () => {
        if (vacationData === null && vacationData.length <= 0)
            return;
        return vacationData.map((yearItem, i) => {
            return <div className="col-lg-4 col-xl-4 mb-8" key={i}>
                <div className="card card-custom card-stretch gutter-b">
                    <div className="card-header">
                        <h3 className="card-title text-dark">
                            {yearItem.year}
                        </h3>
                        <div className="card-toolbar">
                            {yearItem.sum} {yearItem.sum == 1 ? 'Tag' : 'Tage'} konsumiert
                        </div>

                    </div>
                    <div className="card-body">
                        <div className="timeline timeline-6 mt-3 zindex-0">
                            {yearItem.data.map((monthitem, j) => {
                                return <div className='timeline-item align-items-start' key={j}>
                                    <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                                        {monthitem.monthReadableShort}
                                    </div>
                                    <div className="timeline-badge">
                                        <i className='fa fa-genderless icon-xl text-info'></i>
                                    </div>
                                    <div className="font-weight-mormal font-size-lg timeline-content pl-3">
                                        {monthitem.data?.map((day, k) => {
                                            return <div className="row pb-1" key={k}>
                                                <div className="col-6">
                                                    <Moment format="DD.MM.YYYY">{day.day}</Moment>
                                                </div>
                                                <div className="col-6 text-right">
                                                    {day.vacation} Tag
                                                </div>
                                            </div>
                                        })}

                                    </div>
                                </div>;

                            })}
                        </div>
                    </div>
                </div>
            </div>

        });
    };


    useEffect(() => {
        subheader.setActions(null);
        subheader.setTitle("Urlaube");
        fetchAllUsers();
        fetchVacations();
        setSelectedUser({ label: user.name, value: user.id });
        MpAsideSecondary.setAsideEnabled(true);
        pageTitle.setPageTitle(`🐮 Urlaube`);
    }, [subheader, uiService]);

    useEffect(() => {
        if (urlUser && selectUserConfig.length > 0) {
            let u = selectUserConfig.filter(d => d.value === parseInt(urlUser));
            if (u.length > 0)
                setSelectedUser(u[0])

        }
    }, [selectUserConfig]);

    useEffect(() => {
        fetchVacations();
    }, [selectedUser]);


    return (<>
        {userProvider.can('adminStempel') &&
            <div className="row">
                <div className="col-12 d-print-none mb-8">
                    <div className="card card-custom">
                        <div className="card-header">
                            <h3 className="card-title text-dark">
                                Benutzer
                            </h3>
                        </div>
                        <div className="card-body">
                            <Select options={selectUserConfig} value={selectedUser} onChange={(o) => {
                                setSelectedUser(o);
                            }} className="mb-2" classNamePrefix="sl2def" />

                        </div>

                    </div>
                </div>
            </div>
        }
        <div className="row">
            {generateVacationCards()}

        </div>
    </>);
}
