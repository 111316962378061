import React, { createContext, useState, useContext } from "react";

const MpContentContainerContext = createContext();

export function useContentContainer() {
  return useContext(MpContentContainerContext);
}

export const MpContentContainerConsumer = MpContentContainerContext.Consumer;

export function MpContentContainerProvider({ children }) {
  const [containerClass, setContainerClass] = useState("container");
  const reset = () => {
    setContainerClass("container");
  }
  const value = { containerClass, setContainerClass, reset};
  return (
    <MpContentContainerContext.Provider value={value}>
      {children}
    </MpContentContainerContext.Provider>
  );
}
