import React from "react";
import {Redirect, Route} from "react-router-dom";
import { useUserProvider } from "../../../../providers/AllUsersProvider";
import {Content} from "./Content";



export function ContentRoute({ children, component, render, ...props }) {
  const userProvider = useUserProvider();
  return (
    <Route {...props}>
      {routeProps => {
        if(props.can !== undefined && !userProvider.can(props.can)) {
          return <Redirect to="/error/403" />;
        }
        if(props.canAny !== undefined && !userProvider.canAny(props.canAny)) {
          return <Redirect to="/error/403" />;
        }
        if(props.canAll !== undefined && !userProvider.canAll(props.canAll)) {
          return <Redirect to="/error/403" />;
        }
        if (typeof children === "function") {
          return <Content>{children(routeProps)}</Content>;
        }

        if (!routeProps.match) {
          return null;
        }

        if (children) {
          return <Content>{children}</Content>;
        }

        if (component) {
          return (
            <Content>{React.createElement(component, routeProps)}</Content>
          );
        }

        if (render) {
          return <Content>{render(routeProps)}</Content>;
        }

        return null;
      }}
    </Route>
  );
}
