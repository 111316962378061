import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Modal, Form, Col, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useUserProvider } from "../../../../providers/AllUsersProvider";
import Select from 'react-select'
import { ProjectCreationTasks } from "./partials/ProjectCreationTasks";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { TaskInfoEditor } from "ckeditor5-stadl-editor";
import axios from "axios";
import { toast } from 'react-toastify';
import { useHistory, useLocation } from "react-router-dom";


export function ProjectEditModal(props) {

    const userProvider = useUserProvider();

    const [selectPersonsConfig, setSelectPersonsConfig] = useState({});
    const [selectedPersons, setSelectedPersons] = useState([]);

    const [selectCustomerConfig, setSelectCustomerConfig] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState({});

    const [description, setDescription] = useState("");
    const descEditorRef = useRef();

    const loc = useLocation();
    const history = useHistory();

    const mapToSelectPersonsConfig = () => {
        let lUsers = userProvider.allUsers.filter((u) => u.deleted_at === null);
        const users = lUsers.map((item) => {
            return { label: item.name, value: item.id };
        });
        setSelectPersonsConfig(users);
    };

    const submitForm = (values) => {
        let body = values;
        body.customer_id = selectedCustomer.value;
        body.description = description;

        return axios.put(process.env.REACT_APP_API_URL + '/api/projects/'+props.project.id, body);
    }

    const initialValues = {
        name: '',
        tasks: ['']
    };
    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
        }),
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            setSubmitting(false);
            submitForm(values).then(({data}) => {
                toast.success("Gespeichert");
                console.log(data.link);
                console.log(loc.pathname);
                if(data.link !== loc.pathname)
                    props.onSubmit();
                //history.push(data.link);

            }).catch(e => {
                toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
                props.onSubmit();
            });
            setSubmitting(true);

        },
    });

    const fetchAllCustomers = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/customers').then(({ data }) => {
            mapCustomersForSelect(data);
        }).catch(e => props.onSubmit());
    };

    useEffect(() => {
        if (userProvider.allUsers.length <= 0)
            return;
        mapToSelectPersonsConfig();
    }, [userProvider.allUsers]);


    const mapCustomersForSelect = (data) => {
        let options = data.map((item, i) => {
            return { label: item.name, value: item.id };
        });
        setSelectCustomerConfig(options);
    };


    useEffect(() => {
        fetchAllCustomers();
        if (props.project !== undefined && props.project.id !== undefined){
            setSelectedCustomer({label: props.project.customer_short.name, value: props.project.customer_short.id});
            formik.setFieldValue('name', (props.project.name == null ? '' : props.project.name));
            
        }
    }, [props.project]);

    return <>
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Projekt bearbeiten
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={formik.handleSubmit} className={`bg-white position-relative`}>
                <Modal.Body>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Name"
                                name="name"
                                value={''}
                                {...formik.getFieldProps("name")}
                                isInvalid={formik.errors.name}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row className="zindex-4 position-relative">
                        <Form.Group as={Col}>
                            <Form.Label>Kunde</Form.Label>
                            <Select options={selectCustomerConfig} value={selectedCustomer} onChange={(o) => {
                                setSelectedCustomer(o);
                            }}
                                classNamePrefix="sl2def"
                                className={Object.keys(selectedCustomer).length <= 0 && 'select2-has-error'}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Projekt Beschreibung</Form.Label>
                            <CKEditor
                                className="h-150px"
                                editor={TaskInfoEditor}
                                config={{
                                    language: 'de'
                                }}
                                data={description}
                                onReady={editor => {
                                    descEditorRef.current = editor;
                                }}
                                onChange={(event, editor) => {
                                    setDescription(editor.getData());
                                }}

                            />
                        </Form.Group>
                    </Form.Row>


                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="primary" type="submit">Speichern</Button>
                    <Button variant="default " onClick={props.onHide}>Abbrechen</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>
}