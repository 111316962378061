import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useUserProvider } from "../../../../providers/AllUsersProvider";


export function ProjectList(props) {

    const userProvider = useUserProvider();
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        if (props.projects === undefined && props.projects.length === 0)
            return;
        setProjects(props.projects);
    }, [props.projects]);

    const renderUsers = (item) => {

        if (item.persons === undefined || item.persons === null || item.persons.length <= 0)
            return null;
        const p = userProvider.getUsersByIds(item.persons);

        if (p === undefined || p.length <= 0)
            return null;
        return <>
            Hauptakteure: {' '}
            {p.map((user, key) => { return <span className="pItem" key={key}>{user?.firstname} {user?.lastname.charAt(0)}.{key < p.length - 1 && ', '}</span> })}
        </>;

    }
    return <>

        {
            projects.map((item, key) => {
                return <div className="projectItem col-lg-6" key={key}>
                    <div className="card card-custom card-stretch gutter-b">
                        <Link to={`projekt/${item.id}/`}>
                            <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between flex-wrap">
                                    <div className="mr-2">
                                        <h3 className="font-weight-bolder">{item.name}</h3>
                                        {/*<div className="text-dark-50 font-size-lg mt-2">Generate the latest CRM Report</div>*/}
                                        <div className="text-dark-50 font-size-sm mt-4">
                                            {renderUsers(item)}
                                        </div>

                                    </div>
                                    <OverlayTrigger
                                        placement="left"
                                        overlay={
                                            <Tooltip id="latest-project">Tasks</Tooltip>
                                        }
                                    >
                                        <div className="btn btn-icon btn-primary ml-3 h-40px w-40px flex-shrink-0">
                                            {item.tasksCount}
                                        </div>
                                    </OverlayTrigger>
                                </div>

                            </div>
                        </Link>
                    </div>
                </div>
            })
        }

    </>
}