import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Popover, Spinner, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Link, useHistory, useParams } from 'react-router-dom'
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import axios from "axios";
import PerfectScrollbar from "react-perfect-scrollbar";
import CustomerInfoModal from "../CustomerInfoModal";
import { useUserProvider } from "../../../../../providers/AllUsersProvider";

export function CustomersMenu(props) {

    const history = useHistory();
    const userProvider = useUserProvider();

    let { id, path, taskpath, projectpath } = useParams();

    const [allCustomers, setAllCustomers] = useState([]);
    const [filteredCustomers, setFilteredCustomers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [showCustomerModal, setShowCustomerModal] = useState(false);

    //const loc = useLocation();
    const defaultLinkClass = "btn bg-dark-o-10 btn-hover-primary mb-6 px-6 py-6 rounded-md d-flex align-items-center text-left";
    const activeLinkClass = "btn btn-primary btn-hover-primary mb-6 px-6 py-6 rounded-md d-flex align-items-center text-left";

    /*const arrayEquals = (a, b) => {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }*/

    const getClass = (r) => {
        if (typeof r === 'string')
            return (r === path) ? activeLinkClass : defaultLinkClass;

        let t = false;
        r.forEach(item => {
            if (item === path)
                t = true;
        });
        return t ? activeLinkClass : defaultLinkClass;
    }


    const fetchAllCustomers = () => {
        setIsLoading(true);
        axios.get(process.env.REACT_APP_API_URL + '/api/customers').then(({ data }) => {
            setAllCustomers(data);
            setFilteredCustomers(data);
            setIsLoading(false);
        }).catch(e => console.log(e));
    };

    const modalClose = () => {
        setShowCustomerModal(false);
    }
    const modalSubmit = (id) => {
        setShowCustomerModal(false);
        history.push('/kunden/' + id + '/tasks');
        fetchAllCustomers();
    }


    const handleSearch = () => {
        var filtered = allCustomers;
        if (searchTerm !== '' && searchTerm.length > 2) {
            filtered = allCustomers.filter(t => {
                return t.name.toLowerCase().includes(searchTerm.toLowerCase());
            });

            filtered.sort((a, b) => a.name.localeCompare(b.name));
        }
        setFilteredCustomers(filtered);
    }

    const renderCustomers = () => {

        return filteredCustomers.map((item, index) => {
            return <div className="px-4 pl-lg-10 pr-lg-7" key={item.id}><div className="linkItem">
                <OverlayTrigger
                    placement="right"
                    overlay={
                        <Popover>
                            <div className="p-4">
                                <div className="d-flex py-1 align-items-center">
                                    <div className="mr-2 d-inline-block">
                                        <i className="la la-list icon-md"></i>
                                    </div>
                                    <div className="flex-grow-1 text text-center">
                                        {item.tasksCount}
                                    </div>
                                </div>

                                <div className="d-flex py-1 align-items-center">
                                    <div className="mr-2 d-inline-block">
                                        <i className="la la-server icon-md"></i>
                                    </div>
                                    <div className="flex-grow-1 text text-center">
                                        {item.hostsCount}
                                    </div>
                                </div>

                                <div className="d-flex py-1 align-items-center">
                                    <div className="mr-2 d-inline-block">
                                        <i className="la la-envelope-o icon-md"></i>
                                    </div>
                                    <div className="flex-grow-1 text text-center">
                                        {item.emailAccountsCount}
                                    </div>
                                </div>

                                <div className="d-flex py-1 align-items-center">
                                    <div className="mr-2 d-inline-block">
                                        <i className="la la-key icon-md"></i>
                                    </div>
                                    <div className="flex-grow-1 text text-center">
                                        {item.miscAccountsCount}
                                    </div>
                                </div>
                            </div>
                        </Popover>
                    }
                >
                    <Link to={`/kunden/${item.id}`} className={getClass('')}>
                        <span className="font-weight-bold font-size-lg ">
                            {item.name}
                        </span>
                    </Link>
                </OverlayTrigger>
            </div></div>
        });
    }

    useEffect(() => {
        fetchAllCustomers();
    }, []);
    useEffect(() => {
        handleSearch();
    }, [searchTerm]);

    const renderSearch = () => {
        return (<div className="vh-100 d-flex flex-column justify-content-start">
            <div className="p-4 px-lg-10 pt-lg-8 pb-lg-10">

                <div className="d-flex">
                    <div className="input-icon h-40px flex-grow-1">
                        <input
                            type="text"
                            className="form-control form-control-lg form-control-solid h-40px"
                            placeholder="Suchen ..."
                            id="generalSearch"
                            autoComplete="false"
                            //autoFocus={true}
                            onKeyUp={(e) => setSearchTerm(e.currentTarget.value)}

                        />
                        <span>
                            <span className="svg-icon svg-icon-lg">
                                <SVG
                                    src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                                />
                            </span>
                        </span>
                    </div>
                    {userProvider.can('createCustomers') &&
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="latest-project">Neuen Kunden erstellen</Tooltip>
                            }
                        >
                            <div
                                onClick={() => setShowCustomerModal(true)}
                                className={`btn btn-icon btn-default btn-hover-primary ml-3 h-40px w-40px flex-shrink-0`}
                            >
                                <span className="svg-icon svg-icon-lg">
                                    <SVG
                                        src={toAbsoluteUrl(
                                            "/media/svg/icons/Code/Plus.svg"
                                        )}
                                    />
                                </span>
                            </div>
                        </OverlayTrigger>
                    }

                </div>
            </div>
            <div className="flex-grow-1 customers-list d-flex flex-column" >
                <div style={{ position: 'relative', height: '100%' }}>
                    <PerfectScrollbar
                        options={{wheelPropagation: false}}
                        className="scroll pr-4"
                        style={{ height: '100%', width: '100%', position: "absolute" }}
                    >

                        {isLoading ? <div className="text-center pt-2 pb-2"><Spinner animation="border" variant="primary" /></div> : renderCustomers()}
                    </PerfectScrollbar>
                </div>
            </div>
            {userProvider.can('createCustomers') &&
                <CustomerInfoModal show={showCustomerModal} onHide={modalClose} onSubmit={modalSubmit} edit={0} />
            }
        </div>)
    }
    //className={getClass('/stempeluhr')}
    const renderSubNav = () => {
        return (
            <div className="p-4 px-lg-10 pt-lg-8">
                <div className="backLink mb-10">
                    <div className="d-flex align-items-center">
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="latest-project">Zurück</Tooltip>
                            }
                        >
                            <Link
                                to="/kunden"
                                className={`btn btn-icon btn-default btn-hover-primary mr-3 h-40px w-40px flex-shrink-0`}
                            >
                                <span className="svg-icon svg-icon-lg">
                                    <SVG
                                        src={toAbsoluteUrl(
                                            "/media/svg/icons/Navigation/Arrow-left.svg"
                                        )}
                                    />
                                </span>
                            </Link>
                        </OverlayTrigger>
                        <div className="font-weight-bold font-size-lg text-center flex-grow-1 pr-9">
                            Kunden Details
                        </div>
                    </div>
                </div>
                <div className="linkItem">
                    <Link to={`/kunden/${id}/tasks`} className={getClass(['tasks', 'task'])}>
                        <span className="svg-icon svg-icon-2x d-block mr-6 mb-1">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Text/Bullet-list.svg")}
                            ></SVG>
                        </span>
                        <span className="font-weight-bold font-size-lg ">
                            Allgemeine Tasks
                        </span>
                    </Link>
                </div>
                <div className="linkItem">
                    <Link to={projectpath === undefined || taskpath === undefined ? `/kunden/${id}/projekte` : `/kunden/${id}/projekt/${projectpath}/`} className={projectpath === undefined || taskpath === undefined ? getClass(['projekte', 'projekt']) : activeLinkClass}>
                        <span className="svg-icon svg-icon-2x d-block mr-6 mb-1">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-grid.svg")}
                            ></SVG>
                        </span>
                        <span className="font-weight-bold font-size-lg ">
                            Projekte
                        </span>
                    </Link>
                </div>
                {userProvider.canAny(['showHosts', 'showHostsData', 'showMisc', 'showEmailAccounts']) &&
                    <div className="linkItem">
                        <Link to={`/kunden/${id}/zugangsdaten`} className={getClass('zugangsdaten')}>
                            <span className="svg-icon svg-icon-2x d-block mr-6 mb-1">
                                <SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Home/Key.svg")}
                                ></SVG>
                            </span>
                            <span className="font-weight-bold font-size-lg ">
                                Zugangsdaten
                            </span>
                        </Link>
                    </div>
                }

                <div className="linkItem">
                    <Link to={`/kunden/${id}/info`} className={getClass('info')}>
                        <span className="svg-icon svg-icon-2x d-block mr-6 mb-1">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                            ></SVG>
                        </span>
                        <span className="font-weight-bold font-size-lg ">
                            Kunden Infos
                        </span>
                    </Link>
                </div>
            </div>
        );
    }

    return (id === undefined ? renderSearch() : renderSubNav());
}