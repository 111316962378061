import { Field, FieldArray, Formik } from "formik";
import React from "react";
import { Button, Form } from "react-bootstrap";

export function TasksFieldArray(props) {
    return <>
        <React.Fragment>
            <FieldArray
                name={props.name}
                render={arrayHelpers => (
                    <div>
                        {props.formik.values !== undefined && props.formik.values[props.name] && props.formik.values[props.name].length > 0 ? (
                            props.formik.values[props.name].map((friend, index) => (
                                <div key={index}>
                                    <div className="d-flex mb-4">
                                        <Field name={`tasks.${index}`} className="form-control" placeholder="Task Titel" />
                                        <div className="actions flex-grow-1 d-flex ml-2">
                                            <Button
                                                variant="light-primary mr-2"
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                            >
                                                -
                                            </Button>
                                            <Button
                                                variant="light-primary"
                                                type="button"
                                                onClick={() => arrayHelpers.insert(index + 1, '')} // insert an empty string at a position
                                            >
                                                +
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <Button variant="primary" type="button" onClick={() => arrayHelpers.push('')}>
                                Task hinzufügen
                            </Button>
                        )}

                    </div>
                )}
            />
        </React.Fragment>
    </>

}
