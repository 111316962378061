import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useAsideSecondary } from "../../../../_metronic/layout/_core/MpAsideSecondary";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { TechnikHosts } from "../components/TechnikHosts";
import { TechnikTools } from "../components/TechnikTools";
import { useSubheader } from "../../../../_metronic/layout";
import { useContentContainer } from "../../../../_metronic/layout/_core/MpContentContainer";
import { useUserProvider } from "../../../../providers/AllUsersProvider";
import { TecExtensionList } from "../../TecExtensions/pages/TecExtensionList";

export function TechnikPage(props) {
    const MpAsideSecondary = useAsideSecondary();
    const subheader = useSubheader();
    const contentContainer = useContentContainer();
    const userProvider = useUserProvider();

    let { path } = useParams();

    useEffect(() => {
        MpAsideSecondary.setAsideEnabled(true);
        subheader.setActions(null);
        contentContainer.reset();
    }, []);

 
    const renderEmpty = () => {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                <div className="text-center">
                    <div className="svg-icon svg-icon-8x">
                        <SVG
                            src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-left.svg")}
                        />
                    </div>
                    <div className="font-size-h6 mt-10">Wähle ein Modul aus.</div>
                </div>
            </div>
        );
    };
    const renderContent = () => {
        switch (path) {
            case 'hosts':
              return userProvider.can('showCustomers') && userProvider.canAny(['showHosts', 'showHostsData']) ? <TechnikHosts /> : <Redirect to="/error/403" />;
            case 'tools':
                return <TechnikTools />
            case 'extensions':
                return <TecExtensionList />
            default:
                return <TechnikHosts />;
        }

    };


    return  <>{renderContent()}</>
    //(path === undefined ? renderEmpty() : renderContent());
}