import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Moment from "react-moment";
import moment from "moment";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import { DatePickerLocale } from "../../../../constants/DatePickerLocale";

export function StempeluhrUrlaubeGeneral(props) {

    const [vacations, setVacations] = useState([]);
    const [pickerDates, setPickerDates] = useState({ start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })
    const pickerRef = useRef();
    const pickerInputRef = useRef();

    const saveVacation = () => {
        if (
            pickerDates.start === undefined || pickerDates.start === null ||
            pickerDates.end === undefined || pickerDates.end === null
        )
            return;
        let body = {
            from: pickerDates.start,
            to: pickerDates.end,
        };
        axios.post(process.env.REACT_APP_API_URL + '/api/stempelvacgeneral', body).then(({ data }) => {
            toast.success("Gespeichert")
            fetchVacations();
        }).catch((e) => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
            fetchVacations();
        });
    }

    const applyPicker = (e, picker) => {
        setPickerDates({ start: picker.startDate.format('YYYY-MM-DD'), end: picker.endDate.format('YYYY-MM-DD') });
    };

    const fetchVacations = () => {

        axios.get(process.env.REACT_APP_API_URL + '/api/stempelvacgeneral').then(({ data }) => {
            setVacations(data);
        }).catch(e => console.log(e));
    }

    const deleteItem = (id) => {
        axios.delete(process.env.REACT_APP_API_URL + '/api/stempelvac/' + id).then(({ data }) => {
            toast.success("Gelöscht")
            fetchVacations();
        }).catch((e) => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
            fetchVacations();
        });
    }

    useEffect(() => {
        fetchVacations();
    }, []);


    return <>
        <div className="row">
            <div className="col-6 mt-10">
                <h4>Betriebsurlaub eintragen</h4>
                <div className="d-flex mt-6">
                    <Form.Group className="flex-grow-1 mb-0">
                        <DateRangePicker
                            ref={pickerRef}
                            initialSettings={{
                                showCustomRangeLabel: false,
                                locale: DatePickerLocale,
                                autoApply: true,
                                autoUpdateInput: true
                            }}
                            onApply={(e, picker) => applyPicker(e, picker)}
                        //onCancel={(e, picker) => clearDates(e, picker)}
                        >
                            <Form.Control size="sm" type="text" onFocus={(e) => e.currentTarget.blur()} style={{ height: "38px" }} ref={pickerInputRef} />
                        </DateRangePicker>
                    </Form.Group>

                    <Form.Group className="flex-shrink-0 mb-0 ml-3">
                        <Button variant="primary" onClick={() => saveVacation()}>Speichern</Button>
                    </Form.Group>
                </div>
            </div>
        </div>

        {vacations.length > 0 && vacations.map((year, yi) => {

            return <div className="row mt-10" key={yi}>
                <div className="col-12 mb-6">
                    <h4>{year.year}</h4>
                </div>
                <div className="col-6">
                    <div className="bg-dark-o-10 mb-4 rounded-md font-weight-bold font-size-lg py-2 px-5">
                        <div className="row">

                            <div className="col-3">
                                Von
                            </div>
                            <div className="col-3">
                                Bis
                            </div>
                            <div className="col-3">
                                Dauer
                            </div>
                            <div className="col-3 text-center">
                                Entfernen
                            </div>

                        </div>
                    </div>
                    {year.data.length > 0 && year.data.map((item, i) => {
                        return <div className="vacItem py-2 px-5" key={i}>
                            <div className="row" >
                                <div className="col-3">
                                    <Moment format="DD.MM.YYYY">{item.from}</Moment>
                                </div>
                                <div className="col-3">
                                    <Moment format="DD.MM.YYYY">{item.to}</Moment>
                                </div>
                                <div className="col-3">
                                    {moment.duration(moment(item.to).diff(moment(item.from))).asDays() + 1} Tag(e)
                                </div>
                                <div className="col-3 text-center">
                                    <Button variant="btn btn-icon btn-clean btn-hover-light-danger btn-sm mt-n2"
                                        onClick={() => { deleteItem(item.id) }}
                                    ><i className="la la-trash icon-md text-danger"></i></Button>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>

        })

        }</>

}