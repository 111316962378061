
import React from "react"
import { useParams } from "react-router-dom";
import { CustomersMenu } from "./partials/CustomersMenu"
export function CustomersMenuRenderer(props) {

    let { id, path, taskpath, projectpath } = useParams();

    const getMenu = () => {
        return <CustomersMenu />
    }

    return getMenu();
}