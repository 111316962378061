import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Modal, Form, Col, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useUserProvider } from "../../../../providers/AllUsersProvider";
import Select from 'react-select'
import { ProjectCreationTasks } from "./partials/ProjectCreationTasks";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { TaskInfoEditor } from "ckeditor5-stadl-editor";
import axios from "axios";
import { toast } from 'react-toastify';


export function ProjectCreationModal(props) {

    const userProvider = useUserProvider();

    const [selectPersonsConfig, setSelectPersonsConfig] = useState({});
    const [selectedPersons, setSelectedPersons] = useState([]);

    const [taskTemplates, setTaskTemplates] = useState([]);
    const [selectTemplateConfig, setSelectTemplateConfig] = useState({});

    const [selectCustomerConfig, setSelectCustomerConfig] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState({});

    const [description, setDescription] = useState("");
    const descEditorRef = useRef();

    const mapToSelectPersonsConfig = () => {
        let lUsers = userProvider.allUsers.filter((u) => u.deleted_at === null);
        const users = lUsers.map((item) => {
            return { label: item.name, value: item.id };
        });
        setSelectPersonsConfig(users);
    };
    const mapToSelectTemplateConfig = () => {
        const templates = taskTemplates.map((item, index) => {
            return { label: item.title, value: index };
        });
        setSelectTemplateConfig(templates);
    };

    const submitForm = (values) => {
        let body = values;
        body.customer_id = selectedCustomer.value;

        let personsArray = null;
        if (selectedPersons !== null) {
            personsArray = selectedPersons.map((person) => person.value);
        }
        body.persons = personsArray
        body.description = description;
        return axios.post(process.env.REACT_APP_API_URL + '/api/projects', body);
    }

    const initialValues = {
        name: '',
        tasks: ['']
    };
    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            tasks: Yup.array(),

        }),
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            setSubmitting(false);
            submitForm(values).then(d => {
                toast.success("Gespeichert");
                props.onSubmit(d.data.id);
            }).catch(e => {
                toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
                props.onSubmit();
            });
            setSubmitting(true);

        },
    });

    const fetchAllCustomers = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/customers').then(({ data }) => {
            mapCustomersForSelect(data);
        }).catch(e => props.onSubmit());
    };

    useEffect(() => {
        if (userProvider.allUsers.length <= 0)
            return;
        mapToSelectPersonsConfig();
    }, [userProvider.allUsers]);

    useEffect(() => {
        if (taskTemplates.length <= 0)
            return;
        mapToSelectTemplateConfig();
    }, [taskTemplates]);

    const mapCustomersForSelect = (data) => {
        let options = data.map((item, i) => {
            return { label: item.name, value: item.id };
        });
        setSelectCustomerConfig(options);
    };

    const getTaskTemplates = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/projecttemplates').then(({ data }) => {
            if (data.length <= 0)
                return;
            let d = data.map((item, key) => {
                return {
                    title: item.name,
                    id: item.id,
                    items: item.data
                }
            });
            console.log(d);
            setTaskTemplates(d);
        }).catch(e => console.log(e));
    }

    useEffect(() => {
        fetchAllCustomers();
        if (props.customer)
            setSelectedCustomer(props.customer);
    }, [props.customer]);

    useEffect(() => {
        if (props.show === true)
            getTaskTemplates();
    }, [props.show]);



    return <>
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Projekt erstellen
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={formik.handleSubmit} className={`bg-white position-relative`}>
                <Modal.Body>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Name"
                                name="name"
                                value={''}
                                {...formik.getFieldProps("name")}
                                isInvalid={formik.errors.name}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row className="zindex-4 position-relative">
                        <Form.Group as={Col}>
                            <Form.Label>Kunde</Form.Label>
                            <Select options={selectCustomerConfig} value={selectedCustomer} onChange={(o) => {
                                setSelectedCustomer(o);
                            }}
                                classNamePrefix="sl2def"
                                className={Object.keys(selectedCustomer).length <= 0 && 'select2-has-error'}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className="zindex-3 position-relative">

                        <Form.Group as={Col}>
                            <Form.Label>Hauptakteure</Form.Label>
                            {userProvider.allUsers.length > 0 ? <div className="statusSelect"><Select options={selectPersonsConfig} value={selectedPersons}
                                classNamePrefix="sl2def"
                                isClearable={false}
                                components={{ DropdownIndicator: null }}
                                //Option: CustomOption, SingleValue: CustomControl,
                                lang="de"
                                noOptionsMessage={() => "Person nicht gefunden"}
                                key="persons"
                                isMulti
                                onChange={(val) => setSelectedPersons(val)}
                                placeholder={<div key="none" className="status-item bg-light cursor-pointer font-size-md rounded-md py-1 px-3 text-center flex-grow-1">Keine Person hinzugefügt</div>}
                            /></div> : null}
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Projekt Beschreibung</Form.Label>
                            <CKEditor
                                className="h-150px"
                                editor={TaskInfoEditor}
                                config={{
                                    language: 'de'
                                }}
                                data={description}
                                onReady={editor => {
                                    descEditorRef.current = editor;
                                }}
                                onChange={(event, editor) => {
                                    setDescription(editor.getData());
                                }}

                            />
                        </Form.Group>
                    </Form.Row>


                </Modal.Body>
                <Modal.Body className="border-top">
                    <div className="row">
                        <div className="col-12 font-size-lg mb-5">
                            Tasks gleich mit erstellen
                        </div>
                    </div>
                    {Object.keys(selectTemplateConfig).length > 0 &&
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Von Vorlage</Form.Label>
                                <div className="statusSelect">
                                    <Select options={selectTemplateConfig}
                                        isClearable={true}
                                        lang="de"
                                        noOptionsMessage={() => "Vorlage nicht gefunden"}
                                        key="status"
                                        placeholder={<div key="none" className="status-item bg-light cursor-pointer font-size-md rounded-md py-1 px-3 text-center flex-grow-1">Vorlage wählen</div>}
                                        classNamePrefix="sl2def"

                                        onChange={(val) => val !== null ? formik.setFieldValue('tasks', taskTemplates[val.value].items) : null}
                                    />
                                </div>
                            </Form.Group>
                        </Form.Row>
                    }

                    <Form.Row>
                        <Form.Group as={Col}>
                            <FormikProvider value={formik}>
                                <Form.Label>Tasks</Form.Label>
                                <ProjectCreationTasks formik={formik} name="tasks" />
                            </FormikProvider>
                        </Form.Group>
                    </Form.Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit">Erstellen</Button>
                    <Button variant="default " onClick={props.onHide}>Abbrechen</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>
}