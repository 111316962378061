import React, { useEffect, useState } from "react";
import { useUserProvider } from "../../../../../providers/AllUsersProvider";
import Select from 'react-select'
import { toast } from "react-toastify"
import axios from "axios";

export function StempeluhrChipsSelect(props) {

    const userProvider = useUserProvider();
    const [selectConfig, setSelectConfig] = useState({});
    const [selected, setSelected] = useState([]);


    const updateChip = (o = null) => {
        let val = null;
        if (o !== null) {
            val = o.value;
        }
        setSelected(o);
        axios.post(process.env.REACT_APP_API_URL + '/api/stempelchips/' + props.chip.id, { user_id: val }).then(({ data }) => {
            props.refetch();
            toast.success("Gespeichert")

        }).catch(e => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
        });
    };

    const mapToSelectConfig = () => {
        let lUsers = userProvider.allUsers.filter((u) => u.deleted_at === null);
        const users = lUsers.map((item) => {
            return { label: item.name, value: item.id };
        });
        setSelectConfig(users);
    };

    useEffect(() => {
        if (props.chip === undefined)
            return;
        mapToSelectConfig();
        if (props.chip.user_id !== undefined && props.chip.user_id !== null) {
            let lUser = userProvider.getUserById(props.chip.user_id);
            if (lUser !== null)
                setSelected({ label: lUser.name, value: lUser.id });
        }
    }, [props.chip, userProvider]);


    return <Select options={selectConfig} value={selected}
        onChange={(o, a) => {
            if (a.action === 'clear') {
                updateChip();
            } else {
                updateChip(o);
            }
        }}
        isClearable={true}
        classNamePrefix="sl2def"
    />


}