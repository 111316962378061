import axios from "axios";
import { reducer } from "./authRedux";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/oauth/token`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}/api/user`;

export const OAUTH_CLIENT_ID = `${process.env.REACT_APP_OAUTH_CLIENT_ID}`;
export const OAUTH_CLIENT_SECRET = `${process.env.REACT_APP_OAUTH_CLIENT_SECRET}`;


function generateLoginBody(email, password) {
  return {
    grant_type: 'password',
    client_id: OAUTH_CLIENT_ID,
    client_secret: OAUTH_CLIENT_SECRET,
    username: email,
    password: password,
    scope: ''
  };
}

function generateJWTHeader(authToken) {
  return {
    headers: {
      'Authorization': 'Bearer ' + authToken
    }
  };
}

export function login(email, password) {
  return axios.post(LOGIN_URL, generateLoginBody(email, password));
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken(authToken) {
  return axios.get(ME_URL, generateJWTHeader(authToken));
}

