import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Form, Col } from "react-bootstrap";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import axios from "axios";
import Select from 'react-select'

export default function TecExtensionModal(props) {

    const [selectCatConfig, setSelectCatConfig] = useState({});
    const [selectedCat, setSelectedCat] = useState({});
    const [selectedEnv, setSelectedEnv] = useState({label: 'Production', value: 'prod'});

    const selectEnvConfig = [
        {label: 'Production', value: 'prod'},
        {label: 'Development', value: 'dev'},
    ];

    const initialValues = {
        name: '',
        desc: '',
        filename: ''
    };
    const saveNew = (values) => {
        let body = values;
        body.cat_id = selectedCat.value;
        body.env = selectedEnv.value;
        return axios.post(process.env.REACT_APP_API_URL + '/api/exts', body);
    };
    const updateExisting = (values) => {
        let body = values;
        body.cat_id = selectedCat.value;
        body.env = selectedEnv.value;
        return axios.put(process.env.REACT_APP_API_URL + '/api/exts/' + props.edit, body);
    };


    const fetchAllCategories = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/extcats').then(({ data }) => {
            mapCustomersForSelect(data);
        }).catch(e => props.onSubmit());
    };
    const fetchEntry = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/exts/' + props.edit).then(({ data }) => {
            formik.setFieldValue('name', (data.name == null ? '' : data.name));
            formik.setFieldValue('desc', (data.desc == null ? '' : data.desc));
            formik.setFieldValue('filename', (data.filename == null ? '' : data.filename));
            if(data.env == null){
                let env = selectEnvConfig.filter(o => o.value = data.env);
                setSelectedEnv(env);
            }
            if(data.cat_id == null){
                let cat = selectCatConfig.filter(o => o.value = data.cat_id);
                setSelectedEnv(cat !== null ? cat : {});
            }

        }).catch(e => props.onSubmit());
    };
    const deleteData = () => {
        axios.delete(process.env.REACT_APP_API_URL + '/api/exts/' + props.edit).then((d) => {
            toast.success("Gelöscht");
            props.onSubmit();
        }).catch(e => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.",{autoClose: 5500});
            props.onSubmit();
        });
    }

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            desc: Yup.string(),
            filename: Yup.string().required(),
        }),
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {

            setSubmitting(false);
            if (props.edit === null) {
                saveNew(values).then(d => { toast.success("Gespeichert"); resetForm(); props.onSubmit() }).catch(e => console.log(e));
            } else {
                updateExisting(values).then(d => { toast.success("Gespeichert"); resetForm(); props.onSubmit() }).catch(e => console.log(e));
            }
            setSubmitting(true);

        },
    });


    const mapCustomersForSelect = (data) => {
        let options = data.map((item, i) => {
            return { label: item.name, value: item.id };
        });
        setSelectCatConfig(options);
    };

    useEffect(() => {
        fetchAllCategories();
        if(props.cat)
            setSelectedCat({label: props.cat.name, value: props.cat.id});
    }, [props.cat]);

    useEffect(() => {
        if (props.edit !== null)
            fetchEntry();
    }, [props.edit]);



    const renderForm = () => {
        return <Form onSubmit={formik.handleSubmit} className={`bg-white position-relative`}>
            <Modal.Body>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Extension Kategorie</Form.Label>
                        <Select options={selectCatConfig} value={selectedCat} onChange={(o) => {
                            setSelectedCat(o);
                        }}
                            classNamePrefix="sl2def"
                            className={Object.keys(selectedCat).length <= 0 && 'select2-has-error'}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Name"
                            name="name"
                            value={''}
                            {...formik.getFieldProps("name")}
                            isInvalid={formik.errors.name}
                        />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Kurz Beschreibung</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Kurz Beschreibung"
                            name="desc"
                            value={''}
                            rows={6}
                            {...formik.getFieldProps("desc")}
                            isInvalid={formik.errors.desc}
                        />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Datei Name (mit Erweiterung!)</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Datei Name"
                            name="filename"
                            value={''}
                            {...formik.getFieldProps("filename")}
                            isInvalid={formik.errors.filename}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Environment Typ</Form.Label>
                        <Select options={selectEnvConfig} value={selectedEnv} onChange={(o) => {
                            setSelectedEnv(o);
                        }}
                            classNamePrefix="sl2def"
                            className={Object.keys(selectedEnv).length <= 0 && 'select2-has-error'}
                        />
                    </Form.Group>
                </Form.Row>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit">Speichern</Button>
                <Button variant="default " onClick={props.onHide}>Schließen</Button>
            </Modal.Footer>
        </Form>
    }

    const deleteForm = () => {
        return <>
            <Modal.Body>
                <p>Bist du dir sicher dass du diese Extension löschen möchtest?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={deleteData}>Ja, Extension löschen</Button>
                <Button variant="default " onClick={props.onHide}>Nein, Extension nicht löschen</Button>
            </Modal.Footer>

        </>
    }

    return <>
        <Modal
            show={props.show}
            size={(props.type && props.type === 'delete') ? ' md' : 'lg'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Extension
                    {
                        (props.type && props.type === 'delete') ? ' löschen' :
                            (props.edit === null) ? ' erstellen' : ' bearbeiten'
                    }
                </Modal.Title>
            </Modal.Header>
            {(props.type && props.type === 'delete') ? deleteForm() : renderForm()}
        </Modal>
        </>

}