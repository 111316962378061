import React, { createContext, useState, useContext, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import axios from "axios";
const PageTitleContext = createContext();

export function usePageTitle() {
  return useContext(PageTitleContext);
}

export const PageTitleConsumer = PageTitleContext.Consumer;

export function PageTitleProvider({ children }) {
  const [title, setTitle] = useState("🐮 Moohooo");

  useEffect(() => {
    document.title = title;
  }, [title]);

  const setPageTitle = (data) => {
    setTitle(data);
  };
  const resetPageTitle = (data) => {
    setTitle("🐮 Moohooo");
  };

  const value = { setPageTitle, resetPageTitle }
  return (
    <PageTitleContext.Provider value={value}>
      {children}
    </PageTitleContext.Provider>
  );
}
