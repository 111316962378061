import { Field, FieldArray, Formik, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Form } from "react-bootstrap";
import * as Yup from "yup";
import { toast } from "react-toastify"
import axios from "axios";
import Select from 'react-select'
import { TasksFieldArray } from "./taskForm/TasksFieldArray";

export function TaskCreationModal(props) {


    const [selectCustomerConfig, setSelectCustomerConfig] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState({})

    const [selectProjectConfig, setSelectProjectConfig] = useState({});
    const [selectedProject, setSelectedProject] = useState({})

    const [taskTemplates, setTaskTemplates] = useState([]);
    const [selectTemplateConfig, setSelectTemplateConfig] = useState({});

    const fetchAllCustomers = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/customers').then(({ data }) => {
            mapCustomersForSelect(data);
        }).catch(e => props.onSubmit());
    };

    const mapCustomersForSelect = (data) => {
        let options = data.map((item, i) => {
            return { label: item.name, value: item.id };
        });
        setSelectCustomerConfig(options);
    };

    const mapToSelectTemplateConfig = () => {
        const templates = taskTemplates.map((item, index) => {
            return { label: item.title, value: index };
        });
        setSelectTemplateConfig(templates);
    };

    const fetchCustomersProjects = () => {
        if (selectedCustomer.value === undefined)
            return;
        axios.get(process.env.REACT_APP_API_URL + '/api/projectsbycustomer/' + selectedCustomer.value).then(({ data }) => {
            // Todo: Set Task Type
            let projectsData = data;
            if (data.length > 0)
                projectsData = data.filter(t => t.archived === 0);

            projectsData.push({ id: 0, name: 'Allgemeine Tasks' });
            projectsData.sort((a, b) => a.name.localeCompare(b.name));
            mapProjectsForSelect(projectsData);
        }).catch(e => console.log(e));

    };

    const getTaskTemplates = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/projecttemplates').then(({ data }) => {
            if (data.length <= 0)
                return;
            let d = data.map((item, key) => {
                return {
                    title: item.name,
                    id: item.id,
                    items: item.data
                }
            });
            setTaskTemplates(d);
        }).catch(e => console.log(e));
    }

    const mapProjectsForSelect = (data) => {
        let options = data.map((item, i) => {
            return { label: item.name, value: item.id };
        });
        setSelectProjectConfig(options);
    };


    const initialValues = {
        tasks: ['']
    };
    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            tasks: Yup.array().min(1).required().test({
                name: 'is-email',
                test: (values) => {
                    let lValues = values.filter((u) => u !== null || u !== undefined || u !== '');
                    return lValues.length > 0;
                }
            }),
        }),
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            setSubmitting(false);
            let body = values;
            if (selectedCustomer.value === undefined || selectedProject === undefined || selectedProject.value === undefined)
                return;
            body.customer_id = selectedCustomer.value;
            body.project_id = selectedProject.value;

            return axios.post(process.env.REACT_APP_API_URL + '/api/tasks', body).then((d) => {
                toast.success("Task(s) erstellt.");
                resetForm();
                props.onSubmit();
                props.onHide();
            }).catch(e => {
                toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
                resetForm();
                props.onSubmit();
                props.onHide();
            });
            setSubmitting(true);

        },
    });
    useEffect(() => {
        fetchAllCustomers();
        if (props.customer) {
            setSelectedCustomer({ label: props.customer.name, value: props.customer.id });
            fetchCustomersProjects();
        }
    }, [props.customer]);

    useEffect(() => {
        if (props.project !== undefined && props.project.id !== undefined)
            setSelectedProject({ value: props.project.id, label: props.project.name });

    }, [props.project]);

    useEffect(() => {
        fetchCustomersProjects();
    }, [selectedCustomer]);

    useEffect(() => {
        if (selectedProject.name === undefined && props.project === undefined)
            setSelectedProject({ value: 0, label: 'Allgemeine Tasks' });
    }, [selectProjectConfig]);

    useEffect(() => {
        if (taskTemplates.length <= 0)
            return;
        mapToSelectTemplateConfig();
    }, [taskTemplates]);
    
    useEffect(() => {
        if (props.show === true)
            getTaskTemplates();
    }, [props.show]);
    return <>
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Tasks erstellen
                </Modal.Title>
            </Modal.Header>


            <Form onSubmit={formik.handleSubmit} className={`bg-white position-relative`}>
                <Modal.Body>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Kunde</Form.Label>
                            <Select options={selectCustomerConfig} value={selectedCustomer} onChange={(o) => {
                                setSelectedCustomer(o);
                                fetchCustomersProjects();
                            }}
                                classNamePrefix="sl2def"
                                className={Object.keys(selectedCustomer).length <= 0 && 'select2-has-error'}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Projekt</Form.Label>
                            {Object.keys(selectProjectConfig).length > 0 ?
                                <Select options={selectProjectConfig} value={selectedProject} onChange={(o) => {
                                    setSelectedProject(o);
                                }}
                                    classNamePrefix="sl2def"
                                    className={Object.keys(selectedProject).length <= 0 && 'select2-has-error'}
                                />
                                :
                                <div className="alert bg-light-info mt-0">Bitte zuerst einen Kunden wählen.</div>
                            }
                        </Form.Group>
                    </Form.Row>

                    {Object.keys(selectTemplateConfig).length > 0 &&
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Von Vorlage</Form.Label>
                                <div className="statusSelect">
                                    <Select options={selectTemplateConfig}
                                        isClearable={true}
                                        lang="de"
                                        noOptionsMessage={() => "Vorlage nicht gefunden"}
                                        key="status"
                                        placeholder={<div key="none" className="status-item bg-light cursor-pointer font-size-md rounded-md py-1 px-3 text-center flex-grow-1">Vorlage wählen</div>}
                                        classNamePrefix="sl2def"

                                        onChange={(val) => val !== null ? formik.setFieldValue('tasks', taskTemplates[val.value].items) : null}
                                    />
                                </div>
                            </Form.Group>
                        </Form.Row>
                    }

                    <Form.Row>
                        <Form.Group as={Col}>
                            <FormikProvider value={formik}>
                                <Form.Label>Tasks</Form.Label>
                                <TasksFieldArray formik={formik} name="tasks" />
                            </FormikProvider>
                        </Form.Group>
                    </Form.Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit">Erstellen</Button>
                    <Button variant="default " onClick={props.onHide}>Abbrechen</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>
}