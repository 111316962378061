import React, { useEffect, useState } from "react";
import { Nav, OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { useUserProvider } from "../../../../providers/AllUsersProvider";
import { DashboardTaskTab } from "./DashboardTaskTab";
import Select from 'react-select'


export function DashboardTasksTabs(props) {

    const [selectUserConfig, setSelectUserConfig] = useState({});
    const [selectedUser, setSelectedUser] = useState({});

    const user = useSelector((state) => state.auth.user, shallowEqual);
    const userProvider = useUserProvider();

    const [key, setKey] = useState('today');


    const mapUsersForSelect = (data) => {
        let options = data.map((item, i) => {
            return { label: item.name, value: item.id };
        });
        setSelectUserConfig(options);
    };

    useEffect(() => {
        if (user.id && selectUserConfig.length > 0) {
            let u = selectUserConfig.filter(d => d.value === parseInt(user.id));
            if (u.length > 0)
                setSelectedUser(u[0])

        }
    }, [selectUserConfig]);
    useEffect(() => {
        if (userProvider.allUsers.length > 0)
            mapUsersForSelect(userProvider.allUsers.filter(d => d.deleted_at === null));
    }, [userProvider.allUsers])


    return <div className={`card card-custom gutter-b`}>
        <Tab.Container defaultActiveKey={key}>
            <div className="card-header">
                <h3 className="card-title flex-shrink-0">
                    Aufgaben Schnellansicht
                </h3>
                <div className="card-toolbar flex-flex-grow-1">

                    <div className="userSelect min-w-200px mr-6 mb-4 mb-md-0">
                        <Select options={selectUserConfig} value={selectedUser} onChange={(o) => {
                            setSelectedUser(o);
                        }} className="mb-0" classNamePrefix="sl2def" />
                    </div>
                    <Nav
                        as="ul"
                        onSelect={_key => setKey(_key)}
                        className="nav nav-pills nav-pills-sm nav-dark-75"
                    >

                        {selectedUser.value === user.id &&

                            <Nav.Item className="nav-item" as="li">
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="latest-project">Von dir gepinnte Tasks</Tooltip>
                                    }
                                >
                                    <Nav.Link
                                        eventKey="pinned"
                                        className={`nav-link py-2 px-4 ${key === "pinned" ? "active" : ""
                                            }`}
                                    >
                                        <i className="la la-star mr-1"></i> Gepinnt
                                    </Nav.Link>
                                </OverlayTrigger>
                            </Nav.Item>
                        }
                        <Nav.Item className="nav-item" as="li">
                            <Nav.Link
                                eventKey="today"
                                className={`nav-link py-2 px-4 ${key === "today" ? "active" : ""
                                    }`}
                            >
                                Heute
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="nav-item" as="li">
                            <Nav.Link
                                eventKey="week"
                                className={`nav-link py-2 px-4 ${key === "week" ? "active" : ""
                                    }`}
                            >
                                Woche
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="nav-item" as="li">
                            <Nav.Link
                                eventKey="month"
                                className={`nav-link py-2 px-4 ${key === "month" ? "active" : ""
                                    }`}
                            >
                                Monat
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="nav-item" as="li">
                            <Nav.Link
                                eventKey="specific"
                                className={`nav-link py-2 px-4 ${key === "specific" ? "active" : ""
                                    }`}
                            >
                                Zeitraum
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="nav-item" as="li">
                            <Nav.Link
                                eventKey="all"
                                className={`nav-link py-2 px-4 ${key === "all" ? "active" : ""
                                    }`}
                            >
                                Alle
                            </Nav.Link>
                        </Nav.Item>


                    </Nav>

                </div>
            </div>
            <div className="card-body">
                <Tab.Content>
                    <Tab.Pane eventKey="today">
                        <DashboardTaskTab type="today" selectedUser={selectedUser} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="week">
                        <DashboardTaskTab type="week" selectedUser={selectedUser} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="month">
                        <DashboardTaskTab type="month" selectedUser={selectedUser} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="specific">
                        <DashboardTaskTab type="specific" selectedUser={selectedUser} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="all">
                        <DashboardTaskTab type="all" selectedUser={selectedUser} />
                    </Tab.Pane>
                    {selectedUser.value === user.id &&
                        <Tab.Pane eventKey="pinned">
                            <DashboardTaskTab type="pinned" selectedUser={selectedUser} />
                        </Tab.Pane>
                    }
                </Tab.Content>

            </div>
        </Tab.Container>

    </div >
}