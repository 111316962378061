import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
const TaskStatusContext = createContext();

export function useTaskStatusProvider() {
  return useContext(TaskStatusContext);
}

export const AllTaskStatusConsumer = TaskStatusContext.Consumer;

export function TaskStatusProvider({ children }) {
  const [allTaskStatus, setTaskStatus] = useState([]);

  const getTaskStatusByIds = (ids) => {
    let tasks_status =  ids.map((id) => getTaskStatusById(id));
    return tasks_status.sort((a, b) => a.name.localeCompare(b.name));
  }

  const getTaskStatusById = (id) => {
    let status = allTaskStatus.filter(t => {
      return t.id === id;
    });
    return status.length === 0 ? null : status[0];
  }

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + '/api/tasks_status').then(({ data }) => {
      const d =  data.sort((a, b) => a.name.localeCompare(b.name));
      setTaskStatus(d);
    }).catch(e => console.log(e));

  }, []);

  const value = { allTaskStatus, getTaskStatusById, getTaskStatusByIds }
  return (
    <TaskStatusContext.Provider value={value}>
      {children}
    </TaskStatusContext.Provider>
  );
}
