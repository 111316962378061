import React, { useEffect, useRef, useState } from "react";
import { useHtmlClassService, useSubheader } from "../../../../_metronic/layout";
import axios from "axios";
import moment from 'moment';
import Moment from "react-moment";

import Select from 'react-select';

import { shallowEqual, useSelector } from "react-redux";
import { useAsideSecondary } from "../../../../_metronic/layout/_core/MpAsideSecondary";
import { useParams } from "react-router-dom";
import { useUserProvider } from "../../../../providers/AllUsersProvider";
import { Button, Form } from "react-bootstrap";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import { DatePickerLocale } from "../../../constants/DatePickerLocale";

import { toast } from "react-toastify";
import { usePageTitle } from "../../../../providers/PageTitleProvider";


export function StempeluhrAdminUrlaube(props) {
    const user = useSelector((state) => state.auth.user, shallowEqual);

    const subheader = useSubheader();
    const MpAsideSecondary = useAsideSecondary();
    const pageTitle = usePageTitle();

    const uiService = useHtmlClassService();
    const [selectUserConfig, setSelectUserConfig] = useState({});
    const [selectedUser, setSelectedUser] = useState({});
    const [vacationData, setVacationData] = useState([]);

    const [pickerDates, setPickerDates] = useState({ start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })

    const { urlUser } = useParams();

    const userProvider = useUserProvider();

    const pickerRef = useRef();
    const pickerInputRef = useRef();


    const fetchVacations = () => {
        let u = null;
        if (userProvider.can('adminStempel'))
            u = selectedUser.value !== undefined ? '/' + selectedUser.value : '';

        axios.get(process.env.REACT_APP_API_URL + '/api/stempelvacbyuser' + u).then(({ data }) => {
            setVacationData(data);
        }).catch(e => console.log(e));
    };
    const fetchAllUsers = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/users').then(({ data }) => {
            mapUsersForSelect(data);
        }).catch(e => console.log(e));
    };

    const deleteItem = (id) => {
        axios.delete(process.env.REACT_APP_API_URL + '/api/stempelvac/' + id).then(({ data }) => {
            toast.success("Gelöscht")
            fetchVacations();
        }).catch((e) => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
            fetchVacations();
        });
    }
    const saveVacation = () => {
        if (
            pickerDates.start === undefined || pickerDates.start === null ||
            pickerDates.end === undefined || pickerDates.end === null
        )
            return;
        let body = {
            from: pickerDates.start,
            to: pickerDates.end,
            user_id: selectedUser.value
        };
        axios.post(process.env.REACT_APP_API_URL + '/api/stempelvac', body).then(({ data }) => {
            toast.success("Gespeichert")
            fetchVacations();
        }).catch((e) => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
            fetchVacations();
        });
    }
    const applyPicker = (e, picker) => {
        setPickerDates({ start: picker.startDate.format('YYYY-MM-DD'), end: picker.endDate.format('YYYY-MM-DD') });
    };

    const mapUsersForSelect = (data) => {
        let options = data.map((item, i) => {
            return { label: item.name, value: item.id };
        });
        setSelectUserConfig(options);
    };

    const generateVacationCards = () => {
        if (vacationData === null && vacationData.length <= 0)
            return;
        return vacationData.map((yearItem, i) => {
            return <div className="col-lg-4 col-xl-4 mb-8" key={i}>
                <div className="card card-custom card-stretch gutter-b">
                    <div className="card-header">
                        <h3 className="card-title text-dark">
                            {yearItem.year}
                        </h3>
                    </div>
                    <div className="card-body">
                        <div className="bg-dark-o-10 mb-4 rounded-md font-weight-bold font-size-lg py-2 px-5">
                            <div className="row">

                                <div className="col-3">
                                    Von
                                </div>
                                <div className="col-3">
                                    Bis
                                </div>
                                <div className="col-3">
                                    Dauer
                                </div>
                                <div className="col-3 text-center">
                                    Entfernen
                                </div>

                            </div>
                        </div>
                        {yearItem.data?.map((item, j) => {
                            return <div className="vacItem py-2 px-5" key={j}>
                                <div className="row">
                                    <div className="col-3">
                                        <Moment format="DD.MM.YYYY">{item.from}</Moment>
                                    </div>
                                    <div className="col-3">
                                        <Moment format="DD.MM.YYYY">{item.to}</Moment>
                                    </div>
                                    <div className="col-3">
                                        {moment.duration(moment(item.to).diff(moment(item.from))).asDays() + 1} Tag(e)
                                    </div>
                                    <div className="col-3 text-center">
                                        <Button variant="btn btn-icon btn-clean btn-hover-light-danger btn-sm mt-n2"
                                            onClick={() => { deleteItem(item.id) }}
                                        ><i className="la la-trash icon-md text-danger"></i></Button>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div >

        });
    };


    useEffect(() => {
        subheader.setTitle('Urlaube');
        subheader.setActions(null);
        fetchAllUsers();
        fetchVacations();
        setSelectedUser({ label: user.name, value: user.id });
        MpAsideSecondary.setAsideEnabled(true);
    }, [subheader, uiService]);

    useEffect(() => {
        if (urlUser && selectUserConfig.length > 0) {
            let u = selectUserConfig.filter(d => d.value === parseInt(urlUser));
            if (u.length > 0)
                setSelectedUser(u[0])

        }
    }, [selectUserConfig]);

    useEffect(() => {
        fetchVacations();
    }, [selectedUser]);

    useEffect(() => {
        subheader.setTitle("Urlaubs-Administration");
        pageTitle.setPageTitle(`🐮 Urlaubs-Administration`);
    }, [selectedUser]);

    return (userProvider.can('adminStempel') && <>

        <div className="row">
            <div className="col-lg-8 d-print-none mb-8">
                <div className="card card-custom">
                    <div className="card-header">
                        <h3 className="card-title text-dark">
                            Benutzer
                        </h3>
                    </div>
                    <div className="card-body">

                        <Select options={selectUserConfig} value={selectedUser} onChange={(o) => {
                            setSelectedUser(o);
                        }} className="mb-2" classNamePrefix="sl2def" />

                    </div>

                </div>
            </div>
            <div className="col-lg-4 col-xl-4 mb-4">
                <div className="card card-custom gutter-b">
                    <div className="card-header">
                        <h3 className="card-title text-dark">
                            Urlaub eintragen
                        </h3>
                    </div>
                    <div className="card-body">

                        <div className="d-flex">
                            <Form.Group className="flex-grow-1 mb-0">
                                <DateRangePicker
                                    ref={pickerRef}
                                    initialSettings={{
                                        showCustomRangeLabel: false,
                                        locale: DatePickerLocale,
                                        autoApply: true,
                                        autoUpdateInput: true
                                    }}
                                    onApply={(e, picker) => applyPicker(e, picker)}
                                //onCancel={(e, picker) => clearDates(e, picker)}
                                >
                                    <Form.Control size="sm" type="text" onFocus={(e) => e.currentTarget.blur()} style={{ height: "38px" }} ref={pickerInputRef} />
                                </DateRangePicker>
                            </Form.Group>

                            <Form.Group className="flex-shrink-0 mb-0 ml-3">
                                <Button variant="primary" onClick={() => saveVacation()}>Speichern</Button>
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">

            {generateVacationCards()}

        </div>
    </>);
}