import React, { createContext, useContext } from "react";
import { getUserByToken } from "../app/modules/Auth/_redux/authCrud";
import Pusher from "pusher-js"
import Echo from "laravel-echo";

export const EchoContext = createContext();

export function useEchoProvider() {
    return useContext(EchoContext);
}

export const EchoConsumer = EchoContext.Consumer;

export class EchoProvider extends React.Component {
    state = {
        authToken: null,
        user: null,
        echo: null,
        ready: false,
    }

    API_URL = `${process.env.REACT_APP_API_URL}`;
    PUSHER_KEY = `${process.env.REACT_APP_VAPID_PUBLIC_KEY}`;
    PUSHER_PORT = `${process.env.REACT_APP_PUSHER_PORT}`;
    PUSHER_HOST = `${process.env.REACT_APP_PUSHER_HOST}`;

    getAuthToken = async () => {
        let lstore = await localStorage.getItem('persist:stadl');

        if (lstore === undefined || lstore === null)
            return;
        lstore = JSON.parse(lstore);
        if (lstore.authToken === undefined || lstore.authToken === null)
            return;
        this.setState({ authToken: lstore.authToken.replaceAll('"', '') });
    }

    getUser = async () => {

        if (this.state.authToken === null)
            return;
        return getUserByToken(this.state.authToken);
    }

    openSocket = () => {

        let lecho = new Echo({
            broadcaster: 'pusher',
            key: this.PUSHER_KEY,
            wsHost: this.PUSHER_HOST,
            wsPort: this.PUSHER_PORT,
            wssPort: this.PUSHER_PORT,
            forceTLS: true,
            disableStats: true,
            encrypted: true,
            authEndpoint: this.API_URL + '/broadcasting/auth',
            enabledTransports: ['ws', 'wss'],
            auth: {
                headers: {
                    'Authorization': 'Bearer ' + this.state.authToken,
                }
            }
        });
        this.setState({ echo: lecho });
        this.setState({ ready: true });
    }

    componentDidMount() {
        this.getAuthToken().then(() => {
            this.getUser().then((d) => {
                this.setState({ user: d.data });
                this.openSocket();
            });
        });

    }

    render() {
        return <EchoContext.Provider value={this.state} >
            {this.props.children}
        </EchoContext.Provider>
    }

}
