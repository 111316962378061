import React, { useEffect, useState } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import { DayList } from "../components/DayList";
import { MonthList } from "../components/MonthList";
import axios from "axios";
import moment from 'moment';


import { shallowEqual, useSelector } from "react-redux";
import { useAsideSecondary } from "../../../../_metronic/layout/_core/MpAsideSecondary";
import { useHistory, useParams } from "react-router-dom";
import { usePageTitle } from "../../../../providers/PageTitleProvider";
import { MonthVacation } from "../components/MonthVacation";

export function StempeluhrPage() {
  const user = useSelector((state) => state.auth.user, shallowEqual);

  const subheader = useSubheader();
  const MpAsideSecondary = useAsideSecondary();
  const pageTitle = usePageTitle();

  const [currentDate, setCurrentDate] = useState(moment());
  const [selectedUser, setSelectedUser] = useState({});
  const [allUsers, setAllUsers] = useState([]);
  const [shouldRefetch, setShouldRefetch] = useState(true);

  const { urlDate, urlUser } = useParams();

  const history = useHistory();

  const fetchAllUsers = () => {
    axios.get(process.env.REACT_APP_API_URL + '/api/users').then(({ data }) => {
      setAllUsers(data);
    }).catch(e => console.log(e));
  };
  const changeDate = (date) => {
    let uLink = selectedUser.value !== undefined ? '/' + selectedUser.value : '';
    history.push('/stempeluhr/details/' + date.format('YYYY-MM-DD') + uLink);
    setCurrentDate(date);
  }
  const changeUser = (u) => {
    history.push('/stempeluhr/details/' + currentDate.format('YYYY-MM-DD') + '/' + u.value);
    setSelectedUser(u);
  }

  useEffect(() => {
    subheader.setTitle('Stempeluhr');
    subheader.setActions(null);
  }, [subheader]);
  useEffect(() => {
    fetchAllUsers();
    setSelectedUser({ label: user.name, value: user.id });
    MpAsideSecondary.setAsideEnabled(true);
  }, [user]);

  useEffect(() => {
    if (urlDate !== undefined && urlDate !== null)
      setCurrentDate(moment(urlDate));
  }, [urlDate]);
  useEffect(() => {
    if (urlUser && allUsers.length > 0) {
      let u = allUsers.filter(d => d.id === parseInt(urlUser));
      if(u.length > 0 && urlDate !== undefined && urlDate !== null)
        setSelectedUser({ label: u[0].name, value: u[0].id })
    }
  }, [allUsers]);

  useEffect(() => {
    subheader.setTitle("Stempeluhr");
    pageTitle.setPageTitle(`🐮 Stempeluhr`);
}, [selectedUser]);

  return (<>
    <div className="row">
      <div className="col-lg-6 col-xxl-4 d-print-none">
        <DayList
          displayType="card"
          pageType="full"
          title="Tages-Buchungen"
          currentDate={currentDate}
          dateCallback={changeDate}
          datePicker={true}
          stempelForm={true}
          scroll={false}
          setShouldRefetch={setShouldRefetch}
          users={{
            selectedUser: selectedUser,
            setSelectedUser: changeUser,
            allUsers: allUsers
          }}
          selectedUser={selectedUser}

        />
        <MonthVacation
          title="Urlaubsdaten"
          currentDate={currentDate}
          dateCallback={changeDate}
          user={selectedUser}
          setShouldRefetch={setShouldRefetch}
          shouldRefetch={shouldRefetch}

        />
      </div>
      <div className="col-lg-6 col-xxl-8">
        <MonthList
          displayType="card"
          title="Monats Daten"
          currentDate={currentDate}
          dateCallback={changeDate}
          user={selectedUser}
          setShouldRefetch={setShouldRefetch}
          shouldRefetch={shouldRefetch}

        />
      </div>

    </div>
  </>);
}
