import React, { useEffect, useMemo } from "react";
import { useUserProvider } from "../../../providers/AllUsersProvider";
import { usePageTitle } from "../../../providers/PageTitleProvider";
import { useHtmlClassService, useSubheader } from "../../../_metronic/layout";
import { useAsideSecondary } from "../../../_metronic/layout/_core/MpAsideSecondary";
import { useContentContainer } from "../../../_metronic/layout/_core/MpContentContainer";
import { DayList } from "../Stempeluhr/components/DayList";
import { TechnikTickets } from "../Technik/components/TechnikTickets";
import { DashboardOnlineUsers } from "./partials/DashboardOnlineUsers";
import { DashboardStempelStats } from "./partials/DashboardStempelStats";
import { DashboardTasksTabs } from "./partials/DashboardTasksTabs";
import { DashboardGeneralAlerts } from "./partials/DashboardGeneralAlerts";

export function Dashboard() {
    const uiService = useHtmlClassService();
    const subheader = useSubheader();
    const contentContainer = useContentContainer();
    const MpAsideSecondary = useAsideSecondary();
    const pageTitle = usePageTitle()
    const userProvider = useUserProvider();

    useEffect(() => {
        MpAsideSecondary.setAsideEnabled(false);
        subheader.setActions(null);
        contentContainer.reset();

        subheader.setTitle("Dashboard");
        pageTitle.setPageTitle(`🐮 Dashboard`);
    }, [])


    return <>
        <div className="row">
            <div className="col-12">
                <DashboardGeneralAlerts />
            </div>
            <div className="col-xxl-9 order-xxl-2">
                <DashboardTasksTabs />
                <TechnikTickets />

            </div>

            <div className="col-xxl-3 order-xxl-1">
                {userProvider.canAny(['canStempel', 'adminStempel']) &&
                    <>
                        <div className="row">
                            <div className="col-lg-6 col-xxl-12">

                                <DayList className="gutter-b" displayType="card" title="Heutige Stempel Daten" linkToOverview={true} stempelButton={true} />
                            </div>
                            <div className="col-lg-6 col-xxl-12">
                                <DashboardStempelStats />
                            </div>
                        </div>
                    </>
                }
                <DashboardOnlineUsers cols="col-6 col-sm-4 col-md-3 col-xl-6" />
            </div>

        </div>
    </>;
}
