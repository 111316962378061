import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { TaskInfoEditor } from "ckeditor5-stadl-editor";
import { Button } from "react-bootstrap";
import {toast} from "react-toastify";

export function CustomerDepartmentInfoTab(props) {

    const [info, setInfo] = useState({});
    const [content, setContent] = useState("");
    const contentEditorRef = useRef();

    const apply = () => {
        if(info.content !== undefined && content === info.content)
            return;

        let body = {};
        body.content = content;
        body.type = props.type;

        axios.post(process.env.REACT_APP_API_URL + `/api/customerinfobycustomer/${props.customer.id}`, body).then(d => {
            toast.success("Gespeichert");
            props.onSubmit();
        }).catch(e => {

            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
            props.onSubmit();
        });
    }

    useEffect(() => {
        if(props.customer && props.type){
            setInfo(props.getInfo === null ? {} : props.getInfo);
        }
    },[props.customer]);

    useEffect(() => {
        setInfo(props.getInfo === null ? {} : props.getInfo);
    },[props.getInfo])


    useEffect(() => {
        if(info.content !== undefined)
            setContent(info.content);
    },[info])


    return <div className="editContent mt-10">
        <CKEditor
            className="h-150px"
            editor={TaskInfoEditor}
            config={{
                language: 'de'
            }}
            data={content}
            onReady={editor => {
                contentEditorRef.current = editor;
            }}
            onChange={(event, editor) => {
                setContent(editor.getData());
            }}

        />
        <div className="d-flex justify-content-end mt-6">
            <Button variant="primary mr-4" onClick={() => apply()}>Speichern</Button>
            <Button variant="default " onClick={props.onHide}>Abbrechen</Button>
        </div>
    </div>
}