import React from "react";

export class BookList extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return null;
    }

}