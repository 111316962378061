import React, { useEffect, useState } from "react";
import { usePageTitle } from "../../../../providers/PageTitleProvider";
import { useSubheader } from "../../../../_metronic/layout";
import axios from "axios";
import { ProjectList } from "../../Projects/components/ProjectList";
import { Button, Spinner } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { ProjectCreationModal } from "../../Projects/components/ProjectCreationModal";

function TopActionButtons(props) {

    return <>
        <Button variant="secondary" className="mr-4" onClick={() => {
            props.setShowArchived(!props.showArchived);
        }}><span className="svg-icon svg-icon-lg">
                <SVG
                    src={toAbsoluteUrl(
                        "/media/svg/icons/Home/Box.svg"
                    )}
                /></span>
            {` `}{props.showArchived ? 'Aktive' : 'Archivierte'} Projekte</Button>
        <Button variant="primary" onClick={() => props.setShowModal(true)}><span className="svg-icon svg-icon-lg">
            <SVG
                src={toAbsoluteUrl(
                    "/media/svg/icons/Files/Folder-plus.svg"
                )}
            /></span>
            {` `}Neues Projekt erstellen</Button>
    </>
}


export function CustomerProjects(props) {
    const subheader = useSubheader();
    const pageTitle = usePageTitle();

    const [allProjects, setAllProjects] = useState([]);
    const [projects, setProjects] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const [lCustomer, setLCustomer] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showArchived, setShowArchived] = useState(false);


    //let { taskpath } = useParams();

    const fetchCustomerProjects = () => {
        if (Object.keys(lCustomer).length <= 0)
            return;
        axios.get(process.env.REACT_APP_API_URL + '/api/projectsbycustomer/' + lCustomer.id).then(({ data }) => {
            let prjData = data;
            setAllProjects(prjData);
            handleArchived(prjData);
            setIsLoading(false);
        }).catch(e => console.log(e));
    }

    const handleArchived = (data) => {
        let prjData = data;
        if (prjData.length > 0)
            prjData = data.filter(t => showArchived ? t.archived === 1 : t.archived === 0);
        setProjects(prjData);
    }

    const handleSearch = () => {
        var filtered = allProjects;
        if (searchTerm !== '' && searchTerm.length > 2) {
            filtered = allProjects.filter(t => {
                return t.name.toLowerCase().includes(searchTerm.toLowerCase());// || t.user?.toLowerCase().includes(searchTerm.toLowerCase());
            });

            filtered.sort((a, b) => a.name.localeCompare(b.name));
        }
        handleArchived(filtered);
    }

    const setSubheaderActions = () => {
        let content = <TopActionButtons setShowModal={setShowModal} setShowArchived={setShowArchived} showArchived={showArchived} />
        subheader.setActions(content);
    }

    const modalClose = () => {
        setShowModal(false);
    };
    const modalSubmit = () => {
        fetchCustomerProjects();
        setShowModal(false);
    };

    useEffect(() => {
        setLCustomer(props.customer);
        setSubheaderActions();
    }, [props.customer]);

    useEffect(() => {
        if (lCustomer.id === undefined)
            return;
        fetchCustomerProjects();
        subheader.setTitle('Projekte - ' + lCustomer.name);
        pageTitle.setPageTitle(`🐮 Projekte${lCustomer.name !== undefined ? ' | ' + lCustomer.name : ''}`);
    }, [lCustomer]);

    useEffect(() => {
        handleSearch();
        setSubheaderActions();
    }, [searchTerm, showArchived]);

    const renderLoading = () => {

        return (<>
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                <div className="text-center">
                    <div className="d-flex flex-column align-items-center justify-content-center h-full mt-4 mb-4"><Spinner animation="border" variant="primary" /></div>
                </div>
            </div>
        </>
        );

    }
    const renderContent = () => {
        return <><div className="row">
            {projects.length > 0 ?
                <ProjectList customer={lCustomer} projects={projects} />
                :
                (!showArchived ?
                    <div className="col-12 col-lg-6">
                        <div className="alert alert-custom alert-outline-primary fade show mb-5" role="alert">
                            <div className="alert-icon"><i className="la la-info-circle "></i></div>
                            <div className="alert-text">Aktuell gibt es für diesen Kunden keine Projekte.</div>
                            <div className="action-button">
                                <Button variant="primary" onClick={() => setShowModal(true)}>
                                    Erstes Projekt erstellen
                                </Button>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="col-12 col-lg-6">
                        <div className="alert alert-custom alert-outline-primary fade show mb-5" role="alert">
                            <div className="alert-icon"><i className="la la-info-circle "></i></div>
                            <div className="alert-text">Keine archivierte Projekte.</div>
                        </div>
                    </div>
                )


            }
        </div>
            <ProjectCreationModal customer={{ label: lCustomer.name, value: lCustomer.id }} show={showModal} onHide={modalClose} onSubmit={modalSubmit} />
        </>
    }
    return (<>
        {isLoading ? renderLoading() : renderContent()}
    </>)
}