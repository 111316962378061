import { CKEditor } from "@ckeditor/ckeditor5-react";

import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { TaskInfoEditor } from "ckeditor5-stadl-editor";
import axios from "axios";
import { toast } from "react-toastify"
import { Parser } from 'html-to-react'

export function TaskDescription(props) {

    const [task, setTask] = useState({});
    const [description, setDescription] = useState("");
    const [canSubmit, setCanSubmit] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [edit, setEdit] = useState(false);

    const descEditorRef = useRef();

    const updateTask = (val) => {
        if (isSubmitting)
            return;
        setIsSubmitting(true);
        if (!canSubmit) {
            setIsSubmitting(false);
            return;
        }
        let body = {
            value: description,
        };
        axios.post(process.env.REACT_APP_API_URL + '/api/tasks/' + task.id + '/updateDescription', body).then((d) => {
            toast.success("Info wurde geändert")
            setEdit(false);
            setIsSubmitting(false);
            props.refetch();
        }).catch((e) => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
            setEdit(false);
            setIsSubmitting(false);
            props.refetch();
        });
    }

    useEffect(() => {
        setTask(props.task);
    }, [props.task]);


    useEffect(() => {
        if (
            (Object.keys(task).length > 0) === true &&
            (task.data !== undefined && task.data !== null) === true &&
            (task.data.description !== undefined && task.data.description !== null)
        )
            setDescription(task.data.description);
        setCanSubmit(true);


    }, [task]);

    return <>
        <div className="row">
            {!edit && <div className="col-12">
                {description === "" ? <div className="alert bg-light-info mt-10">Bislang keine Beschreibung vorhanden.</div> : <div className=" mt-10">{Parser().parse(description)}</div>}
                <Button variant="primary mt-4" onClick={() => setEdit(true)}>Bearbeiten</Button>
            </div>
            }
            {edit && <div className="col-12 mt-10">

                {canSubmit && <><CKEditor
                    className="h-150px"
                    editor={TaskInfoEditor}
                    config={{
                        language: 'de'
                    }}
                    data={description}
                    onReady={editor => {
                        descEditorRef.current = editor;
                    }}
                    onChange={(event, editor) => {
                        setDescription(editor.getData());
                    }}

                />


                    <div className="mt-4 d-flex justify-content-between">
                        <div className="formActionButtons">
                            <Button variant="default mr-4" onClick={() => setEdit(false)}>Abbrechen</Button>

                            <Button variant="primary" onClick={() => updateTask()}>{!isSubmitting ? "Speichern" : <Spinner animation="border" variant="white" size="sm" className="mb-1" />}</Button>
                        </div>

                    </div>
                </>
                }
            </div>
            }
        </div>




    </>;

}