import { Field, FieldArray, Formik, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Form } from "react-bootstrap";
import * as Yup from "yup";
import { toast } from "react-toastify"
import axios from "axios";
import Select from 'react-select'
import { TasksFieldArray } from "../../../ProjectTasks/partials/taskForm/TasksFieldArray";


export function AdminProjectTemplateModal(props) {


    const saveNew = (values) => {
        let body = values;
        return axios.post(process.env.REACT_APP_API_URL + '/api/projecttemplates', body);
    };
    const updateExisting = (values) => {
        let body = values;
        return axios.put(process.env.REACT_APP_API_URL + '/api/projecttemplates/' + props.edit, body);
    };

    const fetchEntry = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/projecttemplates/' + props.edit).then(({ data }) => {
            formik.setFieldValue('name', (data.name == null ? '' : data.name));
            formik.setFieldValue('tasks', (data.data == null ? '' : data.data));
        }).catch(e => props.onSubmit());
    };

    const deleteData = () => {
        axios.delete(process.env.REACT_APP_API_URL + '/api/projecttemplates/' + props.edit).then((d) => {
            toast.success("Gelöscht");
            props.onSubmit();
        }).catch(e => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
            props.onSubmit();
        });
    }
    const initialValues = {
        name: '',
        tasks: ['']
    };
    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            tasks: Yup.array().min(1).required().test({
                name: 'is-email',
                test: (values) => {
                    let lValues = values.filter((u) => u !== null || u !== undefined || u !== '');
                    return lValues.length > 0;
                }
            }),
        }),
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            setSubmitting(false);
            if (props.edit === 0) {
                saveNew(values).then(d => { toast.success("Gespeichert"); resetForm(); props.onSubmit() }).catch(e => console.log(e));
            } else {
                updateExisting(values).then(d => { toast.success("Gespeichert"); resetForm(); props.onSubmit() }).catch(e => console.log(e));
            }
            setSubmitting(true);

        },
    });

    useEffect(() => {
        if (props.edit !== 0)
            fetchEntry();
    }, [props.edit]);

    const renderForm = () => {
        return <Form onSubmit={formik.handleSubmit} className={`bg-white position-relative`}>
            <Modal.Body>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Name"
                            name="name"
                            value={''}
                            {...formik.getFieldProps("name")}
                            isInvalid={formik.errors.name}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <FormikProvider value={formik}>
                            <Form.Label>Tasks</Form.Label>
                            <TasksFieldArray formik={formik} name="tasks" />
                        </FormikProvider>
                    </Form.Group>
                </Form.Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit">Erstellen</Button>
                <Button variant="default " onClick={props.onHide}>Abbrechen</Button>
            </Modal.Footer>
        </Form>
    }

    const deleteForm = () => {
        return <>
            <Modal.Body>
                <p>Bist du dir sicher dass du diese Vorlage löschen möchtest?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={deleteData}>Ja, Vorlage löschen</Button>
                <Button variant="default " onClick={props.onHide}>Nein, Vorlage nicht löschen</Button>
            </Modal.Footer>

        </>
    }

    return <Modal
        show={props.show}
        size={props.type !== 'delete' ? 'lg' : 'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={props.onHide}
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Vorlage
                {
                    (props.type && props.type === 'delete') ? ' löschen' :
                        (props.edit === 0) ? ' erstellen' : ' bearbeiten'
                }
            </Modal.Title>
        </Modal.Header>
        {(props.type && props.type === 'delete') ? deleteForm() : renderForm()}
    </Modal>
}