import React, { useEffect, useState } from "react";
import axios from "axios"
import PerfectScrollbar from "react-perfect-scrollbar";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

export function TenorSearch(props) {

    const [searchTerm, setSearchTerm] = useState("");
    const [results, setResults] = useState([]);

    const searchTenor = () => {
        axios.get(`https://g.tenor.com/v1/search?limit=33&contentfilter=medium&media_filter=minimal&key=${process.env.REACT_APP_TENOR_KEY}&q=${searchTerm}`).then(({ data }) => {
            setResults(data);
        }).catch(e => console.log(e));
    }
    //REACT_APP_TENOR_KEY

    const renderResults = () => {
        if (results.length <= 0 || results.results.length <= 0)
            return null;
        return results.results?.map((item, index) => {

            return <div className="tenorItem bg-light hover-opacity-50 cursor-pointer" key={index} style={{ width: "33.3333%", height: 100, overflow: 'hidden' }} onClick={() => props.onSelect(item)}>
                <img src={item.media[0].tinygif.url} alt={item.content_description} />
            </div>

        });
    };

    useEffect(() => {
        if (searchTerm.length > 2) {
            searchTenor();
        } {
            setResults([]);
        }
    }, [searchTerm]);

    return <>
        <div className="tenorSearch w-xl-400px">
            <div className="row">
                <div className="col-12 mb-4">
                    <input type="text" className="form-control" onChange={(e) => setSearchTerm(e.currentTarget.value)} />
                </div>
            </div>
            <div className="row">
                <div className="col-12 h-300px">
                    <div className={`${(results.length > 0 || results.results?.length > 0) ? 'd-block' : 'd-none'} overflow-hidden position-relative h-full`}>
                        <PerfectScrollbar
                            className="scroll"
                            options={{wheelPropagation: false}}

                            style={{ height: '100%', width: '100%', position: "absolute" }}
                        >
                            <div>
                                <div className="d-flex flex-wrap" style={{ flexBasis: "33.3333%" }}>
                                    {renderResults()}
                                </div>
                            </div>

                        </PerfectScrollbar>
                    </div>
                    <div style={{ position: 'relative', height: '100%' }} className={`${(results.length <= 0 || results.results?.length <= 0) ? 'd-flex' : 'd-none'} justify-content-center align-items-center`}>
                        <div className="text-center">
                            <div className="svg-icon svg-icon-8x">
                                <SVG
                                    src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                                />
                            </div>
                            <div className="font-size-h6 mt-10">Durchstöbere die GIF Galerie.</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </>;

}