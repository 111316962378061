import React, { useEffect, useState } from "react";
import { useFormik, validateYupSchema } from "formik";
import { Button, Form, Col } from "react-bootstrap";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import moment from 'moment';
import axios from "axios";

export function StempelForm(props) {

    const initialValues = {
        stempelTime: '',
        stempelType: ''
    };
    const [time, setTime] = useState('');
    const [dayEntry, setDayEntry] = useState({});
    const [prechecked, setPrechecked] = useState(0);
    const [timeEntry, setTimeEntry] = useState('');


    const startsWithTwo = time[0] === '2';

    const timeMask = [
        /[0-2]/,
        startsWithTwo ? /[0-3]/ : /[0-9]/,
        ':',
        /[0-5]/,
        /[0-9]/
    ];

    const handleInput = ({ target: { value } }) => setTime(value);

    const storeTimeEntry = (values) => {
        let body = {
            user: props.user.value,
            day: props.selectedDate,
            time: values.stempelTime,
            type: parseInt(values.stempelType)
        }
        return axios.post(process.env.REACT_APP_API_URL + '/api/stempeltime', body);
    };
    const updateTimeEntry = (values) => {
        let body = {
            user: props.user.value,
            day: props.selectedDate,
            time: values.stempelTime,
            type: parseInt(values.stempelType),
            id: props.editTimeEntry.id
        }
        return axios.post(process.env.REACT_APP_API_URL + '/api/stempeltimeupdate', body);

    };

    const handleRadioChange = (e) => {
        formik.setFieldValue('stempelType', parseInt(e.currentTarget.value))
        setPrechecked(parseInt(e.currentTarget.value));
    };

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            stempelTime: Yup.string()
                .required()
                .test('is_time', 'Must be time!', (value) => {
                    if(value === undefined)
                        return false;
                    return value.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/);
                }),
            stempelType: Yup.string()
        }),
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            setSubmitting(false);
            
            let submitType;
            if(props.type === 'new'){
                submitType = storeTimeEntry(values);
            } else {
                submitType = updateTimeEntry(values);
            }

            submitType.then(() => {
                props.callback();
                resetForm();
            }).catch(() => {
                props.callback();
                resetForm();
            });
            setSubmitting(true);

        },
    });

    const setLastOpposite = () => {
        let last = (Object.keys(props.dayData).length !== 0 && props.dayData?.data?.length > 0) ? props.dayData?.data?.slice(-1)[0].type : 0;
        last = last === 0 ? 1 : 0;
        setPrechecked(last);
        formik.setFieldValue('stempelType', last);
    };

    useEffect(() => {
        setDayEntry(props.dayData);
        if(props.type === 'new'){
            setLastOpposite();
            return;
        }
        setPrechecked(props.editTimeEntry.type);
        formik.setFieldValue('stempelType', props.editTimeEntry.type);
        formik.setFieldValue('stempelTime', props.editTimeEntry.timeReadable);
    }, [props.dayData, props.selectedDate, props.user, props.editTimeEntry, props.type])

    return <>
        <Form onSubmit={formik.handleSubmit} className={`bg-white position-relative ${props.type === 'edit' ? 'pb-6' : ''}`}>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Control
                        type="text"
                        placeholder={moment().format('HH:mm')}
                        as={InputMask}
                        onChange={handleInput}
                        mask={timeMask}
                        name="stempelTime"
                        value={''}
                        {...formik.getFieldProps("stempelTime")}
                        //isInvalid={formik.errors.stempelTime}
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} xs="6">
                    <label className="radio radio-lg radio-lg radio-success">
                        <input  type="radio" onChange={e => handleRadioChange(e)} name="stempelType" value="1" checked={prechecked === 1} />
                        <span className="bg-success"></span>
                        <p className="m-0 ml-3 p-0">Kommen</p>
                    </label>
                </Form.Group>
                <Form.Group as={Col} xs="6">
                    <label className="radio radio-lg radio-lg radio-danger">
                        <input  type="radio" onChange={e => handleRadioChange(e)} name="stempelType" value="0" checked={prechecked === 0} />
                        <span className="bg-danger"></span>
                        <p className="m-0 ml-3 p-0">Gehen</p>
                    </label>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} xs="6">
                    <label className="radio radio-lg radio-lg radio-info">
                        <input  type="radio" onChange={e => handleRadioChange(e)} name="stempelType" value="3" />
                        <span className="bg-info"></span>
                        <p className="m-0 ml-3 p-0">Urlaub Anfang</p>
                    </label>
                </Form.Group> 
                <Form.Group as={Col} xs="6">
                    <label className="radio radio-lg radio-lg radio-info">
                        <input  type="radio" onChange={e => handleRadioChange(e)} name="stempelType" value="2" />
                        <span className="bg-info"></span>
                        <p className="m-0 ml-3 p-0">Urlaub Ende</p>
                    </label>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} xs="6">
                    <label className="radio radio-lg radio-lg radio-primary">
                        <input  type="radio" onChange={e => handleRadioChange(e)} name="stempelType" value="5" />
                        <span className="bg-primary"></span>
                        <p className="m-0 ml-3 p-0">Krank/Arzt Anfang</p>
                    </label>
                </Form.Group>
                <Form.Group as={Col} xs="6">
                    <label className="radio radio-lg radio-lg radio-primary">
                        <input  type="radio" onChange={e => handleRadioChange(e)} name="stempelType" value="4" />
                        <span className="bg-primary"></span>
                        <p className="m-0 ml-3 p-0">Krank/Arzt Ende</p>
                    </label>
                </Form.Group>
            </Form.Row>

            <Form.Row>
                <Form.Group as={Col} className="mb-3">
                    <Button variant="success btn-shadow-hover" type="submit">Speichern</Button>
                </Form.Group>
            </Form.Row>
        </Form>
    </>

}
