
export const DatePickerLocale = {
    "format": "DD.MM.YYYY",
    "separator": " - ",
    "applyLabel": "Wählen",
    "cancelLabel": "Abbrechen",
    "fromLabel": "Von",
    "toLabel": "Bis",
    "customRangeLabel": "Benutzerdefiniert",
    "weekLabel": "W",
    "daysOfWeek": [
        "So",
        "Mo",
        "Di",
        "Mi",
        "Do",
        "Fr",
        "Sa"
    ],
    "monthNames": [
        "Jänner",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember"
    ],
    "firstDay": 1
};