
import React, { useEffect, useState } from "react";
import { SelectStatus } from "./taskForm/SelectStatus";
import { SelectUsers } from "./taskForm/SelectUsers";
import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import { toast } from 'react-toastify';
import { SelectTiming } from "./taskForm/SelectTiming";
import { SetQuickInfo } from "./taskForm/SetQuickInfo";
import { SetExternalInfo } from "./taskForm/SetExternalInfo";

export function TaskDetailForm(props) {

    const [task, setTask] = useState({});


    useEffect(() => {
        setTask(props.task);
    }, [props.task]);


    return <>
        <div className="row">
            <div className="col-6">
                <Form.Group>
                    <Form.Label>Timing {task.parent_id !== null && <>

                        (Achtung {' '}
                        <OverlayTrigger
                            overlay={
                                <Popover>
                                    <div className="p-4">
                                        Es kann sein, dass du dein gewünschtes Start-Datum nicht einstellen kannst, da dieser Task ein Eltern Element hat.
                                        Wenn du einen Zeitraum einstellst so wird das Start-Datum auf 1 Tag nach dem Eltern-Ende gesetzt und das End-Datum wird anhand der von dir eingestellten Differenz drauf gerechnet.
                                    </div>
                                </Popover>
                            }
                        >
                            <i className='la la-info-circle'></i>
                        </OverlayTrigger>
                        )
                    </>}</Form.Label>
                    <SelectTiming task={task} refetch={props.refetch} />
                </Form.Group>
            </div>
            <div className="col-6">
                <Form.Group>
                    <Form.Label>Status</Form.Label>
                    <SelectStatus task={task} refetch={props.refetch} />
                </Form.Group>
            </div>
        </div>
        <div className="row">
            <div className="col-6">
                <Form.Group>
                    <Form.Label>Quick Info</Form.Label>
                    <SetQuickInfo task={task} refetch={props.refetch} />
                </Form.Group>
            </div>
            <div className="col-6">
                <Form.Group>
                    <Form.Label>Externes</Form.Label>
                    <SetExternalInfo task={task} refetch={props.refetch} />
                </Form.Group>
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <Form.Group>
                    <Form.Label>Personen</Form.Label>
                    <SelectUsers task={task} refetch={props.refetch} />
                </Form.Group>
            </div>
        </div>


    </>;
}