import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useUserProvider } from "../../../../providers/AllUsersProvider";
import { usePageTitle } from "../../../../providers/PageTitleProvider";
import { useHtmlClassService, useSubheader } from "../../../../_metronic/layout";
import { useAsideSecondary } from "../../../../_metronic/layout/_core/MpAsideSecondary";
import axios from "axios";
import Select from 'react-select'
import Moment from "react-moment";
import { Button } from "react-bootstrap";
import StempeluhrProfileModal from "../components/adminBetrieb/StempeluhrProfileModal";

export function StempeluhrProfile(props) {

    const user = useSelector((state) => state.auth.user, shallowEqual);

    const subheader = useSubheader();
    const MpAsideSecondary = useAsideSecondary();

    const pageTitle = usePageTitle();

    const uiService = useHtmlClassService();
    const [selectUserConfig, setSelectUserConfig] = useState({});
    const [selectedUser, setSelectedUser] = useState({});
    const [profilesData, setProfilesData] = useState([]);

    const [editId, setEditId] = useState(0);

    const [showProfileModal, setShowProfileModal] = useState(false);
    const [formTypeDelete, setFormTypeDelete] = useState(false);

    const { urlUser } = useParams();

    const userProvider = useUserProvider();

    const days = [
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
        'Sonntag',
    ]

    const fetchProfiles = () => {
        let u = '';
        if (userProvider.can('adminStempel') && selectedUser.value !== undefined)
            u = '/' + selectedUser.value;

        axios.get(process.env.REACT_APP_API_URL + '/api/stempelprofilesbyuser' + u).then(({ data }) => {
            setProfilesData(data);
        }).catch(e => console.log(e));
    };

    const fetchAllUsers = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/users').then(({ data }) => {
            mapUsersForSelect(data);
        }).catch(e => console.log(e));
    };

    const mapUsersForSelect = (data) => {
        let options = data.map((item, i) => {
            return { label: item.name, value: item.id };
        });
        setSelectUserConfig(options);
    };

    const renderProfiles = () => {
        if (profilesData.length <= 0)
            return;

        return profilesData.map((item, i) => {
            return <div className="row" key={i}>
                <div className="col-12">
                    <div className="card card-custom gutter-b">
                        <div className="card-header">
                            <h3 className="card-title text-dark">
                                <Moment format="DD.MM.YYYY">{item.from}</Moment><span className="px-2">-</span><Moment format="DD.MM.YYYY">{item.to}</Moment>
                            </h3>
                            <div className="card-toolbar">
                                <Button variant="btn btn-icon btn-clean btn-hover-light-primary btn-sm mr-2"
                                    onClick={() => { setEditId(item.id); setFormTypeDelete(false); setShowProfileModal(true) }}
                                ><i className="la la-pencil icon-md text-primary"></i></Button>

                                <Button variant="btn btn-icon btn-clean btn-hover-light-danger btn-sm"
                                    onClick={() => { setEditId(item.id); setFormTypeDelete(true); setShowProfileModal(true) }}
                                ><i className="la la-trash icon-md text-danger"></i></Button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-7 bg-light rounded-md py-2">
                                    <div className="d-flex justify-content-between">
                                        {item.all_days_readable.map((day, dayIndex) => {
                                            return <div className="dayItem" key={dayIndex}>
                                                <small className="mb-4 d-block">{days[dayIndex]}</small>
                                                {day}
                                            </div>
                                        })}
                                    </div>

                                </div>
                                <div className="col-2 py-2 ml-4">
                                    <div className="d-flex justify-content-between">
                                        <div className="infoItem">

                                            <small className="mb-4 d-block">Urlaubstage</small>
                                            {item.vacation}
                                        </div>
                                        <div className="infoItem">
                                            <small className="mb-4 d-block">Überstunden Vorperiode</small>
                                            {item.overtime_readable}
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="d-flex justify-content-start">
                                        <div className={`infoItem rounded-md px-2 py-2 mx-2 ${item.stats_of_period.vacationAmountRest > 0 ? 'bg-light-success' : 'bg-light-danger'}`}>

                                            <small className="mb-4 d-block">Stand Resturlaub</small>
                                            {item.stats_of_period.vacationAmountRest}
                                        </div>
                                        <div className={`infoItem rounded-md px-2 py-2 mx-2 ${item.stats_of_period.diff > 0 ? 'bg-light-success' : 'bg-light-danger'}`}>
                                            <small className="mb-4 d-block">Stand Überstunden</small>
                                            {item.stats_of_period.diffReadable}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        })
    }

    const modalClose = () => {
        setShowProfileModal(false);
        setEditId(0);
        setTimeout(() => setFormTypeDelete(false), 300);
    }
    const modalSubmit = () => {
        setShowProfileModal(false);
        fetchProfiles();
        setEditId(0);
        setTimeout(() => setFormTypeDelete(false), 300);
    }

    useEffect(() => {
        subheader.setActions(null);
        subheader.setTitle("Stempeluhr Profile");
        fetchAllUsers();
        fetchProfiles();
        setSelectedUser({ label: user.name, value: user.id });
        MpAsideSecondary.setAsideEnabled(true);
        pageTitle.setPageTitle(`🐮 Stempeluhr Profile`);
    }, [subheader, uiService]);

    useEffect(() => {
        if (urlUser && selectUserConfig.length > 0) {
            let u = selectUserConfig.filter(d => d.value === parseInt(urlUser));
            if (u.length > 0)
                setSelectedUser(u[0])

        }
    }, [selectUserConfig]);

    useEffect(() => {
        fetchProfiles();
    }, [selectedUser]);


    return (<>
        {userProvider.can('adminStempel') &&
            <div className="row">
                <div className="col-12 d-print-none mb-8">
                    <div className="card card-custom">
                        <div className="card-header">
                            <h3 className="card-title text-dark">
                                Benutzer
                            </h3>
                            <div className="card-toolbar">

                                <Button variant="btn btn-icon btn-clean btn-hover-light-primary btn-sm"
                                    onClick={() => { setFormTypeDelete(false); setShowProfileModal(true) }}
                                ><i className="la la-plus icon-md text-primary"></i></Button>
                            </div>
                        </div>
                        <div className="card-body">
                            <Select options={selectUserConfig} value={selectedUser} onChange={(o) => {
                                setSelectedUser(o);
                            }} className="mb-2" classNamePrefix="sl2def" />

                        </div>

                    </div>
                </div>
            </div>
        }

        {renderProfiles()}
        <StempeluhrProfileModal type={formTypeDelete ? 'delete' : 'edit'} user={selectedUser.value} edit={editId} show={showProfileModal} onHide={modalClose} onSubmit={() => modalSubmit()} />
    </>);
}