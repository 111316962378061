/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import UserSettingsModal from "../../../../../app/modules/Misc/UserSettingsModal";

export function QuickUser() {
  const history = useHistory();
  const user = useSelector((state) => state.auth.user, shallowEqual);

  const [showUserModal, setShowUserModal] = useState(false);

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };

  const modalClose = () => {
    setShowUserModal(false);
  }
  const modalSubmit = () => {
    setShowUserModal(false);
    window.location.reload();
  }

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-left offcanvas p-10">
      {/*<div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">
          Benutzer Profil
        </h3>
  </div>*/}

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">

          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {user.firstname} {user.lastname}
            </a>
            <div className="text-muted mt-1">{user.occupation}</div>
            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Mail-notification.svg"
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {user.email}
                  </span>
                </span>
              </a>
            </div>
            <button
              className="btn btn-light-primary btn-bold mt-4"
              onClick={() => setShowUserModal(true)}
            >
              Einstellungen
            </button>
            <button
              className="btn btn-light-danger btn-bold mt-4"
              onClick={logoutClick}
            >
              Abmelden
            </button>
          </div>
        </div>

        <div className="separator separator-dashed mt-12 mb-5" />

      </div>

      <UserSettingsModal show={showUserModal} onHide={modalClose} onSubmit={modalSubmit} />
    </div>
  );
}
