import React, { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import $ from "jquery";

export class NotificationItem extends React.Component {
    
    setReadStatus(notifyId) {

        return axios.post(process.env.REACT_APP_API_URL + '/api/notification/' + notifyId).then(({ data, ...r }) => {
            this.props.setShouldUpdate(true);
        }).catch(e => {
        });
    }

    getIconByType() {
        switch (this.props.item.type) {

            case 'App\\Notifications\\TaskUpdateArchivated':
                return [<i className={`la ${this.props.item.data.type === 'archived' ? 'la-box text-warning' : 'la-box-open text-success'} icon-lg`}></i>, (this.props.item.data.type === 'archived' ? 'Task archiviert' : 'Task reaktiviert')];
            case 'App\\Notifications\\TaskUpdateComment':
                return [<i className="la la-comment-alt text-primary icon-lg"></i>, 'Neuer Kommentar'];
            case 'App\\Notifications\\TaskUpdateMove':
                return [<i className="la la-arrows-v text-warning icon-lg"></i>, 'Task-Verschiebung'];
            case 'App\\Notifications\\TaskUpdatePersons':
                return [<i className={`la ${this.props.item.data.type === 'added' ? 'la-user-plus text-success' : 'la-user-times text-danger'}  icon-lg`}></i>, (this.props.item.data.type === 'added' ? 'Hinzugefügt' : 'Entfernt')];
            case 'App\\Notifications\\TaskUpdateTiming':
                return [<i className="la la-calendar-o text-primary icon-lg"></i>, 'Timingänderung'];
            case 'App\\Notifications\\TaskUpdateStatus':
                return [<i className="la la-hashtag text-primary icon-lg"></i>, 'Status-Änderung'];
            default:
                return [null];
        }
    }

    generateItem() {

        return <div className="navi-item">
            <div className="navi-link rounded">

                <div className="symbol symbol-80 mr-5 cursor-pointer" onClick={() =>this.setReadStatus(this.props.item.data.notifyId)}>
                    <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="toggle-quick-panel">{this.getIconByType()[1]}</Tooltip>}
                    >
                        <div className="symbol-label">
                            {this.getIconByType()[0]}
                        </div>
                    </OverlayTrigger>
                </div>
                <Link to={'/notification/' + this.props.item.data.notifyId} className="navi-text" onClick={() => {
                    $('#kt_quick_panel').removeClass('offcanvas-on');
                    $('.offcanvas-overlay').remove();
                }}>
                    <div className="font-weight-bold font-size-lg">
                        {this.props.item.task && this.props.item.task.name}
                    </div>
                    {this.props.item.task && <>

                        {(this.props.item.task.customer_short !== null && this.props.item.task.customer_short.name !== undefined && this.props.item.task.customer_short.name !== null) && (<div className="text-muted"><i className="la la-home mr-1"></i>{this.props.item.task.customer_short.name}</div>)}
                        {(this.props.item.task.project_short !== null && this.props.item.task.project_short.name !== undefined && this.props.item.task.project_short.name !== null) && (<div className="text-muted"><i className="la la-folder-open-o mr-1"></i>{this.props.item.task.project_short.name}</div>)}
                    </>}
                    <div className="text-muted">{this.props.item.from && (<><i className="la la-user mr-1"></i>{this.props.item.from.firstname + ' ' + this.props.item.from.lastname.charAt(0)}</>)}</div>
                </Link>
            </div>
        </div>



    }

    render() {
        return this.generateItem();
    }
}