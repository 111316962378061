import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import moment from "moment";
import { useSubheader } from "../../../../_metronic/layout";
import { usePageTitle } from "../../../../providers/PageTitleProvider";
import CustomerInfoModal from "./CustomerInfoModal";
import { useHistory } from "react-router-dom";
import { CustomerDepartmentInfo } from "./partials/CustomerDepartmentInfo";
import { CustomerContacts } from "./partials/CustomerContacts";
import { useUserProvider } from "../../../../providers/AllUsersProvider";

export function CustomerInfo(props) {

    const userProvider = useUserProvider();

    const pageTitle = usePageTitle();
    const subheader = useSubheader();

    const history = useHistory();

    const [showCustomerModal, setShowCustomerModal] = useState(false);
    const [formTypeDelete, setFormTypeDelete] = useState(false);
    const [lCustomer, setLCustomer] = useState({});

    const modalSubmit = () => {
        setShowCustomerModal(false);
        if (formTypeDelete) {
            history.push('/kunden');
            setFormTypeDelete(false);
            props.refetch();
        } else {
            props.refetch();
        }
    }
    const modalClose = () => {
        setShowCustomerModal(false);
        setFormTypeDelete(false);
    }


    useEffect(() => {
        setLCustomer(props.customer);
        subheader.setTitle(props.customer.name);
        subheader.setActions(null);
        pageTitle.setPageTitle(`🐮 Infos${props.customer.name !== undefined ? ' | ' + props.customer.name : ''}`);
    }, [props.customer, subheader]);

    return (<>
        <div className="row">
            <div className="col-12 col-xl-5">
                <div className={`card card-custom card-stretch  gutter-b`}>
                    <div className="card-header border-1">
                        <h3 className="card-title text-dark">
                            Kunden Informationen
                        </h3>
                        <div className="card-toolbar">

                            <div className="d-flex d-print-none justify-content-end flex-nowrap">
                                {userProvider.can('editCustomers') &&

                                    <Button variant="btn btn-icon btn-clean btn-hover-light-primary btn-sm mr-2"
                                        onClick={() => setShowCustomerModal(true)}
                                    ><i className="la la-pencil icon-md text-primary"></i></Button>
                                }
                                {userProvider.can('deleteCustomers') &&
                                    <Button variant="btn btn-icon btn-clean btn-hover-light-danger btn-sm"
                                        onClick={() => { setFormTypeDelete(true); setShowCustomerModal(true) }}
                                    ><i className="la la-trash icon-md text-danger"></i></Button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <Table responsive bordered={false} borderless={true}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '30%' }}>Name:</td><td>{lCustomer.name}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '30%' }}>Erstellt am:</td><td>{moment(lCustomer.created_at).format('DD.MM.YYYY')}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '30%' }}>Adresse:</td><td>{lCustomer.address}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '30%' }}>PLZ/Ort:</td><td>{lCustomer.zip} {lCustomer.city}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '30%' }}>Land:</td><td>{lCustomer.country}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '30%' }}>E-Mail:</td><td>{lCustomer.email}</td>
                                </tr>

                                <tr>
                                    <td style={{ width: '30%' }}>Telefon:</td><td>{lCustomer.phone}</td>
                                </tr>
                                {lCustomer.data !== null && lCustomer.data !== undefined && lCustomer.data.contact !== undefined && <>
                                    <tr>
                                        <td style={{ width: '30%' }}>Hauptwebsite URL:</td><td>{lCustomer.data.contact.url}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}><hr /></td>
                                    </tr>

                                    <tr>
                                        <td style={{ width: '30%' }}>UID:</td><td>{lCustomer.data.contact.uid}</td>
                                    </tr>

                                    <tr>
                                        <td style={{ width: '30%' }}>Steuernummer:</td><td>{lCustomer.data.contact.stnr}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '30%' }}>Firmenbuchnummer:</td><td>{lCustomer.data.contact.fbnr}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '30%' }}>Firmenbuchgericht:</td><td>{lCustomer.data.contact.fbgr}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '30%' }}>Sitz:</td><td>{lCustomer.data.contact.sitz}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}><hr /></td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '30%' }}>Bank:</td><td>{lCustomer.data.contact.bank}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '30%' }}>Kontonummer:</td><td>{lCustomer.data.contact.kontonummer}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '30%' }}>BLZ:</td><td>{lCustomer.data.contact.blz}</td>
                                    </tr>

                                    <tr>
                                        <td style={{ width: '30%' }}>IBAN:</td><td>{lCustomer.data.contact.iban}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '30%' }}>BIC:</td><td>{lCustomer.data.contact.bic}</td>
                                    </tr>

                                </>}



                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            <div className="col-12 col-xl-7">
                <div className={`card card-custom  card-stretch gutter-b`}>
                    <div className="card-header border-1">
                        <h3 className="card-title text-dark">
                            Kunden Kontakte
                        </h3>
                    </div>
                    <div className="card-body">
                        <CustomerContacts customer={lCustomer} />
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-12">

                <CustomerDepartmentInfo customer={lCustomer} />
            </div>
        </div>
        {userProvider.canAny(['editCustomers', 'deleteCustomers']) &&
            <CustomerInfoModal type={formTypeDelete ? 'delete' : 'edit'} show={showCustomerModal} onHide={modalClose} onSubmit={modalSubmit} edit={lCustomer.id} />
        }
    </>
    )
}


/*
{props.linkToOverview !== undefined && props.linkToOverview === true &&
                        (<Link to="/stempeluhr" className="nav-link btn btn-icon btn-clean btn-hover-light-primary btn-sm">
                            <span className="svg-icon svg-icon-sm">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/Text/Bullet-list.svg"
                                    )}
                                />
                            </span>
                        </Link>
                        )}
*/