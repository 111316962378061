import React from "react";
import SVG from "react-inlinesvg";
import { Link, useLocation } from 'react-router-dom'
import { useUserProvider } from "../../../../providers/AllUsersProvider";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";


export function InterCredMenu(props) {
    const userProvider = useUserProvider();

    const loc = useLocation();
    const defaultClass = "btn bg-dark-o-10 btn-hover-primary mb-6 px-6 py-6 roundedNew = () => import('@/components/New.vue') d-flex align-items-center text-left";
    const activeClass = "btn btn-primary btn-hover-primary mb-6 px-6 py-6 roundedNew = () => import('@/components/New.vue') d-flex align-items-center text-left";

    const arrayEquals = (a, b) => {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }

    const getClass = (r) => {

        let ll = loc.pathname.split('/');
        let rr = r.split('/');

        return arrayEquals(ll, rr) ? activeClass : defaultClass;
    }

    return (<>
        <div className="p-4 px-lg-10 pt-lg-8">
            {/* userProvider.can('showInterCredGeneral') && 
             */}
            <div className="linkItem">
                <Link to="/internal-credentials/general" className={getClass('/internal-credentials/general')}>
                    <span className="svg-icon svg-icon-2x d-block mr-6 mb-1">
                        <SVG
                            src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}
                        ></SVG>
                    </span>
                    <span className="font-weight-bold font-size-lg ">
                        Gemeinsame Zugänge
                    </span>
                </Link>
            </div>
            {/* userProvider.can('showInterCredOwn') && 
             */}
            <div className="linkItem">
                <Link to="/internal-credentials/user" className={getClass('/internal-credentials/user')}>
                    <span className="svg-icon svg-icon-2x d-block mr-6 mb-1">
                        <SVG
                            src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                        ></SVG>
                    </span>
                    <span className="font-weight-bold font-size-lg ">
                        Benutzerdefinierte Zugänge
                    </span>
                </Link>
            </div>
            {/* userProvider.can('showInterCredCritical') && 
             */}

            <div className="linkItem">
                <Link to="/internal-credentials/critical" className={getClass('/internal-credentials/critical')}>
                    <span className="svg-icon svg-icon-2x d-block mr-6 mb-1">
                        <SVG
                            src={toAbsoluteUrl("/media/svg/icons/General/Shield-protected.svg")}
                        ></SVG>
                    </span>
                    <span className="font-weight-bold font-size-lg ">
                        Sicherheitskritische Zugänge
                    </span>
                </Link>
            </div>


        </div>

    </>);
}