import React, { useEffect, useState } from "react";
import Select from 'react-select'
import { useUserProvider } from "../../../../../providers/AllUsersProvider";

import axios from "axios";
import { toast } from 'react-toastify';

export function SelectUsers(props) {
    const [task, setTask] = useState({});

    const userProvider = useUserProvider();
    const [selectConfig, setSelectConfig] = useState({});
    const [selected, setSelected] = useState([]);

    /* const CustomOption = ({ innerRef, innerProps, data }) => {
        const tStatus = userProvider.getTaskStatusById(data.value);
        const style = { backgroundColor: `#${tStatus.color}`, color: `#${tStatus.fontcolor}` };
        return <div {...innerProps} key={data.value} ref={innerRef} className="status-item cursor-pointer font-size-md rounded-md py-4 px-3 text-center m-2" style={style} >{tStatus.name}</div>;
    }
    const CustomControl = ({ children, data, ...props }) => {
        const tStatus = userProvider.getTaskStatusById(data.value);
        if (tStatus === null)
            return <div key="none" className="status-item bg-light cursor-pointer font-size-md rounded-md py-1 px-3 text-center flex-grow-1">Kein Status</div>
        const style = { backgroundColor: `#${tStatus.color}`, color: `#${tStatus.fontcolor}` };
        return <div {...props} key={data.value} className="status-item cursor-pointer font-size-md rounded-md py-1 px-3 text-center flex-grow-1" style={style} >{tStatus.name}</div>;
    }; */
    
    const mapToSelectConfig = () => {
        let lUsers = userProvider.allUsers.filter((u) => u.deleted_at === null);
        const users = lUsers.map((item) => {
            return { label: item.name, value: item.id };
        });
        setSelectConfig(users);
    };


    const setSelectedItems = () => {
        if (task.persons === undefined || task.persons === null)
            return;
        const itemsAll = userProvider.getUsersByIds(task.persons);

        if(itemsAll === null || itemsAll.length <= 0)
            return
        const items = itemsAll.map((item) => {
            if(item === null)
                return;
            return { label: item.name, value: item.id }
        })
        setSelected(items);
    };

    const updateTask = (persons) => {
        let personsArray = null;
        if(persons !== null){
            personsArray = persons.map((person) => person.value);
            personsArray = JSON.stringify(personsArray);
        }
        let body = {
            persons: personsArray,
        };
        axios.post(process.env.REACT_APP_API_URL + '/api/tasks/' + task.id + '/updatePersons', body).then((d) => {
            toast.success("Personen Zuordnung wurde geändert")
            props.refetch();
        }).catch((e) => { 
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.",{autoClose: 5500});
            props.refetch();
        });
    };

    const applyPersons = (persons) => {
        setSelected(persons);
        updateTask(persons);
    };

    useEffect(() => {
        setTask(props.task);
    }, [props.task]);
    useEffect(() => {
        if (userProvider.allUsers.length <= 0)
            return;
        mapToSelectConfig();
        setSelectedItems();
    }, [task, userProvider.allUsers]);

    return <> {userProvider.allUsers.length > 0 ? <div className="statusSelect"><Select options={selectConfig} value={selected}
        classNamePrefix="sl2def"
        isClearable={false}
        components={{  DropdownIndicator:null }}
        //Option: CustomOption, SingleValue: CustomControl,
        lang="de"
        noOptionsMessage={() => "Person nicht gefunden"}
        key="persons"
        isMulti
        onChange={(val) => applyPersons(val)}
        placeholder={<div key="none" className="status-item bg-light cursor-pointer font-size-md rounded-md py-1 px-3 text-center flex-grow-1">Keine Person hinzugefügt</div>}
    /></div> : null}</>
}