import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Form, Col } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CopyToClipboard from "react-copy-to-clipboard";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy as highlightStyle } from "react-syntax-highlighter/dist/esm/styles/prism";
import axios from "axios";
import { toast } from 'react-toastify';


export function TecExtensionUploadModal(props) {

    const initialValues = {
        file: '',
    };
    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            file: Yup.mixed().required('A file is required')
                .test('fileFormat', 'PDF only', (value) => {
                    return value && ['application/zip'].includes(value.type);
                })
        }),
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {

            setSubmitting(false);
            let formData = new FormData();
            formData.token = props.item.token;
            formData.append('token', props.item.token);
            formData.append('via', 'direct');
            formData.append('file', values.file);

            axios.post(process.env.REACT_APP_API_URL + '/api/extup', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((d) => {
                toast.success("Upload abgeschlossen");
                props.onSubmit();
            }).catch(e => {
                toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
                props.onSubmit();
            });

            setSubmitting(true);

        },
    });

    return <Modal
        show={props.show}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={props.onHide}
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Extension hochladen
            </Modal.Title>
        </Modal.Header>
        {props.item !== null &&
            <Form onSubmit={formik.handleSubmit} className={`bg-white position-relative`}>
                <Modal.Body>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Datei</Form.Label>
                            <div className="custom-file">
                                <input type="file" className="custom-file-input" id="fupload" name="file"
                                    onChange={(event) => {
                                        formik.setFieldValue("file", event.currentTarget.files[0]);
                                    }}
                                />
                                <label className="custom-file-label" htmlFor="fupload">Direkter Upload</label>
                            </div>

                        </Form.Group>
                    </Form.Row>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" type="submit">Hochladen</Button>
                    <Button variant="default " onClick={props.onHide}>Schließen</Button>
                </Modal.Footer>
            </Form>
        }
    </Modal>

}
