import React, { useEffect, useState } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import { usePageTitle } from "../../../../providers/PageTitleProvider";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { TechnikPasswortGenerator } from "./tools/TechnikPasswortGenerator";
import { TechnikDnsCheck } from "./tools/TechnikDnsCheck";
import { TechnikSSLCheck } from "./tools/TechnikSSLCheck";
import { TechnikQrGenerator } from "./tools/TechnikQrGenerator";

function NavItem(props) {

    const itemClass = "nav-link border py-10 d-flex flex-grow-1 rounded flex-column align-items-center cursor-pointer " + (props.active !== undefined && props.tabKey !== undefined  && props.active === props.tabKey ? 'active' : '')
    return <li className="nav-item d-flex col-sm flex-grow-1 flex-shrink-0 mr-3 mb-3 mb-lg-0">
        <div className={itemClass} data-toggle="pill" onClick={() => props.onClick(props.tabKey)}>
            <span className="nav-icon py-2 w-auto">
                <span className="svg-icon svg-icon-3x">
                    <SVG
                        src={toAbsoluteUrl(props.iconPath)}
                    />
                </span>
            </span>
            <span className="nav-text font-size-lg py-2 font-weight-bold text-center">{props.title}</span>
        </div>
    </li>

}
function TabItem(props) {

    const itemClass = (props.active !== undefined && props.tabKey !== undefined  && props.active === props.tabKey ? 'd-block' : 'd-none')
    return <div className={itemClass}>{props.children}</div>
}


export function TechnikTools(props) {
    const subheader = useSubheader();
    const pageTitle = usePageTitle();

    const [active, setActive] = useState(0);

    useEffect(() => {
        subheader.setTitle("Technik Tools");
        pageTitle.setPageTitle(`🐮 Technik Tools`);
    }, [])

    return <>
        <div className="card card-custom">

            <div className="card-body">
                <ul className="dashboard-tabs nav nav-pills nav-primary row row-paddingless m-0 p-0 flex-column flex-sm-row">
                    <NavItem active={active} tabKey={0} onClick={setActive} iconPath="/media/svg/icons/Home/Key.svg" title="Passwort Generator" />
                    <NavItem active={active} tabKey={1} onClick={setActive} iconPath="/media/svg/icons/Code/Github.svg" title="DNS Record Check" />
                    <NavItem active={active} tabKey={2} onClick={setActive} iconPath="/media/svg/icons/General/Shield-check.svg" title="SSL Check" />
                    <NavItem active={active} tabKey={3} onClick={setActive} iconPath="/media/svg/icons/Shopping/Barcode-read.svg" title="QR Code Generator" />
                    {
                        //<NavItem active={active} tabKey={4} onClick={setActive} iconPath="/media/svg/icons/General/Cursor.svg" title="Link List" />
                    }
                </ul>
            </div>
        </div>

        <div className="tabHolder mt-8">
            <TabItem active={active} tabKey={0}>
                <TechnikPasswortGenerator />
            </TabItem>
            <TabItem active={active} tabKey={1}>
                <TechnikDnsCheck />
            </TabItem>
            <TabItem active={active} tabKey={2}>
                <TechnikSSLCheck />
            </TabItem>
            <TabItem active={active} tabKey={3}>
                <TechnikQrGenerator />
            </TabItem>
        </div>


    </>
}