import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Form, Col } from "react-bootstrap";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import axios from "axios";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { DatePickerLocale } from "../../../constants/DatePickerLocale";
import moment from "moment-timezone";
import InputMask from "react-input-mask";

export default function BBBModal(props) {

    const pickerStartRef = useRef();
    const pickerEndRef = useRef();

    const initialValues = {
        name: '',
        start: moment().format('DD.MM.YYYY'),
        starttime: '',
        end: moment().format('DD.MM.YYYY'),
        endtime: '',
    };
    const saveNew = (values) => {
        let body = {
            name: values.name,
            start: values.start + ' '+ values.starttime,
            end: values.end + ' '+ values.endtime,
        };
        return axios.post(process.env.REACT_APP_API_URL + '/api/bbb', body);
    };

    const deleteData = () => {
        axios.delete(process.env.REACT_APP_API_URL + '/api/bbb/' + props.edit).then((d) => {
            toast.success("Gelöscht");
            props.onSubmit();
        }).catch(e => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
            props.onSubmit();
        });
    }

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            start: Yup.string().required(),
            starttime: Yup.string().required().test('is_time', 'Must be time!', (value) => {
                if (value === undefined)
                    return false;
                return value.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/);
            }),
            endtime: Yup.string().required().test('is_time', 'Must be time!', (value) => {
                if (value === undefined)
                    return false;
                return value.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/);
            }),
            //end: Yup.string().required(),
        }),
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            setSubmitting(false);
            saveNew(values).then(d => { toast.success("Gespeichert"); resetForm(); props.onSubmit() }).catch(e => console.log(e));
            setSubmitting(true);

        },
    });

    const startsWithTwoStart = formik.values.starttime[0] === '2';
    const startsWithTwoEnd = formik.values.endtime[0] === '2';

    const timeMask = (val) => [
        /[0-2]/,
        val ? /[0-3]/ : /[0-9]/,
        ':',
        /[0-5]/,
        /[0-9]/
    ];

    const renderForm = () => {
        return <Form onSubmit={formik.handleSubmit} className={`bg-white position-relative`}>
            <Modal.Body>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Name"
                            name="name"
                            value={''}
                            {...formik.getFieldProps("name")}
                            isInvalid={formik.errors.name}
                        />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Start des Meetings</Form.Label>
                        <DateRangePicker
                            ref={pickerStartRef}
                            initialSettings={{
                                singleDatePicker: true,
                                locale: DatePickerLocale,
                                autoApply: true,
                            }}
                            onApply={(e, picker) => formik.setFieldValue('start', picker.startDate.format('DD.MM.YYYY'))}
                        //onCancel={(e, picker) => clearDates(e, picker)}
                        >
                            <Form.Control size="sm" type="text"
                                name="start"
                                onFocus={(e) => e.currentTarget.blur()}
                                style={{ height: "38px" }}
                                value={''}
                                {...formik.getFieldProps("start")}
                                isInvalid={formik.errors.start}
                            />
                        </DateRangePicker>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>&nbsp;</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={moment().format('HH:mm')}
                            as={InputMask}
                            //onChange={handleInput}
                            mask={timeMask(startsWithTwoStart)}
                            name="starttime"
                            value={''}
                            {...formik.getFieldProps("starttime")}
                            isInvalid={formik.errors.starttime}
                        />
                    </Form.Group>
                </Form.Row>



                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Ende / Schließung des Meetings</Form.Label>
                        <DateRangePicker
                            ref={pickerEndRef}
                            initialSettings={{
                                singleDatePicker: true,
                                locale: DatePickerLocale,
                                autoApply: true,
                            }}
                            onApply={(e, picker) => formik.setFieldValue('end', picker.startDate.format('DD.MM.YYYY'))}
                        //onCancel={(e, picker) => clearDates(e, picker)}
                        >
                            <Form.Control size="sm" type="text"
                                name="end"
                                onFocus={(e) => e.currentTarget.blur()}
                                style={{ height: "38px" }}
                                value={''}
                                {...formik.getFieldProps("end")}
                                isInvalid={formik.errors.end}
                            />
                        </DateRangePicker>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>&nbsp;</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={moment().format('HH:mm')}
                            as={InputMask}
                            //onChange={handleInput}
                            mask={timeMask(startsWithTwoEnd)}
                            name="endtime"
                            value={''}
                            {...formik.getFieldProps("endtime")}
                            isInvalid={formik.errors.endtime}
                        />
                    </Form.Group>
                </Form.Row>


            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit">Speichern</Button>
                <Button variant="default " onClick={props.onHide}>Schließen</Button>
            </Modal.Footer>
        </Form>
    }

    const deleteForm = () => {
        return <>
            <Modal.Body>
                <p>Bist du dir sicher dass du dieses Meeting löschen möchtest? <b>Achtung, hierbei wrden auch alle Aufnahemn gelöscht!</b></p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={deleteData}>Ja, Meeting löschen</Button>
                <Button variant="default " onClick={props.onHide}>Nein, Meeting nicht löschen</Button>
            </Modal.Footer>

        </>
    }

    return <>
        <Modal
            show={props.show}
            size={(props.type && props.type === 'delete') ? ' md' : 'lg'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Meeting
                    {
                        (props.type && props.type === 'delete') ? ' löschen' :
                            (props.edit === null) ? ' erstellen' : ' bearbeiten'
                    }
                </Modal.Title>
            </Modal.Header>
            {(props.type && props.type === 'delete') ? deleteForm() : renderForm()}
        </Modal>
    </>

}