import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { TaskInfoEditor } from "ckeditor5-stadl-editor";
import { Button } from "react-bootstrap";
import {toast} from "react-toastify";

export function ProjectEditDescription(props) {

    const [lProject, setLProject] = useState({});
    const [description, setDescription] = useState("");
    const descEditorRef = useRef();

    const apply = () => {
        let body = {};
        body.value = description;
        axios.post(process.env.REACT_APP_API_URL + `/api/projects/${lProject.id}/updateDescription`, body).then(d => {
            toast.success("Gespeichert");
            props.onSubmit();
        }).catch(e => {
            console.log(e)
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
            props.onSubmit();
        });
    }
    useEffect(() => {
        if (props.project !== null)
            setLProject(props.project);
    }, [props.project]);

    useEffect(() => {
        if (lProject.data !== undefined && lProject.data !== null && lProject.data.description !== undefined && lProject.data.description !== null)
            setDescription(lProject.data.description);
    }, [lProject]);


    return <div className="editDescription">
        <CKEditor
            className="h-150px"
            editor={TaskInfoEditor}
            config={{
                language: 'de'
            }}
            data={description}
            onReady={editor => {
                descEditorRef.current = editor;
            }}
            onChange={(event, editor) => {
                setDescription(editor.getData());
            }}

        />
        <div className="d-flex justify-content-end mt-6">
            <Button variant="primary mr-4" onClick={() => apply()}>Speichern</Button>
            <Button variant="default " onClick={props.onHide}>Abbrechen</Button>
        </div>
    </div>
}