import React, { useEffect, useRef, useState } from "react";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { DatePickerLocale } from "../../../../constants/DatePickerLocale";
import moment from "moment";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import { toast } from 'react-toastify';

let DatePickerLocaleHere = DatePickerLocale;
DatePickerLocaleHere.cancelLabel = 'Löschen';

export function SelectTiming(props) {

    const [task, setTask] = useState({});

    const pickerRef = useRef();
    const pickerInputRef = useRef();

    const updateTask = (start, end) => {
        let body = {
            start: start,
            end: end
        };
        axios.post(process.env.REACT_APP_API_URL + '/api/tasks/' + task.id + '/updateTiming', body).then((d) => {
            toast.success("Timing wurde geändert")
            props.refetch();
        }).catch((e) => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.",{autoClose: 5500});
            props.refetch();
         });
    };
    const applyDates = (e, picker) => {
        if (picker.startDate.format('YYYY-MM-DD') === task.from && picker.endDate.format('YYYY-MM-DD') === task.to)
            return;
        updateTask(picker.startDate.format('YYYY-MM-DD'), picker.endDate.format('YYYY-MM-DD'));
        e.target.value = picker.startDate.format('DD.MM.YYYY') + ' - ' + picker.endDate.format('DD.MM.YYYY');
    };


    const clearDates = (e, picker) => {
        pickerRef.current.setStartDate(undefined);
        pickerRef.current.setEndDate(undefined);
        picker.element[0].value = '';

        if (task.from !== null && task.to !== null)
            updateTask('', '');
    }

    const setExistingDates = () => {
        if (task.from === undefined || task.to === undefined || task.from === null || task.to === null)
            return;
        pickerRef.current.setStartDate(moment(task.from));
        pickerRef.current.setEndDate(moment(task.to));

        pickerRef.current.ref.value = moment(task.from).format('DD.MM.YYYY') + ' - ' + moment(task.to).format('DD.MM.YYYY');
    }

    useEffect(() => {
        setTask(props.task);
    }, [props.task]);
    useEffect(() => {
        setExistingDates();
    }, [task]);


    return <><DateRangePicker
        ref={pickerRef}
        initialSettings={{
            showCustomRangeLabel: false,
            locale: DatePickerLocaleHere,
            autoApply: false,
            autoUpdateInput: false,
            /*ranges: {
                'Nächste 3 Tage': [moment(), moment().add(3, 'days')],
                'Nächste 7 Tage': [moment(), moment().add(7, 'days')],
                'Nächste 2 Wochen': [moment(), moment().add(2, 'weeks')],
                'Nächste 4 Wochen': [moment(), moment().add(4, 'weeks')],
                'Nächste 6 Wochen': [moment(), moment().add(6, 'weeks')],
            }*/
        }}
        onApply={(e, picker) => applyDates(e, picker)}
        onCancel={(e, picker) => clearDates(e, picker)}
    >
        <Form.Control size="sm" type="text" onFocus={(e) => e.currentTarget.blur()} style={{ height: "38px" }} ref={pickerInputRef} />
    </DateRangePicker>
        

    </>
}