import React, { useEffect, useRef, useState } from "react";
import { useHtmlClassService, useSubheader } from "../../../../_metronic/layout";
import axios from "axios";
import moment from 'moment';
import Moment from "react-moment";

import Select from 'react-select';

import { shallowEqual, useSelector } from "react-redux";
import { useAsideSecondary } from "../../../../_metronic/layout/_core/MpAsideSecondary";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useUserProvider } from "../../../../providers/AllUsersProvider";
import { Button, Col, Form, Tab, Tabs } from "react-bootstrap";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import { DatePickerLocale } from "../../../constants/DatePickerLocale";

import { toast } from "react-toastify";
import { usePageTitle } from "../../../../providers/PageTitleProvider";
import { StempeluhrUrlaubeGeneral } from "../components/adminBetrieb/StempeluhrUrlaubeGeneral";
import { StempeluhrUrlaubePublic } from "../components/adminBetrieb/StempeluhrUrlaubePublic";


export function StempeluhrAdminBetriebsurlaube(props) {
    const user = useSelector((state) => state.auth.user, shallowEqual);

    const subheader = useSubheader();
    const MpAsideSecondary = useAsideSecondary();
    const pageTitle = usePageTitle();

    const loc = useLocation();
    const history = useHistory();

    const uiService = useHtmlClassService();

    const [key, setKey] = useState('urlaube');

    const setTab = (k) => {
        setKey(k)
        let ll = loc;
        ll.hash = k;
        history.replace(ll);
    }

    useEffect(() => {
        subheader.setActions(null);
        subheader.setTitle("Betriebsurlaube / Feiertage");
        pageTitle.setPageTitle(`🐮 Betriebsurlaube / Feiertage`);
        //fetchVacations();
        MpAsideSecondary.setAsideEnabled(true);
    }, [subheader, uiService]);

    useEffect(() => {
        if (loc.hash !== undefined && loc.hash !== '')
            setKey(loc.hash.replace('#', ''));
    }, [])

    return <div className="row">
        <div className="col-12">
            <div className={`card card-custom`}>
                <div className="card-body">
                    <Tabs
                        id="uncontrolled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setTab(k)}
                    >
                        <Tab eventKey="urlaube" title="Betriebsurlaube">
                            <StempeluhrUrlaubeGeneral />
                        </Tab>
                        <Tab eventKey="feiertage" title="Feiertage">
                            <StempeluhrUrlaubePublic />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    </div>;
}