
import React, { useEffect } from "react";
import axios from "axios";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg"
import { Link } from "react-router-dom";
export class DashboardStempelStats extends React.Component {
    state = {
        stats: []
    }

    fetchStats() {
        return axios.get(process.env.REACT_APP_API_URL + '/api/stempelstats').then(({ data }) => {
            this.setState({ stats: data });
            setTimeout(() => this.fetchStats(), 30000);
        }).catch(e => null);
    }

    componentDidMount() {
        this.fetchStats();
    }
    componentDidUpdate() {
    }


    render() {
        return (Object.keys(this.state.stats).length > 0 &&
            <Link to="/stempeluhr" className="d-xl-flex mb-7">
                <div className={`col ${this.state.stats.diff >= 0 ? 'bg-light-success' : ' bg-light-danger'}  px-6 py-4 rounded-md mr-4 mb-8 mb-xl-0`}>
                    <div className={`${this.state.stats.diff >= 0 ? 'text-success' : ' text-danger'} font-size-h6 font-size-sm`}>
                        {this.state.stats.diffReadable && this.state.stats.diffReadable}
                    </div>
                    <div className={`${this.state.stats.diff >= 0 ? 'text-success' : ' text-danger'} font-weight-bold font-size-sm mt-2`}>
                        Gleitzeit
                    </div>
                </div>
                <div className={`col ${this.state.stats.vacationAmountRest >= 0 ? 'bg-light-success' : ' bg-light-danger'}  px-6 py-4 rounded-md mr-4 mb-8 mb-xl-0`}>
                    <div className={`${this.state.stats.vacationAmountRest >= 0 ? 'text-success' : ' text-danger'} font-size-h6 font-size-sm`}>
                        {this.state.stats.vacationAmountRest && this.state.stats.vacationAmountRest} Tage
                    </div>
                    <div className={`${this.state.stats.vacationAmountRest >= 0 ? 'text-success' : ' text-danger'} font-weight-bold font-size-sm mt-2`}>
                        Resturlaub
                    </div>
                </div>
                <div className={`col ${this.state.stats.invalid_days <= 0 ? 'bg-light-success' : ' bg-light-danger'}  px-6 py-4 rounded-md mb-8 mb-xl-0`}>
                    <div className={`${this.state.stats.invalid_days <= 0 ? 'text-success' : ' text-danger'} font-size-h6 font-size-sm`}>
                        {this.state.stats.invalid_days && this.state.stats.invalid_days}x
                    </div>
                    <div className={`${this.state.stats.invalid_days <= 0 ? 'text-success' : ' text-danger'} font-weight-bold font-size-sm mt-2`}>
                        Fehlbuchungen
                    </div>
                </div>

            </Link>
        )
    }
}
