import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Moment from "react-moment";
import { useTaskStatusProvider } from "../../../../providers/TaskStatusProvider";
import { useUserProvider } from "../../../../providers/AllUsersProvider";
import { shallowEqual, useSelector } from "react-redux";
import { Form, OverlayTrigger, Popover, Spinner, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import moment from "moment";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import { DatePickerLocale } from "../../../constants/DatePickerLocale";
import { TaskSingleCreationModal } from "../../ProjectTasks/partials/TaskSingleCreationModal";

export function DashboardTaskTab(props) {

    const [allTasks, setAllTaks] = useState([]);
    const [tasks, setTasks] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const taskStatusProvider = useTaskStatusProvider();
    const userProvider = useUserProvider();
    const currentUser = useSelector((state) => state.auth.user, shallowEqual);

    const [searchTerm, setSearchTerm] = useState('');

    const [pickerDates, setPickerDates] = useState({ start: moment().format('YYYY-MM-DD'), end: moment().add(4, 'weeks').format('YYYY-MM-DD') })


    const pickerRef = useRef();
    const pickerInputRef = useRef();

    const renderUsers = (persons, id) => {
        const p = userProvider.getUsersByIds(persons);
        const hasMe = persons.includes(currentUser.id);

        if (p.length <= 0)
            return <div className="btn btn-light btn-icon position-relative">
                <i className='la la-users icon-2x'></i>
                <span className="badge badge-light badge-count-mini position-absolute right-0 mr-n2 mt-n2">0</span>
            </div>;


        return <OverlayTrigger
            key={id}
            overlay={
                <Popover>
                    <div className="p-4">
                        {p.map((person, index) => {
                            return <div key={person.id} className={`${person.id === currentUser.id && "font-size-lg font-weight-bold"} p-1`}>{person.firstname} {person.lastname}</div>
                        })}
                    </div>
                </Popover>
            }
        >
            <div className={`btn ${hasMe ? "btn-light-success" : "btn-light-primary"} btn-icon position-relative`}>
                <i className='la la-users icon-2x'></i>
                <span className="badge badge-primary badge-count-mini position-absolute right-0 mr-n2 mt-n2">{p.length}</span>
            </div>
        </OverlayTrigger>
    }
    const renderTaskStatus = (id) => {
        if (id === null)
            return <div className="status-item bg-light font-size-xs rounded-md p-3">kein Status</div>;

        const tStatus = taskStatusProvider.getTaskStatusById(id);
        const style = { backgroundColor: `#${tStatus.color}`, color: `#${tStatus.fontcolor}` };
        return <div className="status-item font-size-xs rounded-md p-3" style={style} >{tStatus.name}</div>;

    };
    const renderBullet = (id) => {
        if (id === null)
            return <span className="bullet bullet-bar min-w-3px bg-light align-self-stretch"></span>;
        const tStatus = taskStatusProvider.getTaskStatusById(id);
        return <span className="bullet bullet-bar min-w-3px align-self-stretch" style={{ backgroundColor: `#${tStatus.color}` }}></span>
    };
    const renderCommentButton = (item) => {
        return <div className="comments">
            <div className={`btn btn-light-primary ${item.updatesCount > 0 ? 'btn-light-primary' : 'btn-light'} btn-icon position-relative`}>
                <i className='la la-comments-o icon-2x'></i>
                <span className={`badge ${item.updatesCount > 0 ? 'badge-primary' : 'badge-light'} badge-count-mini position-absolute right-0 mr-n2 mt-n2`}>{item.updatesCount}</span>
            </div>
        </div>;
    }
    const getTaskLink = (item) => {
        if (item.project_id !== null)
            return `/projekte/${item.project_id}/task/${item.id}`;

        return `/projekte/0_${item.customer_id}/task/${item.id}`;

    }

    const fetchData = () => {
        setIsLoading(true);
        let body = {};
        if (props.selectedUser !== undefined && props.selectedUser.value !== undefined)
            body.user_id = props.selectedUser.value;
        if(props.type === 'specific'){
            body.start = pickerDates.start;
            body.end = pickerDates.end;
        }
        axios.post(process.env.REACT_APP_API_URL + '/api/tasks_my/' + props.type, body).then(({ data }) => {
            setTasks(data);
            setAllTaks(data);
            setIsLoading(false);

        });
    }

    const handleSearch = () => {
        var filtered = allTasks;
        if (searchTerm !== '' && searchTerm.length > 2) {
            filtered = allTasks.filter(t => {
                return t.name.toLowerCase().includes(searchTerm.toLowerCase());// || t.user?.toLowerCase().includes(searchTerm.toLowerCase());
            });

            filtered.sort((a, b) => a.name.localeCompare(b.name));
            setTasks(filtered);
            return;
        }
        setTasks(allTasks);

    }


    const applyPicker = (e, picker) => {
        setPickerDates({ start: picker.startDate.format('YYYY-MM-DD'), end: picker.endDate.format('YYYY-MM-DD') });
    };


    useEffect(() => {
        if (props.type !== undefined && userProvider.allUsers.length > 0)
            fetchData();
    }, [userProvider.allUsers])

    useEffect(() => {
        if (props.type !== undefined && userProvider.allUsers.length > 0)
            fetchData();
    }, [props.selectedUser])

    useEffect(() => {
        if (props.type !== undefined && userProvider.allUsers.length > 0)
            fetchData();
    }, [pickerDates])

    useEffect(() => {
        handleSearch();
    }, [searchTerm])


    const renderTasks = () => {

        return tasks.length > 0 && tasks.map((item, i) => {

            return <Link to={getTaskLink(item)} className="d-block p-5 border-bottom bg-hover-light-o-20" key={item.id}>
                <div className="row min-h-50px flex-lg-nowrap">
                    {renderBullet((item.status ? item.status : null))}
                    <div className='col-12 col-lg-5 font-weight-bold font-size-lg py-1'>
                        <div className="d-flex">
                            <div className="title flex-grow-1 pr-2">
                                {item.name}
                                
                                {(item.customer_short && item.customer_short.name) && <><br/><span className="font-size-sm text-dark-50">{item.customer_short.name}</span></>}
                                {(item.project_short && item.project_short.name) && <><br/><span className="font-size-sm text-dark-50">{item.project_short.name}</span></>}
                            </div>
                            {renderCommentButton(item)}
                        </div>
                    </div>
                    <div className='col-9 col-lg-3 mt-3 mt-lg-0  font-weight-bold font-size-lg py-1 text-lg-center'>
                        {item.from && <>
                            <Moment format="DD.MM.YYYY">{item.from}</Moment>
                        </>}
                        {item.to && <>
                            &nbsp;-&nbsp;<Moment format="DD.MM.YYYY">{item.to}</Moment>
                        </>}

                    </div>
                    
                    <div className='col-3 col-lg-2 text-right text-lg-center py-1'>
                        {renderUsers((item.persons ? item.persons : []), item.id)}
                    </div>
                    <div className='col-12 col-lg-2 text-center py-1'>
                        {renderTaskStatus((item.status ? item.status : null))}
                    </div>
                </div>
            </Link>

        })
    }
    return <>
        {isLoading ?
            <div className={`d-flex flex-column align-items-center justify-content-center h-full mt-4 mb-4`}><Spinner animation="border" variant="primary" /></div>
            :
            <>
                <div className="d-flex mb-6 d-print-none">

                    <div className="input-icon h-40px flex-grow-1">
                        <input
                            type="text"
                            className="form-control form-control-lg form-control-solid h-40px"
                            placeholder="Suchen ..."
                            id="hostsSearch"
                            autoComplete="false"
                            //autoFocus={true}
                            onKeyUp={(e) => setSearchTerm(e.currentTarget.value)}
                        />
                        <span>
                            <span className="svg-icon svg-icon-lg">
                                <SVG
                                    src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                                />
                            </span>
                        </span>
                    </div>
                    
                    {props.type === 'specific' &&
                        <div className="h-40px flex-grow-1 mt-4 mt-md-0 ml-md-6">
                            <DateRangePicker
                                ref={pickerRef}
                                initialSettings={{
                                    showCustomRangeLabel: false,
                                    locale: DatePickerLocale,
                                    autoApply: false,
                                    autoUpdateInput: true
                                }}
                                onApply={(e, picker) => applyPicker(e, picker)}
                            //onCancel={(e, picker) => clearDates(e, picker)}
                            >
                                <Form.Control defaultValue={moment(pickerDates.start).format('DD.MM.YYYY') + ' - ' + moment(pickerDates.end).format('DD.MM.YYYY')} size="sm" type="text" onFocus={(e) => e.currentTarget.blur()} style={{ height: "38px" }} ref={pickerInputRef} />
                            </DateRangePicker>
                        </div>
                    }

                    {<OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="latest-project">Task erstellen</Tooltip>
                        }
                    >
                        <div
                            onClick={() => setShowModal(true)}
                            className={`btn btn-icon btn-default btn-hover-primary ml-3 h-40px w-40px flex-shrink-0`}
                        >
                            <span className="svg-icon svg-icon-lg">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/Code/Plus.svg"
                                    )}
                                />
                            </span>
                        </div>
                    </OverlayTrigger>
                    }
                </div>

                {tasks.length > 0 ?
                    <>
                        <div className="d-none d-lg-block bg-dark-o-10 mb-0 rounded-md font-weight-bold font-size-lg py-2 px-5">
                            <div className="row">

                                <div className="col-5">
                                    Name
                                </div>
                                <div className="col-3 text-center">
                                    Zeitraum
                                </div>
                                <div className="col-2 text-center">
                                    Personen
                                </div>
                                <div className="col-2 text-center">
                                    Status
                                </div>

                            </div>

                        </div>
                        {renderTasks()}
                    </>
                    : <div className="alert bg-light-info mt-10">Keine Tasks für diesen Zeitraum.</div>
                }
                <TaskSingleCreationModal show={showModal} onHide={() => setShowModal(false)} onSubmit={() => fetchData()} />
            </>
        }
    </>

}