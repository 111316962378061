/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import { AllUsersProvider } from "../providers/AllUsersProvider";
import { TaskStatusProvider } from "../providers/TaskStatusProvider";
import { ErrorPage } from "./modules/ErrorPages/ErrorPage";
import { EchoProvider } from "../providers/EchoProvider";
import { NotificationBadgeProvider } from "../providers/NotificationBadgeProvider";


export function Routes() {
    const { isAuthorized } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual
    );

    return (
        <Switch>
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from="/auth" to="/" />
            )}

            <Route path="/error/:type" component={ErrorPage} />
            <Route path="/logout" component={Logout} />


            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/login" />
            ) : (
                <AllUsersProvider>
                    <TaskStatusProvider>
                        <EchoProvider>
                            <NotificationBadgeProvider>
                                <Layout>
                                    <BasePage />
                                </Layout>
                            </NotificationBadgeProvider>
                        </EchoProvider>
                    </TaskStatusProvider>
                </AllUsersProvider>
            )}
        </Switch>
    );
}
