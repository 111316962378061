import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { Link, useLocation } from 'react-router-dom'
import { useUserProvider } from "../../../../providers/AllUsersProvider";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import axios from "axios";

export function StempeluhrMenu(props) {

    const userProvider = useUserProvider();

    const loc = useLocation();
    const defaultClass = "btn bg-dark-o-10 btn-hover-primary mb-6 px-6 py-6 roundedNew = () => import('@/components/New.vue') d-flex align-items-center text-left";
    const activeClass = "btn btn-primary btn-hover-primary mb-6 px-6 py-6 roundedNew = () => import('@/components/New.vue') d-flex align-items-center text-left";

    const [invalidStempelEntries, setInvalidStempelEntries] = useState(0);

    const arrayEquals = (a, b) => {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }

    const getClass = (r) => {

        let ll = loc.pathname.split('/');
        let rr = r.split('/');

        return arrayEquals(ll, rr) ? activeClass : defaultClass;
    }
    const countInvalidStempelEntries = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/stempel_invalid_count').then(({ data }) => {
            setInvalidStempelEntries(data);
        }).catch((e) => {
            setInvalidStempelEntries(0);
        });
    }
    return (<>
        <div className="p-4 px-lg-10 pt-lg-8">
            {userProvider.canAny(['canStempel', 'adminStempel']) &&
                <div className="linkItem">
                    <Link to="/stempeluhr/details" className={getClass('/stempeluhr/details')}>
                        <span className="svg-icon svg-icon-2x d-block mr-6 mb-1">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Home/Timer.svg")}
                            ></SVG>
                        </span>
                        <span className="font-weight-bold font-size-lg ">
                            Stempeluhr
                        </span>
                    </Link>
                </div>
            }
            {userProvider.canAny(['canStempel', 'adminStempel']) &&

                <div className="linkItem">
                    <Link to="/stempeluhr/urlaube" className={getClass('/stempeluhr/urlaube')}>
                        <span className="svg-icon svg-icon-2x d-block mr-6 mb-1">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Clothes/Briefcase.svg")}
                            ></SVG>
                        </span>
                        <span className="font-weight-bold font-size-lg ">
                            Urlaubs-Übersicht
                        </span>
                    </Link>
                </div>
            }
            {userProvider.can('adminStempel') &&

                <div className="linkItem">
                    <Link to="/stempeluhr/admin-urlaube" className={getClass('/stempeluhr/admin-urlaube')}>
                        <span className="svg-icon svg-icon-2x d-block mr-6 mb-1">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Shopping/Sort1.svg")}
                            ></SVG>
                        </span>
                        <span className="font-weight-bold font-size-lg ">
                            Urlaubs-Administration
                        </span>
                    </Link>
                </div>
            }

            {userProvider.can('adminStempel') &&
                <div className="linkItem">
                    <Link to="/stempeluhr/betriebsurlaube-feiertage" className={getClass('/stempeluhr/betriebsurlaube-feiertage')}>
                        <span className="svg-icon svg-icon-2x d-block mr-6 mb-1">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Navigation/Waiting.svg")}
                            ></SVG>
                        </span>
                        <span className="font-weight-bold font-size-lg ">
                            Betriebsurlaube <br /> Feiertage
                        </span>
                    </Link>
                </div>
            }
            {userProvider.can('adminStempel') &&

                <div className="linkItem">
                    <Link to="/stempeluhr/admin-fehlbuchungen" className={getClass('/stempeluhr/admin-fehlbuchungen')}>
                        <span className="svg-icon svg-icon-2x d-block mr-6 mb-1 position-relative">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Code/Error-circle.svg")}
                            ></SVG>
                            <span className={`badge ${invalidStempelEntries > 0 ? 'badge-danger' : 'badge-light'} badge-count-mini position-absolute right-0 mr-n2 mt-n2`}>{invalidStempelEntries}</span>
                        </span>
                        {countInvalidStempelEntries()}
                        <span className="font-weight-bold font-size-lg position-relative">
                            Fehlbuchungen
                        </span>
                    </Link>
                </div>
            }
            {userProvider.can('adminStempel') &&
                <div className="linkItem">
                    <Link to="/stempeluhr/admin-profile" className={getClass('/stempeluhr/admin-profile')}>
                        <span className="svg-icon svg-icon-2x d-block mr-6 mb-1">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}
                            ></SVG>
                        </span>
                        <span className="font-weight-bold font-size-lg ">
                            Stempelprofile
                        </span>
                    </Link>
                </div>
            }
            {userProvider.can('adminStempel') &&
                <div className="linkItem">
                    <Link to="/stempeluhr/chips" className={getClass('/stempeluhr/chips')}>
                        <span className="svg-icon svg-icon-2x d-block mr-6 mb-1">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Shopping/Credit-card.svg")}
                            ></SVG>
                        </span>
                        <span className="font-weight-bold font-size-lg ">
                            NFC Chip Verwaltung
                        </span>
                    </Link>
                </div>
            }
        </div>

    </>);
}