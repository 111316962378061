import React, { useEffect, useState } from "react";
import { useContentContainer } from "../../../../_metronic/layout/_core/MpContentContainer";
import axios from "axios";
import { useSubheader } from "../../../../_metronic/layout";
import { useAsideSecondary } from "../../../../_metronic/layout/_core/MpAsideSecondary";
import { usePageTitle } from "../../../../providers/PageTitleProvider";
import { Gantt } from "../components/timeline/Gantt";

export function ProjectsTimeline(params) {
    const MpAsideSecondary = useAsideSecondary();
    const subheader = useSubheader();
    const pageTitle = usePageTitle();

    const contentContainer = useContentContainer();

    useEffect(() => {
        contentContainer.setContainerClass('container-fluid p-0');
        MpAsideSecondary.setAsideEnabled(false);
        subheader.setTitle('Timeline');
        subheader.setActions(null);
        pageTitle.setPageTitle(`🐮  Timeline`);
    }, []);

    return (
        <div className="mb-n24">
            <Gantt />
        </div>
    );

}

// https://ej2.syncfusion.com/react/documentation/api/gantt/#datastatechange