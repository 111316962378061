import React, { useEffect, useState } from "react";
import { OverlayTrigger, Spinner, Table, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import axios from "axios";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useUserProvider } from "../../../../providers/AllUsersProvider";
import { useSubheader } from "../../../../_metronic/layout";
import { usePageTitle } from "../../../../providers/PageTitleProvider";
import InterCredAccountsModal from "./InterCredAccountsModal";

export default function InterCredAccounts(props) {

	const userProvider = useUserProvider();
	const subheader = useSubheader();
	const pageTitle = usePageTitle();

	const [accounts, setAccounts] = useState([]);
	const [allAccounts, setAllAccounts] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [isLoading, setIsLoading] = useState(true);


	const [showModal, setShowModal] = useState(false);
	const [formTypeDelete, setFormTypeDelete] = useState(false);

	const [editId, setEditId] = useState(0);

	const permByType = {
		general: {
			create: 'createInterCredGeneral',
			edit: 'editInterCredGeneral',
			show: 'showInterCredGeneral',
			delete: 'deleteInterCredGeneral',
		},
		user: {
			create: 'createInterCredOwn',
			edit: 'editInterCredOwn',
			show: 'showInterCredOwn',
			delete: 'deleteInterCredOwn',
		},
		critical: {
			create: 'createInterCredCritical',
			edit: 'editInterCredCritical',
			show: 'showInterCredCritical',
			delete: 'deleteInterCredCritical',
		}

	}

	const epByType = {
		general: process.env.REACT_APP_API_URL + '/api/internalcredentials',
		user: process.env.REACT_APP_API_URL + '/api/internalcredentialsu',
		critical: process.env.REACT_APP_API_URL + '/api/internalcredentialsc',
	}

	const pageTitleByType = {
		general: 'Allgemeine interne Zugangsdaten',
		user: 'Mitarbeiterspezifische Zugangsdaten',
		critical: 'Sicherheitskritische Zugangsdaten',
	}

	const fetchInterCreds = () => {
		axios.get(epByType[props.type]).then(({ data }) => {
			setAllAccounts(data);
			setAccounts(data);
			setIsLoading(false);
		}).catch(e => console.log(e));
	};

	const handleSearch = () => {
		var filtered = allAccounts;
		if (searchTerm !== '' && searchTerm.length > 2) {
			filtered = allAccounts.filter(t => {
				if (props.type === 'user' && userProvider.can('manageInterCredOthers'))

					return t.type.toLowerCase().includes(searchTerm.toLowerCase()) || t.user?.toLowerCase().includes(searchTerm.toLowerCase()) || t.employeename?.toLowerCase().includes(searchTerm.toLowerCase());

				return t.type.toLowerCase().includes(searchTerm.toLowerCase()) || t.user?.toLowerCase().includes(searchTerm.toLowerCase());
			});

			filtered.sort((a, b) => a.type.localeCompare(b.type));
		}
		setAccounts(filtered);
	}

	const modalClose = () => {
		setShowModal(false);
		setEditId(0);
		setTimeout(() => setFormTypeDelete(false), 300);
	}
	const modalSubmit = () => {
		setShowModal(false);
		fetchInterCreds();
		setEditId(0);
		setTimeout(() => setFormTypeDelete(false), 300);
	}

	useEffect(() => {
		handleSearch();
	}, [searchTerm]);


	useEffect(() => {
		subheader.setTitle(pageTitleByType[props.type]);
		pageTitle.setPageTitle("🐮 " + pageTitleByType[props.type]);
		fetchInterCreds();
	}, [subheader]);

	const renderInterCreds = () => {
		return accounts.map((item, index) => {
			return (
				<tr key={item.id}>
					<td className="py-8">
						<div className="d-flex">
							{(item.type !== undefined && item.type !== "" && item.type !== null) && <>

								<CopyToClipboard className="d-print-none cursor-pointer" text={item.type}>
									<div className="btn btn-hover-light-primary btn-icon btn-sm mt-n2 ml-n2">
										<i><i className="la la-clipboard"></i></i>
									</div>
								</CopyToClipboard>
								<div className="pl-2">{item.type}</div>
							</>}

						</div>
					</td>
					{(props.type === 'user' && userProvider.can('manageInterCredOthers')) &&
						<td className="py-8">
							{item.employeename}
						</td>
					}
					<td className="py-8">
						<div className="d-flex">
							{(item.user !== undefined && item.user !== "" && item.user !== null) && <>

								<CopyToClipboard className="d-print-none cursor-pointer" text={item.user}>
									<div className="btn btn-hover-light-primary btn-icon btn-sm mt-n2 ml-n2">
										<i><i className="la la-clipboard"></i></i>
									</div>
								</CopyToClipboard>
								<div className="pl-2">{item.user}</div>
							</>}

						</div>
					</td>
					<td className="py-8">
						<div className="d-flex">
							{(item.password !== undefined && item.password !== "" && item.password !== null) && <>
								<CopyToClipboard className="d-print-none cursor-pointer" text={item.password}>
									<div className="btn btn-hover-light-primary btn-icon btn-sm mt-n2 ml-n2">
										<i><i className="la la-clipboard"></i></i>
									</div>
								</CopyToClipboard>
								<div className="pl-2">{item.password}</div>
							</>}

						</div>
					</td>
					<td className="py-8">
						<div className="d-flex">
							{(item.site !== undefined && item.site !== "" && item.site !== null) && <>
								<CopyToClipboard className="d-print-none cursor-pointer" text={item.site}>
									<div className="btn btn-hover-light-primary btn-icon btn-sm mt-n2 ml-n2">
										<i><i className="la la-clipboard"></i></i>
									</div>
								</CopyToClipboard>
								<div className="pl-2">{item.site}</div>
							</>}

						</div>
					</td>

					<td className="py-8">
						<div className="d-flex d-print-none justify-content-end flex-nowrap">
							{userProvider.can(permByType[props.type].edit) &&
								<Button variant="btn btn-icon btn-clean btn-hover-light-primary btn-sm mt-n2 mr-2"
									onClick={() => { setEditId(item.id); setShowModal(true) }}
								><i className="la la-pencil icon-md text-primary"></i></Button>
							}
							{userProvider.can(permByType[props.type].delete) &&
								<Button variant="btn btn-icon btn-clean btn-hover-light-danger btn-sm mt-n2"
									onClick={() => { setEditId(item.id); setShowModal(true); setFormTypeDelete(true) }}
								><i className="la la-trash icon-md text-danger"></i></Button>
							}
						</div>
					</td>
				</tr>
			);
		});
	}

	return (<>
		<div className="row">
			<div className="col-12">
				<div className={`card card-custom `}>
					<div className="card-header border-1">
						<h3 className="card-title text-dark">
							Zugangsdaten
						</h3>
						<div className="card-toolbar">
							{/*<Link to="/stempeluhr" className="nav-link btn btn-icon btn-clean btn-hover-light-primary btn-sm">
                            <span className="svg-icon svg-icon-sm">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/Text/Bullet-list.svg"
                                    )}
                                />
                            </span>
                                    </Link>*/}
						</div>
					</div>
					<div className="card-body">
						<div className={`${isLoading ? 'd-flex' : 'd-none'} flex-column align-items-center justify-content-center h-full mt-12 mb-4`}><Spinner animation="border" variant="primary" /></div>
						<div className={`${isLoading ? 'd-none' : 'd-block'}`}>
							<div className="d-flex mt-0 mb-6 d-print-none">

								<div className="input-icon h-40px flex-grow-1">
									<input
										type="text"
										className="form-control form-control-lg form-control-solid h-40px"
										placeholder="Suchen ..."
										id="accountsSearch"
										autoComplete="false"
										//autoFocus={true}
										onKeyUp={(e) => setSearchTerm(e.currentTarget.value)}

									/>
									<span>
										<span className="svg-icon svg-icon-lg">
											<SVG
												src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
											/>
										</span>
									</span>
								</div>
								{userProvider.can(permByType[props.type].create) &&

									<OverlayTrigger
										placement="bottom"
										overlay={
											<Tooltip id="latest-project">Zugangsdaten anlegen</Tooltip>
										}
									>
										<div
											onClick={() => setShowModal(true)}
											className={`btn btn-icon btn-default btn-hover-primary ml-3 h-40px w-40px flex-shrink-0`}
										>
											<span className="svg-icon svg-icon-lg">
												<SVG
													src={toAbsoluteUrl(
														"/media/svg/icons/Code/Plus.svg"
													)}
												/>
											</span>
										</div>
									</OverlayTrigger>
								}
							</div>
							{allAccounts.length > 0 ? <Table responsive variant="lg">
								<thead>
									<tr>
										<td style={{ width: '40%' }}>Typ</td>
										{(props.type === 'user' && userProvider.can('manageInterCredOthers')) &&
											<td style={{ width: '20%' }}>Mitarbeiter:in</td>
										}
										<td style={{ width: '20%' }}>User</td>
										<td style={{ width: '20%' }}>Passwort</td>
										<td style={{ width: '10%' }}>URL</td>
										<td style={{ width: '10%' }}></td>
									</tr>
								</thead>
								<tbody>
									{renderInterCreds()}
								</tbody>
							</Table>
								: <div className="alert bg-light-info mt-10">Bislang keine Zugangsdaten vorhanden.</div>}

						</div>
						{userProvider.canAny([permByType[props.type].create, permByType[props.type].edit, permByType[props.type].delete]) &&
							<InterCredAccountsModal type={formTypeDelete ? 'delete' : 'edit'} epType={props.type} edit={editId} show={showModal} onHide={modalClose} onSubmit={() => modalSubmit()} />
						}
					</div>
				</div>
			</div>
		</div>

	</>)
}
