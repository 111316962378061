import { useFormik } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal, Form, Col } from "react-bootstrap";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import axios from "axios";
import Select from 'react-select'

import countries from "i18n-iso-countries";
import countriesDE from "i18n-iso-countries/langs/de.json";
countries.registerLocale(countriesDE);
const allCountries = countries.getNames("de");

export default function CustomerInfoModal(props) {

    const [selectCountryConfig, setSelectCountryConfig] = useState({});
    const [selectedCountry, setSelectedCountry] = useState({});

    const initialValues = {
        name: '',
        address: '',
        zip: '',
        city: '',
        email: '',
        phone: '',
        data: {
            url: '',
            uid: '',
            stnr: '',
            fbnr: '',
            fbgr: '',
            eroi: '',
            sitz: '',
            bank: '',
            kontonummer: '',
            blz: '',
            iban: '',
            bic: ''
        }
    };
    const saveNew = (values) => {
        let body = values;
        body.country = selectedCountry.value;
        return axios.post(process.env.REACT_APP_API_URL + '/api/customers', body);
    };
    const updateExisting = (values) => {
        let body = values;
        body.country = selectedCountry.value;
        return axios.put(process.env.REACT_APP_API_URL + '/api/customers/' + props.edit, body);
    };

    const deleteData = () => {
        axios.delete(process.env.REACT_APP_API_URL + '/api/customers/' + props.edit).then((d) => {
            toast.success("Gelöscht");
            props.onSubmit();
        }).catch(e => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
            props.onSubmit();
        });
    }

    const fetchEntry = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/customers/' + props.edit).then(({ data }) => {
            formik.setFieldValue('name', (data.name == null ? '' : data.name));
            formik.setFieldValue('address', (data.address == null ? '' : data.address));
            formik.setFieldValue('zip', (data.zip == null ? '' : data.zip));
            formik.setFieldValue('city', (data.city == null ? '' : data.city));
            formik.setFieldValue('email', (data.email == null ? '' : data.email));
            formik.setFieldValue('phone', (data.phone == null ? '' : data.phone));

            if (data.country !== null)
                setSelectedCountry({ label: allCountries[data.country], value: data.country })

            formik.setFieldValue('data.url', (data.data.contact.url == null ? '' : data.data.contact.url));

            formik.setFieldValue('data.uid', (data.data.contact.uid == null ? '' : data.data.contact.uid));
            formik.setFieldValue('data.stnr', (data.data.contact.stnr == null ? '' : data.data.contact.stnr));
            formik.setFieldValue('data.fbnr', (data.data.contact.fbnr == null ? '' : data.data.contact.fbnr));
            formik.setFieldValue('data.fbgr', (data.data.contact.fbgr == null ? '' : data.data.contact.fbgr));
            formik.setFieldValue('data.eroi', (data.data.contact.eroi == null ? '' : data.data.contact.eroi));
            formik.setFieldValue('data.sitz', (data.data.contact.sitz == null ? '' : data.data.contact.sitz));
            formik.setFieldValue('data.bank', (data.data.contact.bank == null ? '' : data.data.contact.bank));
            formik.setFieldValue('data.kontonummer', (data.data.contact.kontonummer == null ? '' : data.data.contact.kontonummer));
            formik.setFieldValue('data.blz', (data.data.contact.blz == null ? '' : data.data.contact.blz));
            formik.setFieldValue('data.iban', (data.data.contact.iban == null ? '' : data.data.contact.iban));
            formik.setFieldValue('data.bic', (data.data.contact.bic == null ? '' : data.data.contact.bic));

        }).catch(e => props.onSubmit());
    };


    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            address: Yup.string(),
            zip: Yup.string(),
            city: Yup.string(),
            phone: Yup.string(),
            data: Yup.object().shape({
                url: Yup.string(),
                uid: Yup.string(),
                stnr: Yup.string(),
                fbnr: Yup.string(),
                fbgr: Yup.string(),
                eroi: Yup.string(),
                sitz: Yup.string(),
                bank: Yup.string(),
                kontonummer: Yup.string(),
                blz: Yup.string(),
                iban: Yup.string(),
                bic: Yup.string(),
            }),

            email: Yup.string().test({
                name: 'is-email',
                test: (value) => {
                    if (value === undefined || value.length === 0)
                        return true;
                    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
                }
            })
        }),
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {

            setSubmitting(false);
            if (props.edit === 0) {
                saveNew(values).then((d) => {
                    toast.success("Gespeichert");
                    props.onSubmit(d.data.id);
                }).catch((e) => {
                    toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
                    props.onSubmit();
                });
            } else {
                updateExisting(values).then((d) => {
                    toast.success("Gespeichert");
                    props.onSubmit();
                }).catch((e) => {
                    toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
                    props.onSubmit();
                });
            }
            setSubmitting(true);

        },
    });
    const mapCountriesForSelect = () => {
        let options = Object.keys(allCountries).map((key, i) => {
            let item = allCountries[key];
            return { label: item, value: key };
        });
        setSelectCountryConfig(options);
    };

    useEffect(() => {
        mapCountriesForSelect();
    }, []);

    useEffect(() => {
        if (props.edit === undefined || props.edit === 0)
            return;
        fetchEntry();
    }, [props.edit]);


    const renderForm = () => {
        return <Form onSubmit={formik.handleSubmit} className={`bg-white position-relative`}>
            <Modal.Body>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Name"
                            name="name"
                            value={''}
                            {...formik.getFieldProps("name")}
                            isInvalid={formik.errors.name}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Adresse</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Adresse"
                            name="address"
                            value={''}
                            {...formik.getFieldProps("address")}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} sm="6">
                        <Form.Label>PLZ</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="PLZ"
                            name="zip"
                            value={''}
                            {...formik.getFieldProps("zip")}
                        />
                    </Form.Group>
                    <Form.Group as={Col} sm="6">
                        <Form.Label>Ort</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ort"
                            name="city"
                            value={''}
                            {...formik.getFieldProps("city")}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Land</Form.Label>
                        <Select options={selectCountryConfig} value={selectedCountry} onChange={(o) => {
                            setSelectedCountry(o);
                        }}
                            classNamePrefix="sl2def"
                        //className={Object.keys(selectedCountry).length <= 0 && 'select2-has-error'}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md={6}>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Email"
                            name="email"
                            value={''}
                            {...formik.getFieldProps("email")}
                            isInvalid={formik.errors.email}
                        />
                    </Form.Group>

                    <Form.Group as={Col} md={6}>
                        <Form.Label>Telefon</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Telefon"
                            name="phone"
                            value={''}
                            {...formik.getFieldProps("phone")}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md={6}>
                        <Form.Label>Hauptwebsite URL</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="URL"
                            name="data.url"
                            value={''}
                            {...formik.getFieldProps("data.url")}
                        />
                    </Form.Group>
                </Form.Row>
                <hr />
                <Form.Row>
                    <Form.Group as={Col} md={6}>
                        <Form.Label>UID</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="UID"
                            name="data.uid"
                            value={''}
                            {...formik.getFieldProps("data.uid")}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                        <Form.Label>Steuernummer</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Steuernummer"
                            name="data.stnr"
                            value={''}
                            {...formik.getFieldProps("data.stnr")}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md={6}>
                        <Form.Label>Firmenbuchnummer</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Firmenbuchnummer"
                            name="data.fbnr"
                            value={''}
                            {...formik.getFieldProps("data.fbnr")}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                        <Form.Label>Firmenbuchgericht</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Firmenbuchgericht"
                            name="data.fbgr"
                            value={''}
                            {...formik.getFieldProps("data.fbgr")}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md={6}>
                        <Form.Label>Sitz</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Sitz"
                            name="data.sitz"
                            value={''}
                            {...formik.getFieldProps("data.sitz")}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                        <Form.Label>EORI</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="EORI"
                            name="data.eori"
                            value={''}
                            {...formik.getFieldProps("data.eori")}
                        />
                    </Form.Group>
                </Form.Row>
                <hr />
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Bank</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Bank"
                            name="data.bank"
                            value={''}
                            {...formik.getFieldProps("data.bank")}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md={6}>
                        <Form.Label>Kontonummer</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Kontonummer"
                            name="data.kontonummer"
                            value={''}
                            {...formik.getFieldProps("data.kontonummer")}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                        <Form.Label>BLZ</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="BLZ"
                            name="data.blz"
                            value={''}
                            {...formik.getFieldProps("data.blz")}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md={6}>
                        <Form.Label>IBAN</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="IBAN"
                            name="data.iban"
                            value={''}
                            {...formik.getFieldProps("data.iban")}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                        <Form.Label>BIC</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="BIC"
                            name="data.bic"
                            value={''}
                            {...formik.getFieldProps("data.bic")}
                        />
                    </Form.Group>
                </Form.Row>


            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit">Speichern</Button>
                <Button variant="default " onClick={props.onHide}>Schließen</Button>
            </Modal.Footer>
        </Form>
    }

    const deleteForm = () => {
        return <>
            <Modal.Body>
                <p>Bist du dir sicher dass du diesen Kunden löschen möchtest?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={deleteData}>Ja, Kunden löschen</Button>
                <Button variant="default " onClick={props.onHide}>Nein, Kunden nicht löschen</Button>
            </Modal.Footer>

        </>
    }

    return <>
        <Modal
            show={props.show}
            size={(props.type && props.type === 'delete') ? ' md' : 'lg'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Kunde
                    {
                        (props.type && props.type === 'delete') ? ' löschen' :
                            (props.edit === 0) ? ' erstellen' : ' bearbeiten'
                    }
                </Modal.Title>
            </Modal.Header>
            {(props.type && props.type === 'delete') ? deleteForm() : renderForm()}
        </Modal>
    </>

}