import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useAsideSecondary } from "../../../../_metronic/layout/_core/MpAsideSecondary";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { AdminUsers } from "../components/users/AdminUsers";
import { AdminUserGroups } from "../components/users/AdminUserGroups";
import { useSubheader } from "../../../../_metronic/layout";
import { useContentContainer } from "../../../../_metronic/layout/_core/MpContentContainer";
import { useUserProvider } from "../../../../providers/AllUsersProvider";
import AdminProjectTemplates from "../components/AdminProjectTemplates";

export function AdminPage(props) {
    const MpAsideSecondary = useAsideSecondary();
    const contentContainer = useContentContainer();
    const subheader = useSubheader();
    const userProvider = useUserProvider();

    let { path } = useParams();

    useEffect(() => {
        MpAsideSecondary.setAsideEnabled(true);
        subheader.setActions(null);
        contentContainer.reset();
    }, []);


    const renderEmpty = () => {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                <div className="text-center">
                    <div className="svg-icon svg-icon-8x">
                        <SVG
                            src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-left.svg")}
                        />
                    </div>
                    <div className="font-size-h6 mt-10">Wähle ein Modul aus.</div>
                </div>
            </div>
        );
    };
    const renderContent = () => {
        switch (path) {
            case 'users':
                return userProvider.can('adminUsers') ? <AdminUsers /> : <Redirect to="/error/403" />;
            case 'usergroups':
                return userProvider.can('adminRoles') ? <AdminUserGroups /> : <Redirect to="/error/403" />;
            case 'project-templates':
                return userProvider.can('adminProjectTemplates') ? <AdminProjectTemplates /> : <Redirect to="/error/403" />;
            default:
                return null;
        }

    };


    return (path === undefined ? renderEmpty() : renderContent());
}