import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Button, Dropdown, Modal, Spinner } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { useUserProvider } from "../../../../providers/AllUsersProvider";
import Moment from "react-moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
    DropdownCustomToggler,
} from "../../../../_metronic/_partials/dropdowns";
import { toast } from "react-toastify";
import { Parser } from 'html-to-react'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";


export function TaskUpdates(props) {

    const currentUser = useSelector((state) => state.auth.user, shallowEqual);
    const userProvider = useUserProvider();

    const scrollRef = useRef();
    const updatesHolder = useRef();

    const [isLoading, setIsLoading] = useState(true);
    const [deleteUpdate, setDeleteUpdate] = useState(null);

    const [task, setTask] = useState('');
    const [updates, setUpdates] = useState([]);

    const fetchUpdates = () => {
        props.setEditUpdate(null);
        if (task.id === undefined ||
            currentUser.id === undefined ||
            userProvider.allUsers === undefined ||
            userProvider.allUsers === null ||
            userProvider.allUsers.length <= 0
        )
            return;

        setIsLoading(true);
        axios.get(process.env.REACT_APP_API_URL + '/api/tasks_updates_by_task/' + task.id).then(({ data }) => {
            setUpdates(data);
            setIsLoading(false);
            props.setShouldRefetch(false);
        }).catch(e => console.log(e));
    }

    const renderUpdates = () => {
        if (updates === undefined || updates.length === 0)
            return null;

        return updates.map((item, key) => {
            const itemUser = userProvider.getUserById(item.user_id);
            let isSame = false;
            if (itemUser.id === currentUser.id)
                isSame = true;

            return <div className={`taskUpdateItem d-flex flex-column mb-10 ${isSame ? "align-items-end pl-4 pl-lg-8" : "align-items-start pr-4 pr-lg-8"}`} key={key}>
                <div className="d-flex align-items-center">
                    {isSame ?
                        <><span className="text-muted font-size-sm px-2"><Moment format="DD.MM.YYYY HH:mm">{item.created_at}</Moment></span>
                            <div className={`${itemUser.deleted_at === null ? "text-dark-75" : "text-muted"} font-weight-bold font-size-h6"`}>{itemUser.deleted_at === null ? itemUser.name : <del>{itemUser.firstname}</del>}</div></>
                        :
                        <><div className={`${itemUser.deleted_at === null ? "text-dark-75" : "text-muted"} font-weight-bold font-size-h6"`}>{itemUser.deleted_at === null ? itemUser.name : <del>{itemUser.firstname}</del>}</div>
                            <span className="text-muted font-size-sm px-2"><Moment format="DD.MM.YYYY HH:mm">{item.created_at}</Moment></span></>
                    }
                    {isSame &&

                        <Dropdown className="ml-2" drop="left">
                            <Dropdown.Toggle
                                className="btn btn-clean btn-hover-light btn-sm btn-icon cursor-pointer"
                                variant="transparent"
                                id="taskudpateDrop"
                                as={DropdownCustomToggler}
                            >
                                <i className="ki ki-bold-more-hor"></i>

                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => props.setEditUpdate(item.id)}>Bearbeiten</Dropdown.Item>
                                <Dropdown.Item onClick={() => setDeleteUpdate(item.id)}>Löschen</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    }

                </div>
                <div className={`mt-2 rounded p-5 ${isSame ? 'bg-light-primary' : 'bg-dark-o-30' } text-dark-75 text-left max-w-600px line-height-lg font-size-sm flex-grow-1 w-100`}>
                    {Parser().parse(item.body)}
                </div>
            </div>
        });
    };
    const onDeleteModalSubmit = () => {
        axios.delete(process.env.REACT_APP_API_URL + '/api/tasks_updates/' + deleteUpdate).then((d) => {
            toast.success("Gelöscht");
            setDeleteUpdate(null)
            fetchUpdates();
        }).catch(e => toast.error("Es ist ein Fehler aufgetreten"));
    };

    useEffect(() => {
        setTask(props.task);
    }, [props.task]);
    useEffect(() => {
        fetchUpdates();
    }, [task, userProvider, currentUser]);

    useEffect(() => {
        if (isLoading)
            return;
        scrollRef.current._container.scrollTop = scrollRef.current._container.childNodes[0].clientHeight;
        setTimeout(() => {
            if (scrollRef.current._container.scrollTop !== scrollRef.current._container.childNodes[0].clientHeight)
                scrollRef.current._container.scrollTop = scrollRef.current._container.childNodes[0].clientHeight;
        }, 300);
    }, [scrollRef, isLoading]);


    useEffect(() => {
        if (props.shouldRefetch)
            fetchUpdates();
    }, [props.shouldRefetch]);

    return <>
        <div className={`${isLoading ? 'd-flex' : 'd-none'} flex-column align-items-center justify-content-center h-full`}><Spinner animation="border" variant="primary" /></div>

        <div style={{ position: 'relative', height: '100%' }} className={`${isLoading || updates.length <= 0 ? 'd-none' : 'd-block'}`}>
            <PerfectScrollbar
                className="scroll pr-4 p-8"
                options={{ wheelPropagation: false }}
                style={{ height: '100%', width: '100%', position: "absolute" }}
                ref={scrollRef}
            >
                <div>
                    {renderUpdates()}
                </div>
            </PerfectScrollbar>
        </div>
        <div style={{ position: 'relative', height: '100%' }} className={`${!isLoading && updates.length <= 0 ? 'd-flex' : 'd-none'} justify-content-center align-items-center`}>
            <div className="text-center">
                <div className="svg-icon svg-icon-8x">
                    <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Communication/Group-chat.svg")}
                    />
                </div>
                <div className="font-size-h6 mt-10">Schreibe ein erstes Update.</div>
            </div>
        </div>


        <Modal
            show={deleteUpdate !== null}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => setDeleteUpdate(null)}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Update löschen?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Bist du dir sicher dass du dieses Update löschen möchtest?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => onDeleteModalSubmit()}>Ja, Update löschen</Button>
                <Button variant="default" onClick={() => setDeleteUpdate(null)}>Nein, Update nicht löschen</Button>
            </Modal.Footer>
        </Modal>
    </>;
}