import { Field, FieldArray, Formik, FormikProvider, useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Modal, Form } from "react-bootstrap";
import * as Yup from "yup";
import { toast } from "react-toastify"
import axios from "axios";
import Select, { components } from 'react-select'
import { TasksFieldArray } from "./taskForm/TasksFieldArray";
import { useUserProvider } from "../../../../providers/AllUsersProvider";
import moment from "moment";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import { DatePickerLocale } from "../../../constants/DatePickerLocale";
import { useTaskStatusProvider } from "../../../../providers/TaskStatusProvider";

export function TaskSingleCreationModal(props) {

    const userProvider = useUserProvider();
    const taskStatusProvider = useTaskStatusProvider();

    const [selectStatusConfig, setSelectStatusConfig] = useState({});
    const [selectedStatus, setSelectedStatus] = useState({});

    const [selectCustomerConfig, setSelectCustomerConfig] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState({})

    const [selectProjectConfig, setSelectProjectConfig] = useState({});
    const [selectedProject, setSelectedProject] = useState({})


    const [selectPersonsConfig, setSelectPersonsConfig] = useState({});
    const [selectedPersons, setSelectedPersons] = useState([]);

    const [pickerDates, setPickerDates] = useState({ start: null, end: null })

    const pickerRef = useRef();
    const pickerInputRef = useRef();


    const CustomOption = ({ innerRef, innerProps, data }) => {
        const tStatus = taskStatusProvider.getTaskStatusById(data.value);
        const style = { backgroundColor: `#${tStatus.color}`, color: `#${tStatus.fontcolor}` };
        return <div {...innerProps} key={data.value} ref={innerRef} className="status-item cursor-pointer font-size-md rounded-md py-4 px-3 text-center m-2" style={style} >{tStatus.name}</div>;
    }
    const CustomControl = ({ data, ...props }) => {

        const tStatus = taskStatusProvider.getTaskStatusById(data.value);
        if (tStatus === null)
            return <components.SingleValue {...props}><div className="status-item bg-light cursor-pointer font-size-md rounded-sm py-1 px-3 h-full text-center flex-grow-1">Kein Status</div></components.SingleValue>
        const style = { backgroundColor: `#${tStatus.color}`, color: `#${tStatus.fontcolor}` };
        return <components.SingleValue {...props}><div className="status-item cursor-pointer font-size-md rounded-sm py-1 px-3 h-full text-center flex-grow-1" style={style} >{tStatus.name}</div></components.SingleValue>;
    };

    const mapToSelectConfig = () => {
        const statuses = taskStatusProvider.allTaskStatus.map((item) => {
            return { label: item.name, value: item.id };
        });
        setSelectStatusConfig(statuses);
    };


    const mapToSelectPersonsConfig = () => {
        let lUsers = userProvider.allUsers.filter((u) => u.deleted_at === null);
        const users = lUsers.map((item) => {
            return { label: item.name, value: item.id };
        });
        setSelectPersonsConfig(users);
    };


    const fetchAllCustomers = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/customers').then(({ data }) => {
            mapCustomersForSelect(data);
        }).catch(e => props.onSubmit());
    };

    const mapCustomersForSelect = (data) => {
        let options = data.map((item, i) => {
            return { label: item.name, value: item.id };
        });
        setSelectCustomerConfig(options);
    };

    const fetchCustomersProjects = () => {
        if (selectedCustomer.value === undefined)
            return;
        axios.get(process.env.REACT_APP_API_URL + '/api/projectsbycustomer/' + selectedCustomer.value).then(({ data }) => {
            // Todo: Set Task Type
            let projectsData = data;
            if (data.length > 0)
                projectsData = data.filter(t => t.archived === 0);

            projectsData.push({ id: 0, name: 'Allgemeine Tasks' });
            projectsData.sort((a, b) => a.name.localeCompare(b.name));
            mapProjectsForSelect(projectsData);
        }).catch(e => console.log(e));

    };

    const mapProjectsForSelect = (data) => {
        let options = data.map((item, i) => {
            return { label: item.name, value: item.id };
        });
        setSelectProjectConfig(options);
    };

    const applyPicker = (e, picker) => {
        let start = picker.startDate;
        let end= picker.endDate;
        setPickerDates({ start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD')});
        e.target.value = picker.startDate.format('DD.MM.YYYY') + ' - ' + picker.endDate.format('DD.MM.YYYY');
        
    };

    const applyStatus = (status) => {
        setSelectedStatus(status);
    };

    const clearDates = (e, picker) => {
        pickerRef.current.setStartDate(undefined);
        pickerRef.current.setEndDate(undefined);
        picker.element[0].value = '';
        setPickerDates({start:null, end:null});
    }

    const initialValues = {
        name: '',
        info: '',
        external: ''
    };
    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
        }),
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            setSubmitting(false);
            let body = values;
            if (selectedCustomer.value === undefined || selectedProject === undefined || selectedProject.value === undefined)
                return;
            body.customer_id = selectedCustomer.value;
            body.project_id = selectedProject.value;

            let personsArray = null;
            if (selectedPersons !== null) {
                personsArray = selectedPersons.map((person) => person.value);
                personsArray = JSON.stringify(personsArray);
            }
            body.persons = personsArray;
            body.status = selectedStatus.value;

            body.start = pickerDates.start;
            body.end = pickerDates.end;
            
            return axios.post(process.env.REACT_APP_API_URL + '/api/taskssingle', body).then((d) => {
                toast.success("Task erstellt.");
                resetForm();
                props.onSubmit();
                props.onHide();
            }).catch(e => {
                toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
                resetForm();
                props.onSubmit();
                props.onHide();
            });
            setSubmitting(true);

        },
    });
    useEffect(() => {
        fetchAllCustomers();
        if (props.customer) {
            setSelectedCustomer({ label: props.customer.name, value: props.customer.id });
            fetchCustomersProjects();
        }
    }, [props.customer]);

    useEffect(() => {
        if (userProvider.allUsers.length <= 0)
            return;
        mapToSelectPersonsConfig();
    }, [userProvider.allUsers]);

    useEffect(() => {
        if (props.project !== undefined && props.project.id !== undefined)
            setSelectedProject({ value: props.project.id, label: props.project.name });

    }, [props.project]);

    useEffect(() => {
        fetchCustomersProjects();
    }, [selectedCustomer]);
    useEffect(() => {
        if (selectedProject.name === undefined && props.project === undefined)
            setSelectedProject({ value: 0, label: 'Allgemeine Tasks' });
    }, [selectProjectConfig]);


    useEffect(() => {
        if (taskStatusProvider.allTaskStatus.length <= 0)
            return;
        mapToSelectConfig();
    }, [taskStatusProvider.allTaskStatus]);


    return <>
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Einzelnen Task erstellen
                </Modal.Title>
            </Modal.Header>


            <Form onSubmit={formik.handleSubmit} className={`bg-white position-relative`}>
                <Modal.Body>
                    <Form.Row className="zindex-3 position-relative">
                        <Form.Group as={Col}>
                            <Form.Label>Kunde</Form.Label>
                            <Select options={selectCustomerConfig} value={selectedCustomer} onChange={(o) => {
                                setSelectedCustomer(o);
                                fetchCustomersProjects();
                            }}
                                classNamePrefix="sl2def"
                                className={Object.keys(selectedCustomer).length <= 0 && 'select2-has-error'}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row className="zindex-2 position-relative">
                        <Form.Group as={Col}>
                            <Form.Label>Projekt</Form.Label>
                            {Object.keys(selectProjectConfig).length > 0 ?

                                <Select options={selectProjectConfig} value={selectedProject} onChange={(o) => {
                                    setSelectedProject(o);
                                }}
                                    classNamePrefix="sl2def"
                                    className={Object.keys(selectedProject).length <= 0 && 'select2-has-error'}
                                />
                                :
                                <div className="alert bg-light-info mt-0">Bitte zuerst einen Kunden wählen.</div>
                            }
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Name"
                                name="name"
                                value={''}
                                {...formik.getFieldProps("name")}
                                isInvalid={formik.errors.name}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row className="zindex-1 position-relative">
                        <Form.Group as={Col}>
                            <Form.Label>Personen</Form.Label>
                            {userProvider.allUsers.length > 0 ? <div className="statusSelect"><Select options={selectPersonsConfig} value={selectedPersons}
                                classNamePrefix="sl2def"
                                isClearable={false}
                                components={{ DropdownIndicator: null }}
                                //Option: CustomOption, SingleValue: CustomControl,
                                lang="de"
                                noOptionsMessage={() => "Person nicht gefunden"}
                                key="persons"
                                isMulti
                                onChange={(val) => setSelectedPersons(val)}
                                placeholder={<div key="none" className="status-item bg-light cursor-pointer font-size-md rounded-md py-1 px-3 text-center flex-grow-1">Keine Person hinzugefügt</div>}
                            /></div> : null}
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Status</Form.Label>
                            <> {taskStatusProvider.allTaskStatus.length > 0 ? <div className="statusSelect"><Select options={selectStatusConfig} value={selectedStatus}
                                isClearable={true}
                                components={{ Option: CustomOption, SingleValue: CustomControl, DropdownIndicator: null }}
                                lang="de"
                                noOptionsMessage={() => "Status nicht gefunden"}
                                key="status"
                                placeholder={<div key="none" className="status-item bg-light cursor-pointer font-size-md rounded-md py-1 px-3 text-center flex-grow-1">Kein Status</div>}
                                classNamePrefix="sl2def"
                                styles={{
                                    singleValue: (base) => ({
                                        ...base,
                                        display: 'flex',
                                        width: 'calc(100% - 10px)'
                                    }),
                                }}
                                onChange={(val) => applyStatus(val)}
                            /></div> : null}
                            </>
                        </Form.Group>


                        <Form.Group as={Col} lg={6}>
                            <Form.Label>Timing</Form.Label>
                            <DateRangePicker
                                ref={pickerRef}
                                initialSettings={{
                                    showCustomRangeLabel: false,
                                    locale: DatePickerLocale,
                                    autoApply: false,
                                    autoUpdateInput: false
                                }}
                                onApply={(e, picker) => applyPicker(e, picker)}
                                onCancel={(e, picker) => clearDates(e, picker)}
                            >
                                <Form.Control defaultValue={''} size="sm" type="text" onFocus={(e) => e.currentTarget.blur()} style={{ height: "38px" }} ref={pickerInputRef} />
                            </DateRangePicker>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} lg={6}>
                            <Form.Label>Quick Info</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Quick Info"
                                name="info"
                                value={''}
                                {...formik.getFieldProps("info")}
                                isInvalid={formik.errors.info}
                            />
                        </Form.Group>
                        <Form.Group as={Col} lg={6}>
                            <Form.Label>Externes</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Externes"
                                name="external"
                                value={''}
                                {...formik.getFieldProps("external")}
                                isInvalid={formik.errors.external}
                            />
                        </Form.Group>
                    </Form.Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit">Erstellen</Button>
                    <Button variant="default " onClick={props.onHide}>Abbrechen</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>
}