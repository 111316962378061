import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useUserProvider } from "../../../../../providers/AllUsersProvider";
import Select from 'react-select'
import { Button } from "react-bootstrap";


export function ProjectEditPersons(props) {

    const [lProject, setLProject] = useState({});

    const userProvider = useUserProvider();
    const [selectConfig, setSelectConfig] = useState({});
    const [selected, setSelected] = useState([]);

    const mapToSelectConfig = () => {
        let lUsers = userProvider.allUsers.filter((u) => u.deleted_at === null);
        const users = lUsers.map((item) => {
            return { label: item.name, value: item.id };
        });
        setSelectConfig(users);
    };


    const setSelectedItems = () => {
        if (lProject.persons === undefined || lProject.persons === null)
            return;
        const itemsAll = userProvider.getUsersByIds(lProject.persons);
        if (itemsAll === null)
            return
        const items = itemsAll.map((item) => {
            return { label: item.name, value: item.id }
        })
        setSelected(items);
    };

    const apply = () => {
        let personsArray = null;
        if (selected !== null) {
            personsArray = selected.map((person) => person.value);
            personsArray = JSON.stringify(personsArray);
        }
        let body = {
            persons: personsArray,
        };
        axios.post(process.env.REACT_APP_API_URL + '/api/projects/' + lProject.id + '/updatePersons', body).then((d) => {
            toast.success("Personen Zuordnung wurde geändert")
            props.onSubmit();
        }).catch((e) => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
            props.onSubmit();
        });
    };


    useEffect(() => {
        setLProject(props.project);
    }, [props.project]);
    useEffect(() => {
        if (userProvider.allUsers.length <= 0 || Object.keys(lProject).length <= 0)
            return;
        mapToSelectConfig();
        setSelectedItems();
    }, [lProject, userProvider.allUsers]);

    return <div className="editUsers">{userProvider.allUsers.length > 0 ? <div className="statusSelect"><Select options={selectConfig} value={selected}
        classNamePrefix="sl2def"
        isClearable={false}
        components={{ DropdownIndicator: null }}
        //Option: CustomOption, SingleValue: CustomControl,
        lang="de"
        noOptionsMessage={() => "Person nicht gefunden"}
        key="persons"
        isMulti
        onChange={(val) => setSelected(val)}
        placeholder={<div key="none" className="status-item bg-light cursor-pointer font-size-md rounded-md py-1 px-3 text-center flex-grow-1">Keine Person hinzugefügt</div>}
    /></div> : null}

        <div className="d-flex justify-content-end mt-6">
            <Button variant="primary mr-4" onClick={() => apply()}>Speichern</Button>
            <Button variant="default " onClick={props.onHide}>Abbrechen</Button>
        </div>
    </div>

}