import React, { useEffect, useState } from 'react';
import { useSubheader } from '../../../_metronic/layout';
import axios from "axios";
import { Link, useHistory, useParams } from 'react-router-dom';

import '@ckeditor/ckeditor5-build-classic/build/translations/de';
import { TaskDetailForm } from './partials/TaskDetailForm';
import { OverlayTrigger, Tab, Tabs, Tooltip } from 'react-bootstrap';
import { TaskUpdates } from './partials/TaskUpdates';
import { TaskUpdateForm } from './partials/TaskUpdateForm';
import { TaskDescription } from './partials/TaskDescription';
import { TaskSettings } from './partials/TaskSettings';
import { usePageTitle } from '../../../providers/PageTitleProvider';
import { useEchoProvider } from '../../../providers/EchoProvider';
import { shallowEqual, useSelector } from 'react-redux';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import SVG from "react-inlinesvg";
import { TaskPin } from './partials/TaskPin';

export function TaskDetail(props) {
    const pageTitle = usePageTitle();
    const subheader = useSubheader();
    const echoProvider = useEchoProvider();

    let { taskpath } = useParams();

    const [task, setTask] = useState({});
    const [lCustomer, setLCustomer] = useState({});
    const [key, setKey] = useState('quick');
    const [shouldRefetchUpdates, setShouldRefetchUpdates] = useState(true);

    const [echoUsers, setEchoUsers] = useState([]);

    const [editUpdate, setEditUpdate] = useState(null);

    const history = useHistory();
    const currentUser = useSelector((state) => state.auth.user, shallowEqual);

    const fetchCustomerByTask = () => {
        if (task.customer_id === undefined)
            return;

        let localCid = task.customer_id;
        if (String(task.customer_id).substring(0, 2) === '0_')
            localCid = localCid.slice(2);

        axios.get(process.env.REACT_APP_API_URL + '/api/customers/' + localCid).then(({ data }) => {
            setLCustomer(data);
        }).catch(e => console.log(e));
    }
    const fetchTask = () => {

        axios.get(process.env.REACT_APP_API_URL + '/api/tasks/' + taskpath).then(({ data }) => {
            setTask(data);
            if (props.customer.id !== data.customer_id && data.project_id === null)
                history.push('/projekte/0_' + data.customer_id + '/task/' + data.id);
            if (props.project !== undefined && props.project.id !== undefined && props.project.id !== data.project_id)
                history.push('/projekte/' + data.project_id + '/task/' + data.id);

        }).catch(e => console.log(e));
    }

    useEffect(() => {
        if (task.name === undefined || lCustomer.name === undefined)
            return;

        subheader.setTitle(<>
            <div className='d-flex flex-nowrap justify-content-start align-items-center'><div>{task.name}</div> {task.archived === 1 && <div className='badge badge-sm badge-primary font-size-xs text-white ml-3 py-1'>archiviert</div>}</div>
            <small className="font-size-sm">Kunde: <Link to={"/kunden/" + lCustomer.id}>{lCustomer.name}</Link></small>
            {(props.project !== undefined && props.project.id !== undefined) && <small className="pl-6 font-size-sm">Projekt: <Link to={"/projekte/" + props.project.id}>{props.project.name}</Link></small>}
        </>);
        pageTitle.setPageTitle(`🐮📋 ${task.name !== undefined ? task.name : ''}${lCustomer.name !== undefined ? ' | ' + lCustomer.name : ''}`);

    }, [task, lCustomer]);
    useEffect(() => {
        fetchCustomerByTask();
    }, [task]);

    useEffect(() => {
        if (currentUser.id === undefined || echoProvider === undefined || echoProvider === null || !echoProvider.ready)
            return;

        let locUsers = []
        echoProvider.echo.join(`taskdetail.${task.id}`)
            .here((users) => {
                locUsers = users;
                locUsers = locUsers.filter((u) => u.id !== currentUser.id)
                setEchoUsers(locUsers);
            })
            .joining((user) => {
                locUsers = [...locUsers, user];
                locUsers = locUsers.filter((u) => u.id !== currentUser.id)
                setEchoUsers(locUsers);
            })
            .leaving((user) => {
                locUsers = locUsers.filter((u) => u.id !== user.id)
                locUsers = locUsers.filter((u) => u.id !== currentUser.id)
                setEchoUsers(locUsers);
            })
            .error((error) => {
                //console.error(error);
            });
        return () => {
            echoProvider.echo.leave(`taskdetail.${task.id}`)
        };
    }, [task, echoProvider, currentUser]);

    useEffect(() => {
        subheader.setActions(
            <>
                <TaskPin task={task}/>
                <OverlayTrigger
                    placement="left"
                    overlay={
                        <Tooltip id="latest-project">Andere Personen die sich diesen Task jetzt gerade ansehen.</Tooltip>
                    }
                >
                    <div className="btn btn-secondary ">
                        <span className="svg-icon svg-icon-lg">
                            <SVG
                                src={toAbsoluteUrl(
                                    "/media/svg/icons/General/Visible.svg"
                                )}
                            /></span>{` `}{echoUsers.length}

                    </div>
                </OverlayTrigger>
            </>

        );
    }, [echoUsers]);

    useEffect(() => {
        fetchTask();
    }, [taskpath]);
    useEffect(() => {
        if (props.project !== undefined)
            fetchTask();
    }, [props.project]);
    useEffect(() => {
        setLCustomer(props.customer);
    }, [props.customer]);
    /*

    <div className="row">
                {echoUsers.map((item, key) => {
                    return <p>{item.firstname}</p>
                })}
            </div>

            */

    return <div className="d-flex h-full">
        <div className="inner flex-grow-1">

            <div className="row h-full">
                <div className="col-xl-6 order-xl-1">
                    <div className="card h-full d-flex p-6">
                        <Tabs
                            id="uncontrolled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                        >
                            <Tab eventKey="quick" title="Quick Actions">
                                <div className='h-full d-flex flex-column'>
                                    <div className="infoFormOuter zindex-2 pt-6">
                                        <TaskDetailForm task={task} refetch={fetchTask} />
                                    </div>

                                    <div className="editorOuter flex-grow-1 pt-2">
                                        <div className="text-dark-75 font-weight-bold font-size-h6 mb-2">Update schreiben</div>
                                        <TaskUpdateForm task={task} refetch={fetchTask} setShouldRefetch={setShouldRefetchUpdates} editUpdate={editUpdate} setEditUpdate={setEditUpdate} />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="desc" title="Task Beschreibung">
                                <TaskDescription task={task} refetch={fetchTask} />
                            </Tab>
                            <Tab eventKey="settings" title="Task Einstellungen">
                                <div className='h-full d-flex flex-column'>
                                    <div className="infoFormOuter zindex-2 pt-6">
                                        <TaskSettings task={task} refetch={fetchTask} />
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                <div className="col-xl-6 order-xl-0 min-h-500px min-h-xl-150px mt-10 mt-xl-0">
                    <div className="card h-full">
                        <TaskUpdates task={task} shouldRefetch={shouldRefetchUpdates} setShouldRefetch={setShouldRefetchUpdates} setEditUpdate={setEditUpdate} />
                    </div>
                </div>
            </div>
        </div>
    </div>;
}