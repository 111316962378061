import React from "react"
import { Link, useParams } from 'react-router-dom';

export function SwitchLinkDiv(props) {

    const getProps = () => {
        let lProps = {...props};
        delete lProps.isLink;
        return lProps;
    }
    return <>{
        (props.isLink === undefined ||  props.isLink) ?
            <Link {...getProps()}>
                {props.children}
            </Link>
            :
            <div {...getProps()}>
                {props.children}
            </div>
    }</>
}
