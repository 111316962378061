import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify"
import axios from "axios";

export function TaskBulkDeleteModal(props) {

    const [tasks, setTasks] = useState({});
    useEffect(() => {
        setTasks(props.tasks);
    }, [props.tasks]);

    const deleteData = () => {
        let body = {};
        if(tasks === undefined || tasks.length <= 0)
            return;
        body.tasks = tasks;

        axios.post(process.env.REACT_APP_API_URL + '/api/tasks/bulkDelete', body).then((d) => {
            toast.success("Gelöscht");
            props.onSubmit();
            props.onHide();
        }).catch(e => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.",{autoClose: 5500});
            props.onSubmit();
            props.onHide();
        });
        
    }

    return <>
        <Modal
            show={props.show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Task löschen
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Bist du dir sicher dass du diese Task(s) löschen möchtest?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={deleteData}>Ja, Task(s) löschen</Button>
                <Button variant="default" onClick={props.onHide}>Nein, Task(s) nicht löschen</Button>
            </Modal.Footer>

        </Modal>
    </>
}
