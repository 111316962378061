import React, { useState } from "react"
import { TaskBulkArchiveModal } from "./bulkModals/TaskBulkArchiveModal"
import { TaskBulkDeleteModal } from "./bulkModals/TaskBulkDeleteModal";
import { TaskBulkMoveModal } from "./bulkModals/TaskBulkMoveModal";

export function TaskBulkActionButtons(props) {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showArchiveModal, setShowArchiveModal] = useState(false);
    const [showMoveModal, setShowMoveModal] = useState(false);

    return <>
        <div className={`bulkActionButtons ${props.bulkChecked.length > 0 && 'active'}`}>
            <div className="card card-custom shadow-sm">
                <div className="card-body">
                    <div className="d-flex">
                        <div className="btn btn-sm btn-default rounded-md d-flex align-items-center mx-1" onClick={() => props.onCancle()}>
                            Abbrechen
                        </div>
                        <div className="btn btn-sm btn-light-primary rounded-md d-flex align-items-center mx-1" onClick={() => setShowArchiveModal(true)}>
                            Archivieren/Reaktivieren
                        </div>
                        <div className="btn btn-sm btn-light-warning rounded-md d-flex align-items-center mx-1 "onClick={() => setShowMoveModal(true)}>
                            Verschieben
                        </div>
                        <div className="btn btn-sm btn-light-danger rounded-md d-flex align-items-center mx-1" onClick={() => setShowDeleteModal(true)}>
                            Löschen
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <TaskBulkArchiveModal tasks={props.bulkChecked} show={showArchiveModal} onSubmit={props.onSubmit} onHide={() => setShowArchiveModal(false)} />
        <TaskBulkDeleteModal tasks={props.bulkChecked} show={showDeleteModal} onSubmit={props.onSubmit} onHide={() => setShowDeleteModal(false)} />
        <TaskBulkMoveModal tasks={props.bulkChecked} show={showMoveModal} onSubmit={props.onSubmit} onHide={() => setShowMoveModal(false)} />
    </>

}