import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import axios from "axios";
import { toast } from 'react-toastify';

export function SetExternalInfo(props) {

    const [inputValue, setInputValue] = useState('');
    const [task, setTask] = useState('');

    const updateTask = (val) => {
        let body = {
            value: val,
        };
        axios.post(process.env.REACT_APP_API_URL + '/api/tasks/' + task.id + '/updateExternalInfo', body).then((d) => {
            toast.success("Externe Kurz-Infos wurde geändert");
            props.refetch();
        }).catch((e) => { 
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.",{autoClose: 5500});
            props.refetch();
        });
    }
    const applyInfo = (val) => {
        const str = val.currentTarget.value;
        if (task.data?.external === str)
            return;
        setInputValue(str);
        updateTask(str);
    }


    useEffect(() => {
        setTask(props.task);
    }, [props.task]);
    useEffect(() => {
        if (task.data !== undefined && task.data !== null && task.data.external !== undefined)
            setInputValue(task.data.external);
    }, [task]);


    return <>
        <Form.Control
            type="text"
            placeholder="Externes"
            name="external"
            defaultValue={inputValue}
            onBlur={(val) => applyInfo(val)}
        />
        
    </>
}