import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { usePageTitle } from "../../../../providers/PageTitleProvider";
import { useSubheader } from "../../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { TasksList } from "../../ProjectTasks/TasksList";
import { TaskCreationModal } from "../../ProjectTasks/partials/TaskCreationModal";
import { Parser } from 'html-to-react'
import axios from "axios";
import { useUserProvider } from "../../../../providers/AllUsersProvider";
import { ProjectEditDescription } from "./partials/ProjectEditDescription";
import { ProjectEditPersons } from "./partials/ProjectEditPersons";
import { TaskBulkActionButtons } from "../../ProjectTasks/partials/TaskBulkActionButtons";
import { ProjectEditModal } from "./ProjectEditModal";
import { ProjectArchiveModal } from "./ProjectArchiveModal";

export function ProjectDetail(props) {

    const userProvider = useUserProvider();
    const subheader = useSubheader();
    const pageTitle = usePageTitle();

    const [allTasks, setAllTaks] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const [editDescription, setEditDescription] = useState(false);
    const [editPersons, setEditPersons] = useState(false);

    const [showArchived, setShowArchived] = useState(false);

    const [lProject, setLProject] = useState({});
    const [showTaskModal, setShowTaskModal] = useState(false);
    const [showProjectModal, setShowProjectModal] = useState(false);
    const [showProjectArchivateModal, setShowProjectArchivateModal] = useState(false);

    const [checkedTasks, setCheckedTasks] = useState([]);
    const [showBulkEdit, setShowBulkEdit] = useState(false);

    //let { taskpath } = useParams();

    const fetchProjectTasks = () => {
        if (Object.keys(lProject).length <= 0)
            return;
        axios.get(process.env.REACT_APP_API_URL + '/api/tasksbyproject/' + lProject.id).then(({ data }) => {
            setAllTaks(data);
            handleArchived(data);
            setIsLoading(false);
        }).catch(e => console.log(e));
    }

    const handleArchived = (data) => {
        let taskData = data;
        if (taskData.length > 0)
            taskData = data.filter(t => showArchived ? t.archived === 1 : t.archived === 0);
        setTasks(taskData);
    }
    const handleSearch = () => {
        var filtered = allTasks;
        if (searchTerm !== '' && searchTerm.length > 2) {
            filtered = allTasks.filter(t => {
                return t.name.toLowerCase().includes(searchTerm.toLowerCase());// || t.user?.toLowerCase().includes(searchTerm.toLowerCase());
            });

            filtered.sort((a, b) => a.name.localeCompare(b.name));
        }
        handleArchived(filtered);
    }
    const renderUsers = () => {

        if (lProject.persons === undefined || lProject.persons === null || lProject.persons.length <= 0)
            return null;
        const p = userProvider.getUsersByIds(lProject.persons);

        if (p === undefined || p.length <= 0)
            return null;
        return p.map((user, key) => { return <div className="pItem" key={key}>{user?.name}</div> });

    }
    const onSubmitDescriptionEdit = () => {
        props.refetch();
        fetchProjectTasks();
        setEditDescription(false);
    }
    const onCancleDescriptionEdit = () => {
        setEditDescription(false);
    }
    const onSubmitPersonsEdit = () => {
        props.refetch();
        fetchProjectTasks();
        setEditPersons(false);
    }
    const onCanclePersonsEdit = () => {
        setEditPersons(false);
    }
    const handleBulkCheck = (e) => {
        const { checked, value } = e.target;
        if (checked) {
            setCheckedTasks(checked => [...checked, parseInt(value)]);
        } else {
            setCheckedTasks(checkedTasks.filter((v) => v !== parseInt(value)));

        }
    };
    const bulkCancle = () => {
        setCheckedTasks([]);
        setShowBulkEdit(false);
    }
    const bulkSubmit = () => {
        setCheckedTasks([]);
        setShowBulkEdit(false);
        fetchProjectTasks();
        handleSearch();
    }


    const setSubheaderActions = () => {
        let content = <>
            <Button variant="secondary" className="mr-4" onClick={() => setShowProjectArchivateModal(true)}><span className="svg-icon svg-icon-lg">
                <SVG
                    src={toAbsoluteUrl(
                        "/media/svg/icons/Home/Box.svg"
                    )}
                /></span>
                {` `}Projekt {lProject.archived === 1 ? 'reaktivieren' : 'archivieren'}</Button>
            <Button variant="primary" onClick={() => setShowProjectModal(true)}><span className="svg-icon svg-icon-lg">
                <SVG
                    src={toAbsoluteUrl(
                        "/media/svg/icons/General/Settings-2.svg"
                    )}
                /></span>
                {` `}Projekt bearbeiten</Button>


        </>

        subheader.setActions(content);
    }

    useEffect(() => {
        setLProject(props.project);
    }, [props.project]);

    useEffect(() => {
        if (lProject.id === undefined)
            return;
        fetchProjectTasks();
        subheader.setTitle(lProject.name);
        setSubheaderActions();
        pageTitle.setPageTitle(`🐮 Tasks${lProject.name !== undefined ? ' | ' + lProject.name : ''}`);

    }, [lProject]);

    useEffect(() => {
        handleSearch();
        setSubheaderActions();
    }, [searchTerm, showArchived]);

    return (<>
        <div className="row">
            <div className="col-12">
                <div className="card card-custom gutter-b">
                    <div className="card-header border-1">
                        <h3 className="card-title text-dark">
                            Projekt Tasks
                        </h3>
                        <div className="card-toolbar">
                            <OverlayTrigger
                                placement="left"
                                overlay={
                                    <Tooltip id="latest-project">{showBulkEdit ? 'Bulk Bearbeitung abbrechen' : 'Bulk Bearbeitung starten'}</Tooltip>
                                }
                            >
                                <div className={`btn ${showBulkEdit ? 'btn-light-warning' : 'btn-hover-light-primary'} btn-icon btn-sm mr-2`} onClick={() => {
                                    if (showBulkEdit)
                                        bulkCancle()
                                    setShowBulkEdit(!showBulkEdit)
                                }}>
                                    <i><i className={`la ${showBulkEdit ? 'la-times' : 'la-edit'}`} ></i></i>
                                </div>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="left"
                                overlay={
                                    <Tooltip id="latest-project">{showArchived ? 'Aktive Tasks anzeigen' : 'Archivierte Tasks anzeigen'}</Tooltip>
                                }
                            >
                                <div className={`btn ${showArchived ? 'btn-light-primary' : 'btn-hover-light-primary'}  btn-icon btn-sm`} onClick={() => setShowArchived(!showArchived)}>
                                    <i><i className={`la ${showArchived ? 'la-folder' : 'la-folder-open'}`}></i></i>
                                </div>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="d-flex mb-6 d-print-none">

                            <div className="input-icon h-40px flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control form-control-lg form-control-solid h-40px"
                                    placeholder="Suchen ..."
                                    id="hostsSearch"
                                    autoComplete="false"
                                    //autoFocus={true}
                                    onKeyUp={(e) => setSearchTerm(e.currentTarget.value)}

                                />
                                <span>
                                    <span className="svg-icon svg-icon-lg">
                                        <SVG
                                            src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                                        />
                                    </span>
                                </span>
                            </div>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="latest-project">Task erstellen</Tooltip>
                                }
                            >
                                <div
                                    onClick={() => setShowTaskModal(true)}
                                    className={`btn btn-icon btn-default btn-hover-primary ml-3 h-40px w-40px flex-shrink-0`}
                                >
                                    <span className="svg-icon svg-icon-lg">
                                        <SVG
                                            src={toAbsoluteUrl(
                                                "/media/svg/icons/Code/Plus.svg"
                                            )}
                                        />
                                    </span>
                                </div>
                            </OverlayTrigger>

                        </div>
                        <TasksList bulkEdit={showBulkEdit} handleBulkCheck={handleBulkCheck} bulkChecked={checkedTasks} tasks={tasks} isLoading={isLoading} />
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-9">
                <div className="card card-custom gutter-b">
                    <div className="card-header border-1">
                        <h3 className="card-title text-dark">
                            Allgemeine Projekt Infos
                        </h3>
                        <div className="card-toolbar">
                            <Button variant="icon btn-clean btn-hover-light-primary btn-sm" onClick={() => setEditDescription(!editDescription)}><i className="la la-pen"></i></Button>

                        </div>
                    </div>
                    <div className="card-body">
                        {!editDescription ? Parser().parse(lProject.data?.description) :
                            <ProjectEditDescription project={lProject} onSubmit={onSubmitDescriptionEdit} onHide={onCancleDescriptionEdit} />
                        }
                    </div>
                </div>
            </div>
            <div className="col-3">
                <div className="card card-custom gutter-b">
                    <div className="card-header border-1">
                        <h3 className="card-title text-dark">
                            Hauptakteure
                        </h3>
                        <div className="card-toolbar">
                            <Button variant="icon btn-clean btn-hover-light-primary btn-sm" onClick={() => setEditPersons(!editPersons)}><i className="la la-pen"></i></Button>
                        </div>
                    </div>
                    <div className="card-body">
                        {!editPersons ? renderUsers() :
                            <ProjectEditPersons project={lProject} onSubmit={onSubmitPersonsEdit} onHide={onCanclePersonsEdit} />
                        }
                    </div>
                </div>
            </div>
        </div>
        <TaskCreationModal customer={lProject.customer_short} project={lProject} show={showTaskModal} onHide={() => setShowTaskModal(false)} onSubmit={() => fetchProjectTasks()} />
        <TaskBulkActionButtons bulkChecked={checkedTasks} onSubmit={bulkSubmit} onCancle={bulkCancle} />
        <ProjectEditModal project={lProject} show={showProjectModal} onHide={() => setShowProjectModal(false)} onSubmit={() => window.location.reload()} />
        <ProjectArchiveModal project={lProject} show={showProjectArchivateModal} onHide={() => setShowProjectArchivateModal(false)} onSubmit={() => {
            props.refetch();
            setShowProjectArchivateModal(false);
        }} />

    </>)

}