import React from "react";
import SVG from "react-inlinesvg";
import { Link, useLocation } from 'react-router-dom'
import { useUserProvider } from "../../../../providers/AllUsersProvider";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";


export function TechnikMenu(props) {
    const userProvider = useUserProvider();
    const loc = useLocation();
    const defaultClass = "btn bg-dark-o-10 btn-hover-primary mb-6 px-6 py-6 roundedNew = () => import('@/components/New.vue') d-flex align-items-center text-left";
    const activeClass = "btn btn-primary btn-hover-primary mb-6 px-6 py-6 roundedNew = () => import('@/components/New.vue') d-flex align-items-center text-left";

    const arrayEquals = (a, b) => {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }

    const getClass = (r) => {

        let ll = loc.pathname.split('/');
        let rr = r.split('/');

        return arrayEquals(ll, rr) ? activeClass : defaultClass;
    }

    return (<>
        <div className="p-4 px-lg-10 pt-lg-8">
            <div className="linkItem">
                {userProvider.can('showCustomers') &&
                    <Link to="/technik/hosts" className={getClass('/technik/hosts')}>
                        <span className="svg-icon svg-icon-2x d-block mr-6 mb-1">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Devices/Server.svg")}
                            ></SVG>
                        </span>
                        <span className="font-weight-bold font-size-lg ">
                            Hosting Account Liste
                        </span>
                    </Link>
                }
            </div>
            <div className="linkItem">
                <Link to="/technik/tools" className={getClass('/technik/tools')}>
                    <span className="svg-icon svg-icon-2x d-block mr-6 mb-1">
                        <SVG
                            src={toAbsoluteUrl("/media/svg/icons/Tools/Swiss-knife.svg")}
                        ></SVG>
                    </span>
                    <span className="font-weight-bold font-size-lg ">
                        Tools
                    </span>
                </Link>
            </div>
            <div className="linkItem">
                <Link to="/technik/extensions" className={getClass('/technik/extensions')}>
                    <span className="svg-icon svg-icon-2x d-block mr-6 mb-1">
                        <SVG
                            src={toAbsoluteUrl("/media/svg/icons/Devices/USB.svg")}
                        ></SVG>
                    </span>
                    <span className="font-weight-bold font-size-lg ">
                        Extensions
                    </span>
                </Link>
            </div>

            

        </div>

    </>);
}