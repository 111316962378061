/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import objectPath from "object-path";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";
import { AsideSearch } from "./AsideSearch";
import { AsideMenu } from "./aside-menu/AsideMenu";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuickUserToggler";
import { Brand } from "../brand/Brand";
import { KTUtil } from "./../../../_assets/js/components/util";

import { useAsideSecondary } from "../../_core/MpAsideSecondary";
import { Link, useLocation, withRouter } from "react-router-dom";
import { SecondaryRouter } from "./SecondaryRouter";
import { useUserProvider } from "../../../../providers/AllUsersProvider";

import axios from "axios";
import { useEchoProvider } from "../../../../providers/EchoProvider";
import { useNotificationBadge } from "../../../../providers/NotificationBadgeProvider";

export function Aside() {
  const uiService = useHtmlClassService();
  const userProvider = useUserProvider();
  const MpAsideSecondary = useAsideSecondary();
  const location = useLocation();
  const echoProvider = useEchoProvider();
  const notifyBadge = useNotificationBadge();
  const [isListening, setIsListening] = useState(false);


  const isActive = (where) => {
    let p = location.pathname.match(/^\/[^\/]+/);
    if (p === null)
      return where === null;

    return where === p[0];
  };
  // Aside Secondary
  const updateAside = () => {

    if (MpAsideSecondary.asideEnabled && !MpAsideSecondary.asideHide) {
      document.body.classList.add("aside-secondary-enabled");
      document.body.classList.remove("aside-secondary-disabled");
    } else {
      document.body.classList.add("aside-secondary-disabled");
      document.body.classList.remove("aside-secondary-enabled");
    }
  }
  
  useEffect(() => {
    if (echoProvider === undefined || echoProvider === null || !echoProvider.ready || isListening)
      return;
    echoProvider.echo.private('notify.' + echoProvider.user.id)
      .notification((notification) => {
        notifyBadge.setUpdateNotificationBadge(true);
      });
    setIsListening(true);
  }, [echoProvider]);

  useEffect(() => {
    updateAside();
  }, [MpAsideSecondary.asideEnabled, MpAsideSecondary.asideHide]);

  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses("aside", true),
      asideSecondaryDisplay: objectPath.get(
        uiService.config,
        "aside.secondary.display"
      ),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      extrasSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      extrasNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      extrasQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      extrasQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      extrasLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      extrasUserDisplay: objectPath.get(
        uiService.config,
        "extras.user.display"
      ),
    };
  }, [uiService]);



  return (
    <>
      {/* begin::Aside */}
      <div
        id="kt_aside"
        className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`}
      >
        {/* begin::Primary */}
        <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
          <Brand />
          {/* begin::Nav Wrapper */}
          {
            // flex-column-fluid 
          }
          <div className="aside-nav d-flex flex-column align-items-center py-5 scroll scroll-pull">
            {/* begin::Nav */}
            <ul className="list-unstyled flex-column" role="tablist">
              <li
                className="nav-item mb-3"
                data-toggle="tooltip"
                data-placement="rigth"
                data-container="body"
                data-boundary="window"
                title="Latest Project"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="toggle-quick-panel">Benachrichtigungen</Tooltip>}
                >
                  <a
                    href="#"
                    className="btn btn-icon btn-clean btn-lg position-relative"
                    id="kt_quick_panel_toggle"
                    data-placement="right"
                    data-container="body"
                    data-boundary="window"
                  >
                    <span className="svg-icon svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Notifications1.svg"
                        )}
                      />
                    </span>
                    <span className={`label label-sm ${notifyBadge.countUnreadNotifications > 0 ? 'label-light-danger blink_me' : ''} label-rounded font-weight-bolder position-absolute top-0 right-0 mt-1 mr-1`}>
                      {notifyBadge.countUnreadNotifications}
                    </span>
                  </a>
                </OverlayTrigger>
              </li>
              {/* begin::Item */}
              <li
                className="nav-item mb-3"
                data-toggle="tooltip"
                data-placement="rigth"
                data-container="body"
                data-boundary="window"
                title="Latest Project"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="latest-project">Dashboard</Tooltip>
                  }
                >
                  <Link
                    to="/"
                    className={`nav-link btn btn-icon btn-clean btn-lg ${isActive(null) && 'active'}`}
                  >
                    <span className="svg-icon svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Food/Coffee1.svg"
                        )}
                      />
                    </span>
                  </Link>
                </OverlayTrigger>
              </li>
              {/* end::Item */}
              {/* begin::Item */}
              {userProvider.can('showCustomers') &&
                <li
                  className="nav-item mb-3"
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                  title="Latest Project"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="latest-project">Kunden</Tooltip>
                    }
                  >
                    <Link
                      to="/kunden"
                      className={`nav-link btn btn-icon btn-clean btn-lg ${isActive('/kunden') && 'active'}`}
                    >
                      <span className="svg-icon svg-icon-lg">
                        <SVG
                          src={toAbsoluteUrl(
                            //"/media/svg/icons/Communication/Group.svg"
                            "/media/svg/icons/Clothes/Shirt.svg"
                          )}
                        />
                      </span>
                    </Link>
                  </OverlayTrigger>
                </li>
              }
              {/* end::Item */}
              {/* begin::Item */}
              {userProvider.can('showCustomers') &&
                <li
                  className="nav-item mb-3"
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                  title="Latest Project"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="latest-project">Projekte</Tooltip>
                    }
                  >
                    <Link
                      to="/projekte"
                      className={`nav-link btn btn-icon btn-clean btn-lg ${isActive('/projekte') && 'active'}`}
                    >
                      <span className="svg-icon svg-icon-lg">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Layout/Layout-grid.svg"
                          )}
                        />
                      </span>
                    </Link>
                  </OverlayTrigger>
                </li>
              }
              {/* end::Item */}
              {/* begin::Item */}
              {userProvider.can('showCustomers') &&
                <li
                  className="nav-item mb-3"
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                  title="Latest Project"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="latest-project">Timeline</Tooltip>
                    }
                  >
                    <Link
                      to="/timeline"
                      className={`nav-link btn btn-icon btn-clean btn-lg`}
                    >
                      <span className="svg-icon svg-icon-lg">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Text/Toggle-Right.svg"
                          )}
                        />
                      </span>
                    </Link>
                  </OverlayTrigger>
                </li>
              }
              {/* end::Item */}

              {/* begin::Item */}
              <li
                className="nav-item mb-3"
                data-toggle="tooltip"
                data-placement="rigth"
                data-container="body"
                data-boundary="window"
                title="Latest Project"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="latest-project">Meetings planen</Tooltip>
                  }
                >
                  <Link
                    to="/bbb"
                    className={`nav-link btn btn-icon btn-clean btn-lg ${isActive('/bbb') && 'active'}`}
                  >
                    <span className="svg-icon svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Devices/Video-camera.svg"
                        )}
                      />
                    </span>
                  </Link>
                </OverlayTrigger>
              </li>
              {/* end::Item */}


              {/* begin::Item */}
              {userProvider.canAny(['canStempel', 'adminStempel']) &&

                <li
                  className="nav-item mb-3"
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                  title="Latest Project"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="latest-project">Stempeluhr</Tooltip>
                    }
                  >
                    <Link
                      to="/stempeluhr"
                      className={`nav-link btn btn-icon btn-clean btn-lg ${isActive('/stempeluhr') && 'active'}`}
                    >
                      <span className="svg-icon svg-icon-lg">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Home/Timer.svg"
                          )}
                        />
                      </span>
                    </Link>
                  </OverlayTrigger>
                </li>
              }

              {/* end::Item */}


              {/* begin::Item */}
              <li
                className="nav-item mb-3"
                data-toggle="tooltip"
                data-placement="rigth"
                data-container="body"
                data-boundary="window"
                title="Latest Project"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="latest-project">Technik</Tooltip>
                  }
                >
                  <Link
                    to="/technik"
                    className={`nav-link btn btn-icon btn-clean btn-lg ${isActive('/technik') && 'active'}`}
                  >
                    <span className="svg-icon svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Code/Code.svg"
                        )}
                      />
                    </span>
                  </Link>
                </OverlayTrigger>
              </li>
              {/* end::Item */}

              {/* begin::Item */}
              <li
                className="nav-item mb-3"
                data-toggle="tooltip"
                data-placement="rigth"
                data-container="body"
                data-boundary="window"
                title="Latest Project"
              >
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="latest-project">Interne Zugangsdaten</Tooltip>
                  }
                >
                  <Link
                    to="/internal-credentials"
                    className={`nav-link btn btn-icon btn-clean btn-lg ${isActive('/internal-credentials') && 'active'}`}
                  >
                    <span className="svg-icon svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Home/Key.svg"
                        )}
                      />
                    </span>
                  </Link>
                </OverlayTrigger>
              </li>
              {/* end::Item */}


              {/* end::Item */}
            </ul>
            {/* end::Nav */}
          </div>
          {/* end::Nav Wrapper */}

          {/* begin::Footer */}
          <div className="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
            {/* begin::Aside Toggle */}
            {MpAsideSecondary.asideEnabled && (
              <>
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="toggle-aside">Toggle Aside</Tooltip>}
                >
                  <span
                    className="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
                    id="kt_aside_toggle"
                    onClick={() => MpAsideSecondary.setAsideHide(!MpAsideSecondary.asideHide)}
                  >
                    {!MpAsideSecondary.asideHide ? <i className="ki ki-bold-arrow-back icon-sm" /> : <i className="ki ki-bold-arrow-next icon-sm" />}
                  </span>
                </OverlayTrigger>
              </>
            )}
            {/* end::Aside Toggle */}

            {/* begin::Search */}

            {userProvider.canAny(['adminUsers', 'adminRoles', 'adminProjectTemplates']) &&
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="toggle-search">Admin Area</Tooltip>}
              >
                <Link
                  to="/admin"
                  className="btn btn-icon btn-clean btn-lg mb-1"
                >
                  <span className="svg-icon svg-icon-lg">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Shopping/Settings.svg")}
                    />
                  </span>
                </Link>
              </OverlayTrigger>
            }

            {/* end::Search */}

            {/* begin::Search */}



            {/* begin::Search */}
            {layoutProps.extrasSearchDisplay && (
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="toggle-search">Quick Search</Tooltip>}
              >
                <a
                  href="#"
                  className="btn btn-icon btn-clean btn-lg mb-1"
                  id="kt_quick_search_toggle"
                >
                  <span className="svg-icon svg-icon-lg">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            {/* end::Search */}

            {/* begin::Notifications */}
            {layoutProps.extrasNotificationsDisplay && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="toggle-notifications">Notifications</Tooltip>
                }
              >
                <a
                  href="#"
                  className="btn btn-icon btn-clean btn-lg mb-1 position-relative"
                  id="kt_quick_notifications_toggle"
                  data-placement="right"
                  data-container="body"
                  data-boundary="window"
                >
                  <span className="svg-icon svg-icon-lg">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            {/* end::Notifications */}

            {/* begin::Quick Actions */}
            {/*layoutProps.extrasQuickActionsDisplay && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="toggle-quick-actions">Quick Actions</Tooltip>
                }
              >
                <a
                  href="#"
                  className="btn btn-icon btn-clean btn-lg mb-1"
                  id="kt_quick_actions_toggle"
                >
                  <span className="svg-icon svg-icon-lg">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Media/Equalizer.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
                      )*/}
            {/* end::Quick Actions */}


            {/* begin::Languages*/}
            {layoutProps.extrasLanguagesDisplay && <LanguageSelectorDropdown />}
            {/* end::Languages */}

            {/* begin::User*/}
            {layoutProps.extrasUserDisplay && <QuickUserToggler />}
            {/* end::User */}
          </div>
          {/* end::Footer */}
        </div>
        {/* end::Primary */}

        {MpAsideSecondary.asideEnabled && (
          <>
            {/* begin::Secondary */}
            <div className="aside-secondary d-flex flex-row-fluid">
              {/* begin::Workspace  scroll scroll-push*/}
              <div className="aside-workspace my-2">
                <SecondaryRouter />
                {/* 
                <div className="tab-content">
                  <AsideSearch isActive={activeTab === tabs.tabId1} />
                  <AsideMenu isActive={activeTab === tabs.tabId2} />
                </div>
                */}
              </div>
              {/* end::Workspace */}
            </div>
            {/* end::Secondary */}
          </>
        )}
      </div>
      {/* end::Aside */}
    </>
  );
}
