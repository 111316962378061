import React, { createContext, useState, useContext } from "react";

const MpAsideContext = createContext();

export function useAsideSecondary() {
  return useContext(MpAsideContext);
}

export const MpAsideConsumer = MpAsideContext.Consumer;

export function MpAsideSecondaryProvider({ children }) {
  const [asideEnabled, setAsideEnabled] = useState(false);
  const [asideHide, setAsideHide] = useState(false);
  const value = { asideEnabled, setAsideEnabled, asideHide, setAsideHide};
  return (
    <MpAsideContext.Provider value={value}>
      {children}
    </MpAsideContext.Provider>
  );
}
