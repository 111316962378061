import React, { useEffect, useState } from "react";
import { Accordion, Button, Card, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useSubheader } from "../../../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import axios from "axios";
import { AdminUserGroupsModal } from "./partials/AdminUserGroupsModal";
import { usePageTitle } from "../../../../../providers/PageTitleProvider";

export function AdminUserGroups(props) {
    const subheader = useSubheader();
    const pageTitle = usePageTitle();
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const [allGroups, setAllGroups] = useState([]);
    const [groups, setGroups] = useState([]);
    const [editId, setEditId] = useState(0);
    const [formTypeDelete, setFormTypeDelete] = useState(false);

    const permissionCatMap = {
        "admin": "Administration",
        "emailaccounts": "Email Accounts",
        "tec": "Technischer Bereich",
        "hosts": "Hosting Accounts",
        "customers": "Kunden",
        "misc": "Sonstige Accounts",
        "stempel": "Stempeluhr",
        "projects": "Projekte",
        "tasks": "Tasks",
        "intercredcritical" : "Interne Zugangsdaten (kritisch)",
        "intercredown" : "Interne Zugangsdaten (eigene)",
        "intercredgeneral" : "Interne Zugangsdaten (allgemein)",
    }

    const handleSearch = () => {
        var filtered = allGroups;
        if (searchTerm !== '' && searchTerm.length > 2) {
            filtered = allGroups.filter(t => {
                return t.desc.toLowerCase().includes(searchTerm.toLowerCase()) || t.name.toLowerCase().includes(searchTerm.toLowerCase());
            });

            filtered.sort((a, b) => a.desc.localeCompare(b.desc));
        }
        setGroups(filtered);
    }
    const fetchGroups = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/roles').then(({ data }) => {
            let d = data.filter(t => t.name !== "super");
            setAllGroups(d);
            setGroups(d);
            setIsLoading(false);
        }).catch(e => console.log(e));
    };
    const renderPermission = (items, pgroup, pi) => {
        return <Card>
            <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey={pi}>
                    {permissionCatMap[pgroup]} ({items.length})
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={pi}>
                <Card.Body>{items.map((item, i) => {
                    return <div className="permissionItem" key={i}>
                        - {item.desc}
                    </div>
                })}</Card.Body>
            </Accordion.Collapse>
        </Card>
    }
    const renderGroups = () => {
        return groups.map((group, i) => <div key={i} className="d-block p-5 border-bottom">
            <div className="row">
                <div className="col-lg-2 py-4">{group.desc}</div>
                <div className="col-lg-2 py-4">{group.name}</div>
                <div className="col-lg-7 py-2">
                    {Object.keys(group.permissions).length > 0 && Object.keys(group.permissions).map((pgroup, pi) => <Accordion key={pi}>{renderPermission(group.permissions[pgroup], pgroup, pi)}</Accordion>)}
                </div>
                <div className="col-lg-1 py-4">
                    <div className="d-flex d-print-none justify-content-end flex-nowrap">
                        <Button variant="btn btn-icon btn-clean btn-hover-light-primary btn-sm mt-n1 mr-2"
                            onClick={() => { setEditId(group.id); setShowModal(true) }}
                        ><i className="la la-pencil icon-md text-primary"></i></Button>
                        <Button variant="btn btn-icon btn-clean btn-hover-light-danger btn-sm mt-n1"
                            onClick={() => { setEditId(group.id); setShowModal(true); setFormTypeDelete(true) }}
                        ><i className={`la  la-trash text-danger icon-md`}></i></Button>
                    </div>
                </div>

            </div>
        </div>);
    };

    const modalClose = () => {
        setShowModal(false);
        setEditId(0);
        setTimeout(() => setFormTypeDelete(false), 300);
    }
    const modalSubmit = () => {
        setShowModal(false);
        fetchGroups();
        setEditId(0);
        setTimeout(() => setFormTypeDelete(false), 300);
    }
    useEffect(() => {
        fetchGroups();
    }, []);

    useEffect(() => {
        pageTitle.setPageTitle('🐮 Benutzer Gruppen');
        subheader.setTitle("Benutzer Gruppen");
    }, [subheader, pageTitle]);

    useEffect(() => {
        handleSearch();
    }, [searchTerm]);

    return <>
        <div className="row">
            <div className="col-12">
                <div className={`card card-custom `}>
                    <div className="card-header border-1">
                        <h3 className="card-title text-dark">
                            Gruppen
                        </h3>
                        <div className="card-toolbar">
                            {/*<Link to="/stempeluhr" className="nav-link btn btn-icon btn-clean btn-hover-light-primary btn-sm">
                            <span className="svg-icon svg-icon-sm">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/Text/Bullet-list.svg"
                                    )}
                                />
                            </span>
                                    </Link>*/}
                        </div>
                    </div>
                    <div className="card-body">
                        <div className={`${isLoading ? 'd-flex' : 'd-none'} flex-column align-items-center justify-content-center h-full mt-4 mb-4`}><Spinner animation="border" variant="primary" /></div>
                        <div className={`${isLoading ? 'd-none' : 'd-block'}`}>

                            <div className="d-flex mt-0 mb-6 d-print-none">

                                <div className="input-icon h-40px flex-grow-1">
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid h-40px"
                                        placeholder="Suchen ..."
                                        id="hostsSearch"
                                        autoComplete="false"
                                        //autoFocus={true}
                                        onKeyUp={(e) => setSearchTerm(e.currentTarget.value)}

                                    />
                                    <span>
                                        <span className="svg-icon svg-icon-lg">
                                            <SVG
                                                src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                                            />
                                        </span>
                                    </span>
                                </div>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="latest-project">Benutzergruppe erstellen</Tooltip>
                                    }
                                >
                                    <div
                                        onClick={() => setShowModal(true)}
                                        className={`btn btn-icon btn-default btn-hover-primary ml-3 h-40px w-40px flex-shrink-0`}
                                    >
                                        <span className="svg-icon svg-icon-lg">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Code/Plus.svg"
                                                )}
                                            />
                                        </span>
                                    </div>
                                </OverlayTrigger>
                            </div>
                            {allGroups.length > 0 && <>
                                <div className="bg-dark-o-10 mb-0 rounded-md font-weight-bold font-size-lg py-2 px-5">
                                    <div className="row">

                                        <div className="col-2">
                                            Name
                                        </div>
                                        <div className="col-2">
                                            Eindeutige Kennung
                                        </div>
                                        <div className="col-7">
                                            Berechtigungen
                                        </div>
                                        <div className="col-1">
                                        </div>

                                    </div>

                                </div>
                                {renderGroups()}
                            </>
                            }
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <AdminUserGroupsModal type={formTypeDelete ? 'delete' : 'edit'} edit={editId} show={showModal} onHide={modalClose} onSubmit={() => modalSubmit()} />

    </>
}