import React, { useEffect, useState } from "react";
import Select, { components } from 'react-select'
import { useTaskStatusProvider } from "../../../../../providers/TaskStatusProvider";

import axios from "axios";
import { toast } from 'react-toastify';

export function SelectStatus(props) {
    const [task, setTask] = useState({});

    const taskStatusProvider = useTaskStatusProvider();
    const [selectConfig, setSelectConfig] = useState({});
    const [selected, setSelected] = useState({});

    const CustomOption = ({ innerRef, innerProps, data }) => {
        const tStatus = taskStatusProvider.getTaskStatusById(data.value);
        const style = { backgroundColor: `#${tStatus.color}`, color: `#${tStatus.fontcolor}` };
        return <div {...innerProps} key={data.value} ref={innerRef} className="status-item cursor-pointer font-size-md rounded-md py-4 px-3 text-center m-2" style={style} >{tStatus.name}</div>;
    }
    const CustomControl = ({ data, ...props }) => {

        const tStatus = taskStatusProvider.getTaskStatusById(data.value);
        if (tStatus === null)
            return <components.SingleValue {...props}><div className="status-item bg-light cursor-pointer font-size-md rounded-sm py-1 px-3 h-full text-center flex-grow-1">Kein Status</div></components.SingleValue>
        const style = { backgroundColor: `#${tStatus.color}`, color: `#${tStatus.fontcolor}` };
        return <components.SingleValue {...props}><div className="status-item cursor-pointer font-size-md rounded-sm py-1 px-3 h-full text-center flex-grow-1" style={style} >{tStatus.name}</div></components.SingleValue>;
    };

    const mapToSelectConfig = () => {
        const statuses = taskStatusProvider.allTaskStatus.map((item) => {
            return { label: item.name, value: item.id };
        });
        setSelectConfig(statuses);
    };


    const setSelectedItem = () => {
        if (task.status === undefined || task.status === null)
            return;
        const item = taskStatusProvider.getTaskStatusById(task.status);
        setSelected({ label: item.name, value: item.id });
    };

    const updateTask = (status) => {
        let body = {
            status: status,
        };
        
        axios.post(process.env.REACT_APP_API_URL + '/api/tasks/' + task.id + '/updateStatus', body).then((d) => {
            toast.success("Status wurde geändert")
            props.refetch();
        }).catch((e) => { 
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.",{autoClose: 5500});
            props.refetch();
        });
    };

    const applyStatus = (status) => {
        setSelected(status);
        const val = status === null ? null : (status.value !== undefined ? status.value : null);
        if (val === task.status)
            return;
        updateTask(val);
    };

    useEffect(() => {
        setTask(props.task);
    }, [props.task]);
    useEffect(() => {
        if (taskStatusProvider.allTaskStatus.length <= 0)
            return;
        mapToSelectConfig();
        setSelectedItem();
    }, [task, taskStatusProvider.allTaskStatus]);

    return <> {taskStatusProvider.allTaskStatus.length > 0 ? <div className="statusSelect"><Select options={selectConfig} value={selected}
        isClearable={true}
        components={{ Option: CustomOption, SingleValue: CustomControl, DropdownIndicator: null }}
        lang="de"
        noOptionsMessage={() => "Status nicht gefunden"}
        key="status"
        placeholder={<div key="none" className="status-item bg-light cursor-pointer font-size-md rounded-md py-1 px-3 text-center flex-grow-1">Kein Status</div>}
        classNamePrefix="sl2def"
        styles={{
            singleValue: (base) => ({
                ...base,
                display: 'flex',
                width: 'calc(100% - 10px)'
            }),
        }}
        onChange={(val) => applyStatus(val)}
    /></div> : null}
    </>
}