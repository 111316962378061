import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Form, Col } from "react-bootstrap";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import axios from "axios";
import Select from 'react-select'

export default function UserSettingsModal(props) {

    const [user, setUser] = useState({});

    const initialValues = {
        email: '',
        firstname: '',
        lastname: '',
        password: '',
        password1: ''
    };
    const update = (values) => {
        let body = values;
        return axios.put(process.env.REACT_APP_API_URL + '/api/user/', body);
    };

    const fetchEntry = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/user').then(({ data }) => {
            setUser(data);

            formik.setFieldValue('firstname', (data.firstname == null ? '' : data.firstname));
            formik.setFieldValue('lastname', (data.lastname == null ? '' : data.lastname));
            formik.setFieldValue('email', (data.email == null ? '' : data.email));
            //formik.setFieldValue('password', (data.password == null ? '' : data.password));

        }).catch(e => props.onSubmit());
    };
  
    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            email: Yup.string().required(),
            firstname: Yup.string().required(),
            lastname: Yup.string().required(),
            password: Yup.string(),
            password1: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
        }),
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {

            setSubmitting(false);
            update(values).then(d => { toast.success("Gespeichert"); resetForm(); props.onSubmit() }).catch(e => {
                toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
                props.onSubmit();
            });

            setSubmitting(true);

        },
    });

    useEffect(() => {
            fetchEntry();
    }, []);



    const renderForm = () => {
        return <Form onSubmit={formik.handleSubmit} className={`bg-white position-relative`}>
            <Modal.Body>
                <Form.Row>
                    <Form.Group className="col-lg-6">
                        <Form.Label>Vorname</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Vorname"
                            name="firstname"
                            value={''}
                            {...formik.getFieldProps("firstname")}
                            isInvalid={formik.errors.firstname}
                        />
                    </Form.Group>
                    <Form.Group className="col-lg-6">
                        <Form.Label>Nachname</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nachname"
                            name="lastname"
                            value={''}
                            {...formik.getFieldProps("lastname")}
                            isInvalid={formik.errors.lastname}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Email"
                            name="email"
                            value={''}
                            {...formik.getFieldProps("email")}
                            isInvalid={formik.errors.email}
                        />
                    </Form.Group>
                </Form.Row>

            </Modal.Body>
            <Modal.Header className="border-top">
                <Modal.Title id="contained-modal-title-vcenter">
                    Passwort ändern 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Passwort</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Passwort"
                            name="password"
                            autoComplete="chrome-off"
                            value={''}
                            {...formik.getFieldProps("password")}
                            isInvalid={formik.errors.password}

                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Passwort wiederholen</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Passwort wiederholen"
                            name="password1"
                            autoComplete="chrome-off"
                            value={''}
                            {...formik.getFieldProps("password1")}
                            isInvalid={formik.errors.password1}

                        />
                    </Form.Group>
                </Form.Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit">Speichern</Button>
                <Button variant="default " onClick={props.onHide}>Schließen</Button>
            </Modal.Footer>
        </Form>
    }


    return <>
        <Modal
            show={props.show}
            size='lg'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Eigene Daten bearbeiten
                </Modal.Title>
            </Modal.Header>
            {renderForm()}
        </Modal>
    </>

}