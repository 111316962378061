import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { TaskArchiveModal } from "./TaskArchiveModal";
import { TaskDeletionModal } from "./TaskDeletionModal";
import SetName from "./taskForm/SetName"
import { TaskMoveModal } from "./TaskMoveModal";
import { TaskParentModal } from "./TaskParentModal";
import { TaskParentDeletionModal } from "./TaskParentDeletionModal";
import Moment from "react-moment";

export function TaskSettings(props) {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showArchiveModal, setShowArchiveModal] = useState(false);
    const [showParentModal, setShowParentModal] = useState(false);
    const [showParentDeletionModal, setShowParentDeletionModal] = useState(false);
    const [showMoveModal, setShowMoveModal] = useState(false);

    const [task, setTask] = useState({});
    const history = useHistory();
    const loc = useLocation();

    const redirectAfterDelete = () => {
        let p = loc.pathname;
        let reg = p.match(/([^\/]+$)/);
        if (parseInt(reg[0]) !== task.id)
            return;
        p = p.replace(/(\/[^\/]+$)/, "");
        history.push(p += 's');
    }
    const redirectAfterMove = (data) => {

        let prjId = data.project_id;
        if (prjId === null)
            prjId = '0_' + data.customer_id
        history.push('/projekte/' + prjId + '/task/' + data.id);
    }

    useEffect(() => {
        setTask(props.task);
    }, [props.task]);
    return <>
        <div className="row">
            <div className="col-12">
                <Form.Group>
                    <Form.Label>Task Name</Form.Label>
                    <SetName task={task} refetch={props.refetch} />
                </Form.Group>
            </div>
            <div className="col-12">
                <Form.Label>Eltern Task</Form.Label>
                <div className="">{task.parent !== undefined && task.parent !== null ?
                    <>
                        <div className="d-flex justify-content-between">
                            <Link to={"/projekte/" + (task.parent.project_id !== null ? task.parent.project_id : '0_' + task.parent.customer_id) + "/task/" + task.parent.id}><i className="la la-link mr-2"></i>{task.parent.name}</Link>
                            <Button variant="btn btn-icon btn-clean btn-hover-light-danger btn-sm mt-n2"
                                onClick={() => { setShowParentDeletionModal(true); }}
                            ><i className="la la-trash icon-md text-danger"></i></Button>
                        </div>

                    </> :
                    'Dieser Task hat derzeit keinen Eltern Task.'}
                </div>
            </div>
        </div>
        <hr />
        <div className="row mt-8">
            <div className="col-3">
                <Button variant="info btn-sm btn-block" onClick={() => setShowParentModal(true)}>Eltern Task einstellen</Button>
                <TaskParentModal task={task} parenttask={task.parent} show={showParentModal} onHide={() => setShowParentModal(false)} onSubmit={() => { setShowParentModal(false); props.refetch() }} />
                <TaskParentDeletionModal task={task} show={showParentDeletionModal} onHide={() => setShowParentDeletionModal(false)} onSubmit={() => { setShowParentDeletionModal(false); props.refetch() }} />
            </div>
            <div className="col-3">
                <Button variant="secondary btn-sm btn-block" onClick={() => setShowArchiveModal(true)}>Task {task.archived ? "reaktivieren" : "archivieren"}</Button>
                <TaskArchiveModal task={task} show={showArchiveModal} onHide={() => setShowArchiveModal(false)} onSubmit={() => { setShowArchiveModal(false); props.refetch() }} />
            </div>
            <div className="col-3">
                <Button variant="warning btn-sm btn-block" onClick={() => setShowMoveModal(true)}>Task verschieben</Button>
                <TaskMoveModal task={task} show={showMoveModal} onHide={() => setShowMoveModal(false)} onSubmit={redirectAfterMove} />
            </div>
            <div className="col-3">
                <Button variant="danger btn-sm btn-block" onClick={() => setShowDeleteModal(true)}>Task löschen</Button>
                <TaskDeletionModal task={task} show={showDeleteModal} onHide={() => setShowDeleteModal(false)} onSubmit={() => redirectAfterDelete()} />
            </div>

        </div>
        <div className="row mt-6">
            <div className="col-12">
                <small className="mb-2">#{task.id} | Erstellt am: <Moment format="DD.MM.YYYY HH:mm">{task.created_at}</Moment></small>
            </div>
        </div>
    </>
    //Todo: weitere Felder.

}