import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { useSubheader } from "../../../../_metronic/layout";
import { usePageTitle } from "../../../../providers/PageTitleProvider";
import CustomerHosts from "../../Customers/components/partials/CustomerHosts";

export function TechnikHosts(props) {
    const subheader = useSubheader();
    const pageTitle = usePageTitle();


    useEffect(() => {
        subheader.setTitle("Hosting Accounts");
        pageTitle.setPageTitle(`🐮 Hosting Accounts`);
    }, [])
   

    return (<>
        <div className="row">
            <div className="col-12">
                <div className={`card card-custom`}>
                    <div className="card-body">
                        <CustomerHosts all={true} />
                    </div>
                </div>
            </div>
        </div>
    </>)

}