import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useUserProvider } from "../../../../providers/AllUsersProvider";
import axios from "axios";
import { Table } from "react-bootstrap";

export function MonthVacation(props) {
    const user = useSelector((state) => state.auth.user, shallowEqual);
    const dispatch = useDispatch();
    const { authToken } = useSelector(
        ({ auth }) => ({
            authToken: auth.authToken,
        }),
        shallowEqual
    );

    const [isLoading, setIsLoading] = useState(true);
    const [allPeriodData, setAllPeriodData] = useState({});
    const [currentPeriodData, setCurrentPeriodData] = useState({});
    const [sumData, setSumData] = useState({});
    const [selectUserConfig, setSelectUserConfig] = useState({});

    const [editTime, setEditTime] = useState('');

    const [currentDate, setCurrentDate] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);

    const userProvider = useUserProvider();

    const fetchVacationData = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/stempeluhr?day=' + props.currentDate.format('YYYY-MM-DD') + '&user=' + props.user.value).then(({ data }) => {
            setSumData(data.sums);
            setAllPeriodData(data.allPeriodSums);
            setCurrentPeriodData(data.currentPeriodSums);
            console.log(data);
            props.setShouldRefetch(false);
        }).catch(e => null);
    };

    useEffect(() => {
        if (
            Object.keys(props.user).length > 0 &&
            (currentDate === null || currentDate !== props.currentDate) &&
            (currentUser === null || currentUser !== props.users.selectedUser)
        ) {
            fetchVacationData();
            setCurrentDate(currentDate);
            setCurrentUser(currentUser);
        }
    }, [props.currentDate, props.user]);
    useEffect(() => {
        if (props.shouldRefetch !== undefined && props.shouldRefetch === true)
            fetchVacationData();
    }, [props.shouldRefetch]);

    return <>
        <div className={`card card-custom ${props.className} gutter-b`}>
            <div className="card-header">
                <h3 className="card-title text-dark">
                    <span className="d-print-none">{props.title}</span>
                    <span className="d-none d-print-block">Urlaubsdaten</span>
                </h3>
            </div>
            <div className="card-body p-6">
                <Table responsive borderless className="mb-1">
                    {sumData?.vacation !== undefined && <><tr>
                        <td>Verbrauchte Urlaube in diesem Monat</td>
                        <td className="text-right">{sumData?.vacation}</td>
                    </tr>
                        <tr>
                            <td colSpan={2} className="py-0"><hr/></td>
                        </tr>
                    </>
                    }
                    {currentPeriodData?.vacationAmountThisPeriod !== undefined && <tr>
                        <td>Start-Wert der Periode</td>
                        <td className="text-right">{currentPeriodData?.vacationAmountThisPeriod}</td>
                    </tr>}

                    {currentPeriodData?.vacationAmountConsumed !== undefined && <tr>
                        <td>Verbrauchte Urlaube in dieser Periode</td>
                        <td className="text-right">{currentPeriodData?.vacationAmountConsumed}</td>
                    </tr>}
                    {currentPeriodData?.vacationAmountRest !== undefined && <tr>
                        <td>Rest-Urlaub dieser Periode</td>
                        <td className="text-right">{currentPeriodData?.vacationAmountRest}</td>
                    </tr>}

                    {allPeriodData?.vacationAmountRest !== undefined && <tr>
                        <td className={`rounded-left align-middle mt-2 ${allPeriodData?.vacationAmountRest > 0 ? 'bg-light-success' : 'bg-light-danger'}`}>Rest-Urlaub aller Perioden<br/><small>Das ist der aktuelle Stand deines Rest-Urlaubs<br/>ohne zukünftig eingetragene Urlaube.</small></td>
                        <td className={`rounded-right align-middle text-right mt-2 ${allPeriodData?.vacationAmountRest > 0 ? 'bg-light-success' : 'bg-light-danger'}`}>{allPeriodData?.vacationAmountRest}</td>
                    </tr>}

                </Table>
            </div>
        </div>
    </>

}