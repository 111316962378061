import React, { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Moment from "react-moment";
export class TechnikTickets extends React.Component {

    zammadAxios = null;

    constructor() {
        super();
        this.zammadAxios = axios.create();
    }

    authHeader = {
        headers: {
            Authorization: `Token token=${process.env.REACT_APP_ZAMMAD_TOKEN}`,
        }
    }
    state = {
        data: [],
        states: [],
    }

    fetchTickets() {
        return this.zammadAxios.get(process.env.REACT_APP_ZAMMAD_URL + '/api/v1/tickets/search?query=!geschlossen+AND+!(tags:search-console+OR+tags:inspiration+OR+tags:helpful+OR+tags:code-issues)&limit=50', this.authHeader).then(({ data }) => {
            let objects = Object.values(data.assets.Ticket);
            if (objects !== null && objects.length > 0) {
                objects.sort((a, b) => (a.last_contact_at <= b.last_contact_at) ? 1 : -1)
                this.setState({ data: objects });
            }
        }).catch(e => {});
    };
    fetchTicketStates() {
        return this.zammadAxios.get(process.env.REACT_APP_ZAMMAD_URL + '/api/v1/ticket_states', this.authHeader).then(({ data }) => {
            this.setState({ states: data });
        }).catch(e => {});
    };

    componentDidMount() {
        this.fetchTickets();
        this.fetchTicketStates();
    }

    componentDidUpdate() {
        if (this.props.shouldUpdate)
            this.fetchTickets();
    }

    getState(id) {
        if (Object.keys(this.state.states) <= 0)
            return null;
        return this.state.states.filter(d => d.id === parseInt(id))[0];
    }

    generateTicketItem(item, key) {

        return <a target="_blank" key={key} href={`${process.env.REACT_APP_ZAMMAD_URL}/#ticket/zoom/${item.id}`} className="d-block p-5 border-bottom bg-hover-light-o-20">
            <div className="row align-items-center">
                <div className="col-3 col-lg-1">#{item.number}</div>
                <div className="col-12 col-lg-7">{item.title}</div>
                <div className="col-lg-2 text-center"><Moment format="DD.MM.YYYY HH:mm:ss">{item.last_contact_at}</Moment></div>
                <div className="col-lg-2 mt-4 mt-lg-0 align-items-center"><div className={`status-item ${item.state_id === 14 ? 'bg-light-danger' :
                    ([1, 2].includes(item.state_id) ? 'bg-light-warning' : 'bg-light')

                    } text-center font-size-xs rounded-md p-3`}>{this.getState(item.state_id) !== null && this.getState(item.state_id).name}</div></div>
            </div>
        </a>
    }

    render() {
        return <div className="card card-custom gutter-b">
            <div className="card-header">
                <h3 className="card-title d-block d-lg-flex">
                    Aktuelle Technik-Tickets
                    <small className="d-block d-lg-inline mt-4 mt-lg-0 ml-lg-2">
                        (max. 50 werden angezeigt. Search-Console Tickets sind ausgeblendet)
                    </small>
                </h3>
            </div>
            <div className="card-body">
                {this.state.data.length > 0 &&
                    <div className="d-none d-lg-block bg-dark-o-10 mb-0 rounded-md font-weight-bold font-size-lg py-2 px-5">
                        <div className="row">
                            <div className="col-1">
                                #ID
                            </div>
                            <div className="col-7">
                                Titel
                            </div>
                            <div className="col-2 text-center">
                                Letzes Update
                            </div>
                            <div className="col-2 text-center">
                                Status
                            </div>
                        </div>
                    </div>
                }
                {this.state.data.length > 0 ? this.state.data.map((item, key) => {
                    if (item.title === undefined)
                        return null;
                    return this.generateTicketItem(item, key);
                }) :
                    <div className="alert bg-light-info mt-10">Keine Tickets oder Verbindung zum Zammad nicht möglich..</div>
                }

            </div>
        </div>
    }
}