import React from "react";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { DatePickerLocale } from "../../../../constants/DatePickerLocale";
import { Button, Form } from "react-bootstrap";
import Select from 'react-select'
import { useRef, useState } from "react";
import moment from "moment";

export function GanttTopForm(props) {
    const [pickerDates, setPickerDates] = useState({ start: moment().subtract(2, 'months').format('YYYY-MM-DD'), end: moment().add(6, 'months').format('YYYY-MM-DD') })

    const [queryType, setSelectedQueryType] = useState({ label: 'Projekte', value: 'projects' });
    const pickerRef = useRef();
    const pickerInputRef = useRef();

    const selectFilterTypeConfig = [
        { label: 'Projekte', value: 'projects' },
        { label: 'Mitarbeiter', value: 'users' },
    ];

    const applyPicker = (e, picker) => {
        setPickerDates({ start: picker.startDate.format('YYYY-MM-DD'), end: picker.endDate.format('YYYY-MM-DD') });
        props.setPickerDates({ start: picker.startDate.format('YYYY-MM-DD'), end: picker.endDate.format('YYYY-MM-DD') });
    };

    return <>
        <div className="row w-100" style={{zIndex: 20}}>
            <div className="col-4">
                <DateRangePicker
                    ref={pickerRef}
                    initialSettings={{
                        showCustomRangeLabel: false,
                        locale: DatePickerLocale,
                        autoApply: false,
                        autoUpdateInput: true
                    }}
                    onApply={(e, picker) => applyPicker(e, picker)}
                //onCancel={(e, picker) => clearDates(e, picker)}
                >
                    <Form.Control defaultValue={moment(pickerDates.start).format('DD.MM.YYYY') + ' - ' + moment(pickerDates.end).format('DD.MM.YYYY')} size="sm" type="text" onFocus={(e) => e.currentTarget.blur()} style={{ height: "38px" }}
                    ref={pickerInputRef} 
                    />
                </DateRangePicker>
            </div>
            <div className="col-4">
                <Select options={selectFilterTypeConfig} value={queryType} onChange={(o) => {
                    setSelectedQueryType(o);
                    props.setQueryType(o);
                }}
                    classNamePrefix="sl2def"
                />
            </div>
            <div className="col-2">
                <Button variant="primary" onClick={() => props.refetch()}>Filtern</Button>
            </div>
        </div>
    </>
}
