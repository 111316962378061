/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { Tab, Nav, Button, OverlayTrigger, Popover, Tooltip, Spinner } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { NotificationList } from "./NotificationList";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NotificationSettings } from "./NotificationSettings";
import axios from "axios";
import { useEchoProvider } from "../../../providers/EchoProvider";
import { useNotificationBadge } from "../../../providers/NotificationBadgeProvider";

export function NotificationPanel() {
    const [selectedTab, setSelectedTab] = useState("Ungelesen");
    const [shouldUpdate, setShouldUpdate] = useState(true);
    const [isListening, setIsListening] = useState(false);

    const echoProvider = useEchoProvider();
    const notifyBadge = useNotificationBadge();


    const markAllAsRead = () => {
        return axios.post(process.env.REACT_APP_API_URL + '/api/notifications_all_as_read').then(({ data }) => {
            setShouldUpdate(true);
            notifyBadge.setUpdateNotificationBadge(true);
        }).catch(e => null);
    };

    const setTab = _tabName => {
        setSelectedTab(_tabName);
        setShouldUpdate(true)
    };
    useEffect(() => {
        if (echoProvider === undefined || echoProvider === null || !echoProvider.ready || isListening)
            return;
        echoProvider.echo.private('notify.' + echoProvider.user.id)
            .notification((notification) => {
                setShouldUpdate(true);
            });
        setIsListening(true);
    }, [echoProvider]);

    useEffect(() => {
        if (notifyBadge.updateNotificationBadge)
            setShouldUpdate(true);
    }, [notifyBadge.updateNotificationBadge]);


    return (
        <div id="kt_quick_panel" className="offcanvas offcanvas-left pt-5 pb-10 d-flex flex-column justify-content-start">
            <Tab.Container
                defaultActiveKey={selectedTab}
            >
                {/*begin::Header*/}
                <div
                    className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
                    <Nav
                        onSelect={setTab}
                        as="ul"
                        role="tablist"
                        className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
                    >
                        <Nav.Item as="li">
                            <Nav.Link
                                eventKey="Ungelesen"
                                className={`nav-link ${selectedTab === "Ungelesen" ? "active" : ""
                                    }`}
                            >
                                Ungelesen
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="nav-item" as="li">
                            <Nav.Link
                                eventKey="Gelesen"
                                className={`nav-link ${selectedTab === "Gelesen" ? "active" : ""
                                    }`}
                            >
                                Gelesen
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link
                                eventKey="Settings"
                                className={`nav-link ${selectedTab === "Settings" ? "active" : ""
                                    }`}
                            >
                                Settings
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <div className="offcanvas-close mt-n2 mt-lg-n4 pr-5" style={{ position: "absolute", top: "15px", right: "10px" }}>
                        <a
                            href="#"
                            className="btn btn-xs btn-icon btn-light btn-hover-primary"
                            id="kt_quick_panel_close"
                        >
                            <i className="ki ki-close icon-xs text-muted"></i>
                        </a>
                    </div>
                </div>
                {/*end::Header*/}

                {/*begin::Content*/}
                <div className="offcanvas-content px-10 flex-grow-1">
                    <div className="tab-content h-100">
                        <div
                            role="tabpanel"
                            className={`tab-pane h-100 fade pt-3 pr-5 mr-n5 ${selectedTab === "Ungelesen" ? "active show" : ""}`}
                        >
                            <div className="d-flex flex-column justify-content-start h-100">
                                <div className="d-flex justify-content-between">
                                    <h5 className="font-weight-bold mb-5">Benachrichtigungen {shouldUpdate && <Spinner animation="border" variant="primary ml-2" size="sm" />}</h5>
                                    <div className="markRead">
                                        {/*
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={
                                                <Tooltip>Wählen</Tooltip>
                                            }
                                        >
                                            <Button variant="btn btn-icon btn-clean btn-hover-light-warning btn-sm mt-n2"
                                                onClick={() => { markAllAsRead(); }}
                                            ><i className="la la-edit icon-md text-primary"></i></Button>
                                        </OverlayTrigger>
                                        */}

                                        <OverlayTrigger
                                            placement="right"
                                            overlay={
                                                <Tooltip>Alle als gelesen markieren</Tooltip>
                                            }
                                        >
                                            <Button variant="btn btn-icon btn-clean btn-hover-light-primary btn-sm mt-n2"
                                                onClick={() => { markAllAsRead(); }}
                                            ><i className="la la-eye icon-md text-primary"></i></Button>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <div style={{ position: 'relative', height: '100%' }}>
                                    <PerfectScrollbar
                                        options={{ wheelPropagation: false }}
                                        className="scroll pr-4"
                                        style={{ height: '100%', width: '100%', position: "absolute" }}
                                    >
                                        <NotificationList read={false} shouldUpdate={shouldUpdate} setShouldUpdate={setShouldUpdate} />
                                    </PerfectScrollbar>
                                </div>
                            </div>
                        </div>
                        <div
                            role="tabpanel"
                            className={`tab-pane h-100 fade pt-3 pr-5 mr-n5 ${selectedTab === "Gelesen" ? "active show" : ""}`}
                        >
                            <div className="d-flex flex-column justify-content-start h-100">
                                <h5 className="font-weight-bold mb-5">Gelesene Benachrichtigungen {shouldUpdate && <Spinner animation="border" variant="primary ml-2" size="sm" />}</h5>
                                <div style={{ position: 'relative', height: '100%' }}>
                                    <PerfectScrollbar
                                        className="scroll pr-4"
                                        style={{ height: '100%', width: '100%', position: "absolute" }}
                                        options={{ wheelPropagation: false }}
                                    >
                                        <NotificationList read={true} shouldUpdate={shouldUpdate} setShouldUpdate={setShouldUpdate} />
                                    </PerfectScrollbar>
                                </div>
                            </div>
                        </div>
                        <div
                            id="kt_quick_panel_settings"
                            role="tabpanel"
                            className={`tab-pane fade pt-3 pr-5 mr-n5 scroll ps ${selectedTab === "Settings" ? "active show" : ""}`}
                        >
                            <NotificationSettings />
                        </div>
                    </div>
                </div>
                {/*end::Content*/}
            </Tab.Container>
        </div>
    );
}
