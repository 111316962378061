import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useAsideSecondary } from "../../../../_metronic/layout/_core/MpAsideSecondary";
import { useSubheader } from "../../../../_metronic/layout";
import { useContentContainer } from "../../../../_metronic/layout/_core/MpContentContainer";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { CustomerTasks } from "../../Customers/components/CustomerTasks";
import { ProjectDetail } from "../components/ProjectDetail";
import { usePageTitle } from "../../../../providers/PageTitleProvider";
import { TaskDetail } from "../../ProjectTasks/TaskDetail";

export function ProjectsPage(params) {

    //const user = useSelector((state) => state.auth.user, shallowEqual);
    const MpAsideSecondary = useAsideSecondary();
    const subheader = useSubheader();
    const contentContainer = useContentContainer();
    const pageTitle = usePageTitle();

    const [projects, setProjects] = useState({});
    const [project, setProject] = useState({});
    const [customer, setCustomer] = useState({});

    let { id, taskpath } = useParams();

    const getIdString = () => {
        return String(id);
    }
    const idIsCustomer = () => {
        return getIdString().substring(0, 2) === '0_';
    }

    const fetchProjects = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/projects').then(({ data }) => {
            setProjects(data);
        }).catch(e => console.log(e));
    };
    const fetchProject = () => {
        if(id !== null && idIsCustomer())
            return;
        axios.get(process.env.REACT_APP_API_URL + '/api/projects/' + id).then(({ data }) => {
            setProject(data);
        }).catch(e => console.log(e));
    };
    const fetchCustomer = () => {
        if(id !== null && !idIsCustomer())
            return;
        axios.get(process.env.REACT_APP_API_URL + '/api/customers/' + getIdString().slice(2)).then(({ data }) => {
            setCustomer(data);
        }).catch(e => console.log(e));
    };

    const renderContent = () => {
        if(id === null)
            return null;
        if (taskpath !== undefined)
            return <TaskDetail customer={customer} project={project} />;
        if(idIsCustomer())
            return <CustomerTasks customer={customer} />
        if(!idIsCustomer())
            return <ProjectDetail project={project} refetch={fetchProject} />
    };


    useEffect(() => {
        MpAsideSecondary.setAsideEnabled(true);
        contentContainer.reset();
        pageTitle.setPageTitle(`🐮  Projekte`);
    }, []);

    useEffect(() => {
        fetchProjects();
        if (id !== undefined) {
            fetchProject();
            fetchCustomer();
        } else {
            subheader.setActions(null);
        }
    }, [id]);

    const renderEmpty = () => {
        return (<>
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                <div className="text-center">
                    <div className="svg-icon svg-icon-8x">
                        <SVG
                            src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-left.svg")}
                        />
                    </div>
                    <div className="font-size-h6 mt-10">Wähle einen Projekt aus.</div>
                </div>
            </div>
        </>
        );
    };

    return (id === undefined ? renderEmpty() : renderContent());


}