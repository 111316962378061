import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Form, Col } from "react-bootstrap";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import axios from "axios";
import Select from 'react-select'

export default function AdminUserModal(props) {

    const [selectGroupConfig, setSelectGroupConfig] = useState({});
    const [selectedDepartment, setSelectedDepartment] = useState({});
    const [selectedGroup, setSelectedGroup] = useState({});

    const [user, setUser] = useState({});

    const departments = [
        { value: 0, label: 'nicht definiert' },
        { value: 1, label: 'Chef' },
        { value: 2, label: 'Marketing / Verwaltung' },
        { value: 3, label: 'Online - Marketing' },
        { value: 4, label: 'Grafik' },
        { value: 5, label: 'Programmierung' },
        { value: 6, label: 'Textierung' },
        { value: 7, label: 'Web-Crossing' },
    ];

    const initialValues = {
        email: '',
        firstname: '',
        lastname: '',
        password: '',
        password1: '',
        externalallowed: false,
        t3_login: false,
        t3_maintainer: false,
    };
    const saveNew = (values) => {
        let body = values;
        body.department = selectedDepartment.value;
        body.group = selectedGroup.value;
        return axios.post(process.env.REACT_APP_API_URL + '/api/users', body);
    };
    const updateExisting = (values) => {
        let body = values;
        body.department = selectedDepartment.value;
        body.group = selectedGroup.value;
        return axios.put(process.env.REACT_APP_API_URL + '/api/users/' + props.edit, body);
    };
    const mapGroupsForSelect = (data) => {
        let options = data.map((item, i) => {
            return { label: item.desc, value: item.name };
        });
        options = options.filter((item) => item.value !== 'super');
        setSelectGroupConfig(options);
    };
    const fetchAllGroups = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/roles').then(({ data }) => {
            mapGroupsForSelect(data);
        }).catch(e => props.onSubmit());
    };

    const fetchEntry = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/users/' + props.edit).then(({ data }) => {
            setUser(data);

            formik.setFieldValue('firstname', (data.firstname == null ? '' : data.firstname));
            formik.setFieldValue('lastname', (data.lastname == null ? '' : data.lastname));
            formik.setFieldValue('email', (data.email == null ? '' : data.email));

            formik.setFieldValue('externalallowed', (data.externalallowed == null ? false : data.externalallowed));
            formik.setFieldValue('t3_login', (data.t3_login == null ? false : data.t3_login));
            formik.setFieldValue('t3_maintainer', (data.t3_maintainer == null ? false : data.t3_maintainer));

            //formik.setFieldValue('password', (data.password == null ? '' : data.password));

            setSelectedDepartment({ label: departments[data.department].label, value: data.department });

            if (data.access_roles.length <= 0)
                return;
            if (data.access_roles[0].name !== 'super') {
                setSelectedGroup({ label: data.access_roles[0].desc, value: data.access_roles[0].name });
            } else {
                setSelectedGroup({ label: data.access_roles[1].desc, value: data.access_roles[1].name });
            }


        }).catch(e => props.onSubmit());
    };
    const deleteData = () => {
        axios.delete(process.env.REACT_APP_API_URL + '/api/users/' + props.edit).then((d) => {
            toast.success(user.deleted_at === null ? 'Benutzer gelöscht' : 'Benutzer reaktiviert');
            props.onSubmit();
        }).catch(e => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
            props.onSubmit();
        });
    }

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            email: Yup.string().required(),
            firstname: Yup.string().required(),
            lastname: Yup.string().required(),
            password: Yup.string(),
            password1: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
        }),
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {

            setSubmitting(false);
            if (props.edit === 0) {
                saveNew(values).then(d => { toast.success("Gespeichert"); resetForm(); props.onSubmit(); }).catch(e => {
                    toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
                    props.onSubmit();
                });
            } else {
                updateExisting(values).then(d => { toast.success("Gespeichert"); resetForm(); props.onSubmit() }).catch(e => {
                    toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
                    props.onSubmit();
                });
            }
            setSubmitting(true);

        },
    });

    useEffect(() => {
        fetchAllGroups();
    }, []);
    useEffect(() => {
        if (props.edit !== 0)
            fetchEntry();
    }, [props.edit]);



    const renderForm = () => {
        return <Form onSubmit={formik.handleSubmit} className={`bg-white position-relative`} autoComplete="new-password">
            <Modal.Body>
                <Form.Row>
                    <Form.Group className="col-lg-6">
                        <Form.Label>Vorname</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Vorname"
                            name="firstname"
                            value={''}
                            {...formik.getFieldProps("firstname")}
                            isInvalid={formik.errors.firstname}
                        />
                    </Form.Group>
                    <Form.Group className="col-lg-6">
                        <Form.Label>Nachname</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nachname"
                            name="lastname"
                            value={''}
                            {...formik.getFieldProps("lastname")}
                            isInvalid={formik.errors.lastname}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Email"
                            name="email"
                            value={''}
                            {...formik.getFieldProps("email")}
                            isInvalid={formik.errors.email}
                        />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group className="col-lg-6">
                        <Form.Label>Abteilung</Form.Label>
                        <Select options={departments} value={selectedDepartment} onChange={(o) => {
                            setSelectedDepartment(o);
                        }}
                            classNamePrefix="sl2def"
                            className={Object.keys(selectedDepartment).length <= 0 && 'select2-has-error'}
                        />
                    </Form.Group>
                    <Form.Group className="col-lg-6">
                        <Form.Label>Gruppenrichtlinie</Form.Label>
                        <Select options={selectGroupConfig} value={selectedGroup} onChange={(o) => {
                            setSelectedGroup(o);
                        }}
                            classNamePrefix="sl2def"
                            className={Object.keys(selectedGroup).length <= 0 && 'select2-has-error'}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group className="col-lg-6">
                        <Form.Label>Externer Login außerhalb unserers Netzes erlaubt?</Form.Label>
                        <span className="switch switch-primary switch-sm">
                            <label>
                                <input
                                    type="checkbox"
                                    onChange={() => formik.setFieldValue('externalallowed', !formik.getFieldProps("externalallowed").value)}
                                    checked={formik.getFieldProps("externalallowed").value}
                                    name="externalallowed"
                                />
                                <span></span>
                            </label>
                        </span>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group className="col-lg-6">
                        <Form.Label>TYPO3 Login erlaubt?</Form.Label>
                        <span className="switch switch-primary switch-sm">
                            <label>
                                <input
                                    type="checkbox"
                                    onChange={() => formik.setFieldValue('t3_login', !formik.getFieldProps("t3_login").value)}
                                    checked={formik.getFieldProps("t3_login").value}
                                    name="t3_login"
                                />
                                <span></span>
                            </label>
                        </span>
                    </Form.Group>
                    <Form.Group className="col-lg-6">
                        <Form.Label>TYPO3 Maintainer?</Form.Label>
                        <span className="switch switch-primary switch-sm">
                            <label>
                                <input
                                    type="checkbox"
                                    onChange={() => formik.setFieldValue('t3_maintainer', !formik.getFieldProps("t3_maintainer").value)}

                                    checked={formik.getFieldProps("t3_maintainer").value}
                                    name="t3_maintainer"
                                />
                                <span></span>
                            </label>
                        </span>
                    </Form.Group>
                </Form.Row>
            </Modal.Body>
            <Modal.Header className="border-top">
                <Modal.Title id="contained-modal-title-vcenter">
                    Passwort
                    {
                        (props.edit === 0) ? ' setzen' : ' ändern'
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Passwort</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Passwort"
                            name="password"
                            autoComplete="new-password"
                            value={''}
                            {...formik.getFieldProps("password")}
                            isInvalid={formik.errors.password}

                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Passwort wiederholen</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Passwort wiederholen"
                            name="password1"
                            autoComplete="new-password"
                            value={''}
                            {...formik.getFieldProps("password1")}
                            isInvalid={formik.errors.password1}

                        />
                    </Form.Group>
                </Form.Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit">Speichern</Button>
                <Button variant="default " onClick={props.onHide}>Schließen</Button>
            </Modal.Footer>
        </Form>
    }

    const deleteForm = () => {
        return <>
            <Modal.Body>
                <p>Bist du dir sicher dass du diesen Benutzer {user.deleted_at === null ? 'löschen' : 'reaktivieren'} möchtest?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={user.deleted_at === null ? 'danger' : 'primary'} onClick={deleteData}>Ja, Benutzer {user.deleted_at === null ? 'löschen' : 'reaktivieren'} </Button>
                <Button variant="default " onClick={props.onHide}>Nein, Benutzer nicht {user.deleted_at === null ? 'löschen' : 'reaktivieren'} </Button>
            </Modal.Footer>

        </>
    }

    return <>
        <Modal
            show={props.show}
            size={(props.type && props.type === 'delete') ? ' md' : 'lg'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Benutzer
                    {
                        (props.type && props.type === 'delete') ? (user.deleted_at === null ? ' löschen' : ' reaktivieren') :
                            (props.edit === 0) ? ' erstellen' : ' bearbeiten'
                    }
                </Modal.Title>
            </Modal.Header>
            {(props.type && props.type === 'delete') ? deleteForm() : renderForm()}
        </Modal>
    </>

}