import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import axios from 'axios';
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useSubheader } from "../../../../_metronic/layout";
import { TasksList } from "../../ProjectTasks/TasksList";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { TaskCreationModal } from "../../ProjectTasks/partials/TaskCreationModal";
import { usePageTitle } from "../../../../providers/PageTitleProvider";
import { TaskBulkActionButtons } from "../../ProjectTasks/partials/TaskBulkActionButtons";


export function CustomerTasks(props) {

    const subheader = useSubheader();
    const pageTitle = usePageTitle();

    const [allTasks, setAllTaks] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const [lCustomer, setLCustomer] = useState({});
    const [showModal, setShowModal] = useState(false);

    const [showArchived, setShowArchived] = useState(false);

    const [checkedTasks, setCheckedTasks] = useState([]);
    const [showBulkEdit, setShowBulkEdit] = useState(false);



    const fetchCustomerTasks = () => {
        if (Object.keys(lCustomer).length <= 0)
            return;
        axios.get(process.env.REACT_APP_API_URL + '/api/tasksbycustomer/' + lCustomer.id).then(({ data }) => {
            setAllTaks(data);
            handleArchived(data);
            setIsLoading(false);
        }).catch(e => console.log(e));
    }
    const handleArchived = (data) => {
        let taskData = data;
        if (taskData.length > 0)
            taskData = data.filter(t => showArchived ? t.archived === 1 : t.archived === 0);
        setTasks(taskData);
    }
    const handleSearch = () => {
        var filtered = allTasks;
        if (searchTerm !== '' && searchTerm.length > 2) {
            filtered = allTasks.filter(t => {
                return t.name.toLowerCase().includes(searchTerm.toLowerCase());// || t.user?.toLowerCase().includes(searchTerm.toLowerCase());
            });

            filtered.sort((a, b) => a.name.localeCompare(b.name));
        }
        handleArchived(filtered);
    }

    const handleBulkCheck = (e) => {
        const { checked, value } = e.target;
        if (checked) {
            setCheckedTasks(checked => [...checked, parseInt(value)]);
        } else {
            setCheckedTasks(checkedTasks.filter((v) => v !== parseInt(value)));

        }
    };
    const bulkCancle = () => {
        setCheckedTasks([]);
        setShowBulkEdit(false);
    }
    const bulkSubmit = () => {
        setCheckedTasks([]);
        setShowBulkEdit(false);
        fetchCustomerTasks();
        handleSearch();
    }

    useEffect(() => {
        setLCustomer(props.customer);
    }, [props.customer]);

    useEffect(() => {
        if (lCustomer.id === undefined)
            return;
        fetchCustomerTasks();
        subheader.setTitle(lCustomer.name);
        subheader.setActions(null);
        pageTitle.setPageTitle(`🐮 Tasks${lCustomer.name !== undefined ? ' | ' + lCustomer.name : ''}`);
    }, [lCustomer]);

    useEffect(() => {
        handleSearch();
    }, [searchTerm, showArchived]);

    return (<>
        <div className="row">
            <div className="col-12">
                <div className="card card-custom">
                    <div className="card-header border-1">
                        <h3 className="card-title text-dark">
                            Allgemeine Tasks
                        </h3>
                        <div className="card-toolbar">
                            <div className="dropdown dropdown-inline">
                                <OverlayTrigger
                                    placement="left"
                                    overlay={
                                        <Tooltip id="latest-project">{showBulkEdit ? 'Bulk Bearbeitung abbrechen' : 'Bulk Bearbeitung starten'}</Tooltip>
                                    }
                                >
                                    <div className={`btn ${showBulkEdit ? 'btn-light-warning' : 'btn-hover-light-primary'} btn-icon btn-sm mr-2`} onClick={() => {
                                        if (showBulkEdit)
                                            bulkCancle()
                                        setShowBulkEdit(!showBulkEdit)
                                    }}>
                                        <i><i className={`la ${showBulkEdit ? 'la-times' : 'la-edit'}`} ></i></i>
                                    </div>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="left"
                                    overlay={
                                        <Tooltip id="latest-project">{showArchived ? 'Aktive Tasks anzeigen' : 'Archivierte Tasks anzeigen'}</Tooltip>
                                    }
                                >
                                    <div className={`btn ${showArchived ? 'btn-light-primary' : 'btn-hover-light-primary'}  btn-icon btn-sm`} onClick={() => setShowArchived(!showArchived)}>
                                        <i><i className={`la ${showArchived ? 'la-folder' : 'la-folder-open'}`} ></i></i>
                                    </div>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="d-flex mb-6 d-print-none">

                            <div className="input-icon h-40px flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control form-control-lg form-control-solid h-40px"
                                    placeholder="Suchen ..."
                                    id="hostsSearch"
                                    autoComplete="false"
                                    //autoFocus={true}
                                    onKeyUp={(e) => setSearchTerm(e.currentTarget.value)}

                                />
                                <span>
                                    <span className="svg-icon svg-icon-lg">
                                        <SVG
                                            src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                                        />
                                    </span>
                                </span>
                            </div>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="latest-project">Task erstellen</Tooltip>
                                }
                            >
                                <div
                                    onClick={() => setShowModal(true)}
                                    className={`btn btn-icon btn-default btn-hover-primary ml-3 h-40px w-40px flex-shrink-0`}
                                >
                                    <span className="svg-icon svg-icon-lg">
                                        <SVG
                                            src={toAbsoluteUrl(
                                                "/media/svg/icons/Code/Plus.svg"
                                            )}
                                        />
                                    </span>
                                </div>
                            </OverlayTrigger>

                        </div>
                        <TasksList bulkEdit={showBulkEdit} handleBulkCheck={handleBulkCheck} bulkChecked={checkedTasks} tasks={tasks} isLoading={isLoading} />
                    </div>
                </div>
            </div>
        </div>
        <TaskCreationModal customer={lCustomer} show={showModal} onHide={() => setShowModal(false)} onSubmit={() => fetchCustomerTasks()} />
        <TaskBulkActionButtons bulkChecked={checkedTasks} onSubmit={bulkSubmit} onCancle={bulkCancle} />
    </>)

}