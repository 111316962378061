import React, { useEffect } from "react";
import axios from "axios";
import { NotificationItem } from "./components/NotificationItem";

export class NotificationList extends React.Component {

    state = {
        unread: [],
        read: [],
    }

    fetchNotifications() {
        return axios.get(process.env.REACT_APP_API_URL + '/api/notifications').then(({ data }) => {
            this.setState({ unread: data.unread });
            this.setState({ read: data.read });
            this.props.setShouldUpdate(false);
        }).catch(e => null);
    };

    componentDidMount() {
        this.fetchNotifications();
    }

    componentDidUpdate(){
        if(this.props.shouldUpdate)
            this.fetchNotifications();
    }

    
    render() {
        return <div className="navi navi-icon-circle navi-spacer-x-0">
            {this.props.read ?
                (
                    this.state.read.length > 0 && this.state.read.map((item, key) => {
                        return <NotificationItem key={key} item={item} shouldUpdate={this.props.shouldUpdate} setShouldUpdate={this.props.setShouldUpdate}/>
                    })
                )
                :
                (
                    this.state.unread.length > 0 && this.state.unread.map((item, key) => {
                        return <NotificationItem key={key} item={item} shouldUpdate={this.props.shouldUpdate} setShouldUpdate={this.props.setShouldUpdate}/>
                    })
                )
            }
        </div>
    }
}