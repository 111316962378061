import React, { useEffect, useState } from "react";
import axios from "axios";
import Moment from "react-moment";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAsideSecondary } from "../../../../_metronic/layout/_core/MpAsideSecondary";
import { useSubheader } from "../../../../_metronic/layout";
import { useContentContainer } from "../../../../_metronic/layout/_core/MpContentContainer";
import { usePageTitle } from "../../../../providers/PageTitleProvider";
import TecExtensionModal from "../components/TecExtensionModal";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import TecExtensionCatModal from "../components/TecExtensionCatModal";
import { TecExtensionPipelineModal } from "../components/TecExtensionPipelineModal";
import { TecExtensionUploadModal } from "../components/TecExtensionUploadModal";
import { useUserProvider } from "../../../../providers/AllUsersProvider";


export function TecExtensionList(props) {

    const MpAsideSecondary = useAsideSecondary();
    const subheader = useSubheader();
    const contentContainer = useContentContainer();
    const pageTitle = usePageTitle();
    const userProvider = useUserProvider();


    const [cats, setCats] = useState([]);

    const [showExtModal, setShowExtModal] = useState(false);
    const [showCatModal, setShowCatModal] = useState(false);
    const [showPipeModal, setShowPipeModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);

    const [formTypeExtDelete, setFormTypeExtDelete] = useState(false);
    const [formTypeCatDelete, setFormTypeCatDelete] = useState(false);

    const [currentCat, setCurrentCat] = useState(null);
    const [editExtId, setEditExtId] = useState(null);

    const [editCatId, setEditCatId] = useState(null);
    const [pipeItem, setPipeItem] = useState(null);
    const [uploadItem, setUploadItem] = useState(null);


    const downloadFile = (ext) => {
        let fileUrl = process.env.REACT_APP_API_URL + '/api/extdown/' + ext.filename;
        return axios.post(fileUrl, { token: ext.token }, { responseType: 'arraybuffer', transformResponse: res => res }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', ext.filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
    }
    const getAllCategories = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/extcats').then(({ data }) => {
            setCats(data);
        }).catch(e => null);
    }

    const extModalClose = () => {
        setShowExtModal(false);
        setEditExtId(null);
        setTimeout(() => setFormTypeExtDelete(false), 300);
    }
    const extModalSubmit = () => {
        setShowExtModal(false);
        getAllCategories();
        setEditExtId(null);
        setTimeout(() => setFormTypeExtDelete(false), 300);
    }

    const catModalClose = () => {
        setShowCatModal(false);
        setEditCatId(null);
        setTimeout(() => setFormTypeCatDelete(false), 300);
    }
    const catModalSubmit = () => {
        setShowCatModal(false);
        getAllCategories();
        setEditCatId(null);
        setTimeout(() => setFormTypeCatDelete(false), 300);
    }

    const getViaIcon = (via) => {
        switch (via) {
            case 'bitbucket':
                return <span><i className="la la-bitbucket-square mr-2"></i> Bitbucket</span>
            case 'gitlab':
                return <span><i className="la fab la-gitlab mr-2"></i> Gitlab</span>
            default:
                return <span><i className="la la-upload mr-2"></i>Direkter Upload</span>
        }
    }

    const setSubHeaderActions = () => {
        if (!userProvider.can('tecExtManage'))
            return;
        subheader.setActions(<>
            <Button variant="primary btn-sm" onClick={() => setShowCatModal(true)}><span className="svg-icon svg-icon-md">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Folder-plus.svg")} /></span>
                {` `}Neue Extension Kategorie
            </Button>
        </>);
    }
    useEffect(() => {
        getAllCategories();
        contentContainer.reset();
        pageTitle.setPageTitle(`🐮  Extensions`);
        subheader.setTitle("Extensions");
        setTimeout(() => setSubHeaderActions(), 300)
    }, []);

    return <div className="row">
        {cats.map((item, key) => {
            return <div key={key} className="col-lg-12">
                <div className="card card-custom gutter-b">
                    <div className="card-header">
                        <h3 className="card-title">{item.name}</h3>
                        {userProvider.can('tecExtManage') &&
                            <div className="card-toolbar">
                                <Button variant="btn btn-icon btn-clean btn-hover-light-danger btn-sm mr-2"
                                    onClick={() => { setCurrentCat(item); setShowCatModal(true); setEditCatId(item.id); setFormTypeCatDelete('delete') }}
                                ><i className="la la-trash icon-md text-danger"></i></Button>
                                <Button variant="btn btn-icon btn-clean btn-hover-light-primary btn-sm mr-2"
                                    onClick={() => { setEditCatId(item.id); setShowCatModal(true) }}
                                ><i className="la la-edit icon-md text-primary"></i></Button>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="latest-project">Extension hinzufügen</Tooltip>
                                    }
                                >
                                    <Button variant="btn btn-icon btn-clean btn-hover-light-primary btn-sm"
                                        onClick={() => { setCurrentCat(item); setShowExtModal(true) }}
                                    ><i className="la la-plus icon-md text-primary"></i></Button>
                                </OverlayTrigger>
                            </div>
                        }
                    </div>

                    {(item.extensions !== undefined && item.extensions.length > 0) ? <div className="card-body">
                        <div className="bg-dark-o-10 mb-4 rounded-md font-weight-bold py-2 px-5">
                            <div className="row">

                                <div className="col-2">
                                    Name
                                </div>
                                <div className="col-2">
                                    Beschreibung
                                </div>
                                <div className="col-1">
                                    Env
                                </div>
                                <div className="col-lg-2">
                                    letzter Upload
                                </div>
                                <div className="col-lg-2">
                                    via
                                </div>
                                <div className="col-3">
                                </div>
                            </div>
                        </div>

                        {item.extensions.sort((a, b) => a.name.localeCompare(b.name)).map((ext, extkey) => {
                            return <div className="py-4 px-5" key={extkey}><div className="row align-items-center">
                                <div className="col-2">
                                    {ext.name}
                                </div>
                                <div className="col-2">
                                    {ext.desc}
                                </div>

                                <div className="col-1">
                                    <span className={`badge ${ext.env === 'prod' ? 'badge-success' : 'badge-warning'}`}>{ext.env}</span>
                                </div>
                                <div className="col-lg-2">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="date">
                                            {ext.lastupload === null ? 'nie' : <>
                                                <Moment format="DD.MM.YY">{ext.lastupload}</Moment>{' '}
                                                <Moment format="HH:mm:ss">{ext.lastupload}</Moment>
                                            </>}
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    {getViaIcon(ext.via)}
                                </div>
                                <div className="col-3 text-lg-right">
                                    {userProvider.can('tecExtManage') && <>
                                        <div className="bg-light rounded-md d-inline-block mr-4">
                                            <Button variant="btn btn-icon btn-clean btn-hover-light-danger btn-sm mr-2"
                                                onClick={() => { setCurrentCat(item); setShowExtModal(true); setEditExtId(ext.id); setFormTypeExtDelete('delete') }}
                                            ><i className="la la-trash icon-md text-danger"></i></Button>

                                            <Button variant="btn btn-icon btn-clean btn-hover-light-primary btn-sm mr-2"
                                                onClick={() => { setCurrentCat(item); setShowExtModal(true); setEditExtId(ext.id); }}
                                            ><i className="la la-edit icon-md text-primary"></i></Button>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="latest-project">CI/CD Build Einstellungen</Tooltip>
                                                }
                                            >

                                                <Button variant="btn btn-icon btn-clean btn-hover-light-primary btn-sm mr-2"
                                                    onClick={() => { setShowPipeModal(true); setPipeItem(ext); }}
                                                ><i className="la la-bolt icon-md text-primary"></i></Button>
                                            </OverlayTrigger>

                                            <Button variant="btn btn-icon btn-clean btn-hover-light-primary btn-sm"
                                                onClick={() => { setShowUploadModal(true); setUploadItem(ext); }}
                                            ><i className="la la-upload icon-md text-primary"></i></Button>
                                        </div>
                                    </>}
                                    {userProvider.can('tecExtDownload') && <>
                                        <Button variant="secondary" className="btn-sm" onClick={() => downloadFile(ext)}>Download</Button>
                                    </>}
                                </div>

                            </div>
                            </div>
                        })}
                    </div>
                        :
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="alert bg-light-info mt-0 mb-1">Keine Extensions für diese Kategorie verfügbar.</div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        })
        }
        <TecExtensionModal type={formTypeExtDelete ? 'delete' : 'edit'} cat={currentCat} edit={editExtId} show={showExtModal} onHide={extModalClose} onSubmit={extModalSubmit} />
        <TecExtensionCatModal type={formTypeCatDelete ? 'delete' : 'edit'} cat={currentCat} edit={editCatId} show={showCatModal} onHide={catModalClose} onSubmit={catModalSubmit} />
        <TecExtensionPipelineModal show={showPipeModal} item={pipeItem} onHide={() => { setShowPipeModal(false); setPipeItem(null); }} />
        <TecExtensionUploadModal show={showUploadModal} item={uploadItem} onHide={() => { setShowUploadModal(false); setUploadItem(null); }} onSubmit={() => {
            setShowUploadModal(false);
            setUploadItem(null);
            getAllCategories();
        }} />
    </div>
}