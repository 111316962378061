import React, { useEffect, useState } from "react";
import generator from "generate-password";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button, Form, Table } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import Moment from "react-moment";

export function TechnikSSLCheck(props) {
    const [data, setData] = useState([]);
    const formik = useFormik({
        initialValues: {
            domain: ''
        },
        validationSchema: Yup.object().shape({
            domain: Yup.string().required().matches(/([a-z0-9|-]+\.)*[a-z0-9|-]+\.[a-z]+/, 'Muss eine gültige Domain sein!'),
        }),
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {

            setSubmitting(false);

            axios.post(process.env.REACT_APP_API_URL + '/api/sslcheck', values).then(d => {
                setData(d.data);
            }).catch(e => {
                toast.error("Die Domain konnte nicht geprüft werden.", { autoClose: 5500 });
                setData([]);
            });
            setSubmitting(true);
        },
    });

    return <>

        <div className="row">
            <div className="col-12 mb-8">
                <div className="card">
                    <div className="card-body">
                        <Form onSubmit={formik.handleSubmit}>
                            <div className="d-flex">
                                <div className="searchInput flex-grow-1 pr-6">

                                    <Form.Control
                                        type="text"
                                        placeholder="Domain eingeben"
                                        name="domain"
                                        value={''}
                                        {...formik.getFieldProps("domain")}
                                        isInvalid={formik.errors.domain}
                                    />
                                </div>
                                <Button variant="primary" type="submit">SSL Status prüfen</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
        {Object.keys(data).length > 0 && (
            <div className="row">
                <div className="col-lg-3">
                    <div className="card card-custom gutter-b">
                        <div className="card-body text-center">
                            {data.valid ?
                                <span className="svg-icon svg-icon-10x svg-icon-success text-center">
                                    <SVG
                                        src={toAbsoluteUrl('/media/svg/icons/General/Shield-check.svg')}
                                    />
                                </span>
                                :
                                <span className="svg-icon svg-icon-10x svg-icon-danger text-center">
                                    <SVG
                                        src={toAbsoluteUrl('/media/svg/icons/General/Shield-disabled.svg')}
                                    />
                                </span>

                            }

                            <div className="text-dark font-weight-bolder font-size-h2 mt-3 mt-3">{data.valid ? 'Gültig' : 'Ungültig'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="card card-custom gutter-b">
                        <div className="card-body">
                            <Table responsive variant="lg" borderless>
                                <tbody>
                                    <tr>
                                        <td>Domain:</td>
                                        <td>{data.domain}</td>
                                    </tr>

                                    <tr>
                                        <td>Gültig seit:</td>
                                        <td>{data.valid ? <Moment format="DD.MM.YYYY HH:mm:ss">{data.valid_from}</Moment> : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Gültig bis:</td>
                                        <td>{data.valid ? <Moment format="DD.MM.YYYY HH:mm:ss">{data.valid_until}</Moment> : '-'}</td>
                                    </tr>
                                    <tr>
                                        <td>Aussteller:</td>
                                        <td>{data.issuer}</td>
                                    </tr>

                                    <tr>
                                        <td>Organisation:</td>
                                        <td>{data.organization}</td>
                                    </tr>
                                    <tr>
                                        <td>Algorithmus:</td>
                                        <td>{data.algorithm}</td>
                                    </tr>
                                    <tr>
                                        <td>Weitere Domains:</td>
                                        <td>{data.additional_domains.length > 0 ? data.additional_domains.map((name, i) => {
                                            return <><span key={i}>{name}</span><br/></>
                                        }) : '-'}</td>
                                    </tr>

                                    <tr>
                                        <td>Fingerprint:</td>
                                        <td>{data.fingerprint}</td>
                                    </tr>
                                    <tr>
                                        <td>Fingerprint SHA265:</td>
                                        <td>{data.fingerprint_sha265}</td>
                                    </tr>

                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>

            </div>
        )}
    </>
}
