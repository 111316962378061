import React from "react";
import axios from "axios";
import { Redirect, useLocation } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { NotificationBadgeContext } from "../../../providers/NotificationBadgeProvider";

export class NotificationRedirect extends React.Component {

    static contextType = NotificationBadgeContext;

    state = {
        redirect: false,
        invalid: false,
        to: ''
    }

    checkRedirect() {
        return axios.get(process.env.REACT_APP_API_URL + '/api/notification/' + this.props.match.params.id).then(({ data, ...r }) => {
            this.setState({ to: data.link });
            this.setState({ redirect: true });
            this.context.setUpdateNotificationBadge(true);
        }).catch(e => {
            this.setState({ invalid: true });
        });
    }
    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.checkRedirect();
        }
    }
    componentDidMount() {
        this.checkRedirect();
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.to} />;
        }

        return <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
            <div className="text-center">
                <div className="svg-icon svg-icon-8x">
                    {this.state.invalid ?
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-1-circle.svg")} /> :
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Route.svg")} />
                    }
                </div>
                <div className="font-size-h6 mt-10">{this.state.invalid ? 'Ziel der Benachrichtigung wurde nicht gefunden!' : 'Du wirst weitergeleitet.'}</div>
            </div>
        </div>;
    }
}