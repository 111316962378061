import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Form, Col, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import axios from "axios";
import Select from 'react-select'
import moment from "moment";


import DateRangePicker from 'react-bootstrap-daterangepicker';
import { DatePickerLocale } from "../../../../constants/DatePickerLocale";

export default function StempeluhrProfileModal(props) {

    const pickerRef = useRef();
    const pickerInputRef = useRef();

    const [pickerDates, setPickerDates] = useState({ start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') })

    const [isSubmitting, setIsSubmitting] = useState(false);

    const applyPicker = (e, picker) => {
        setPickerDates({ start: picker.startDate.format('YYYY-MM-DD'), end: picker.endDate.format('YYYY-MM-DD') });
    };

    const initialValues = {

        day1: '00:00',
        day2: '00:00',
        day3: '00:00',
        day4: '00:00',
        day5: '00:00',
        day6: '00:00',
        day7: '00:00',
        vacation: '0',
        overtime: '00:00',

    };
    const saveNew = (values) => {
        if (
            pickerDates.start === undefined || pickerDates.start === null ||
            pickerDates.end === undefined || pickerDates.end === null
        )
            return;

        let body = values;
        body.user_id = props.user;
        body.from = pickerDates.start;
        body.to = pickerDates.end;
        return axios.post(process.env.REACT_APP_API_URL + '/api/stempelprofiles', body);
    };
    const updateExisting = (values) => {
        if (
            pickerDates.start === undefined || pickerDates.start === null ||
            pickerDates.end === undefined || pickerDates.end === null
        )
            return;

        let body = values;
        body.user_id = props.user;
        body.from = pickerDates.start;
        body.to = pickerDates.end;
        return axios.put(process.env.REACT_APP_API_URL + '/api/stempelprofiles/' + props.edit, body);
    };

    const fetchEntry = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/stempelprofiles/' + props.edit).then(({ data }) => {
            setExistingDates(data);
            formik.setFieldValue('day1', (data.all_days_readable[0] == null ? '00:00' : data.all_days_readable[0]));
            formik.setFieldValue('day2', (data.all_days_readable[1] == null ? '00:00' : data.all_days_readable[1]));
            formik.setFieldValue('day3', (data.all_days_readable[2] == null ? '00:00' : data.all_days_readable[2]));
            formik.setFieldValue('day4', (data.all_days_readable[3] == null ? '00:00' : data.all_days_readable[3]));
            formik.setFieldValue('day5', (data.all_days_readable[4] == null ? '00:00' : data.all_days_readable[4]));
            formik.setFieldValue('day6', (data.all_days_readable[5] == null ? '00:00' : data.all_days_readable[5]));
            formik.setFieldValue('day7', (data.all_days_readable[6] == null ? '00:00' : data.all_days_readable[6]));
            formik.setFieldValue('overtime', (data.overtime_readable == null ? '00:00' : data.overtime_readable));
            formik.setFieldValue('vacation', (data.vacation == null ? '0' : data.vacation));

        }).catch(e => {
            console.log(e);
        });
    };
    const deleteData = () => {
        axios.delete(process.env.REACT_APP_API_URL + '/api/stempelprofiles/' + props.edit).then((d) => {
            toast.success("Gelöscht");
            props.onSubmit();
        }).catch(e => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
            props.onSubmit();
        });
    }

    const setExistingDates = (data) => {
        setPickerDates({ start: moment(data.from).format('YYYY-MM-DD'), end: moment(data.to).format('YYYY-MM-DD') })
        pickerRef.current.setStartDate(moment(data.from));
        pickerRef.current.setEndDate(moment(data.to));
    }

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            day1: Yup.string().required(),
            day2: Yup.string().required(),
            day3: Yup.string().required(),
            day4: Yup.string().required(),
            day5: Yup.string().required(),
            day6: Yup.string().required(),
            day7: Yup.string().required(),
            vacation: Yup.string().required(),
            overtime: Yup.string().required(),
        }),
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            values.vacation = values.vacation.toString().replace(',', '.');
            setIsSubmitting(false);
            if (props.edit === 0) {
                saveNew(values).then(d => { toast.success("Gespeichert"); resetForm(); props.onSubmit(); setIsSubmitting(false) }).catch(e => {console.log(e); setIsSubmitting(false);});
            } else {
                updateExisting(values).then(d => { toast.success("Gespeichert"); resetForm(); props.onSubmit(); setIsSubmitting(false) }).catch(e => {console.log(e); setIsSubmitting(false);});
            }
            setIsSubmitting(true);

        },
    });

    useEffect(() => {
        if (props.edit !== 0 && props.type === 'edit')
            fetchEntry();
    }, [props.edit]);

    const renderForm = () => {
        return <Form onSubmit={formik.handleSubmit} className={`bg-white position-relative`}>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <Form.Label>Zeitraum</Form.Label>

                        <DateRangePicker
                            ref={pickerRef}
                            initialSettings={{
                                showCustomRangeLabel: false,
                                locale: DatePickerLocale,
                                autoApply: true,
                                autoUpdateInput: true
                            }}
                            onApply={(e, picker) => applyPicker(e, picker)}
                        //onCancel={(e, picker) => clearDates(e, picker)}
                        >
                            <Form.Control size="sm" type="text" onFocus={(e) => e.currentTarget.blur()} style={{ height: "38px" }} ref={pickerInputRef} />
                        </DateRangePicker>
                    </div>
                    <div className="col-12 mt-6">
                        <div className="d-flex justify-content-between mx-n2">

                            <div className="dayItem px-2">
                                <Form.Label>Montag</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Montag"
                                    name="day1"
                                    value={''}
                                    {...formik.getFieldProps("day1")}
                                    isInvalid={formik.errors.day1}
                                />
                            </div>
                            <div className="dayItem px-2">
                                <Form.Label>Dienstag</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Dienstag"
                                    name="day2"
                                    value={''}
                                    {...formik.getFieldProps("day2")}
                                    isInvalid={formik.errors.day2}
                                />
                            </div>
                            <div className="dayItem px-2">
                                <Form.Label>Mittwoch</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Mittwoch"
                                    name="day3"
                                    value={''}
                                    {...formik.getFieldProps("day3")}
                                    isInvalid={formik.errors.day3}
                                />
                            </div>
                            <div className="dayItem px-2">
                                <Form.Label>Donnerstag</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Donnerstag"
                                    name="day4"
                                    value={''}
                                    {...formik.getFieldProps("day4")}
                                    isInvalid={formik.errors.day4}
                                />
                            </div>
                            <div className="dayItem px-2">
                                <Form.Label>Freitag</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Freitag"
                                    name="day5"
                                    value={''}
                                    {...formik.getFieldProps("day5")}
                                    isInvalid={formik.errors.day5}
                                />
                            </div>
                            <div className="dayItem px-2">
                                <Form.Label>Samstag</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Samstag"
                                    name="day6"
                                    value={''}
                                    {...formik.getFieldProps("day6")}
                                    isInvalid={formik.errors.day6}
                                />
                            </div>
                            <div className="dayItem px-2">
                                <Form.Label>Sonntag</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Sonntag"
                                    name="day7"
                                    value={''}
                                    {...formik.getFieldProps("day7")}
                                    isInvalid={formik.errors.day7}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 mt-6">
                        <Form.Label>Urlaubstage</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Urlaubstage"
                            name="vacation"
                            value={''}
                            {...formik.getFieldProps("vacation")}
                            isInvalid={formik.errors.vacation}
                        />
                    </div>
                    <div className="col-6 mt-6">
                        <Form.Group as={Col} className="d-flex flex-column">
                            <Form.Label>Übertrag Überstunden</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Übertrag Überstunden"
                                name="overtime"
                                value={''}
                                {...formik.getFieldProps("overtime")}
                                isInvalid={formik.errors.overtime}
                            />
                        </Form.Group>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit"
                    disabled={isSubmitting}
                >{isSubmitting ? <Spinner animation="border" variant="white m-0" size="sm" /> : 'Speichern'}</Button>
                <Button variant="default " onClick={props.onHide}>Schließen</Button>
            </Modal.Footer>
        </Form>
    }

    const deleteForm = () => {
        return <>
            <Modal.Body>
                <p>Bist du dir sicher dass du dieses Stempelprofil löschen möchtest?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={deleteData}>Ja, Stempelprofil löschen</Button>
                <Button variant="default " onClick={props.onHide}>Nein, Stempelprofil nicht löschen</Button>
            </Modal.Footer>

        </>
    }

    return <>
        <Modal
            show={props.show}
            size={(props.type && props.type === 'delete') ? ' md' : 'xl'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Stempelprofil
                    {
                        (props.type && props.type === 'delete') ? ' löschen' :
                            (props.edit === 0) ? ' erstellen' : ' bearbeiten'
                    }
                </Modal.Title>
            </Modal.Header>
            {(props.type && props.type === 'delete') ? deleteForm() : renderForm()}
        </Modal>
    </>

}