import React, { useEffect, useState } from "react";
import { OverlayTrigger, Spinner, Table, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import axios from "axios";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button } from "react-bootstrap";
import CustomerEmailAccountsModal from "./CustomerEmailAccountsModal";
import { useUserProvider } from "../../../../../providers/AllUsersProvider";

export default function CustomerEmailAccounts(props) {
    const userProvider = useUserProvider();

    const [lCustomer, setLCustomer] = useState({});
    const [hosts, setHosts] = useState([]);
    const [allHosts, setAllHosts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [isLoading, setIsLoading] = useState(true);


    const [showModal, setShowModal] = useState(false);
    const [formTypeDelete, setFormTypeDelete] = useState(false);

    const [editId, setEditId] = useState(0);

    const fetchHosts = () => {

        let cid = Object.keys(lCustomer).length > 0 ? lCustomer.id : props.customer.id;
        if (cid === undefined)
            return;

        setSelectedCustomer({ label: props.customer.name, value: props.customer.id })

        axios.get(process.env.REACT_APP_API_URL + '/api/emabycustomer/' + cid).then(({ data }) => {
            setAllHosts(data);
            setHosts(data);
            setIsLoading(false);
        }).catch(e => console.log(e));
    };
    const handleSearch = () => {
        var filtered = allHosts;
        if (searchTerm !== '' && searchTerm.length > 2) {
            filtered = allHosts.filter(t => {
                return t.mail.toLowerCase().includes(searchTerm.toLowerCase());
            });

            filtered.sort((a, b) => a.mail.localeCompare(b.mail));
        }
        setHosts(filtered);
    }

    const modalClose = () => {
        setShowModal(false);
        setEditId(0);
        setTimeout(() => setFormTypeDelete(false), 300);
    }
    const modalSubmit = () => {
        setShowModal(false);
        fetchHosts();
        setEditId(0);
        setTimeout(() => setFormTypeDelete(false), 300);
    }

    useEffect(() => {
        handleSearch();
    }, [searchTerm]);

    const renderHosts = () => {
        return hosts.map((item, index) => {
            return (
                <tr key={item.id}>
                    <td className="py-8">
                        <div className="d-flex">
                            {(item.mail !== undefined && item.mail !== "" && item.mail !== null) && <>
                                <CopyToClipboard className="d-print-none cursor-pointer" text={item.mail}>
                                    <div className="btn btn-hover-light-primary btn-icon btn-sm mt-n2 ml-n2">
                                        <i><i className="la la-clipboard"></i></i>
                                    </div>
                                </CopyToClipboard>
                                <div className="pl-2">{item.mail}</div>
                            </>}
                        </div>
                    </td>
                    <td className="py-8">
                        <div className="d-flex">
                            {(item.password !== undefined && item.password !== "" && item.password !== null) && <>
                                <CopyToClipboard className="d-print-none cursor-pointer" text={item.password}>
                                    <div className="btn btn-hover-light-primary btn-icon btn-sm mt-n2 ml-n2">
                                        <i><i className="la la-clipboard"></i></i>
                                    </div>
                                </CopyToClipboard>
                                <div className="pl-2">{item.password}</div>
                            </>}
                        </div>
                    </td>

                    <td className="py-8">
                        <div className="d-flex d-print-none justify-content-end flex-nowrap">
                            {userProvider.can('editEmailAccounts') &&
                                <Button variant="btn btn-icon btn-clean btn-hover-light-primary btn-sm mt-n2 mr-2"
                                    onClick={() => { setEditId(item.id); setShowModal(true) }}
                                ><i className="la la-pencil icon-md text-primary"></i></Button>
                            }
                            {userProvider.can('deleteEmailAccounts') &&
                                <Button variant="btn btn-icon btn-clean btn-hover-light-danger btn-sm mt-n2"
                                    onClick={() => { setEditId(item.id); setShowModal(true); setFormTypeDelete(true) }}
                                ><i className="la la-trash icon-md text-danger"></i></Button>
                            }
                        </div>
                    </td>
                </tr>
            );
        });
    }

    useEffect(() => {
        setLCustomer(props.customer);
    }, [props.customer])
    useEffect(() => {
        fetchHosts();
    }, [lCustomer])

    return (<>
        <div className={`${isLoading ? 'd-flex' : 'd-none'} flex-column align-items-center justify-content-center h-full mt-12 mb-4`}><Spinner animation="border" variant="primary" /></div>
        <div className={`${isLoading ? 'd-none' : 'd-block'}`}>

            <div className="d-flex mt-12 mb-6 d-print-none">

                <div className="input-icon h-40px flex-grow-1">
                    <input
                        type="text"
                        className="form-control form-control-lg form-control-solid h-40px"
                        placeholder="Suchen ..."
                        id="hostsSearch"
                        autoComplete="false"
                        //autoFocus={true}
                        onKeyUp={(e) => setSearchTerm(e.currentTarget.value)}

                    />
                    <span>
                        <span className="svg-icon svg-icon-lg">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                            />
                        </span>
                    </span>
                </div>
                {userProvider.can('createEmailAccounts') &&
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="latest-project">Zugangsdaten anlegen</Tooltip>
                        }
                    >
                        <div
                            onClick={() => setShowModal(true)}
                            className={`btn btn-icon btn-default btn-hover-primary ml-3 h-40px w-40px flex-shrink-0`}
                        >
                            <span className="svg-icon svg-icon-lg">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/Code/Plus.svg"
                                    )}
                                />
                            </span>
                        </div>
                    </OverlayTrigger>
                }
            </div>


            {allHosts.length > 0 ? <Table responsive variant="lg">
                <thead>
                    <tr>
                        <td style={{ width: '45%' }}>User</td>
                        <td style={{ width: '45%' }}>Passwort</td>
                        <td style={{ width: '10%' }}></td>
                    </tr>
                </thead>
                <tbody>
                    {renderHosts()}
                </tbody>
            </Table>
                : <div className="alert bg-light-info mt-10">Bislang keine Zugangsdaten vorhanden.</div>}
        </div>
        {userProvider.canAny(['createEmailAccounts', 'editEmailAccounts', 'deleteEmailAccounts']) &&
            <CustomerEmailAccountsModal type={formTypeDelete ? 'delete' : 'edit'} customer={selectedCustomer} edit={editId} show={showModal} onHide={modalClose} onSubmit={() => modalSubmit()} />
        }

    </>)
}
