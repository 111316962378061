//import { useWhatChanged } from "@simbathesailor/use-what-changed";

import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Button, Form, Spinner } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { DatePickerLocale } from "../../../constants/DatePickerLocale";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from 'moment';
import Select from 'react-select'
import axios from "axios";
import { getUserByToken } from '../../../../app/modules/Auth/_redux/authCrud';
import { actions } from '../../../../app/modules/Auth/_redux/authRedux';
import { StempelForm } from "./StempelForm";
import { useUserProvider } from "../../../../providers/AllUsersProvider";

export function DayList(props) {
    const user = useSelector((state) => state.auth.user, shallowEqual);
    const dispatch = useDispatch();
    const { authToken } = useSelector(
        ({ auth }) => ({
            authToken: auth.authToken,
        }),
        shallowEqual
    );

    const [isLoading, setIsLoading] = useState(true);
    const [dayData, setDayData] = useState({});
    const [selectUserConfig, setSelectUserConfig] = useState({});

    const [editTime, setEditTime] = useState('');

    const [currentDate, setCurrentDate] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);

    const userProvider = useUserProvider();

    const selectDate = (date) => {
        date = moment(date);
        props.dateCallback(date);
    };
    const getBadgeColor = (type) => {
        switch (type) {
            case 1:
                return 'text-success';
            case 2:
                return 'text-info';
            case 3:
                return 'text-info';
            case 4:
                return 'text-secondary';
            case 5:
                return 'text-secondary';
            default:
                return 'text-danger';
        }
    };
    const getEntryText = (type) => {
        switch (type) {
            case 1:
                return 'Kommen';
            case 2:
                return 'Urlaub Ende';
            case 3:
                return 'Urlaub Anfang';
            case 4:
                return 'Krank/Arzt Ende';
            case 5:
                return 'Krank/Arzt Anfang';
            default:
                return 'Gehen';
        }
    };

    const deleteItem = (id) => {
        let body = {
            user: props.users.selectedUser.value,
            day: props.currentDate.format('YYYY-MM-DD'),
            id: id
        }
        axios.post(process.env.REACT_APP_API_URL + '/api/stempeltimedelete', body).then(() => {
            fetchDayData();
        }).catch((e) => null);

    };
    const onFormSubmit = () => {
        fetchDayData();
        if (props.setShouldRefetch !== undefined)
            props.setShouldRefetch(true);
    }

    const generateList = () => {

        if (dayData.data == null)
            return;
        return Object.values(dayData.data).map((item, i) => {
            return (
                <div key={item.id}>
                    <div className={`timeline-item align-items-start ${(props.stempelForm && props.stempelForm === true && userProvider.can('adminStempel')) ? '' : 'pb-6'}`}>
                        <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                            {item.timeReadable}
                        </div>
                        <div className="timeline-badge">
                            <i className={`fa fa-genderless icon-xl ${getBadgeColor(item.type)}`}></i>
                        </div>
                        {
                            // (i === dayData.data.length - 1 ? 'pb-0' : 'pb-4')
                        }
                        <div className={["font-weight-mormal font-size-lg timeline-content text-muted pl-3 pb-4"]}>
                            {getEntryText(item.type)}<br></br>
                        </div>
                        {(props.stempelForm && props.stempelForm === true && userProvider.can('adminStempel')) &&
                            <div>
                                <Button variant="btn btn-icon btn-clean btn-hover-light-primary btn-sm mt-n2 mr-2" onClick={(e) => { setEditTime(editTime === item.id ? '' : item.id) }}><i className="la la-pencil icon-md text-primary"></i></Button>
                                <Button variant="btn btn-icon btn-clean btn-hover-light-danger btn-sm mt-n2" onClick={(e) => { deleteItem(item.id) }}><i className="la la-trash icon-md text-danger"></i></Button>
                            </div>
                        }

                    </div>
                    {(props.stempelForm && props.stempelForm === true && userProvider.can('adminStempel')) &&
                        <div className={editTime === item.id ? 'd-block' : 'd-none'}>
                            <StempelForm type="edit" editTimeEntry={item} dayData={dayData} callback={onFormSubmit} selectedDate={props.currentDate.format('YYYY-MM-DD')} user={props.users.selectedUser} key={`edit-${item.id}`} />
                        </div>
                    }
                </div>
            );
        });

    }

    const getSumClass = () => {
        if (dayData.is_valid !== 1)
            return 'text-white bg-dark';
        if (dayData.diff === 0)
            return 'bg-light-info';
        if (dayData.diff > 0)
            return 'bg-light-success';
        if (dayData.diff < 0)
            return 'bg-light-danger';
    };
    const decideScroll = () => {

        return (props.scroll !== undefined && props.scroll !== true) ?
            <><div className="timeline timeline-6 mt-3 zindex-0">{generateList()}</div></>
            :
            <>
                <PerfectScrollbar
                    className="scroll pr-4"
                    options={{ wheelPropagation: false }}

                    style={{ maxHeight: "400px", position: "relative" }}
                >
                    <div className="timeline timeline-6 mt-3 zindex-0">{generateList()}</div>
                </PerfectScrollbar>
            </>;

    };
    const getTimeLine = () => {
        if (dayData === null)
            return (<></>);
        if (dayData.data == null)
            return <div className="alert bg-light-info mt-4">Keine Daten für diesen Tag.</div>
        return <>
            {decideScroll()}
            <div className={`alert mt-4 text-center ${getSumClass()}`}>{dayData.diffReadable}</div>
        </>
    }
    const fetchDayData = () => {
        setIsLoading(true);
        setDayData({});
        let date = props.currentDate ? props.currentDate.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        let userString = (props.users?.selectedUser && props.users.selectedUser.value && userProvider.can('adminStempel')) ? '&user=' + props.users.selectedUser.value : '';

        axios.get(process.env.REACT_APP_API_URL + '/api/stempeluhrday?day=' + date + userString).then(({ data }) => {
            if (data !== null && data.data !== undefined && data.data !== null && Object.values(data.data).length > 0)
                data.data = Object.values(data.data).reverse();
            setDayData(data);
            setEditTime('');
            setIsLoading(false);
        }).catch(e => null);
    };
    const mapUsersForSelect = () => {
        if (!props.users)
            return;
        let users = props.users.allUsers;
        let options = users.map((item, i) => {
            return { label: item.name, value: item.id };
        });
        setSelectUserConfig(options);
    };

    const updateUserData = () => getUserByToken(authToken).then(({ data: user2 }) => {
        dispatch(actions.setUser(user2));
        fetchDayData();
        if (props.setShouldRefetch !== undefined)
            props.setShouldRefetch(true);
    });

    const stempelNow = () => axios.post(process.env.REACT_APP_API_URL + '/api/stempelonline').then(() => updateUserData()).catch(() => null);

    useEffect(() => {
        if (currentUser === null || currentUser.value !== props.selectedUser.value)
            setCurrentUser(props.selectedUser);
        if (!isLoading)
            fetchDayData();
    }, [props.selectedUser]);

    useEffect(() => {
        if (
            (props.selectedUser !== undefined && Object.keys(props.selectedUser).length > 0) &&
            (currentDate === null || currentDate.format('YYYY-MM-DD') !== props.currentDate.format('YYYY-MM-DD')) &&
            (currentUser !== null)
        ) {
            setCurrentDate(currentDate);
            if (!isLoading)
                fetchDayData();
        }
    }, [props.currentDate]);
    //useWhatChanged([props.currentDate, props.selectedUser]);

    useEffect(() => {
        if (props.displayType === 'card')
            fetchDayData();

    }, [props.displayType]);

    useEffect(() => {
        mapUsersForSelect();
    }, [props.users]);


    return (props.displayType === 'card' ?
        <><div className={`card card-custom ${props.className}  gutter-b`}>
            <div className="card-header border-1">
                <h3 className="card-title text-dark">
                    {props.title}
                </h3>
                <div className="card-toolbar">
                    {props.linkToOverview !== undefined && props.linkToOverview === true &&
                        (<Link to="/stempeluhr" className="nav-link btn btn-icon btn-clean btn-hover-light-primary btn-sm">
                            <span className="svg-icon svg-icon-sm">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/Text/Bullet-list.svg"
                                    )}
                                />
                            </span>
                        </Link>
                        )}
                </div>
            </div>

            {(props.datePicker && props.datePicker === true) &&
                <div className="border-bottom ">
                    <div className="col-12 p-8">
                        {(props.datePicker && props.datePicker === true && userProvider.can('adminStempel')) &&
                            <Select options={selectUserConfig} value={props.users.selectedUser} onChange={(o) => props.users.setSelectedUser(o)} className="mb-4" classNamePrefix="sl2def" />
                        }

                        <DateRangePicker
                            initialSettings={{ singleDatePicker: true, locale: DatePickerLocale, autoApply: true }}
                            onApply={(e, picker) => selectDate(picker.startDate.format('YYYY-MM-DD'))}
                        >
                            <Form.Control size="sm" type="text" value={props.currentDate.format('DD.MM.YYYY')} onChange={() => null} onFocus={(m) => m.currentTarget.blur()} />
                        </DateRangePicker>
                    </div>
                </div>
            }

            <div className="card-body pt-4 pb-6">
                {isLoading ? <div className="text-center pt-2 pb-2"><Spinner animation="border" variant="success" /></div> : getTimeLine()}
            </div>
            {(props.stempelButton && props.stempelButton === true || !userProvider.can('adminStempel')) &&
                <div className="card-footer pt-6 pb-6">
                    <div className="text-center">
                        <Button variant={user.current_stempel_type === 1 ? "danger btn-shadow-hover" : "success btn-shadow-hover"} type="submit" onClick={() => stempelNow()}>
                            {user.current_stempel_type === 1 ? "Mich jetzt ausstempeln" : "Mich jetzt einstempeln"}
                        </Button>
                    </div>
                </div>
            }
            {(props.stempelForm && props.stempelForm === true && userProvider.can('adminStempel')) &&

                <div className="card-footer pt-6 pb-6">
                    <StempelForm type="new" dayData={dayData} callback={onFormSubmit} selectedDate={props.currentDate.format('YYYY-MM-DD')} user={props.users.selectedUser} />
                </div>
            }

        </div>
        </>
        :
        <>{getTimeLine()}</>
    );

};

