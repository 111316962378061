import React, { createContext, useState, useContext, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import axios from "axios";
const AllUsersContext = createContext();

export function useUserProvider() {
  return useContext(AllUsersContext);
}

export const AllUsersConsumer = AllUsersContext.Consumer;

export function AllUsersProvider({ children }) {
  const [allUsers, setAllUsers] = useState([]);
  const [usersWithoutMe, setUsersWithoutMe] = useState([]);
  const currentUser = useSelector((state) => state.auth.user, shallowEqual);


  const getUsersByIds = (ids) => {
    let users = ids.map((id) => getUserById(id));
    return users.sort((a, b) => a !== null ? a.firstname.localeCompare(b.firstname) : 0);
  }

  const getUserById = (id) => {
    let user = allUsers.filter(t => {
      return t.id === parseInt(id);
    });
    return user.length === 0 ? null : user[0];
  }

  const getUsersWithoutMe = () => {
    let users = allUsers.filter(t => {
      return t.id !== parseInt(currentUser.id);
    });
    setUsersWithoutMe(users.length === 0 ? null : users);
  }
  const fetchUsers = () => {
    axios.get(process.env.REACT_APP_API_URL + '/api/users').then(({ data }) => {
      setAllUsers(data);
    }).catch(e => console.log(e));
  }
  const hasRole = (role, who) => {
    let u = who === null ? currentUser : getUserById(who);
    let could = u.access_roles.filter(t => t.name === role);
    return could.length === 1;
  }
  const can = (what, who = null) => {
    let u = who === null ? currentUser : getUserById(who);
    if(hasRole('super', who === null ? null : u.id))
      return true;
    let could = u.access_permissions.filter(t => t.name === what);
    return could.length === 1;
  }
  const canAny = (what, who = null) => {
    let u = who === null ? currentUser : getUserById(who);
    if(hasRole('super', who === null ? null : u.id))
      return true;
    let could = u.access_permissions.filter(t => what.includes(t.name));
    return could.length >= 1;
  }
  const canAll = (what, who = null) => {
    let u = who === null ? currentUser : getUserById(who);
    if(hasRole('super', who === null ? null : u.id))
      return true;
    let could = u.access_permissions.filter(t => what.includes(t.name));
    return could.length === what.length;
  }

  useEffect(() => {
    fetchUsers();
  }, []);
  useEffect(() => {
    if (allUsers.length > 0 && currentUser.id !== undefined)
      getUsersWithoutMe();
  }, [allUsers, currentUser]);


  const value = { 
    allUsers, 
    getUserById, 
    getUsersByIds, 
    usersWithoutMe, 
    fetchUsers,
    can,
    canAny,
    canAll,
  }
  return (
    <AllUsersContext.Provider value={value}>
      {children}
    </AllUsersContext.Provider>
  );
}
