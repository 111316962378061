import React, { useEffect, useState } from "react";
import { OverlayTrigger, Spinner, Table, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import axios from "axios";

import CopyToClipboard from "react-copy-to-clipboard";
import { Button } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { AdminProjectTemplateModal } from "./projects/AdminProjectTemplateModal";
import { useSubheader } from "../../../../_metronic/layout";
import { usePageTitle } from "../../../../providers/PageTitleProvider";

export default function AdminProjectTemplates(props) {

    const subheader = useSubheader();
    const pageTitle = usePageTitle();

    const [templates, setTemplates] = useState([]);
    const [allTemplates, setAllTemplates] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const [showModal, setShowModal] = useState(false);
    const [formTypeDelete, setFormTypeDelete] = useState(false);

    const [editId, setEditId] = useState(0);

    const fetchProjectTemplates = () => {

        axios.get(process.env.REACT_APP_API_URL + '/api/projecttemplates').then(({ data }) => {
            setAllTemplates(data);
            setTemplates(data);
            setIsLoading(false);
        }).catch(e => console.log(e));
    };
    const handleSearch = () => {
        var filtered = allTemplates;
        if (searchTerm !== '' && searchTerm.length > 2) {
            filtered = allTemplates.filter(t => {
                return t.type.toLowerCase().includes(searchTerm.toLowerCase()) || t.user?.toLowerCase().includes(searchTerm.toLowerCase());
            });

            filtered.sort((a, b) => a.type.localeCompare(b.type));
        }
        setTemplates(filtered);
    }

    const modalClose = () => {
        setShowModal(false);
        setEditId(0);
        setTimeout(() => setFormTypeDelete(false), 300);
    }
    const modalSubmit = () => {
        setShowModal(false);
        fetchProjectTemplates();
        setEditId(0);
        setTimeout(() => setFormTypeDelete(false), 300);
    }

    useEffect(() => {
        pageTitle.setPageTitle('🐮 Projekt Vorlagen');
        subheader.setTitle("Projekt Vorlagen");
    }, [subheader]);

    useEffect(() => {
        handleSearch();
    }, [searchTerm]);
    useEffect(() => {
        fetchProjectTemplates();
    }, []);

    const renderHosts = () => {
        return templates.map((item, index) => {
            return (
                <tr key={item.id}>

                    <td className="py-8">
                        <div className="d-flex">
                            {item.name}
                        </div>
                    </td>

                    <td className="py-8">
                        {item.data.length > 0 && item.data.map((task, i) => {
                            return <div className="taskitem" key={i}>
                                {task}
                            </div>
                        })}

                    </td>

                    <td className="py-8">
                        <div className="d-flex d-print-none justify-content-end flex-nowrap">

                            <Button variant="btn btn-icon btn-clean btn-hover-light-primary btn-sm mt-n2 mr-2"
                                onClick={() => { setEditId(item.id); setShowModal(true) }}
                            ><i className="la la-pencil icon-md text-primary"></i></Button>

                            <Button variant="btn btn-icon btn-clean btn-hover-light-danger btn-sm mt-n2"
                                onClick={() => { setEditId(item.id); setShowModal(true); setFormTypeDelete(true) }}
                            ><i className="la la-trash icon-md text-danger"></i></Button>
                        </div>
                    </td>
                </tr>
            );
        });
    }


    return (<>
        <div className="row">
            <div className="col-12">
                <div className={`card card-custom `}>
                    <div className="card-header border-1">
                        <h3 className="card-title text-dark">
                            Task Vorlagen
                        </h3>
                        <div className="card-toolbar">
                            {/*<Link to="/stempeluhr" className="nav-link btn btn-icon btn-clean btn-hover-light-primary btn-sm">
                            <span className="svg-icon svg-icon-sm">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/Text/Bullet-list.svg"
                                    )}
                                />
                            </span>
                                    </Link>*/}
                        </div>
                    </div>
                    <div className="card-body">
                        <div className={`${isLoading ? 'd-flex' : 'd-none'} flex-column align-items-center justify-content-center h-full mt-12 mb-4`}><Spinner animation="border" variant="primary" /></div>
                        <div className={`${isLoading ? 'd-none' : 'd-block'}`}>
                            <div className="d-flex mt-0 mb-6 d-print-none">

                                <div className="input-icon h-40px flex-grow-1">
                                    <input
                                        type="text"
                                        className="form-control form-control-lg form-control-solid h-40px"
                                        placeholder="Suchen ..."
                                        id="templatesSearch"
                                        autoComplete="false"
                                        //autoFocus={true}
                                        onKeyUp={(e) => setSearchTerm(e.currentTarget.value)}

                                    />
                                    <span>
                                        <span className="svg-icon svg-icon-lg">
                                            <SVG
                                                src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                                            />
                                        </span>
                                    </span>
                                </div>


                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="latest-project">Vorlage erstellen</Tooltip>
                                    }
                                >
                                    <div
                                        onClick={() => setShowModal(true)}
                                        className={`btn btn-icon btn-default btn-hover-primary ml-3 h-40px w-40px flex-shrink-0`}
                                    >
                                        <span className="svg-icon svg-icon-lg">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/icons/Code/Plus.svg"
                                                )}
                                            />
                                        </span>
                                    </div>
                                </OverlayTrigger>

                            </div>
                            {allTemplates.length > 0 ? <Table responsive variant="lg">
                                <thead>
                                    <tr>
                                        <td style={{ width: '20%' }}>Name</td>
                                        <td style={{ width: '50%' }}>Tasks</td>
                                        <td style={{ width: '10%' }}></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderHosts()}
                                </tbody>
                            </Table>
                                : <div className="alert bg-light-info mt-10">Bislang keine Projekt Task Vorlage.</div>}

                        </div>

                        <AdminProjectTemplateModal type={formTypeDelete ? 'delete' : 'edit'} edit={editId} show={showModal} onHide={modalClose} onSubmit={() => modalSubmit()} />
                    </div>
                </div>
            </div>
        </div>
    </>)
}
