import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";

export class GanttHelper {

    setState = null;
    constructor(state, parentSetState) {
        this.state = state;
    }
    passParentStateSetter(parentSetState) {
        this.setState = (a) => parentSetState(a);
    }
    updateState(state) {
        this.state = state;
    }
    taskFields = {
        id: 'id',
        name: 'text',
        startDate: 'start_date',
        endDate: 'end_date',
        baselineStartDate: 'start_date',
        baselineEndDate: 'end_date',
        duration: 'duration',
        resourceInfo: 'resources',
        indicators: 'indicators',
        dependency: 'parent',
        //progress: 'progress'
    }
    timelineSettings = {
        weekStartDay: 1,
        topTier: {
            format: 'MM.yyyy',
            unit: 'Month',
        },
        bottomTier: {
            format: 'W',
            unit: 'Day',
            count: 7
        }
    }
    labelSettings = {
        leftLabel: (a) => a.start_date ? moment(a.start_date).format('DD.MM.YYYY') : '',
        rightLabel: (a) => a.end_date ? moment(a.end_date).format('DD.MM.YYYY') : '',
        taskLabel: '${taskData.text}'
    }
    sortingOptions = { columns: [{ field: 'startTstamp', direction: 'Ascending' }] };

    
    applyTaskbarUpdate = (data, args) => {
        if (args.taskBarEditAction === "ParentDrag")
            return;

        //this.props.setIsReLoading(true);
        let body = {
            start: moment(args.editingFields.startDate).format('YYYY-MM-DD'),
            end: moment(args.editingFields.endDate).format('YYYY-MM-DD')
        };
        axios.post(process.env.REACT_APP_API_URL + '/api/tasks/' + data.id + '/updateTiming', body).then((d) => {
            toast.success("Timing wurde geändert")
            this.getGanttData();
        }).catch((e) => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
            this.getGanttData();
        });
    }

    updateParent = (item, parent) => {
        let body = {
            parent_id: parent
        }
        return axios.post(process.env.REACT_APP_API_URL + '/api/tasks/' + item.id + '/updateParent', body).then((d) => {
            toast.success("Eltern Task gesetzt.");
            this.getGanttData();

        }).catch(e => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
            this.getGanttData();

        });
    }

    parseTasksFromData = (data) => {
        let taskData = [];
        if (data.tasks.length > 0) {

            taskData = data.tasks.map(t => {
                let tData = [];
                if(this.state.queryType.value === 'users'){
                    tData = t.gantt_data_users;
                } else {
                    tData = t.gantt_data;
                }
                tData.start_date = moment(tData.start_date).toDate();
                tData.end_date = moment(tData.end_date).toDate();

                tData.indicators = [];
                return tData;
            });
            taskData.sort((a, b) => a.text.localeCompare(b.text));
        }
        return taskData
    }
    parseResourcesFromData = (data) => {
        let resData = [];
        
        if (this.state.queryType.value === 'users' && data.resources.length > 0) {
            resData = data.resources.map(t => t.gantt_data);
            resData.sort((a, b) => a.name.localeCompare(b.name));
            return resData;
        }

        if (data.resources.length > 0) {
            resData = data.resources.filter(t => t.tasksCountWTiming > 0).map(t => t.gantt_data);
            resData.sort((a, b) => a.name.localeCompare(b.name));
        }

        if (data.customers.length > 0) {
            resData.push(...data.customers.filter(t => t.tasksCountWTiming > 0).map(t => t.gantt_data));
            resData.sort((a, b) => a.name.localeCompare(b.name));
        }
        return resData;
    }

    toolbarClick(t) {
        if (t.item.properties.id === 'today')
            this.ganttInstance.scrollToDate(moment().add(-14, 'days').format('YYYY-MM-DD'));
        if (t.item.properties.id === 'createTask')
            this.setState({ showModal: true });
    }

    getGanttData(ganttInstance = null, jumpToToday = false) {
        if (ganttInstance !== null)
            this.ganttInstance = ganttInstance;

        if (this.ganttInstance === null)
            return;

        this.ganttInstance.showSpinner();

        let rq = process.env.REACT_APP_API_URL + '/api/gantt';
        if(this.state.queryType.value === 'users')
            rq = process.env.REACT_APP_API_URL + '/api/ganttUsers';
        
        return axios.post(rq, {
            from: this.state.pickerDates.start,
            to: this.state.pickerDates.end
        }).then(({ data }) => {
            this.ganttInstance.dataSource = this.parseTasksFromData(data);
            this.ganttInstance.resources = this.parseResourcesFromData(data);
            this.ganttInstance.hideSpinner();

            //this.ganttInstance.refresh();
            /* if (jumpToToday)
                setTimeout(() =>
                    this.ganttInstance.scrollToDate(moment().add(-100, 'days').format('YYYY-MM-DD'))
                    , 300) */
        }).catch(e => console.log(e));
    }

}