import React, { useEffect, useRef, useState } from "react";
import { useContentContainer } from "../../../../../_metronic/layout/_core/MpContentContainer";
import axios from "axios";
import { SubheaderContext, useSubheader } from "../../../../../_metronic/layout";
import { useAsideSecondary } from "../../../../../_metronic/layout/_core/MpAsideSecondary";
import {
    GanttComponent,
    ColumnsDirective,
    ColumnDirective,
    Inject,
    Selection,
    VirtualScroll,
    EventMarkersDirective,
    EventMarkerDirective,
    DayMarkers,
    HolidaysDirective,
    HolidayDirective,
    Edit,
    ContextMenu,
    Resize,
    Toolbar,
    Filter,
    Sort
} from '@syncfusion/ej2-react-gantt';
import { L10n, setCulture, loadCldr } from '@syncfusion/ej2-base';
import moment from "moment"
import * as EJ2_LOCALE from "@syncfusion/ej2-locale/src/de.json"
import gregorian from '../../../../constants/ej2/ca-gregorian.json';
import numbers from '../../../../constants/ej2/numbers.json';
import timeZoneNames from '../../../../constants/ej2/timeZoneNames.json';
import { toast } from "react-toastify";
import { GanttHelper } from "./GanttHelper";
import { TaskSingleCreationModal } from "../../../ProjectTasks/partials/TaskSingleCreationModal";

import { isThisMonth } from "date-fns";
import { GanttTopForm } from "./GanttTopForm";



loadCldr(gregorian, numbers, timeZoneNames);

L10n.load({ de: EJ2_LOCALE.de });
setCulture('de');
export class Gantt extends React.Component {

    static contextType = SubheaderContext;

    ganttInstance = null;

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            queryType: { label: 'Projekte', value: 'projects' },
            pickerDates: { start: moment().subtract(2, 'months').format('YYYY-MM-DD'), end: moment().add(6, 'months').format('YYYY-MM-DD') }
        }
        this.ganttHelper = new GanttHelper(this.state);
    }
    parentSetState(a) {
        this.setState(a);
    }

    updateState = () => {
        this.ganttHelper.updateState(this.state);
    }
    setSubHeaderActions = () => {
        var that = this;
        this.context.setActions(
            <GanttTopForm
                refetch={() => this.ganttHelper.getGanttData(this.ganttInstance, true)}
                setQueryType={(a) => {
                    this.setState({ queryType: a }, () => this.updateState())
                }}
                setPickerDates={(a) => {
                    this.setState({ pickerDates: a }, () => this.updateState())
                }}
            />
        )
    }

    componentDidMount() {
        this.ganttHelper.getGanttData(this.ganttInstance, true);
        this.ganttHelper.passParentStateSetter((a) => this.parentSetState(a));
        setTimeout(() => this.setSubHeaderActions(), 500);
    }

    applyParent(args) {

        let item = args.data.taskData;
        let par = item.parent;
        if (par !== null)
            par = parseInt(par.replace('FS', ''));
        this.ganttHelper.updateParent(item, par)

    };

    actionBegin(args) {

        if (args.requestType === 'taskbarediting' && args.taskBarEditAction === "ParentDrag") {
            args.cancel = true;
        }
        if (args.requestType === 'beforeOpenEditDialog') {
            args.cancel = true;
        }
        if (args.requestType === 'ValidateDependency') {
            if (args.predecessor.type === 'SS' || args.predecessor.type === 'FF' || args.predecessor.type === 'SF') {
                args.isValidLink = false;
                args.cancel = true;
                /*  let tried = args.predecessor.type === 'SF' ? 'Du hast Start zu Ende veruscht.' : '';
                 tried = args.predecessor.type === 'FF' ? 'Du hast Ende zu Ende veruscht.' : tried;
                 tried = args.predecessor.type === 'SS' ? 'Du hast Start zu Start veruscht.' : tried;
                 toast.error('Nur Verbindungen von der End- zur Start-Seite können erstellt werden.' + tried) */
            }
        }
    }

    actionComplete(args) {

        if (args.action === "DrawConnectorLine") {
            this.applyParent(args);
        }
    }

    toolbarOptions = [
        'ExpandAll',
        'CollapseAll',
        'Search',
        'ZoomOut',
        'ZoomIn',
        'ZoomToFit',
        { text: 'Springe zu heute', tooltipText: 'Springe zu heute', id: 'today', align: 'Left' },
        { text: 'Task erstellen', tooltipText: 'Task erstellen', id: 'createTask', align: 'Left' }
    ]

    render() {
        return <><GanttComponent ref={gantt => this.ganttInstance = gantt}
            height={window.innerHeight - 120}
            viewType='ResourceView'
            treeColumnIndex={1}
            //dataSource={this.tasks}
            //resources={this.res}
            delayUpdate={true}
            taskFields={this.ganttHelper.taskFields}
            resourceFields={{
                id: 'resId',
                name: 'name',
                group: 'group'
            }}

            timelineSettings={this.ganttHelper.timelineSettings}
            labelSettings={this.ganttHelper.labelSettings}

            highlightWeekends={true}
            includeWeekend={true}
            enableVirtualization={true}
            dateFormat='dd.MM.yyyy'
            locale='de'
            splitterSettings={{
                position: "28%",
                separatorSize: 5,
                view: 'Default'
            }}
            toolbar={this.toolbarOptions}
            allowSelection={true}
            editSettings={{
                allowEditing: true,
                allowDeleting: false,
                allowTaskbarEditing: true
            }}
            taskbarEdited={({ data, ...args }) => this.ganttHelper.applyTaskbarUpdate(data, args)}
            onTaskbarClick={(...args) => console.log(args)}

            toolbarClick={(t) => this.ganttHelper.toolbarClick(t)}
            actionBegin={(args) => this.actionBegin(args)}
            actionComplete={(args) => this.actionComplete(args)}
            allowResizing={true}
            allowSorting={true}
            sortingOptions={this.ganttHelper.sortingOptions}
        >
            <Inject services={[Edit, Sort, Filter, ContextMenu, Selection, Resize, Toolbar, Selection, VirtualScroll, DayMarkers]} />
            <ColumnsDirective>
                <ColumnDirective field='id' headerText='ID' visible={false} width={10} allowEditing={false} />

                <ColumnDirective field='text' headerText='Titel' width={300} allowEditing={false} />
                <ColumnDirective field='start_date' headerText='Start' width={110} allowEditing={false} />
                <ColumnDirective field='end_date' headerText='Ende' width={110} allowEditing={false} />
                <ColumnDirective field='duration' headerText='Dauer' width={110} allowEditing={false} />
                <ColumnDirective field='group' headerText='Group' allowEditing={false}> </ColumnDirective>
                <ColumnDirective field='startTstamp' headerText='Start Timestamp' visible={false} allowEditing={false} />


            </ColumnsDirective>

            <EventMarkersDirective>
                <EventMarkerDirective day={new Date()} cssClass='e-custom-event-marker' label={`Heute, ${moment().format('DD.MM.YYYY')}`}></EventMarkerDirective>
            </EventMarkersDirective>


        </GanttComponent>
            <TaskSingleCreationModal show={this.state.showModal} onHide={() => this.setState({ showModal: false })} onSubmit={() => {
                this.setState({ showModal: false });
                this.ganttHelper.getGanttData(this.ganttInstance, true);
            }} /></>

    }

}