import React, { useEffect, useState } from "react"
import { useUserProvider } from "../../../../providers/AllUsersProvider";
import { usePageTitle } from "../../../../providers/PageTitleProvider";
import { useHtmlClassService, useSubheader } from "../../../../_metronic/layout";
import { useAsideSecondary } from "../../../../_metronic/layout/_core/MpAsideSecondary";
import axios from "axios";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import moment from "moment";

export function StempeluhrInvalid(props) {
    const subheader = useSubheader();
    const MpAsideSecondary = useAsideSecondary();
    const pageTitle = usePageTitle();

    const uiService = useHtmlClassService();
    const userProvider = useUserProvider();

    const [invalid, setInvalid] = useState([]);

    const fetchInvalidEntries = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/stempel_invalid').then(({ data }) => {
            setInvalid(data);
        }).catch((e) => {
            setInvalid([]);
        });
    }

    useEffect(() => {
        subheader.setTitle('Fehlbuchungen');
        subheader.setActions(null);
        fetchInvalidEntries();
        MpAsideSecondary.setAsideEnabled(true);
    }, []);
    return <>

        <div className="row">
            {invalid.length > 0 && invalid.map((userItem, i) => {
                return <div className="col-lg-4" key={i}>
                    <div className="card card-custom gutter-b">
                        <div className="card-header border-0">
                            <h3 className="card-title text-dark">
                                {userProvider.getUserById(userItem.user_id) && userProvider.getUserById(userItem.user_id).name}
                            </h3>
                        </div>
                        <ul className="list-group">
                            {userItem.data.map((item,j) => {
                                return <li className="list-group-item" key={j}><Link to={`/stempeluhr/details/${moment(item.day).format('YYYY-MM-DD')}/${userItem.user_id}`}><Moment format="DD.MM.YYYY">{item.day}</Moment></Link></li>
                            })}
                        </ul>
                    </div>
                </div>
            })}
        </div>
    </>
}