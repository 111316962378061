import React, { useEffect, useState } from "react";
import generator from "generate-password";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button, Form, Table } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";

export function TechnikQrGenerator(props) {
    const [data, setData] = useState('');
    const [target, setTarget] = useState('');
    const [selectedStyles, setSelectedStyles] = useState({ value: 'square', label: 'Rechteckig' });
    const styles = [
        { value: 'square', label: 'Rechteckig' },
        { value: 'round', label: 'Rund' },
        { value: 'dot', label: 'Dotted' }
    ];

    const [selectedEyeStyles, setSelectedEyeStyles] = useState({ value: 'square', label: 'Rechteckig' });
    const eyestyles = [
        { value: 'square', label: 'Rechteckig' },
        { value: 'circle', label: 'Tropfen' },
    ];

    const formik = useFormik({
        initialValues: {
            qrdata: ''
        },
        validationSchema: Yup.object().shape({
            qrdata: Yup.string().required(),
        }),
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {

            setSubmitting(false);
            let extrastring = ''

            if (selectedStyles.value !== undefined)
                extrastring += '&style=' + selectedStyles.value
            if (selectedEyeStyles.value !== undefined)
                extrastring += '&eyestyle=' + selectedEyeStyles.value
            let t = '?qrdata=' + encodeURIComponent(values.qrdata) + extrastring;
            setTarget(t);

            setData(process.env.REACT_APP_API_URL + '/api/qrcode');
            setSubmitting(true);
        },
    });

    return <>

        <div className="row">
            <div className="col-12 mb-8">
                <div className="card">
                    <div className="card-body">
                        <Form onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-lg-6">
                                    <Form.Label>QR Code Daten</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="QR Code Daten"
                                        name="qrdata"
                                        value={''}
                                        {...formik.getFieldProps("qrdata")}
                                        isInvalid={formik.errors.qrdata}
                                    />
                                </div>
                                <div className="col-lg-2">
                                    <Form.Label>QR Style</Form.Label>
                                    <Select options={styles} value={selectedStyles} onChange={(o) => {
                                        setSelectedStyles(o);
                                    }}
                                        classNamePrefix="sl2def"
                                        className={Object.keys(selectedStyles).length <= 0 && 'select2-has-error'}
                                    />
                                </div>
                                <div className="col-lg-2">
                                    <Form.Label>QR Style der Augen</Form.Label>
                                    <Select options={eyestyles} value={selectedEyeStyles} onChange={(o) => {
                                        setSelectedStyles(o);
                                    }}
                                        classNamePrefix="sl2def"
                                        className={Object.keys(selectedEyeStyles).length <= 0 && 'select2-has-error'}
                                    />
                                </div>

                                <div className="col-lg-2">
                                    <Form.Label>&nbsp;</Form.Label>
                                    <div className="from-control">
                                        <Button variant="primary" className="w-100" type="submit">Vorschau generieren</Button>
                                    </div>
                                </div>
                            </div>

                        </Form>
                    </div>
                </div>
            </div>
        </div>

        {data !== '' &&

            <div className="row">
                <div className="col-lg-3">
                    <div className="card card-custom card-stretch gutter-b">
                        <div className="card-header">
                            <h3 className="card-title font-weight-bolder text-dark">Vorschau {props.type}</h3>
                        </div>
                        <div className="card-body">
                            <img src={`${process.env.REACT_APP_API_URL + '/api/qrcode.png'+target}`} className="img-fluid" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="card card-custom card-stretch gutter-b">
                        <div className="card-header">
                            <h3 className="card-title font-weight-bolder text-dark">Herunterladen</h3>
                        </div>
                        <div className="card-body">
                            <a href={`${process.env.REACT_APP_API_URL + '/api/qrcode.eps'+target}`} download="QR-Code.eps" className="btn btn-primary mb-4" target="_blank">EPS Download</a>
                            <br/>
                            <a href={`${process.env.REACT_APP_API_URL + '/api/qrcode.svg'+target}&size=600`} download="QR-Code.eps" className="btn btn-primary mb-4" target="_blank">SVG Download (600x600)</a>
                            <br/>
                            <a href={`${process.env.REACT_APP_API_URL + '/api/qrcode.svg'+target}&size=1200`} download="QR-Code.eps" className="btn btn-primary mb-4" target="_blank">SVG Download (1200x1200)</a>
                            <br/>
                            <a href={`${process.env.REACT_APP_API_URL + '/api/qrcode.png'+target}&size=600`} download="QR-Code.eps" className="btn btn-primary mb-4" target="_blank">PNG Download (600x600)</a>
                            <br/>
                            <a href={`${process.env.REACT_APP_API_URL + '/api/qrcode.png'+target}&size=1200`} download="QR-Code.eps" className="btn btn-primary mb-4" target="_blank">PNG Download (1200x1200)</a>
                        </div>
                    </div>
                </div>
            </div>
        }


    </>
}
