import React from "react";
import { ContentRoute } from "../..";
import { AdminMenu } from "../../../../app/modules/Admin/components/AdminMenu";
import { CustomersMenuRenderer } from "../../../../app/modules/Customers/components/CustomersMenuRenderer";
import { InterCredMenu } from "../../../../app/modules/InternalCredentials/components/InterCredMenu";
import { ProjectsMenu } from "../../../../app/modules/Projects/components/partials/ProjectsMenu";
import { StempeluhrMenu } from "../../../../app/modules/Stempeluhr/components/StempeluhrMenu";
import { TechnikMenu } from "../../../../app/modules/Technik/components/TechnikMenu";

export function SecondaryRouter({ isActive }) {

    return <>
        <ContentRoute path="/stempeluhr" component={StempeluhrMenu} />
        <ContentRoute path="/admin" component={AdminMenu} />
        <ContentRoute path="/technik" component={TechnikMenu} />
        
        <ContentRoute path="/internal-credentials" component={InterCredMenu} />

        <ContentRoute exact path="/kunden/:id?/:path?/:taskpath?" component={CustomersMenuRenderer} />
        <ContentRoute exact path="/kunden/:id?/projekt/:projectpath?/task/:taskpath?" component={CustomersMenuRenderer} />

        <ContentRoute path="/projekte/:id?" component={ProjectsMenu} />
    </>
}