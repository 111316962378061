import { Field, FieldArray, Formik, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Form } from "react-bootstrap";
import * as Yup from "yup";
import { toast } from "react-toastify"
import axios from "axios";
import Select from 'react-select'

export function TaskBulkMoveModal(props) {

    const [tasks, setTasks] = useState({});
    
    const [selectCustomerConfig, setSelectCustomerConfig] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState({})

    const [selectProjectConfig, setSelectProjectConfig] = useState({});
    const [selectedProject, setSelectedProject] = useState({})


    const fetchAllCustomers = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/customers').then(({ data }) => {
            mapCustomersForSelect(data);
        }).catch(e => props.onSubmit());
    };

    const mapCustomersForSelect = (data) => {
        let options = data.map((item, i) => {
            return { label: item.name, value: item.id };
        });
        setSelectCustomerConfig(options);
    };

    const fetchCustomersProjects = () => {
        if (selectedCustomer.value === undefined) {
            setSelectProjectConfig({});
            setSelectedProject({});
            return;
        }
        axios.get(process.env.REACT_APP_API_URL + '/api/projectsbycustomer/' + selectedCustomer.value).then(({ data }) => {
            // Todo: Set Task Type
            let projectsData = data;
            if (data.length > 0)
                projectsData = data.filter(t => t.archived === 0);

            projectsData.push({ id: 0, name: 'Allgemeine Tasks' });
            projectsData.sort((a, b) => a.name.localeCompare(b.name));
            mapProjectsForSelect(projectsData);
        }).catch(e => console.log(e));

    };

    const mapProjectsForSelect = (data) => {
        let options = data.map((item, i) => {
            return { label: item.name, value: item.id };
        });
        setSelectProjectConfig(options);
    };


    const initialValues = {
        tasks: ['']
    };
    const formik = useFormik({
        initialValues,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            setSubmitting(false);
            let body = values;
            if(selectedCustomer.value === undefined || selectedProject === undefined)
                return;
            body.tasks = tasks;
            body.customer_id = selectedCustomer.value;
            body.project_id = selectedProject.value;

            return axios.post(process.env.REACT_APP_API_URL + '/api/tasks/bulkMove', body).then((d) => {
                toast.success("Tasks verschoben.");
                resetForm();
                props.onSubmit(d.data);
                props.onHide();
            }).catch(e => {
                toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
                resetForm();
                props.onHide();
            });
            setSubmitting(true);

        },
    });
    useEffect(() => {
            fetchAllCustomers();
            fetchCustomersProjects();
            setTasks(props.tasks);
    }, [props.tasks]);
    useEffect(() => {
        fetchCustomersProjects();
    }, [selectedCustomer]);


    return <>
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Tasks verschieben
                </Modal.Title>
            </Modal.Header>


            <Form onSubmit={formik.handleSubmit} className={`bg-white position-relative`}>
                <Modal.Body>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Kunde</Form.Label>
                            <Select options={selectCustomerConfig} value={selectedCustomer} onChange={(o) => {
                                setSelectedCustomer(o);
                                fetchCustomersProjects();
                            }}
                                classNamePrefix="sl2def"
                                className={Object.keys(selectedCustomer).length <= 0 && 'select2-has-error'}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Projekt</Form.Label>
                            {Object.keys(selectProjectConfig).length > 0 ?
                            <Select options={selectProjectConfig} value={selectedProject} onChange={(o) => {
                                setSelectedProject(o);
                            }}
                                classNamePrefix="sl2def"
                                className={Object.keys(selectedProject).length <= 0 && 'select2-has-error'}
                            />
                            :
                            <div className="alert bg-light-info mt-0">Bitte zuerst einen Kunden wählen.</div>
                        }
                        </Form.Group>
                    </Form.Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit">Task verschieben</Button>
                    <Button variant="default " onClick={props.onHide}>Abbrechen</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>
}