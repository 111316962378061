import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useSubheader } from "../../../../../_metronic/layout";
import { usePageTitle } from "../../../../../providers/PageTitleProvider";
import { CustomerDepartmentInfoTab } from "./CustomerDepartmentInfoTab"
import axios from "axios";
import { Alert } from "react-bootstrap";

export function CustomerDepartmentInfo(props) {
    const subheader = useSubheader();
    const pageTitle = usePageTitle();


    const [lCustomer, setLCustomer] = useState({});
    const [key, setKey] = useState('general');

    const [infos, setInfos] = useState([]);

    let { path } = useParams();

    const getInfoByType = (type) => {
        if (infos.length <= 0)
            return {};
        let d =  infos.filter((i) => i.type === type)[0];
        return d === undefined ? {} : d;
    }
    const fetchInfos = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/customerinfobycustomer/' + lCustomer.id).then(({ data }) => {
            setInfos(data);
        }).catch(e => console.log(e));
    }
    useEffect(() => {
        setLCustomer(props.customer);
        subheader.setTitle(props.customer.name);
        subheader.setActions(null);
        pageTitle.setPageTitle(`🐮 Accounts${props.customer.name !== undefined ? ' | ' + props.customer.name : ''}`);
    }, [props.customer])

    useEffect(() => {
        if (lCustomer.id !== undefined)
            fetchInfos();
    }, [lCustomer])

    return (<>
        <div className={`card card-custom gutter-b`}>
            <div className="card-body">
                <Alert variant="danger">Achtung: Wenn du hier was änderst, kann das nicht wieder rückgägnig gemacht werden! Das Feld wird überschrieben und es gibt keine historische Aufzeichnung bzw. kein Log.</Alert>
                <Tabs
                    id="uncontrolled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                >
                    <Tab eventKey="general" title="Allgemeine Infos">
                        <CustomerDepartmentInfoTab type="general" getInfo={getInfoByType('general')} customer={lCustomer} onSubmit={fetchInfos} />
                    </Tab>
                    <Tab eventKey="marketing" title="Marketing Infos">
                        <CustomerDepartmentInfoTab type="marketing" getInfo={getInfoByType('marketing')} customer={lCustomer} onSubmit={fetchInfos} />
                    </Tab>
                    <Tab eventKey="grafik" title="Grafik Infos">

                        <CustomerDepartmentInfoTab type="grafik" getInfo={getInfoByType('grafik')} customer={lCustomer} onSubmit={fetchInfos} />
                    </Tab>
                    <Tab eventKey="online" title="Online-Marketing Infos">
                        <CustomerDepartmentInfoTab type="online" getInfo={getInfoByType('online')} customer={lCustomer} onSubmit={fetchInfos} />
                    </Tab>
                    <Tab eventKey="technik" title="Technik Infos">
                        <CustomerDepartmentInfoTab type="technik" getInfo={getInfoByType('technik')} customer={lCustomer} onSubmit={fetchInfos} />
                    </Tab>
                </Tabs>
            </div>
        </div>

    </>)
}
