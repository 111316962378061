import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useParams } from "react-router-dom";

export function ErrorPage() {

    const { type } = useParams();
    return (
        <div className="d-flex flex-column flex-root">
            <div
                className="error error-5 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
                style={{
                    backgroundImage: `url(${toAbsoluteUrl("/media/bg/background.jpg")})`,
                    backgroundPosition: 'left center'
                }}
            >
                <div className="container d-flex flex-row-fluid flex-column justify-content-md-center  align-items-end p-12">
                    <div className="min-w-lg-600px">
                        <h1 className="font-size-h1 font-weight-boldest text-primary mt-10 mt-md-0 mb-12">
                            Fehler {type !== undefined ? parseInt(type) : 404}
                        </h1>
                        <p className="font-size-h3">
                            {type === undefined || parseInt(type) === 404 ? 'Seite nicht gefunden.' : 'Du hast keine Berechtigung um diesen Content zu sehen.'}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
