import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Form, Col } from "react-bootstrap";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import axios from "axios";
import Select from 'react-select'

export default function TecExtensionCatModal(props) {

    const initialValues = {
        name: '',
        desc: ''
    };
    const saveNew = (values) => {
        let body = values;
        return axios.post(process.env.REACT_APP_API_URL + '/api/extcats', body);
    };
    const updateExisting = (values) => {
        let body = values;
        return axios.put(process.env.REACT_APP_API_URL + '/api/extcats/' + props.edit, body);
    };


    const fetchEntry = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/extcats/' + props.edit).then(({ data }) => {
            formik.setFieldValue('name', (data.name == null ? '' : data.name));
            formik.setFieldValue('desc', (data.desc == null ? '' : data.desc));
            
        }).catch(e => props.onSubmit());
    };
    const deleteData = () => {
        axios.delete(process.env.REACT_APP_API_URL + '/api/extcats/' + props.edit).then((d) => {
            toast.success("Gelöscht");
            props.onSubmit();
        }).catch(e => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.",{autoClose: 5500});
            props.onSubmit();
        });
    }

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            desc: Yup.string(),
        }),
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            setSubmitting(false);
            if (props.edit === null) {
                saveNew(values).then(d => { toast.success("Gespeichert"); resetForm(); props.onSubmit() }).catch(e => console.log(e));
            } else {
                updateExisting(values).then(d => { toast.success("Gespeichert"); resetForm(); props.onSubmit() }).catch(e => console.log(e));
            }
            setSubmitting(true);

        },
    });

    useEffect(() => {
        if (props.edit !== null)
            fetchEntry();
    }, [props.edit]);



    const renderForm = () => {
        return <Form onSubmit={formik.handleSubmit} className={`bg-white position-relative`}>
            <Modal.Body>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Name"
                            name="name"
                            value={''}
                            {...formik.getFieldProps("name")}
                            isInvalid={formik.errors.name}
                        />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Kurz Beschreibung</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Kurz Beschreibung"
                            name="desc"
                            value={''}
                            rows={6}
                            {...formik.getFieldProps("desc")}
                            isInvalid={formik.errors.desc}
                        />
                    </Form.Group>
                </Form.Row>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit">Speichern</Button>
                <Button variant="default " onClick={props.onHide}>Schließen</Button>
            </Modal.Footer>
        </Form>
    }

    const deleteForm = () => {
        return <>
            <Modal.Body>
                <p>Bist du dir sicher dass du diese Extension Kategorie löschen möchtest?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={deleteData}>Ja, Extension Kategorie löschen</Button>
                <Button variant="default " onClick={props.onHide}>Nein, Extension Kategorie nicht löschen</Button>
            </Modal.Footer>

        </>
    }

    return <>
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Extension Kategorie
                    {
                        (props.type && props.type === 'delete') ? ' löschen' :
                            (props.edit === null) ? ' erstellen' : ' bearbeiten'
                    }
                </Modal.Title>
            </Modal.Header>
            {(props.type && props.type === 'delete') ? deleteForm() : renderForm()}
        </Modal>
        </>

}