import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Form, Col } from "react-bootstrap";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import axios from "axios";
import Select from 'react-select'

export default function CustomerEmailAccountsModal(props) {

    const [selectCustomerConfig, setSelectCustomerConfig] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState({});

    const initialValues = {
        mail: '',
        password: ''
    };
    const saveNew = (values) => {
        let body = values;
        body.customer_id = selectedCustomer.value;
        return axios.post(process.env.REACT_APP_API_URL + '/api/ema', body);
    };
    const updateExisting = (values) => {
        let body = values;
        body.customer_id = selectedCustomer.value;
        return axios.put(process.env.REACT_APP_API_URL + '/api/ema/' + props.edit, body);
    };


    const fetchAllCustomers = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/customers').then(({ data }) => {
            mapCustomersForSelect(data);
        }).catch(e => props.onSubmit());
    };
    const fetchEntry = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/ema/' + props.edit).then(({ data }) => {
            formik.setFieldValue('mail', (data.mail == null ? '' : data.mail));
            formik.setFieldValue('password', (data.password == null ? '' : data.password));
        }).catch(e => props.onSubmit());
    };
    const deleteData = () => {
        axios.delete(process.env.REACT_APP_API_URL + '/api/ema/' + props.edit).then((d) => {
            toast.success("Gelöscht");
            props.onSubmit();
        }).catch(e => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.",{autoClose: 5500});
            props.onSubmit();
        });
    }

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            mail: Yup.string().required(),
            password: Yup.string(),
        }),
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {

            setSubmitting(false);
            if (props.edit === 0) {
                saveNew(values).then(d => { toast.success("Gespeichert"); resetForm(); props.onSubmit() }).catch(e => console.log(e));
            } else {
                updateExisting(values).then(d => { toast.success("Gespeichert"); resetForm(); props.onSubmit() }).catch(e => console.log(e));
            }
            setSubmitting(true);

        },
    });


    const mapCustomersForSelect = (data) => {
        let options = data.map((item, i) => {
            return { label: item.name, value: item.id };
        });
        setSelectCustomerConfig(options);
    };

    useEffect(() => {
        fetchAllCustomers();
        if(props.customer)
            setSelectedCustomer(props.customer);
    }, [props.customer]);

    useEffect(() => {
        if (props.edit !== 0)
            fetchEntry();
    }, [props.edit]);



    const renderForm = () => {
        return <Form onSubmit={formik.handleSubmit} className={`bg-white position-relative`}>
            <Modal.Body>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Kunde</Form.Label>
                        <Select options={selectCustomerConfig} value={selectedCustomer} onChange={(o) => {
                            setSelectedCustomer(o);
                        }}
                            classNamePrefix="sl2def"
                            className={Object.keys(selectedCustomer).length <= 0 && 'select2-has-error'}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Email"
                            name="mail"
                            value={''}
                            {...formik.getFieldProps("mail")}
                            isInvalid={formik.errors.mail}
                        />
                    </Form.Group>
                </Form.Row>


                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Passwort</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Passwort"
                            name="password"
                            value={''}
                            {...formik.getFieldProps("password")}
                        />
                    </Form.Group>
                </Form.Row>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit">Speichern</Button>
                <Button variant="default " onClick={props.onHide}>Schließen</Button>
            </Modal.Footer>
        </Form>
    }

    const deleteForm = () => {
        return <>
            <Modal.Body>
                <p>Bist du dir sicher dass du diese Zugangsdaten löschen möchtest?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={deleteData}>Ja, Daten löschen</Button>
                <Button variant="default " onClick={props.onHide}>Nein, Daten nicht löschen</Button>
            </Modal.Footer>

        </>
    }

    return <>
        <Modal
            show={props.show}
            size={(props.type && props.type === 'delete') ? ' md' : 'lg'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Email Account Zugangsdaten
                    {
                        (props.type && props.type === 'delete') ? ' löschen' :
                            (props.edit === 0) ? ' erstellen' : ' bearbeiten'
                    }
                </Modal.Title>
            </Modal.Header>
            {(props.type && props.type === 'delete') ? deleteForm() : renderForm()}
        </Modal>
        </>

}