import React, { useEffect } from "react";
import axios from "axios";
import { EchoContext } from "../../../../providers/EchoProvider";
export class DashboardOnlineUsers extends React.Component {
    static contextType = EchoContext;

    state = {
        users: [],
        listening: false
    }

    fetchUsers() {
        return axios.get(process.env.REACT_APP_API_URL + '/api/stempeluserscurrent').then(({ data }) => {
            this.setState({ users: data });
        }).catch(e => null);
    }
    renderUser(item, key) {
        return <li className={`list-group-item  border-0 ${this.props.cols}`} key={key}>
            <div className="d-flex">
                <i className={`fa fa-circle ${item.current_stempel_type === 1 ? 'text-success' : 'text-danger'}`}></i>
                <span className="ml-3">{item.firstname} {item.lastname.charAt(0)}.</span>
            </div>
        </li>

    }

    componentDidMount() {
        this.fetchUsers();
        if (this.context.echo === undefined || this.context.echo === null || !this.context.ready || this.state.listening)
            return;
        this.context.echo.channel('onlineusers')
            .listen('.anystempeled', (e) => {
                this.fetchUsers();
            });
    }

    componentDidUpdate() {

    }



    render() {
        return <div className="card card-custom gutter-b">
            <div className="card-header">
                <h3 className="card-title">
                    Mitarbeiter
                </h3>
            </div>
            <div className="card-body p-4">
                <div className="users">

                    <ul className="list-group list-group-horizontal-md flex-wrap mb-0">
                        {(this.state.users && Object.keys(this.state.users).length > 0) && this.state.users.map((item, key) => this.renderUser(item, key))}
                    </ul>
                </div>
            </div>
        </div>
    }
}