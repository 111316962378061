import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { StempeluhrPage } from "./modules/Stempeluhr/pages/StempeluhrPage";
import { StempeluhrUrlaube } from "./modules/Stempeluhr/pages/StempeluhrUrlaube";
import { CustomersPage } from "./modules/Customers/pages/CustomersPage";
import { AdminPage } from "./modules/Admin/pages/AdminPage";
import { TechnikPage } from "./modules/Technik/pages/TechnikPage";
import { ProjectsPage } from "./modules/Projects/pages/ProjectsPage";
import { ProjectsTimeline } from "./modules/Projects/pages/ProjectsTimeline";
import { StempeluhrAdminUrlaube } from "./modules/Stempeluhr/pages/StempeluhrAdminUrlaube";
import { StempeluhrAdminBetriebsurlaube } from "./modules/Stempeluhr/pages/StempeluhrAdminBetriebsurlaube";
import { StempeluhrChips } from "./modules/Stempeluhr/pages/StempeluhrChips";
import { StempeluhrProfile } from "./modules/Stempeluhr/pages/StempeluhrProfile";
import { StempeluhrInvalid } from "./modules/Stempeluhr/pages/StempeluhrInvalid";
import { Dashboard } from "./modules/Dashboard/Dashboard";
import { NotificationRedirect } from "./modules/Notifications/NotificationRedirect";
import { InterCredPage } from "./modules/InternalCredentials/pages/InterCredPage";
import { BookList } from "./modules/Books/pages/BookList";
import { BBBPage } from "./modules/BBB/pages/BBBPage";

/* const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
); */

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */

        }

        {/* Kunden begin */}
        <ContentRoute path="/kunden/:id/task/:taskpath" component={CustomersPage} can="showCustomers" />
        <Redirect exact from="/kunden/:id/tasks*(/+)" to="/kunden/:id/tasks" can="showCustomers" />
        <Redirect exact from="/kunden/:id" to="/kunden/:id/tasks" can="showCustomers" />

        <ContentRoute path="/kunden/:id/projekt/:projectpath/task/:taskpath" component={CustomersPage} can="showCustomers" />
        <Redirect exact from="/kunden/:id/projekt/:projectpath/tasks/" to="/kunden/:id/projekt/:projectpath" can="showCustomers" />
        <Redirect exact from="/kunden/:id/projekt/:projectpath/tasks" to="/kunden/:id/projekt/:projectpath" can="showCustomers" />
        <Redirect exact strict from="/kunden/:id/projekt/:projectpath" to="/kunden/:id/projekt/:projectpath/" can="showCustomers" />
        <ContentRoute strict path="/kunden/:id/projekt/:projectpath/" component={CustomersPage} can="showCustomers" />
        <Redirect exact from="/kunden/:id/projekte*(/+)" to="/kunden/:id/projekte" can="showCustomers" />

        <ContentRoute path="/kunden/:id?/:path" component={CustomersPage} can="showCustomers" />
        <ContentRoute path="/kunden" component={CustomersPage} can="showCustomers" />
        {/* Kunden end */}

        {/* Stempeluhr begin */}
        <ContentRoute path="/stempeluhr/urlaube/:urlUser?" component={StempeluhrUrlaube} canAny={['canStempel', 'adminStempel']} />
        <ContentRoute path="/stempeluhr/admin-urlaube/:urlUser?" component={StempeluhrAdminUrlaube} can='adminStempel' />
        <ContentRoute path="/stempeluhr/admin-profile/:urlUser?" component={StempeluhrProfile} can={'adminStempel'} />
        <ContentRoute path="/stempeluhr/betriebsurlaube-feiertage" component={StempeluhrAdminBetriebsurlaube} can='adminStempel' />
        <ContentRoute path="/stempeluhr/admin-fehlbuchungen" component={StempeluhrInvalid} can='adminStempel' />
        <ContentRoute path="/stempeluhr/chips" component={StempeluhrChips} can='adminStempel' />
        <ContentRoute path="/stempeluhr/details/:urlDate?/:urlUser?" component={StempeluhrPage} canAny={['canStempel', 'adminStempel']} />
        <Redirect exact from="/stempeluhr" to="/stempeluhr/details" />
        {/* Stempeluhr end */}

        {/* Admin begin */}
        <ContentRoute path="/admin/:path" component={AdminPage} canAny={['adminUsers', 'adminRoles', 'adminProjectTemplates']} />
        <ContentRoute path="/admin" component={AdminPage} canAny={['adminUsers', 'adminRoles', 'adminProjectTemplates']} />
        {/* Admin end */}

        {/* Technik begin */}
        <Redirect exact from="/technik" to="/technik/hosts" />
        <ContentRoute path="/technik/:path" component={TechnikPage} />
        {/* Technik end */}

        {/* Internal Credentials begin */}
        <ContentRoute path="/internal-credentials/:path" component={InterCredPage} canAny={['showInterCredOwn', 'showInterCredGeneral', 'showInterCredCritical']} />
        <ContentRoute path="/internal-credentials" component={InterCredPage} canAny={['showInterCredOwn', 'showInterCredGeneral', 'showInterCredCritical']} />
        {/* Internal Credentials end */}

        {/* Projekte begin */}
        <ContentRoute path="/projekte/:id/task/:taskpath" component={ProjectsPage} can="showCustomers" />
        <Redirect exact from="/projekte/:id/tasks*(/+)" to="/projekte/:id/tasks" can="showCustomers" />
        <Redirect exact from="/projekte/:id" to="/projekte/:id/tasks" can="showCustomers" />
        <ContentRoute path="/projekte/:id?" component={ProjectsPage} can="showCustomers" />
        {/* Projekte end */}

        {/* Timeline begin */}
        <ContentRoute path="/timeline" component={ProjectsTimeline} can="showCustomers" />
        {/* Timeline end */}

        <ContentRoute path="/notification/:id" component={NotificationRedirect} />

        <ContentRoute path="/books" component={BookList} />

        <ContentRoute path="/bbb" component={BBBPage} />


        <ContentRoute path="/" component={Dashboard} />


        {/*

        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
      */}
        <Redirect to="error/404" />

      </Switch>
    </Suspense>
  );
}
