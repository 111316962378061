import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Popover, Spinner, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Link, useHistory, useParams } from 'react-router-dom'
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import axios from "axios";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ProjectCreationModal } from "../ProjectCreationModal";

export function ProjectsMenu(props) {

    const history = useHistory();

    let { id, path } = useParams();

    const [allProjects, setAllProjects] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [showProjectModal, setShowProjectModal] = useState(false);

    //const loc = useLocation();
    const defaultLinkClass = "btn bg-dark-o-10 btn-hover-primary mb-6 px-6 py-6 rounded-md d-flex align-items-center text-left";
    const activeLinkClass = "btn btn-primary btn-hover-primary mb-6 px-6 py-6 rounded-md d-flex align-items-center text-left";

    /*const arrayEquals = (a, b) => {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }*/

    const getClass = (r) => {
        if (typeof r === 'string')
            return (r === path) ? activeLinkClass : defaultLinkClass;

        let t = false;
        r.forEach(item => {
            if (item === path)
                t = true;
        });
        return t ? activeLinkClass : defaultLinkClass;
    }


    const fetchAllProjects = () => {
        setIsLoading(true);
        axios.get(process.env.REACT_APP_API_URL + '/api/projects').then(({ data }) => {
            let dd = data;
            if (dd.length > 0)
                dd = dd.filter(t => t.archived === undefined || t.archived === 0)
            dd.sort((a, b) => a.name.localeCompare(b.name));

            setAllProjects(dd);
            setFilteredProjects(dd);
            setIsLoading(false);
        }).catch(e => console.log(e));
    };

    const modalClose = () => {
        setShowProjectModal(false);
    }
    const modalSubmit = (itemid) => {
        setShowProjectModal(false);
        history.push('/projekte/' + itemid + '/tasks');
        fetchAllProjects();
    }


    const handleSearch = () => {
        var filtered = allProjects;
        if (searchTerm !== '' && searchTerm.length > 2) {
            filtered = allProjects.filter(t => {
                return t.name.toLowerCase().includes(searchTerm.toLowerCase()) || t.customer_name.toLowerCase().includes(searchTerm.toLowerCase());
            });

            filtered.sort((a, b) => a.name.localeCompare(b.name));
        }
        setFilteredProjects(filtered);
    }

    const renderProjects = () => {

        return filteredProjects.map((item, index) => {
            return <div className="px-4 pl-lg-10 pr-lg-7" key={item.id}><div className="linkItem">
                <OverlayTrigger
                    placement="right"
                    overlay={
                        <Popover>
                            <div className="p-4">
                                <div className="d-flex py-1 align-items-center">
                                    <div className="mr-2 d-inline-block">
                                        <i className="la la-list icon-md"></i>
                                    </div>
                                    <div className="flex-grow-1 text text-center">
                                        {item.tasksCount}
                                    </div>
                                </div>

                            </div>
                        </Popover>
                    }
                >
                    <Link to={`/projekte/${item.id}`} className={String(item.id) === String(id) ? activeLinkClass : defaultLinkClass}>
                        <div className="d-flex flex-column">
                            <span className="font-weight-bold font-size-lg ">
                                {item.name}
                            </span>
                            <span className="font-size-sm mt-4">
                                <div className="text-dark-70" style={{ whiteSpace: 'normal', textAlign: 'left', lineHeight: '18px' }}>{item.customer_name}</div>
                            </span>
                        </div>

                    </Link>
                </OverlayTrigger>
            </div></div>
        });
    }

    useEffect(() => {
        fetchAllProjects();
    }, []);
    useEffect(() => {
        handleSearch();
    }, [searchTerm]);

    const renderSearch = () => {
        return (<div className="vh-100 d-flex flex-column justify-content-start">
            <div className="p-4 px-lg-10 pt-lg-8 pb-lg-10">

                <div className="d-flex">
                    <div className="input-icon h-40px">
                        <input
                            type="text"
                            className="form-control form-control-lg form-control-solid h-40px"
                            placeholder="Suchen ..."
                            id="generalSearch"
                            autoComplete="false"
                            //autoFocus={true}
                            onKeyUp={(e) => setSearchTerm(e.currentTarget.value)}

                        />
                        <span>
                            <span className="svg-icon svg-icon-lg">
                                <SVG
                                    src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                                />
                            </span>
                        </span>
                    </div>
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip id="latest-project">Neues Projekt erstellen</Tooltip>
                        }
                    >
                        <div
                            onClick={() => setShowProjectModal(true)}
                            className={`btn btn-icon btn-default btn-hover-primary ml-3 h-40px w-40px flex-shrink-0`}
                        >
                            <span className="svg-icon svg-icon-lg">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/Code/Plus.svg"
                                    )}
                                />
                            </span>
                        </div>
                    </OverlayTrigger>

                </div>
            </div>
            <div className="flex-grow-1 projects-list d-flex flex-column" >
                <div style={{ position: 'relative', height: '100%' }}>
                    <PerfectScrollbar
                        className="scroll pr-4"
                        options={{ wheelPropagation: false }}
                        style={{ height: '100%', width: '100%', position: "absolute" }}
                    >

                        {isLoading ? <div className="text-center pt-2 pb-2"><Spinner animation="border" variant="primary" /></div> : renderProjects()}
                    </PerfectScrollbar>
                </div>
            </div>

        </div>)
    }

    return <>
        {renderSearch()}
        <ProjectCreationModal show={showProjectModal} onHide={modalClose} onSubmit={modalSubmit} />

    </>
}