import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import axios from "axios";
import { Redirect, useParams } from "react-router-dom";

import { useAsideSecondary } from "../../../../_metronic/layout/_core/MpAsideSecondary";
import { CustomerInfo } from "../components/CustomerInfo";
import { CustomerAccounts } from "../components/CustomerAccounts";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { CustomerTasks } from "../components/CustomerTasks";
import { TaskDetail } from "../../ProjectTasks/TaskDetail";
import { CustomerProjects } from "../components/CustomerProjects";
import { ProjectDetail } from "../../Projects/components/ProjectDetail";
import { useSubheader } from "../../../../_metronic/layout";
import { useContentContainer } from "../../../../_metronic/layout/_core/MpContentContainer";
import { useUserProvider } from "../../../../providers/AllUsersProvider";

export function CustomersPage() {
  //const user = useSelector((state) => state.auth.user, shallowEqual);
  const MpAsideSecondary = useAsideSecondary();
  const subheader = useSubheader();
  const contentContainer = useContentContainer();

  const userProvider = useUserProvider();

  const [customer, setCustomer] = useState({});
  const [project, setProject] = useState({});


  let { id, path, taskpath, projectpath } = useParams();

  const fetchCustomer = () => {
    let localCid = id;
    if (String(id).substring(0, 2) === '0_')
      localCid = localCid.slice(2);

    axios.get(process.env.REACT_APP_API_URL + '/api/customers/' + localCid).then(({ data }) => {
      setCustomer(data);
    }).catch(e => console.log(e));
  };
  const fetchProject = () => {
    axios.get(process.env.REACT_APP_API_URL + '/api/projects/' + projectpath).then(({ data }) => {
      setProject(data);
    }).catch(e => console.log(e));
  };


  useEffect(() => {
    MpAsideSecondary.setAsideEnabled(true);
    contentContainer.reset();

  }, []);

  useEffect(() => {
    if (projectpath !== undefined)
      fetchProject();
  }, [projectpath]);
  useEffect(() => {
    if (id !== undefined) {
      fetchCustomer();
    } else {
      subheader.setActions(null);
    }
  }, [id]);

  const renderEmpty = () => {
    return (<>
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
        <div className="text-center">
          <div className="svg-icon svg-icon-8x">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-left.svg")}
            />
          </div>
          <div className="font-size-h6 mt-10">Wähle einen Kunden aus.</div>
        </div>
      </div>
    </>
    );
  };
  const renderContent = () => {

    switch (path) {
      case 'tasks':
        return <CustomerTasks customer={customer} />
      case 'info':
        return <CustomerInfo customer={customer} refetch={fetchCustomer} />
      case 'zugangsdaten':
        return userProvider.canAny(['showHosts', 'showHostsData', 'showMisc', 'showEmailAccounts']) ? <CustomerAccounts customer={customer} /> : <Redirect to="/error/403" />;
      case 'projekte':
        return <CustomerProjects customer={customer} />
      default:
        let showFollowing = renderEmpty();
        if (taskpath !== undefined)
          showFollowing = <TaskDetail customer={customer} isProject={projectpath !== undefined}/>;
        if (projectpath !== undefined && taskpath === undefined)
          showFollowing = <ProjectDetail project={project} refetch={fetchProject} customer={customer} />;
        return showFollowing;
    }

  };


  return (id === undefined ? renderEmpty() : renderContent());
}