import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";

import CustomerHosts from "./partials/CustomerHosts"
import CustomerEmailAccounts from "./partials/CustomerEmailAccounts";
import CustomerMiscAccounts from "./partials/CustomerMiscAccounts";
import { useSubheader } from "../../../../_metronic/layout";
import { usePageTitle } from "../../../../providers/PageTitleProvider";
import { useUserProvider } from "../../../../providers/AllUsersProvider";

export function CustomerAccounts(props) {
    const subheader = useSubheader();
    const pageTitle = usePageTitle();

    const userProvider = useUserProvider()

    const loc = useLocation();
    const history = useHistory();
    const [lCustomer, setLCustomer] = useState({});
    const [key, setKey] = useState('hosts');

    let { path } = useParams();

    const setTab = (k) => {
        setKey(k)
        let ll = loc;
        ll.hash = k;
        history.replace(ll);
    }

    useEffect(() => {
        setLCustomer(props.customer);
        subheader.setTitle(props.customer.name);
        subheader.setActions(null);
        pageTitle.setPageTitle(`🐮 Accounts${props.customer.name !== undefined ? ' | ' + props.customer.name : ''}`);
    }, [props.customer])
    useEffect(() => {
        if (loc.hash !== undefined && loc.hash !== '')
            setKey(loc.hash.replace('#', ''));
    }, [path])


    return (<>
        <div className="row">
            <div className="col-12">
                <div className={`card card-custom`}>
                    <div className="card-body">
                        <Tabs
                            id="uncontrolled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setTab(k)}
                        >
                            {userProvider.canAny(['showHosts', 'showHostsData']) &&
                                <Tab eventKey="hosts" title="Hosts">
                                    <CustomerHosts customer={lCustomer} />
                                </Tab>
                            }
                            {userProvider.can('showMisc') &&
                                <Tab eventKey="misc" title="Sonstige Accounts">
                                    <CustomerMiscAccounts customer={lCustomer} />
                                </Tab>
                            }
                            {userProvider.can('showEmailAccounts') &&
                                <Tab eventKey="email" title="E-Mail Accounts">
                                    <CustomerEmailAccounts customer={lCustomer} />
                                </Tab>
                            }

                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
