import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify"
import axios from "axios";

export function ProjectArchiveModal(props) {

    const [project, setProject] = useState({});
    useEffect(() => {
        setProject(props.project);
    }, [props.project]);

    const archiveData = () => {
        props.onSubmit();

        axios.post(process.env.REACT_APP_API_URL + '/api/projects/' + project.id + ' /archivate').then((d) => {
            toast.success(project.archived ? "Projekt reaktiviert" : "Projekt archiviert");
            props.onSubmit();
        }).catch(e => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
            props.onSubmit();
        });

    }

    return <>
        <Modal
            show={props.show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Projekt {project.archived ? "reaktivieren" : "archivieren"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {project.archived ?
                    <p>Bist du dir sicher dass du diesen Projekt reaktivieren möchtest?</p>
                    :
                    <><p>Bist du dir sicher dass du diesen Projekt archivieren möchtest?</p>
                        <p className="text-danger">Achtung: durch die Archivierung eines Projekts, werden auch alle Tasks darin archiviert. Bei einer Reaktivierung musst du die einzelnen Tasks auch wieder aktivieren!</p>
                    </>
                }

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={archiveData}>Ja, Projekt {project.archived ? "reaktivieren" : "archivieren"}</Button>
                <Button variant="default " onClick={props.onHide}>Nein, Projekt nicht {project.archived ? "reaktivieren" : "archivieren"}</Button>
            </Modal.Footer>

        </Modal>
    </>
}
