import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Form, Col } from "react-bootstrap";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import axios from "axios";
import Select from 'react-select'

export default function CustomerMiscAccountsModal(props) {

    const [selectCustomerConfig, setSelectCustomerConfig] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState({});

    const initialValues = {
        type: '',
        user: '',
        password: '',
        site: ''
    };
    const saveNew = (values) => {
        let body = values;
        body.customer_id = selectedCustomer.value;
        return axios.post(process.env.REACT_APP_API_URL + '/api/misc', body);
    };
    const updateExisting = (values) => {
        let body = values;
        body.customer_id = selectedCustomer.value;
        console.log(body)
        return axios.put(process.env.REACT_APP_API_URL + '/api/misc/' + props.edit, body);
    };


    const fetchAllCustomers = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/customers').then(({ data }) => {
            mapCustomersForSelect(data);
        }).catch(e => props.onSubmit());
    };
    const fetchEntry = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/misc/' + props.edit).then(({ data }) => {
            formik.setFieldValue('type', (data.type == null ? '' : data.type));
            formik.setFieldValue('user', (data.user == null ? '' : data.user));
            formik.setFieldValue('password', (data.password == null ? '' : data.password));
            formik.setFieldValue('site', (data.site == null ? '' : data.site));
        }).catch(e => props.onSubmit());
    };
    const deleteData = () => {
        axios.delete(process.env.REACT_APP_API_URL + '/api/misc/' + props.edit).then((d) => {
            toast.success("Gelöscht");
            props.onSubmit();
        }).catch(e => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.",{autoClose: 5500});
            props.onSubmit();
        });
    }

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            type: Yup.string().required(),
            user: Yup.string(),
            password: Yup.string(),
            site: Yup.string(),
        }),
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {

            setSubmitting(false);
            if (props.edit === 0) {
                saveNew(values).then(d => { toast.success("Gespeichert"); resetForm(); props.onSubmit() }).catch(e => console.log(e));
            } else {
                updateExisting(values).then(d => { toast.success("Gespeichert"); resetForm(); props.onSubmit() }).catch(e => console.log(e));
            }
            setSubmitting(true);

        },
    });


    const mapCustomersForSelect = (data) => {
        let options = data.map((item, i) => {
            return { label: item.name, value: item.id };
        });
        setSelectCustomerConfig(options);
    };

    useEffect(() => {
        fetchAllCustomers();
        if(props.customer)
            setSelectedCustomer(props.customer);
    }, [props.customer]);

    useEffect(() => {
        if (props.edit !== 0)
            fetchEntry();
    }, [props.edit]);



    const renderForm = () => {
        return <Form onSubmit={formik.handleSubmit} className={`bg-white position-relative`}>
            <Modal.Body>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Kunde</Form.Label>
                        <Select options={selectCustomerConfig} value={selectedCustomer} onChange={(o) => {
                            setSelectedCustomer(o);
                        }}
                            classNamePrefix="sl2def"
                            className={Object.keys(selectedCustomer).length <= 0 && 'select2-has-error'}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Typ</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Typ"
                            name="type"
                            value={''}
                            {...formik.getFieldProps("type")}
                            isInvalid={formik.errors.type}
                        />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>User</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="User"
                            name="user"
                            value={''}
                            {...formik.getFieldProps("user")}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Passwort</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Passwort"
                            name="password"
                            value={''}
                            {...formik.getFieldProps("password")}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>URL</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="URL"
                            name="site"
                            value={''}
                            {...formik.getFieldProps("site")}
                        />
                    </Form.Group>
                </Form.Row>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit">Speichern</Button>
                <Button variant="default " onClick={props.onHide}>Schließen</Button>
            </Modal.Footer>
        </Form>
    }

    const deleteForm = () => {
        return <>
            <Modal.Body>
                <p>Bist du dir sicher dass du diese Zugangsdaten löschen möchtest?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={deleteData}>Ja, Daten löschen</Button>
                <Button variant="default " onClick={props.onHide}>Nein, Daten nicht löschen</Button>
            </Modal.Footer>

        </>
    }

    return <>
        <Modal
            show={props.show}
            size={(props.type && props.type === 'delete') ? ' md' : 'lg'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Sonstige Zugangsdaten
                    {
                        (props.type && props.type === 'delete') ? ' löschen' :
                            (props.edit === 0) ? ' erstellen' : ' bearbeiten'
                    }
                </Modal.Title>
            </Modal.Header>
            {(props.type && props.type === 'delete') ? deleteForm() : renderForm()}
        </Modal>
        </>

}