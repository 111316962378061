import React, { useEffect, useState } from "react";
import generator from "generate-password";
import { CopyToClipboard } from "react-copy-to-clipboard";

function GenerateCard(props) {

    const [passwords, setPassowrds] = useState([]);

    const genPW = () => {
        let pws = generator.generateMultiple(10, props.config);
        setPassowrds(pws);
    };
    useEffect(() => {
        genPW();
    }, []);

    return <div className="card card-custom gutter-b">
        <div className="card-header border-0">
            <h3 className="card-title font-weight-bolder text-dark">Stufe {props.level}</h3>
            <div className="card-toolbar">
                <div className="dropdown dropdown-inline">
                    <div className="btn btn-hover-light-primary btn-icon btn-sm mt-n1 ml-n2">
                        <i><i className="la la-refresh" onClick={() => genPW()}></i></i>
                    </div>
                </div>
            </div>
        </div>
        <div className="card-body pt-0 font-size-lg">
            {
                passwords.map((item, i) => {
                    return <div className="pwItem py-1" key={i}>
                        <CopyToClipboard className="cursor-pointer text-hover-primary" text={item}>
                            <div>
                                <div className="d-flex">
                                    <div className="btn btn-hover-light-primary btn-icon btn-sm mt-n1 ml-n2">
                                        <i><i className="la la-clipboard"></i></i>
                                    </div>
                                    <div className="pl-2">{item}</div>
                                </div>
                            </div>
                        </CopyToClipboard>
                    </div>
                })
            }
        </div>
    </div>

}


export function TechnikPasswortGenerator(props) {



    return <>

        <div className="row">
            <div className="col-lg-4">
                <GenerateCard config={{
                    length: 10,
                    uppercase: false,
                    numbers: true
                }} level={1} />
            </div>
            <div className="col-lg-4">
                <GenerateCard config={{
                    length: 10,
                    uppercase: true,
                    numbers: true
                }} level={2} />
            </div>
            <div className="col-lg-4">
                <GenerateCard config={{
                    length: 16,
                    uppercase: false,
                    numbers: true
                }} level={3} />
            </div>
            <div className="col-lg-4">
                <GenerateCard config={{
                    length: 16,
                    uppercase: true,
                    numbers: true
                }} level={4} />
            </div>
            <div className="col-lg-4">
                <GenerateCard config={{
                    length: 24,
                    uppercase: true,
                    numbers: true
                }} level={5} />
            </div>
            <div className="col-lg-4">
                <GenerateCard config={{
                    length: 24,
                    uppercase: false,
                    numbers: true,
                    strict: true,
                    symbols: '!#?-_$%&\/'
                }} level={6} />
            </div>

        </div>
    </>
}