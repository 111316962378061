import React, { createContext, useState, useContext, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import axios from "axios";
export const NotificationBadgeContext = createContext();

export function useNotificationBadge() {
  return useContext(NotificationBadgeContext);
}

export const PageTitleConsumer = NotificationBadgeContext.Consumer;

export function NotificationBadgeProvider({ children }) {
  const [countUnreadNotifications, setCountUnreadNotifications] = useState(0);
  const [updateNotificationBadge, setUpdateNotificationBadge] = useState(false);

  const countUserNotify = () => {
    axios.get(process.env.REACT_APP_API_URL + '/api/notificationscount').then(({ data }) => {
      setCountUnreadNotifications(parseInt(data));
      setUpdateNotificationBadge(false);
    }).catch(e => null)
  }
  useEffect(() => {
    countUserNotify();
  }, []);

  useEffect(() => {
    if (updateNotificationBadge)
      countUserNotify();
  }, [updateNotificationBadge]);
  
  const value = { updateNotificationBadge, setUpdateNotificationBadge, countUnreadNotifications }
  return (
    <NotificationBadgeContext.Provider value={value}>
      {children}
    </NotificationBadgeContext.Provider>
  );
}
