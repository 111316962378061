import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify"
import axios from "axios";

export function TaskArchiveModal(props) {

    const [task, setTask] = useState({});
    useEffect(() => {
        setTask(props.task);
    }, [props.task]);

    const archiveData = () => {
        props.onSubmit();

        axios.post(process.env.REACT_APP_API_URL + '/api/tasks/' + task.id+' /archivate').then((d) => {
            toast.success(task.archived ? "Task reaktiviert" : "Task archiviert");
            props.onSubmit();
        }).catch(e => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.",{autoClose: 5500});
            props.onSubmit();
        });
        
    }

    return <>
        <Modal
            show={props.show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Task {task.archived ? "reaktivieren" : "archivieren"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Bist du dir sicher dass du diesen Task {task.archived ? "reaktivieren" : "archivieren"} möchtest?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={archiveData}>Ja, Task {task.archived ? "reaktivieren" : "archivieren"}</Button>
                <Button variant="default " onClick={props.onHide}>Nein, Task nicht {task.archived ? "reaktivieren" : "archivieren"}</Button>
            </Modal.Footer>

        </Modal>
    </>
}
