import React from "react";
import { Button, Modal, Table } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy as highlightStyle } from "react-syntax-highlighter/dist/esm/styles/prism";

export function TecExtensionPipelineModal(props) {

    return <Modal
        show={props.show}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={props.onHide}
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Pipeline Beispiel-Konfiguration
            </Modal.Title>
        </Modal.Header>
        {props.item !== null &&
            <Modal.Body>
                <div className="alert bg-light-info mb-10">Um die Extension automatisiert über Pipelines hochzuladen, kannst du folgende Konfigurationen verwenden.</div>

                <h3 className="mb-4">Bitbucket</h3>
                <h5 className="mb-4">Variablen</h5>
                <Table responsive variant="lg">
                    <thead>
                        <tr>
                            <td>Typ</td>
                            <td>Variable</td>
                            <td>Wert</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Token</td>
                            <td>
                                <div className="d-flex">
                                    <CopyToClipboard className="d-print-none cursor-pointer" text="STADL_TOKEN">
                                        <div className="btn btn-hover-light-primary btn-icon btn-sm mt-n2 ml-n2">
                                            <i><i className="la la-clipboard"></i></i>
                                        </div>
                                    </CopyToClipboard>
                                    <div className="pl-2">STADL_TOKEN</div>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex">
                                    <CopyToClipboard className="d-print-none cursor-pointer" text={props.item.token}>
                                        <div className="btn btn-hover-light-primary btn-icon btn-sm mt-n2 ml-n2">
                                            <i><i className="la la-clipboard"></i></i>
                                        </div>
                                    </CopyToClipboard>
                                    <div className="pl-2">{props.item.token}</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Endpoint</td>
                            <td>
                                <div className="d-flex">
                                    <CopyToClipboard className="d-print-none cursor-pointer" text="STADL_EP">
                                        <div className="btn btn-hover-light-primary btn-icon btn-sm mt-n2 ml-n2">
                                            <i><i className="la la-clipboard"></i></i>
                                        </div>
                                    </CopyToClipboard>
                                    <div className="pl-2">STADL_EP</div>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex">
                                    <CopyToClipboard className="d-print-none cursor-pointer" text={process.env.REACT_APP_API_URL + '/api/extup'}>
                                        <div className="btn btn-hover-light-primary btn-icon btn-sm mt-n2 ml-n2">
                                            <i><i className="la la-clipboard"></i></i>
                                        </div>
                                    </CopyToClipboard>
                                    <div className="pl-2">{process.env.REACT_APP_API_URL + '/api/extup'}</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Zip-Name</td>
                            <td>
                                <div className="d-flex">
                                    <CopyToClipboard className="d-print-none cursor-pointer" text="STADL_ZIPNAME">
                                        <div className="btn btn-hover-light-primary btn-icon btn-sm mt-n2 ml-n2">
                                            <i><i className="la la-clipboard"></i></i>
                                        </div>
                                    </CopyToClipboard>
                                    <div className="pl-2">STADL_ZIPNAME</div>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex">
                                    <CopyToClipboard className="d-print-none cursor-pointer" text={props.item.filename.replace('.zip', '')}>
                                        <div className="btn btn-hover-light-primary btn-icon btn-sm mt-n2 ml-n2">
                                            <i><i className="la la-clipboard"></i></i>
                                        </div>
                                    </CopyToClipboard>
                                    <div className="pl-2">{props.item.filename.replace('.zip', '')}</div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <h5 className="mb-4">bitbucket-pipelines.yml</h5>
                <SyntaxHighlighter
                    language="yaml"
                    style={highlightStyle}
                    customStyle={{ padding: '20px 30px' }}
                >{"pipelines:\n default:\n  - step:\n     deployment: production\n     script:\n     - apt-get update && apt-get -qq install zip\n     - cd ../ && mkdir $BITBUCKET_REPO_SLUG && cd build/\n     - cp -r * ../$BITBUCKET_REPO_SLUG/\n     - rm ../$BITBUCKET_REPO_SLUG/bitbucket-pipelines.yml\n     - cd ../$BITBUCKET_REPO_SLUG/ && zip -r ../$STADL_ZIPNAME.zip ./*\n     - curl -k -F \"token=$STADL_TOKEN\" -F \"via=bitbucket\" -F \"file=@../$STADL_ZIPNAME.zip\" $STADL_EP\n     - exit\n"}
                </SyntaxHighlighter>
            </Modal.Body>
        }

        <Modal.Footer>
            <Button variant="default " onClick={props.onHide}>Schließen</Button>
        </Modal.Footer>
    </Modal>

}