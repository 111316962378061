import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import axios from "axios";
import Moment from 'react-moment';
import moment from 'moment';

export function MonthList(props) {

    const [monthData, setMonthData] = useState([]);
    const [sumData, setSumData] = useState({});
    const [currentPeriodData, setCurrentPeriodData] = useState({});
    const [allPeriodData, setAllPeriodData] = useState({});


    const [currentDate, setCurrentDate] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);


    const getDiff = (item) => {
        let color = item.diff === 0 ? '' : !item.is_negative ? 'text-success' : 'text-danger';

        if (item.is_valid !== 1 && moment(item.day).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
            return ['text-danger', (<><div className="badge badge-danger">Fehlbuchung</div></>)];
        }
        if (item.is_valid !== 1 && moment(item.day).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
            return ['', (<><div className="badge badge-success">heute ist noch alles offen <i className="fa fa-smile-wink icon-sm text-white"></i></div></>)];
        }
        if (item.has_public_holiday && item.diff === 0)
            return ['text-warning', 'Feiertag'];
        if (item.has_vacation && item.diff === 0)
            return ['text-info', 'Urlaub'];
        if (([0, 6]).includes(item.dayOfWeekNum) && item.diff === 0)
            return ['text-warning', 'Wochenende'];

        if (item.has_public_holiday && item.diff !== 0)
            return ['text-danger', (<><span className="text-warning">Feiertag</span><span className="text-body px-1">|</span><span className={color}>{item.diffReadable}</span></>)];
        if (item.has_vacation && item.diff !== 0) {
            let vacationText = item.vacation >= 1 ? 'Urlaub' : item.vacation.toString().replace(/\./g, ',') + '-T Urlaub';
            return ['text-info', (<><span className="text-info">{vacationText}</span><span className="text-body px-1">|</span><span className={color}>{item.diffReadable}</span></>)];
        }
        if (item.has_doc && item.diff === 0)
            return ['text-secondary', 'Krantk/Arzt'];

        if (item.has_doc && item.diff !== 0)
            return ['text-secondary', (<><span className="text-secondary">Krantk/Arzt</span><span className="text-body px-1">|</span><span className={color}>{item.diffReadable}</span></>)];

        if (([0, 6]).includes(item.dayOfWeekNum) && item.diff !== 0)
            return ['text-warning', (<><span className="text-warning">Wochenende</span><span className="text-body px-1">|</span><span className={color}>{item.diffReadable}</span></>)];

        return [color, item.diffReadable];
    }


    const generateList = () => {

        if (monthData === undefined || monthData === null || monthData.length <= 0)
            return (<><tr><td colSpan={5}>Keine Daten</td></tr></>);
        return monthData.map((item, i) => {
            return (
                <tr key={i}>
                    <td style={{ width: '5%' }}>{item.dayOfWeek}</td>
                    <td onClick={() => props.dateCallback(moment(item.day))} className="pr-3 text-hover-success cursor-pointer"><Moment format="DD.MM.YYYY">{item.day}</Moment></td>
                    <td className="text-right">{item.sollReadable}</td>
                    <td className="text-right">{item.istReadable}</td>
                    <td className={`text-right ${getDiff(item)[0]}`}>{getDiff(item)[1]}</td>

                </tr>
            );
        });

    }

    const getTimeLine = () => {
        return (<>

            <Table responsive>
                <thead>
                    <tr>
                        <th style={{ width: '5%' }}>Tag</th>
                        <th style={{ width: '40%' }}>Datum</th>
                        <th className="text-right">Soll</th>
                        <th className="text-right">Ist</th>
                        <th className="text-right" style={{ width: '25%' }}>Gleitzeit</th>
                    </tr>
                </thead>
                <tbody>
                    {generateList()}
                    {sumData !== undefined && (<>
                        <tr><td colSpan="6"></td></tr>
                        <tr>
                            <td colSpan="2">Summe für den Monat <span className="text-muted font-size-xs">({sumData.month})</span></td>
                            <td className="text-right">{sumData.sollReadable}</td>
                            <td className="text-right">{sumData.istReadable}</td>
                            <td className={`text-right ${sumData.diff >= 0 ? 'text-success' : 'text-danger'}`}>{sumData.diffReadable}</td>
                        </tr>
                    </>)}
                    {allPeriodData !== undefined && (<>
                        <tr><td colSpan="6"></td></tr>
                        <tr>
                            <td colSpan="2">Summe gesamt seit Aufzeichnungs-Beginn</td>
                            <td className="text-right">{allPeriodData.sollReadable}</td>
                            <td className="text-right">{allPeriodData.istReadable}</td>
                            <td className={`text-right ${allPeriodData.diff >= 0 ? 'text-success' : 'text-danger'}`}>{allPeriodData.diffReadable}</td>
                        </tr>
                    </>)}
                    {currentPeriodData !== undefined && (<>
                        <tr><td colSpan="6"></td></tr>
                        <tr>
                            <td colSpan="2">Summe der Aufzeichnungsperiode in welche dieser Monat fällt.</td>
                            <td className="text-right">{currentPeriodData.sollReadable}</td>
                            <td className="text-right">{currentPeriodData.istReadable}</td>
                            <td className="text-right">{currentPeriodData.diffReadable}</td>
                        </tr>
                    </>)}
                </tbody>
            </Table>

        </>);
    }

    const fetchMonthData = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/stempeluhr?day=' + props.currentDate.format('YYYY-MM-DD') + '&user=' + props.user.value).then(({ data }) => {
            setMonthData(data.data);
            setSumData(data.sums);
            setCurrentPeriodData(data.currentPeriodSums);
            setAllPeriodData(data.allPeriodSums);
            props.setShouldRefetch(false);
        }).catch(e => null);
    };

    useEffect(() => {
        if (
            Object.keys(props.user).length > 0 &&
            (currentDate === null || currentDate !== props.currentDate) &&
            (currentUser === null || currentUser !== props.users.selectedUser)
        ) {
            fetchMonthData();
            setCurrentDate(currentDate);
            setCurrentUser(currentUser);

        }
    }, [props.currentDate, props.user]);
    useEffect(() => {
        if(props.shouldRefetch !== undefined && props.shouldRefetch === true)
            fetchMonthData();
    }, [props.shouldRefetch]);



    return (props.displayType === 'card' ?
        <><div className={`card card-custom ${props.className}`}>
            <div className="card-header border-0">
                <h3 className="card-title text-dark">
                    <span className="d-print-none">{props.title}</span>
                    <span className="d-none d-print-block">Monats Auswertung | {sumData?.month}</span>
                </h3>
                <div className="card-toolbar d-print-none">
                    <a href="#" onClick={(e) => { window.print(); e.preventDefault(); return false; }} className="btn btn-icon btn-clean btn-hover-light-primary btn-sm"><i className="la la-print"></i></a>
                </div>
            </div>
            <div className="card-body pt-2">
                {getTimeLine()}
            </div>
        </div>
        </>
        :
        <>{getTimeLine()}</>
    );

};