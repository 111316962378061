import React from "react";
import SVG from "react-inlinesvg";
import { Link, useLocation } from 'react-router-dom'
import { useUserProvider } from "../../../../providers/AllUsersProvider";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";


export function AdminMenu(props) {
    const userProvider = useUserProvider();

    const loc = useLocation();
    const defaultClass = "btn bg-dark-o-10 btn-hover-primary mb-6 px-6 py-6 roundedNew = () => import('@/components/New.vue') d-flex align-items-center text-left";
    const activeClass = "btn btn-primary btn-hover-primary mb-6 px-6 py-6 roundedNew = () => import('@/components/New.vue') d-flex align-items-center text-left";

    const arrayEquals = (a, b) => {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }

    const getClass = (r) => {

        let ll = loc.pathname.split('/');
        let rr = r.split('/');

        return arrayEquals(ll, rr) ? activeClass : defaultClass;
    }

    return (<>
        <div className="p-4 px-lg-10 pt-lg-8">
            {userProvider.can('adminUsers') &&
                <div className="linkItem">
                    <Link to="/admin/users" className={getClass('/admin/users')}>
                        <span className="svg-icon svg-icon-2x d-block mr-6 mb-1">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                            ></SVG>
                        </span>
                        <span className="font-weight-bold font-size-lg ">
                            Benutzer Konten
                        </span>
                    </Link>
                </div>
            }
            {userProvider.can('adminRoles') &&
                <div className="linkItem">
                    <Link to="/admin/usergroups" className={getClass('/admin/usergroups')}>
                        <span className="svg-icon svg-icon-2x d-block mr-6 mb-1">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}
                            ></SVG>
                        </span>
                        <span className="font-weight-bold font-size-lg ">
                            Benutzer Gruppen
                        </span>
                    </Link>
                </div>
            }
            {userProvider.can('adminProjectTemplates') &&
                <div className="linkItem">
                    <Link to="/admin/project-templates" className={getClass('/admin/project-templates')}>
                        <span className="svg-icon svg-icon-2x d-block mr-6 mb-1">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Files/Group-folders.svg")}
                            ></SVG>
                        </span>
                        <span className="font-weight-bold font-size-lg ">
                            Projekt Task Vorlagen
                        </span>
                    </Link>
                </div>
            }

        </div>

    </>);
}