import React, { useEffect, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";

import axios from "axios";
import { toast } from 'react-toastify';
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { shallowEqual, useSelector } from "react-redux";

export function TaskPin(props) {

    const currentUser = useSelector((state) => state.auth.user, shallowEqual);

    const [task, setTask] = useState('');
    const [pinStatus, setPinStatus] = useState(false);

    const updatePin = (val) => {
        axios.post(process.env.REACT_APP_API_URL + '/api/tasks/' + task.id + '/pin').then(({data}) => {
            toast.success(data.pinned ? "Task angepinnt" : "Task entfernt")
            setPinStatus(data.pinned);
        }).catch((e) => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
        });
    }


    useEffect(() => {
        setTask(props.task);

        if (currentUser.id !== undefined && currentUser.id !== null && props.task.pinned !== undefined && props.task.pinned !== null && props.task.pinned.includes(currentUser.id)) {
            setPinStatus(true);
        } else {
            setPinStatus(false);
        }

    }, [props.task, currentUser]);


    return <>
        <OverlayTrigger
            placement="left"
            overlay={
                <Tooltip id="latest-project">Task im Dashboard anpinnen/entfernen.</Tooltip>
            }
        >
            <div className={`btn btn-icon ${pinStatus ? 'btn-success' : 'btn-secondary'}  mr-6`} onClick={updatePin}>
                <span className="svg-icon svg-icon-lg">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/General/Star.svg"
                        )}
                    /></span>

            </div>
        </OverlayTrigger>

    </>
}