import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useAsideSecondary } from "../../../../_metronic/layout/_core/MpAsideSecondary";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useSubheader } from "../../../../_metronic/layout";
import { useContentContainer } from "../../../../_metronic/layout/_core/MpContentContainer";
import axios from "axios";
import { usePageTitle } from "../../../../providers/PageTitleProvider";
import { Accordion, Card, Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import Moment from "react-moment";
import 'moment/locale/de';
import BBBModal from "../components/BBBModal";
import moment from "moment-timezone";

export function BBBPage(props) {
    const MpAsideSecondary = useAsideSecondary();
    const subheader = useSubheader();
    const contentContainer = useContentContainer();
    const pageTitle = usePageTitle();

    const [isLoading, setIsLoading] = useState(true);

    const [meetings, setMeetings] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [formTypeDelete, setFormTypeDelete] = useState(false);
    const [editId, setEditId] = useState(null);


    const ep = process.env.REACT_APP_API_URL + '/api/bbb';
    const guestUrlPrefix = 'https://meet.mindpark.at/';

    let { path } = useParams();

    const fetchMeetings = () => {
        axios.get(ep).then(({ data }) => {
            setMeetings(data);
            setIsLoading(false);
        }).catch(e => console.log(e));
    };

    useEffect(() => {
        subheader.setTitle('Meetings');
        pageTitle.setPageTitle("🐮 " + 'Meetings');
        fetchMeetings();
    }, [subheader]);

    const setSubHeaderActions = () => {
        subheader.setActions(<>
            <Button variant="primary btn-sm" onClick={() => setShowModal(true)}><span className="svg-icon svg-icon-md">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Video-camera.svg")} /></span>
                {` `}Neues Meeting
            </Button>
        </>);
    }

    const openModerator = (id) => {
        axios.get(ep + '/moderator/' + id).then(({ data }) => {
            if (data.message == 'Success') {
                window.open(data.url, '_blank', 'noreferrer');
            }
        }).catch(e => console.log(e));
    };

    const modalClose = () => {
        setShowModal(false);
        setEditId(null);
        setTimeout(() => setFormTypeDelete(false), 300);
    }
    const extModalSubmit = () => {
        setShowModal(false);
        fetchMeetings();
        setEditId(null);
        setTimeout(() => setFormTypeDelete(false), 300);
    }

    useEffect(() => {
        MpAsideSecondary.setAsideEnabled(false);
        subheader.setActions(null);
        contentContainer.reset();
        setTimeout(() => setSubHeaderActions(), 300);
    }, []);

    const momentToIso = (date) => {
        var d = moment(date).toISOString().replaceAll('-', '').replaceAll(':', '').replaceAll('.', '');
        return d;
    }

    const renderContent = () => {
        return <Accordion>{meetings.map((item, index) => {
            return <div className="card card-custom gutter-b" key={index}>
                <Accordion.Toggle as={Card.Header} eventKey={index} className="card-header border-0 bg-transparent px-6 d-block">
                    <h3 className="card-title font-weight-bolder text-dark px-2 justify-content-between">
                        <div className="d-flex align-items-center">
                            <div className="left font-size-sm pr-4">
                                <Moment format="ddd DD.MM" locale="de">{item.start}</Moment><br />
                                <Moment format="HH:mm" locale="de">{item.start}</Moment>
                            </div>
                            <span className={`bullet bullet-bar ${!item.ended ? 'bg-success' : 'bg-dark-o-50'}  align-self-stretch`}></span>
                            <div className="pl-4">
                                {item.name}
                            </div>
                        </div>
                        <div className="card-toolbar">
                            <Button variant="btn btn-icon btn-clean btn-hover-light-danger btn-sm mr-2"
                                onClick={() => { setShowModal(true); setEditId(item.meetingid); setFormTypeDelete('delete') }}
                            ><i className="la la-trash icon-md text-danger"></i></Button>
                        </div>
                    </h3>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={index}>
                    <div className="card-body pt-0 font-size-lg">
                        <Table responsive variant="lg table-vertical-center">
                            <thead>
                                <tr>
                                    <td style={{ width: '50%' }}>Kunden-Link</td>
                                    <td>Start</td>
                                    <td>Ende</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="d-flex">

                                            <CopyToClipboard className="d-print-none cursor-pointer" text={`${guestUrlPrefix}${item.meetingid}`}>
                                                <div className="btn btn-hover-light-primary btn-icon btn-sm mt-n3 ml-n2">
                                                    <i><i className="la la-clipboard"></i></i>
                                                </div>
                                            </CopyToClipboard>
                                            <div className="pl-2">{`${guestUrlPrefix}${item.meetingid}`}</div>
                                        </div>

                                        {/*
                                        https://www.google.com/calendar/render?action=TEMPLATE&text=Titel&details=beschreibung%2Blink&location=link%2Blink&dates=20230301T160400Z%2F20230322T160700Z
                                        */}


                                    </td>
                                    <td>
                                        <Moment format="DD.MM.YYYY HH:mm">{item.start}</Moment>
                                    </td>
                                    <td>
                                        <Moment format="DD.MM.YYYY HH:mm">{item.end}</Moment>
                                    </td>

                                    <td>
                                        {!item.ended &&
                                            <Button variant="success" onClick={() => openModerator(item.meetingid)} >Moderieren</Button>}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <a href={`https://www.google.com/calendar/render?action=TEMPLATE&text=${item.name}&details=${encodeURI("Dies ist Ihre persönliche MeetMindpark Einladung. <br>Nehmen Sie mit folgendem Link teil:<br><a href=\"")}${guestUrlPrefix}${item.meetingid}${encodeURI("\">")}${guestUrlPrefix}${item.meetingid}${encodeURI("</a>")}&dates=${momentToIso(item.start)}%2F${momentToIso(item.end)}`} target="_blank" className="btn btn-sm btn-dark">Google Kalender Eintrag erstellen.</a>
                                    </td>

                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                            {(item.recordings?.returncode === 'SUCCESS' && item.recordings.recordings.recording !== undefined) &&
                                <><thead>
                                    <tr>
                                        <td style={{ width: '50%' }} colSpan="4">Aufname</td>
                                    </tr>
                                </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan="4">

                                                <div className="d-flex">

                                                    <CopyToClipboard className="d-print-none cursor-pointer" text={item.recordings.recordings.recording.playback.format.url}>
                                                        <div className="btn btn-hover-light-primary btn-icon btn-sm mt-n3 ml-n2">
                                                            <i><i className="la la-clipboard"></i></i>
                                                        </div>
                                                    </CopyToClipboard>
                                                    <div className="pl-2">{item.recordings.recordings.recording.playback.format.url}</div>

                                                </div>

                                            </td>
                                        </tr>
                                    </tbody></>
                            }
                        </Table>
                        <div className="px-3">
                            Meeting-Moderationen können erst gestartet werden, sobald der Tag des Start-Datums erreicht ist.<br></br>
                            Abgelaufene oder händisch geschlossene Meetings können nicht neu eröffnet werden.
                        </div>
                    </div>
                </Accordion.Collapse>
            </div>
        })}</Accordion>;
    };


    return <>
        {renderContent()}
        <BBBModal type={formTypeDelete ?? 'delete'} edit={editId} show={showModal} onHide={modalClose} onSubmit={extModalSubmit} />
    </>
}