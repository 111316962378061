import React, { useEffect } from "react";
import axios from "axios";
import { NotificationItem } from "./components/NotificationItem";
import { Button } from "react-bootstrap";

export class NotificationSettings extends React.Component {

    notificationTypes = [
        {
            label: 'Zum Task hinzugefügt / entfernt',
            key: 'taskAddRemove'
        },
        {
            label: 'Änderungen Task Status',
            key: 'taskStatus'
        },
        {
            label: 'Änderungen Task Timings',
            key: 'taskTimings'
        },
        {
            label: 'Änderungen Task Archivierungs-Status',
            key: 'taskArchivated'
        },
        {
            label: 'Task Verschiebung',
            key: 'taskMove'
        },
        {
            label: 'Updates zu Tasks',
            key: 'taskUpdates'
        }

    ];
    state = {
        mail: [],
        push: [],
        mattermost: [],
        loaded: false
    }

    isChecked(type, key) {
        if (type === 'mail')
            return this.state.mail === undefined || this.state.mail === null ? false : this.state.mail.includes(key);
        if (type === 'push')
            return this.state.push === undefined || this.state.push === null ? false : this.state.push.includes(key);
        return this.state.mattermost === undefined || this.state.mattermost === null ? false : this.state.mattermost.includes(key);
    }
    syncItem(type, key) {
        let isChecked = this.isChecked(type, key);
        let array;

        switch (type) {
            case 'mail':
                array = this.state.mail;
                break;
            case 'push':
                array = this.state.push;
                break;
            case 'mattermost':
                array = this.state.mattermost;
                break;
            default:
                break;
        }

        if (array === undefined)
            array = [];
        if (isChecked) {
            var index = array.indexOf(key);
            if (index !== -1) {
                array.splice(index, 1);
            }
        } else {
            array.push(key);
        }
        switch (type) {
            case 'mail':
                this.state.mail = array;
                break;
            case 'push':
                this.state.push = array;
                break;
            case 'mattermost':
                this.state.mattermost = array;
                break;
            default:
                break;
        }
    }

    fetchNotificationSettings() {
        this.setState({ loaded: false });
        return axios.get(process.env.REACT_APP_API_URL + '/api/notificationsettings').then(({ data }) => {
            this.setState({ mail: data.mail });
            this.setState({ push: data.push });
            this.setState({ mattermost: data.mattermost });
            this.setState({ loaded: true });
        }).catch(e => null);
    };

    save() {
        let body = {
            mail: this.state.mail,
            push: this.state.push,
            mattermost: this.state.mattermost,
        }
        return axios.post(process.env.REACT_APP_API_URL + '/api/notificationsettings', body).then(({ data }) => {
            this.fetchNotificationSettings();
        }).catch(e => null);
    };

    componentDidMount() {
        this.fetchNotificationSettings();
    }

    render() {
        return (
            this.state.loaded && <div className="navi navi-icon-circle navi-spacer-x-0">

                <h5 className="font-weight-bold mb-3">E-Mail Benachrichtigungen</h5>
                {this.notificationTypes.map((item, key) => {
                    return <div className="form-group mb-0 row" key={key}>
                        <label className="col-9 col-form-label">
                            {item.label}
                        </label>
                        <div className="col-3 text-right">
                            <span className="switch switch-primary switch-sm">
                                <label>
                                    <input
                                        type="checkbox"
                                        onChange={(e) => {
                                            this.syncItem('mail', item.key)
                                            e.currentTarget.checked = this.isChecked('mail', item.key);
                                        }}
                                        defaultChecked={this.isChecked('mail', item.key)}
                                        name=""
                                    />
                                    <span></span>
                                </label>
                            </span>
                        </div>
                    </div>

                })}
                <h5 className="font-weight-bold mb-3 mt-10">Mattermost Benachrichtigungen</h5>
                {this.notificationTypes.map((item, key) => {
                    return <div className="form-group mb-0 row" key={key}>
                        <label className="col-9 col-form-label">
                            {item.label}
                        </label>
                        <div className="col-3 text-right">
                            <span className="switch switch-primary switch-sm">
                                <label>
                                    <input
                                        type="checkbox"
                                        onChange={(e) => {
                                            this.syncItem('mattermost', item.key)
                                            e.currentTarget.checked = this.isChecked('mattermost', item.key);
                                        }}
                                        defaultChecked={this.isChecked('mattermost', item.key)}
                                        name=""
                                    />
                                    <span></span>
                                </label>
                            </span>
                        </div>
                    </div>

                })}
                <h5 className="font-weight-bold mb-3 mt-10">Push Benachrichtigungen</h5>
                {this.notificationTypes.map((item, key) => {
                    return <div className="form-group mb-0 row" key={key}>
                        <label className="col-9 col-form-label">
                            {item.label}
                        </label>
                        <div className="col-3 text-right">
                            <span className="switch switch-primary switch-sm">
                                <label>
                                    <input
                                        type="checkbox"
                                        onChange={(e) => {
                                            this.syncItem('push', item.key)
                                            e.currentTarget.checked = this.isChecked('push', item.key);
                                        }}
                                        defaultChecked={this.isChecked('push', item.key)}
                                        name=""
                                    />
                                    <span></span>
                                </label>
                            </span>
                        </div>
                    </div>

                })}
                <div className="d-flex justify-content-center mt-10">
                    <Button variant="primary" onClick={() => this.save()}>Speichern</Button>
                </div>
            </div>
        )
    }
}