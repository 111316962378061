import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { toast } from "react-toastify"

export function AdminUserGroupsModal(props) {
    const [groups, setGroups] = useState([]);
    const [roles, setRoles] = useState([]);
    const [prechecked, setPrechecked] = useState([]);

    const permissionCatMap = {
        "admin": "Administration",
        "emailaccounts": "Email Accounts",
        "tasks": "Tasks",
        "tec": "Technischer Bereich",
        "hosts": "Hosting Accounts",
        "customers": "Kunden",
        "misc": "Sonstige Accounts",
        "stempel": "Stempeluhr",
        "projects": "Projekte",
        "intercredcritical" : "Interne Zugangsdaten (kritisch)",
        "intercredown" : "Interne Zugangsdaten (eigene)",
        "intercredgeneral" : "Interne Zugangsdaten (allgemein)",

    }
    const fetchRole = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/roles/' + props.edit).then(({ data }) => {

            formik.setFieldValue('name', (data.name == null ? '' : data.name));
            formik.setFieldValue('desc', (data.desc == null ? '' : data.desc));

            let perms = data.permissions.map(a => a.name);
            setPrechecked(perms);
            formik.setFieldValue("permissions", perms);

        });
    }
    const fetchRoles = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/roles').then(({ data }) => {
            setRoles(data);
        });
    }
    const fetchPermissoins = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/permissions').then(({ data }) => {
            setGroups(data);
        });
    }

    const renderPermissions = () => {
        if (Object.keys(groups).length <= 0)
            return;
        return Object.keys(groups).map((pgroup, pi) => {
            let perm = groups[pgroup];
            return <div className={`groupItem mb-6 ${pi !== Object.keys(groups).length - 1 && "border-bottom"}`} key={pgroup}>
                <div className="gTitle font-size-lg mb-5">
                    {permissionCatMap[pgroup]}
                </div>
                <div className="groupItemInner px-4">

                    <div className="permissionItems">
                        <Form.Row>
                            {perm.map((item, index) => {
                                return <Form.Group as={Col} lg="4" key={index}>
                                    <label className="checkbox checkbox-lg checkbox-lg checkbox-primary">
                                        <input type="checkbox" name="permissions" value={item.name} onChange={handleChange} checked={prechecked.length > 0 && prechecked.includes(item.name)} />
                                        <span className="bg-primary"></span>
                                        <p className="m-0 ml-3 p-0">{item.desc}</p>
                                    </label>
                                </Form.Group>
                            })}
                        </Form.Row>
                    </div>
                </div>
            </div>
        });
    }
    const renderForm = () => {
        return <Form onSubmit={formik.handleSubmit} className={`bg-white position-relative`}>
            <Modal.Body>

                <Form.Row>
                    <Form.Group className="col-lg-6">
                        <Form.Label>Kennung</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Kennung"
                            name="name"
                            value={''}
                            {...formik.getFieldProps("name")}
                            isInvalid={formik.errors.name}
                            disabled={props.edit !== 0}
                        />
                    </Form.Group>
                    <Form.Group className="col-lg-6">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Name"
                            name="desc"
                            value={''}
                            {...formik.getFieldProps("desc")}
                            isInvalid={formik.errors.desc}
                        />
                    </Form.Group>
                </Form.Row>
                {renderPermissions()}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit">Speichern</Button>
                <Button variant="default " onClick={props.onHide}>Schließen</Button>
            </Modal.Footer>
        </Form>
    };

    const deleteData = () => {
        axios.delete(process.env.REACT_APP_API_URL + '/api/roles/' + props.edit).then((d) => {
            toast.success('Benutzer Gruppe gelöscht');
            props.onSubmit();
        }).catch(e => {
            toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
            props.onSubmit();
        });
    }

    const deleteForm = () => {
        return <>
            <Modal.Body>
                <p>Bist du dir sicher dass du diese Benutzer Gruppe löschen möchtest?</p>
                <p className="text-danger">Achtung: alle Benutzer die dieser Gruppe zugeteilt sind, verlieren alle Berechtigungen zum System. Um das weitere Arbeiten zu ermmöglichen, musst du in den Benutzer Einstellungen, jeweils eine neue Gruppe wählen.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={deleteData}>Ja, Benutzer Gruppe löschen </Button>
                <Button variant="default " onClick={props.onHide}>Nein, Benutzer Gruppe nicht löschen </Button>
            </Modal.Footer>

        </>
    }

    const handleChange = (e) => {
        const { checked, value } = e.target;
        if (checked) {
            formik.setFieldValue("permissions", [...formik.values.permissions, value]);
            setPrechecked(prechecked => [...prechecked, value]);
        } else {
            formik.setFieldValue(
                "permissions",
                formik.values.permissions.filter((v) => v !== value)
            );
            setPrechecked(prechecked.filter((v) => v !== value));

        }
    };
    const saveNew = (values) => {
        let body = values;
        console.log(body);
        return axios.post(process.env.REACT_APP_API_URL + '/api/roles', body);
    };

    const updateRole = (values) => {
        let body = values;
        return axios.post(process.env.REACT_APP_API_URL + '/api/roles/' + props.edit, body);
    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            permissions: []
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required()
                .test({
                    name: 'unique-group-name',
                    test: (value) => {
                        if (props.edit !== 0)
                            return true;
                        if (value === undefined)
                            return false;
                        let existing = roles.filter(r => {
                            return r.name.toLowerCase() === value.toLowerCase();
                        });
                        return existing.length <= 0;
                    }
                }),
            desc: Yup.string().required(),
        }),
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            setSubmitting(false);

            if (props.edit === 0) {
                saveNew(values).then(d => { toast.success("Gespeichert"); resetForm(); props.onSubmit() }).catch(e => {
                    toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
                    props.onSubmit();
                });;
            } else {
                updateRole(values).then(d => { toast.success("Gespeichert"); resetForm(); props.onSubmit() }).catch(e => {
                    toast.error("Es ist ein Fehler aufgetreten oder du bist für diese Aktion nicht berechtigt. Versuche es erneut oder wende dich an einen Administrator.", { autoClose: 5500 });
                    props.onSubmit();
                });;
            }

            setSubmitting(true);

        }
    });

    useEffect(() => {
        fetchRoles();
        fetchPermissoins();
    }, []);
    useEffect(() => {
        if (props.edit !== 0)
            fetchRole();
    }, [props.edit]);

    return <>
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={props.onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Benutzer Gruppe
                    {
                        (props.type && props.type === 'delete') ? ' löschen' :
                            (props.edit === 0) ? ' erstellen' : ' bearbeiten'
                    }
                </Modal.Title>
            </Modal.Header>
            {(props.type && props.type === 'delete') ? deleteForm() : renderForm()}
        </Modal>
    </>
}