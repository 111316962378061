import React, { useEffect, useState } from "react";
import { usePageTitle } from "../../../../providers/PageTitleProvider";
import { useSubheader } from "../../../../_metronic/layout";
import { useAsideSecondary } from "../../../../_metronic/layout/_core/MpAsideSecondary";
import axios from "axios";
import Moment from "react-moment";
import CopyToClipboard from "react-copy-to-clipboard";
import { StempeluhrChipsSelect } from "../components/adminBetrieb/StempeluhrChipsSelect";

export function StempeluhrChips(props) {

    const subheader = useSubheader();
    const MpAsideSecondary = useAsideSecondary();
    const pageTitle = usePageTitle();

    const [chips, setChips] = useState([]);

    const fetchChips = () => {
        axios.get(process.env.REACT_APP_API_URL + '/api/stempelchips').then(({ data }) => {
            let dd = Object.values(data);
            dd.sort((a, b) => a.lastseen.localeCompare(b.lastseen));

            setChips(dd.reverse());
        }).catch(e => console.log(e));
    }
    useEffect(() => {
        subheader.setActions(null);
        subheader.setTitle("NFC Chip Verwaltung");
        pageTitle.setPageTitle(`🐮 NFC Chip Verwaltung`);
        //fetchVacations();
        MpAsideSecondary.setAsideEnabled(true);

        fetchChips();
    }, []);
    return <>
        <div className="row">
            {chips.length > 0 && chips.map((chip, i) => {
                return <div className="col-lg-6" key={i}>
                    <div className="card card-custom gutter-b">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-3">
                                    <small className="mb-4 mb-lg-7 d-block">Code</small>
                                    <div className="d-flex">
                                        <CopyToClipboard className="d-print-none cursor-pointer" text={chip.code}>
                                            <div>
                                                <div className="btn btn-hover-light-primary btn-icon btn-sm mt-n2 ml-n2">
                                                    <i><i className="la la-clipboard"></i></i>
                                                </div>
                                            </div>
                                        </CopyToClipboard>
                                        <div className="pl-1">{chip.code}</div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <small className="mb-4 mb-lg-7 d-block">Zuletzt gesehen</small>
                                    <Moment format="DD.MM.YYYY HH:mm:ss">{chip.lastseen}</Moment>
                                </div>
                                <div className="col-6">
                                    <small className="mb-4 d-block">User</small>
                                    <StempeluhrChipsSelect chip={chip} refetch={fetchChips} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            })}
        </div>
    </>
}